import mitt from 'mitt'

type ApplicationEvents = {
  'popper:event': { type: 'close' | 'next' | 'finish' }
  'cart:add-product': void
}

const emitter = mitt<ApplicationEvents>()

export const useEvent = emitter.emit
export const useListen = emitter.on
