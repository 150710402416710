import { SCREENS_TITLES } from '~/lib/models/data-onboarding'

export const useEnrollmentScreens = () => {
  const authStore = useAuthStore()
  if (!authStore.isUserLogged) return []

  const appStore = useAppStore()
  const screens = appStore.enrollmentScreens

  if (authStore.userHasBirthdateStr) {
    const screensWithoutBirthday = screens.filter(({ title }) => title !== SCREENS_TITLES.BIRTHDATE)

    const orderedScreens = screensWithoutBirthday.map((screen, index) => ({
      ...screen,
      index: index + 1,
      active: index === 0, // set active first screen
      completed: false,
    }))

    return orderedScreens
  }

  return screens.map((screen, index) => ({
    ...screen,
    active: index === 0, // set active first screen
    completed: false,
  }))
}
