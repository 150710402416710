import { StoreService } from '../store.service'
import {
  CreateOrderBody,
  createOrderDto,
  getOrderByIdDto,
  pendingOrdersDto,
  repeatOrderDto,
  payPendingOrderResponseDto,
  finishedOrdersDto,
  getPickupPermittedAreasDto,
  SetPickupCustomerOnRestaurantBody,
  PayPendingOrderBodyDto,
  cancelOrderDto,
  RateOrderBody,
  rateOrderResponseDto,
  storeOrderSchema,
  requestReceiveOrderDto,
} from './order.dto'
import { RestaurantAreas } from '~/lib/interfaces/restaurant'

class OrderService extends StoreService {
  constructor(country: string) {
    super('order', country)
  }

  async createOrder({
    customerToken,
    countryCode,
    order,
  }: {
    customerToken: string
    countryCode: string
    order: CreateOrderBody
  }) {
    const res = await this.request(
      {
        method: 'POST',
        url: `/orders`,
        data: order,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
      },
      createOrderDto
    )
    return res
  }

  async setPickupCustomerOnRestaurant({
    customerToken,
    countryCode,
    flexOrder,
  }: {
    customerToken: string
    countryCode: string
    flexOrder: SetPickupCustomerOnRestaurantBody
  }) {
    const { orderId, ...data } = flexOrder
    const res = await this.request(
      {
        method: 'POST',
        url: `/orders/${orderId}/pickup`,
        data,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
      },
      storeOrderSchema
    )
    return res
  }

  async requestReceiveOrder({
    customerToken,
    countryCode,
    orderId,
  }: {
    customerToken: string
    countryCode: string
    orderId: string
  }) {
    const res = await this.request(
      {
        method: 'POST',
        url: `/orders/${orderId}/delivery`,
        data: {
          area: RestaurantAreas.DLV,
        },
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
      },
      requestReceiveOrderDto
    )
    return res
  }

  async getFinishedByUser({
    customerToken,
    countryCode,
    page,
  }: {
    customerToken: string
    countryCode: string
    page?: number
  }) {
    const res = await this.request(
      {
        url: `/orders/finished`,
        params: { page },
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
      },
      finishedOrdersDto
    )
    return res
  }

  async getPickupPermittedAreas({
    customerToken,
    countryCode,
    orderId,
  }: {
    customerToken: string
    countryCode: string
    orderId: string
  }) {
    const res = await this.request(
      {
        url: `/orders/${orderId}/pickup/permitted-areas`,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
      },
      getPickupPermittedAreasDto
    )
    return res
  }

  async getOrderById({
    customerToken,
    countryCode,
    orderId,
  }: {
    customerToken: string
    countryCode: string
    orderId: string
  }) {
    const res = await this.request(
      {
        url: `/orders/${orderId}`,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
      },
      getOrderByIdDto
    )
    return res
  }

  async getPendingOrdersByUser({
    customerToken,
    countryCode,
  }: {
    customerToken: string
    countryCode: string
  }) {
    const res = await this.request(
      {
        url: `/orders/pending`,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
      },
      pendingOrdersDto
    )
    return res
  }

  async payPendingOrder({
    customerToken,
    countryCode,
    payPendingInput,
  }: {
    customerToken: string
    countryCode: string
    payPendingInput: PayPendingOrderBodyDto
  }) {
    const res = await this.request(
      {
        method: 'POST',
        url: `/orders/payment`,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
        data: payPendingInput,
      },
      payPendingOrderResponseDto
    )
    return res
  }

  async cancelOrder({
    customerToken,
    countryCode,
    orderId,
  }: {
    customerToken: string
    countryCode: string
    orderId: string
  }) {
    const res = await this.request(
      {
        url: `/orders/${orderId}/cancel`,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
      },
      cancelOrderDto
    )
    return res
  }

  async rateOrder({
    countryCode,
    orderId,
    rating,
  }: {
    countryCode: string
    orderId: string
    rating: RateOrderBody
  }) {
    const res = await this.request(
      {
        method: 'POST',
        url: `/orders/${orderId}/v2/web-rate`,
        headers: { 'x-app-country': countryCode },
        data: {
          ...rating,
        },
      },
      rateOrderResponseDto
    )

    return res
  }

  async repeatOrder({
    customerToken,
    countryCode,
    orderId,
  }: {
    customerToken: string
    countryCode: string
    orderId: string
  }) {
    const res = await this.request(
      {
        url: `/orders/${orderId}/repeat`,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
      },
      repeatOrderDto
    )
    return res
  }
}

export const orderService = (country: string) => new OrderService(country)
