import {
  Configuration,
  ConfigurationKeyUnion,
} from '~/lib/services/content/content.configuaration.dtos'

export const useConfiguration = <Key extends ConfigurationKeyUnion>(key: Key) => {
  const contentStore = useContentStore()
  const config = contentStore.site?.configuration
  if (!config) return undefined
  return config.find(
    (
      configElement
    ): configElement is Extract<Configuration, { __component: `configuration.${Key}` }> =>
      configElement.__component === `configuration.${key}`
  )
}
