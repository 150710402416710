import * as z from 'zod'
import { productEcommerceDto } from '../store/catalog/catalog.dtos'
import { basePriceWithTotalSchema } from '../store/order/order.dto'
import { productDTOschema } from '../store/common.dto'
import { RestaurantAreaType } from '~/lib/interfaces/restaurant'

/* Promotion */

export const PromotionKind = {
  TAG: 'TAG',
  COUPON: 'COUPON',
} as const

export type PromotionKindType = (typeof PromotionKind)[keyof typeof PromotionKind]

export const PromotionType = {
  ORDER_AMOUNT: 'ORDER_AMOUNT',
  ORDER_PERCENTAGE: 'ORDER_PERCENTAGE',
  PRODUCT_AMOUNT: 'PRODUCT_AMOUNT',
  PRODUCT_PERCENTAGE: 'PRODUCT_PERCENTAGE',
  PRODUCT_FIXED_PRICE: 'PRODUCT_FIXED_PRICE',
  SHIPPING_PERCENTAGE: 'SHIPPING_PERCENTAGE',
} as const

export const promotionTypeUnion = z.union([
  z.literal(PromotionType.ORDER_AMOUNT),
  z.literal(PromotionType.ORDER_PERCENTAGE),
  z.literal(PromotionType.PRODUCT_AMOUNT),
  z.literal(PromotionType.PRODUCT_PERCENTAGE),
  z.literal(PromotionType.PRODUCT_FIXED_PRICE),
  z.literal(PromotionType.SHIPPING_PERCENTAGE),
])

export type promotionTypeType = (typeof PromotionType)[keyof typeof PromotionType]

export const promoProdDto = z.object({
  id: z.string(),
  name: z.string(),
  qty: z.number(),
  amount: z.number().optional(),
})

const promoProdInputDto = z.object({
  id: z.string(),
  qty: z.number(),
  isPromo: z.boolean().optional(),
})

const promoDiscountDto = z.object({
  applyWithoutAllProducts: z.boolean().optional().nullable(),
  amount: z.number().optional(),
  qty: z.number().optional(),
  all: z.boolean().optional(),
})

export type PromoProductInput = z.infer<typeof promoProdInputDto>

export type PromoProduct = z.infer<typeof promoProdDto>

export const promotionDto = z.object({
  // always present on check-code
  enabled: z.boolean(),
  valid: z.boolean().optional(), // on tag-list may not come
  status: z.number().optional(), // on tag-list may not come

  promotionId: z.string(),
  type: promotionTypeUnion,
  code: z.string().optional(),
  name: z.string(),
  // amount only on promos on order price
  amount: z.number().optional(),
  montoMaxDiscount: z.number().optional(),
  clientSegmentation: z.array(z.string()).optional(),
  products: z.array(promoProdDto).optional(),
  productsRequired: z.array(promoProdDto),
  catalogProducts: z.array(productEcommerceDto),
  termsAndConditions: z.string().optional(),
  infoText: z.string().optional(),

  // only on tag-list
  selfApply: z.boolean().optional(),
  // promotion discount
  discount: promoDiscountDto.optional(),
})
export type Promotion = z.infer<typeof promotionDto>

/* Check code */

export const validDto = z.object({
  enabled: z.boolean(),
  valid: z.boolean(),
  status: z.number(),
})

export const checkCodeDto = z.any()

/* Tag list */
export const tagListDto = z.object({
  promotions: z.array(promotionDto),
})

const checkPromoProductDto = promoProdDto.extend({
  qty: z.number().nullable(),
  amount: z.number().nullable(),
})

/* Check-promos */
export const checkPromosDto = z.array(
  z.object({
    promotionId: z.string(),
    name: z.string().optional(),
    type: z.string().optional(),
    products: z.array(checkPromoProductDto).optional(),
    amount: z.number().optional(),
    montoMaxDiscount: z.number().optional(),
    enabled: z.boolean(),
    productsRequired: z.array(z.any()),
    termsAndConditions: z.string().optional(),
    valid: z.boolean(),
    status: z.number(),
    code: z.string().optional(),
  })
)

/* Input Dto */
type PriceDto = z.infer<typeof basePriceWithTotalSchema>

export type PromoInput = {
  customerToken: string
  countryCode: string
  area: RestaurantAreaType
  restaurant: string
  price: PriceDto
  products: PromoProductInput[]
}

export type PromoCheckPromoInput = {
  customerToken: string
  countryCode: string
  area: RestaurantAreaType
  restaurant: string
  promotionsId: string[]
  products?: string[] // only ids
}

/* Input for /cart */

export const promoCartDto = z.object({
  id: z.string(),
  basicProperties: z.object({
    enabled: z.boolean(),
  }),
  criteria: z.object({
    products: z.array(promoProdDto),
  }),
  discount: z.object({
    type: promotionTypeUnion,
    amount: z.number().optional(),
    products: z.array(promoProdDto).optional(),
    montoMaxDiscount: z.number(),
  }),
})

export const promotionsCartDto = z.array(
  z.object({
    promotion: promoCartDto,
    coupon: z.string().optional(),
    products: z.array(productDTOschema).optional(),
  })
)
