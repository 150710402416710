import axios from 'axios'
import {
  CustomerMetadata,
  IMFiscalData,
  customerDto,
  favoriteAddressesDto,
  requestValidateConfirmDto,
  requestValidateDto,
  imFiscalDataListDto,
  removeTokenDto,
} from '../store/auth.dto'
import { BaseService } from '../base.service'
import { Address } from '../store/order/order.dto'
import { zAny } from '../store/common.dto'
import { UserDevice, userDevicesDto } from './devices.dto'
import { publicConfig } from '~/config/public'
import logRequests from '~/lib/axiosUtils'
import { CUSTOM_IM_ANALYTICS_ERRORS } from '~/lib/models/gtm'
import { extractCustomError } from '~/utils/gtmUtils'

class ImService extends BaseService {
  readonly client
  readonly baseURL

  constructor() {
    super()
    this.baseURL = publicConfig.services.im.url
    this.client = axios.create({
      baseURL: publicConfig.services.im.url,
    })
    this.client.interceptors.response.use(
      res => res,
      error => {
        const authStore = useAuthStore()
        const { trackCustomError } = useGTM()
        trackCustomError(
          extractCustomError({ error, customHttpStatus: CUSTOM_IM_ANALYTICS_ERRORS.IM_API_ERROR })
        )
        if (error.response?.status === 401 || error.response?.status === 403) {
          authStore.removeToken()
        }
        return Promise.reject(error)
      }
    )
    logRequests('im', this.client)
  }

  getProfile(accessToken: string) {
    return this.request(
      {
        method: 'get',
        url: '/profile',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
      customerDto
    )
  }

  logout(accessToken: string) {
    return this.client({
      method: 'post',
      url: '/logout',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
  }

  async setIMProfile({
    accessToken,
    metadata,
    checkCpf,
  }: {
    accessToken: string
    metadata: CustomerMetadata
    checkCpf?: boolean
  }) {
    return this.request(
      {
        method: 'patch',
        url: '/profile',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: { checkCpf, metadata },
      },
      customerDto
    )
  }

  // Favorite addresses
  async saveFavoriteAddress({
    accessToken,
    address,
    country,
  }: {
    accessToken: string
    address: Address
    country: string
  }) {
    return this.request(
      {
        method: 'POST',
        url: '/profile/addresses',
        data: {
          ...address,
          country,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      },
      favoriteAddressesDto
    )
  }

  /* Validation (phone, device, email...) */
  async validateData<T>({ accessToken, data }: { accessToken: string; data: T }) {
    return this.request(
      {
        method: 'POST',
        url: '/profile/data-validation-request',
        data,
        headers: { Authorization: `Bearer ${accessToken}` },
      },
      requestValidateDto
    )
  }

  async validateDataConfirm<T>({ accessToken, data }: { accessToken: string; data: T }) {
    return this.request(
      {
        method: 'POST',
        url: '/profile/data-confirmation-request',
        data,
        headers: { Authorization: `Bearer ${accessToken}` },
      },
      requestValidateConfirmDto // no body response for now
    )
  }

  /* Fiscal fields */
  async fetchFiscalFields({ accessToken, country }: { accessToken: string; country: string }) {
    return this.request(
      {
        url: `/profile/fiscalData?country=${country}`,
        headers: { Authorization: `Bearer ${accessToken}` },
      },
      imFiscalDataListDto
    )
  }

  async saveFiscalFields({
    accessToken,
    fiscalData,
  }: {
    accessToken: string
    fiscalData: IMFiscalData
  }) {
    return this.request(
      {
        method: 'POST',
        url: '/profile/fiscalData',
        data: fiscalData,
        headers: { Authorization: `Bearer ${accessToken}` },
      },
      imFiscalDataListDto
    )
  }

  async updateFiscalFields({
    accessToken,
    fiscalData,
    idFiscalFields,
  }: {
    accessToken: string
    fiscalData: IMFiscalData
    idFiscalFields: string
  }) {
    return this.request(
      {
        method: 'PATCH',
        url: `/profile/fiscalData/${idFiscalFields}`,
        data: fiscalData,
        headers: { Authorization: `Bearer ${accessToken}` },
      },
      imFiscalDataListDto
    )
  }

  getUserDevices({ accessToken }: { accessToken: string }): Promise<{ docs: UserDevice[] }> {
    return this.request(
      {
        method: 'get',
        url: '/profile/devices?sort=-updatedAt&limit=20&paginate=true&page=1',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
      userDevicesDto
    )
  }

  deleteUserDevices({
    accessToken,
    deviceIdentifiers,
  }: {
    accessToken: string
    deviceIdentifiers: string[]
  }): Promise<{ docs: UserDevice[] }> {
    return this.request(
      {
        method: 'POST',
        url: '/profile/close-device-sessions',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: { deviceIdentifiers },
      },
      userDevicesDto
    )
  }

  deleteAllUserSessions({ accessToken }: { accessToken: string }): Promise<{ docs: UserDevice[] }> {
    return this.request(
      {
        method: 'DELETE',
        url: '/profile/close-all-sessions',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
      zAny // empty response 204
    )
  }

  removeTokenCookie() {
    return this.request(
      {
        method: 'POST',
        baseURL: '',
        url: '/api/token',
      },
      removeTokenDto
    )
  }
}

export const imService = new ImService()
