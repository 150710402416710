import { AxiosError } from 'axios'
import get from 'lodash/get'
import {
  GTMEcommerceItem,
  GTMContentGroup,
  GTMViewContentGroup,
  GTMProductTypes,
  tagEmployedCoupon,
  freeShippingCoupon,
  GTMReducedPromo,
  GTMValidationType,
  GTMLocationType,
  GTMFormNameType,
  GTMLValidationTypes,
  GTMLocationTypes,
  GTMFormNameTypes,
  GtmProductType,
  GTM_ERRORS,
} from '~/lib/models/gtm'
import { PromotionType } from '~/lib/services/promos/promos.dto'
import { CartProduct } from '~/lib/services/store/catalog/cart.dtos'
import {
  ProductEcommerce,
  ProductEcommerceLite,
  ProductType,
  ProductTypes,
} from '~/lib/services/store/catalog/catalog.dtos'
import { ProductOrder } from '~/lib/services/store/order/order.dto'

export const createGTMScript = (containerId: string) => {
  const gtmContainerId = isCypressSpecialBehaviour() ? 'XXX-YYYYYYY' : containerId
  return `
  (function(w,d,s,l,i){
    w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmContainerId}');`
}

export const generatePageTitle = (content: GTMViewContentGroup) => {
  if (content.pageTitle) return content.pageTitle
  const arr = [content.cg, content.cg2, content.cg3].filter(x => x)
  return arr.join('/')
}

export const formatAmountGTM = (qty: number) => qty / 100

export const gtmEcommerceItemFromLite = ({
  product,
  categoryTitle,
  itemListName,
  index,
  currency,
}: {
  product: ProductEcommerceLite
  categoryTitle: string
  itemListName?: string
  index: number
  currency: string
}): GTMEcommerceItem => {
  const price = product.price.amount
    ? formatAmountGTM(product.price.amount)
    : formatAmountGTM(product.unifiedPrice?.amount || 0)

  return {
    ...new GTMEcommerceItem(),
    index,
    currency,
    item_name: product.name,
    item_id: product.identifier,
    item_list_name: itemListName,
    price,
    quantity: 1,
    loyalty: false,
    points: 0,
    item_category: categoryTitle?.toLowerCase(),
  }
}

export const gtmEcommerceItemFromProduct = ({
  product,
  categoryTitle,
  currency,
}: {
  product: ProductEcommerce
  categoryTitle?: string
  currency: string
}): GTMEcommerceItem => {
  const price = product.price.amount
    ? formatAmountGTM(product.price.amount)
    : formatAmountGTM(product.unifiedPrice?.amount || 0)

  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: product.name,
    item_id: product.identifier,
    price,
    quantity: 1,
    loyalty: false,
    points: product.points || 0,
    item_category: categoryTitle?.toLowerCase(),
    pre_order: !!product.mcDia,
  }
}

export const gtmEcommerceItemFromCartProduct = ({
  cartProduct,
  currency,
  coupon,
}: {
  cartProduct: CartProduct
  currency: string
  coupon?: string
}): GTMEcommerceItem => {
  const getGTMCoupon = (couponGTM?: string) => {
    if (!couponGTM) return undefined
    else if (cartProduct.isPromo && couponGTM !== freeShippingCoupon) return couponGTM
    else if (
      cartProduct.promotions?.some(
        promo => promo.type === PromotionType.SHIPPING_PERCENTAGE && promo.amount === 100
      )
    )
      return freeShippingCoupon
    return undefined
  }
  const price = formatAmountGTM(cartProduct.computedPrice.priceUnit)
  const discount = cartProduct.isPromo
    ? formatAmountGTM(cartProduct.computedPrice.discountByUnit || 0)
    : 0

  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: cartProduct.name,
    item_id: cartProduct.identifier,
    price,
    quantity: cartProduct.unit,
    loyalty: false,
    item_category: cartProduct?.categoryTitle.toLowerCase(),
    // promos
    discount,
    coupon: getGTMCoupon(coupon),
    item_list_name: itemListNameFromProductType(cartProduct.productType as ProductType),
    pre_order: !!cartProduct.isMcDia,
  }
}

export const gtmEcommerceItemFromOrderProduct = ({
  orderProduct,
  currency,
  isPreOrder,
  coupon,
}: {
  orderProduct: ProductOrder
  currency: string
  isPreOrder: boolean
  coupon?: string
}): GTMEcommerceItem => {
  const getGTMCoupon = (couponGTM?: string) => {
    if (!couponGTM) return undefined
    else if (orderProduct.isPromo && couponGTM !== freeShippingCoupon) return couponGTM
    else if (
      orderProduct.promotions?.some(
        promo => promo.type === PromotionType.SHIPPING_PERCENTAGE && promo.amount === 100
      )
    )
      return freeShippingCoupon
    return undefined
  }
  const price = formatAmountGTM(orderProduct.priceUnit || 0)
  const discount = orderProduct.isPromo
    ? formatAmountGTM(orderProduct.discount / orderProduct.unit)
    : 0

  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: orderProduct.name,
    item_id: orderProduct.id,
    price,
    quantity: orderProduct.unit,
    loyalty: false,
    item_category: orderProduct?.categoryName.toLowerCase(),
    // promos
    discount,
    coupon: getGTMCoupon(coupon),
    item_list_name: itemListNameFromProductType(orderProduct.productType as ProductType),
    pre_order: isPreOrder,
  }
}

export const extractCustomError = ({
  error,
  customHttpStatus = undefined,
}: {
  error: AxiosError
  customHttpStatus?: number
}) => {
  const gtmError = GTM_ERRORS.find(val => val.url === error.config?.url)
  if (gtmError) {
    return {
      type: gtmError.type,
    }
  }

  // defaults
  const defaultStatusCode = 500
  const defaultMessage = 'Internal Server Error'

  const errResponse = error?.response || undefined

  // status
  const statusCode = get(errResponse, 'status')

  if (!errResponse || !statusCode)
    return { code: `${defaultStatusCode}`, type: error?.message || defaultMessage }

  // messages
  const message = get(errResponse, 'data.message')
  // IM message
  const errors = get(errResponse, 'data.errors', {})
  const IMErrorMessageKey = Object.keys(errors).length
    ? Object.keys(errors)[0]
    : get(errResponse, 'data.code', '')
  const stringifiedResponse = JSON.stringify(get(errResponse, 'data', defaultMessage))

  const code = customHttpStatus || statusCode || defaultStatusCode
  const type = IMErrorMessageKey || message || stringifiedResponse

  return {
    code: `${code}`,
    type,
  }
}

export const secondaryContentFromType = (type: 'phone' | 'email' | 'device') => {
  if (type === 'phone') return GTMContentGroup.secondary.PHONE
  else if (type === 'device') return GTMContentGroup.secondary.DEVICE
  else return GTMContentGroup.secondary.EMAIL
}

const getParsedCookie = (): string | undefined => {
  const pattern = /_ga=(.+?)\./
  const match = document.cookie.match(pattern)
  if (!match) return undefined
  const _gaCookie = useCookie('_ga')
  return _gaCookie.value || undefined
}

export const getAnalyticsClientId = () => {
  if (!process.client) return undefined
  if (isCypressSpecialBehaviour()) return 'ga4-client-id'
  const cookie = getParsedCookie()
  if (!cookie) return undefined
  const [, , sessionId, sessionNumber] = cookie.split('.')
  return `${sessionId}.${sessionNumber}`
}

export const itemListNameFromProductType = (type: ProductType | null) => {
  switch (type) {
    case ProductTypes.CROSSSELLING:
      return GTMProductTypes.CROSS_SELLING_CMS
    case ProductTypes.CROSSSELLING_NBO:
      return GTMProductTypes.CROSS_SELLING_NBO
    default:
      return undefined
  }
}

export const getItemListNameTypeFromProducts = (
  products: GTMEcommerceItem[]
): GtmProductType | undefined => {
  let crossSellingCMS = false
  let crossSellingNBO = false
  for (const product of products) {
    if (product.item_list_name === GTMProductTypes.CROSS_SELLING_CMS) crossSellingCMS = true
    if (product.item_list_name === GTMProductTypes.CROSS_SELLING_NBO) crossSellingNBO = true
    if (crossSellingCMS && crossSellingNBO) return GTMProductTypes.CROSS_SELLING_MIX
  }
  if (crossSellingCMS) return GTMProductTypes.CROSS_SELLING_CMS
  if (crossSellingNBO) return GTMProductTypes.CROSS_SELLING_NBO
  return undefined
}

export const extractCouponsFromPromotions = ({
  promotions,
  hasFreeShipping,
}: {
  promotions: Array<GTMReducedPromo>
  hasFreeShipping: boolean
}) => {
  if (!promotions?.length) {
    return {
      coupon: hasFreeShipping ? freeShippingCoupon : undefined,
      coupon2: undefined,
      coupon3: undefined,
    }
  }
  const [firstPromo, secondPromo] = promotions
  let coupon = firstPromo ? firstPromo?.code || tagEmployedCoupon : undefined
  let coupon2 = secondPromo ? secondPromo?.code || tagEmployedCoupon : undefined
  let coupon3

  if (hasFreeShipping) {
    switch (true) {
      case !coupon:
        coupon = freeShippingCoupon
        break
      case coupon && !coupon2:
        coupon2 = freeShippingCoupon
        break
      case !!(coupon && coupon2):
        coupon3 = freeShippingCoupon
        break
    }
  }
  return { coupon, coupon2, coupon3 }
}

export const getValidationFormName = (
  type: GTMLValidationTypes,
  location: GTMLocationTypes
): GTMFormNameTypes | undefined => {
  switch (true) {
    case type === GTMValidationType.DEVICE && location === GTMLocationType.CART:
      // 'verify_device'
      return GTMFormNameType.VERIFY_DEVICE
    case type === GTMValidationType.PHONE && location === GTMLocationType.CART:
      // 'verify_phone'
      return GTMFormNameType.VERIFY_PHONE
    case (type === GTMValidationType.PHONE || type === GTMValidationType.EMAIL) &&
      location === GTMLocationType.PROFILE_PHONE:
      // 'verify_phone_change'
      return GTMFormNameType.PHONE_CHANGE
    case (type === GTMValidationType.PHONE || type === GTMValidationType.EMAIL) &&
      location === GTMLocationType.ENROLL_PHONE:
      // 'verify_enroll_phone'
      return GTMFormNameType.ENROLL_PHONE
    case type === GTMValidationType.EMAIL && location === GTMLocationType.PROFILE_EMAIL:
      // 'verify_email_change'
      return GTMFormNameType.EMAIL_CHANGE
    default:
      return undefined
  }
}
