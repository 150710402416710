import { getHead } from '~/lib/head'

export const useDynamicHead = () => {
  const contentStore = useContentStore()
  const domain = useSiteDomain({
    protocol: true,
    noOverride: true,
  })
  const appStore = useAppStore()
  const router = useRouter()
  const { t } = useLocale()

  const head = computed(() =>
    getHead({
      site: contentStore.site,
      page: contentStore.page,
      locale: appStore.language,
      origin: domain,
      t,
      router: {
        routes: router.options.routes,
        ...router.currentRoute.value,
      },
    })
  )
  useHead(head)
}
