import axios from 'axios'
import { BaseService } from '../base.service'
import { publicConfig } from '~/config/public'
import logRequests from '~/lib/axiosUtils'
import { extractCustomError } from '~/utils/gtmUtils'

export class StoreService extends BaseService {
  readonly client
  baseUrl: string

  constructor(serviceName: string, country: string) {
    super()

    const baseUrl = publicConfig.services.store.url.replace(
      '{countryCode}',
      country ? country.toLowerCase() : 'uy'
    )
    this.baseUrl = baseUrl

    this.client = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'x-app-version': 'web-2.0.0',
      },
    })
    this.client.interceptors.response.use(
      res => res,
      error => {
        const { trackCustomError } = useGTM()
        trackCustomError(extractCustomError({ error }))
        return Promise.reject(error)
      }
    )
    logRequests(`store:${serviceName}`, this.client)
  }
}
