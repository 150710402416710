<script setup lang="ts">
withDefaults(defineProps<{ big?: boolean }>(), {
  big: false,
})

const { t } = useLocale()
</script>

<template>
  <div
    class="flex w-fit flex-row gap-2 rounded-xl bg-mcd-tertiaryBlueDisabled px-2 py-[2px]"
    :class="{ 'py-[4px]': big }"
    data-test="free-shipping-bubble"
  >
    <img src="/images/icons/delivery-blue.svg" class="h-4 w-4" alt="delivery free" />
    <p class="whitespace-nowrap text-xs font-bold leading-4 text-mcd-secondaryBlue">
      {{ t('common.freeShippingLabel') }}
    </p>
  </div>
</template>
