import axios from 'axios'
import { BaseService } from '../base.service'
import {
  CategoryMdw,
  CategoryListMdw,
  categoriesListMdwApiDto,
  categoryDetailListApiMdwDto,
  categoryDetailComboApiMdwDto,
  ProductMdw,
  restaurantMdwDto,
  restaurantDetailDto,
  // MdwConfig,
  countryConfigResponse,
  mdwCountry,
} from './middleware.dtos'
import { publicConfig } from '~/config/public'
import logRequests from '~/lib/axiosUtils'

class MiddlewareService extends BaseService {
  readonly client
  country = 'UY'

  constructor(countryCode: string) {
    super()
    const baseURL = publicConfig.services.middleware.url
    this.country = countryCode
    this.client = axios.create({
      baseURL,
    })
    logRequests('middleware', this.client)
  }

  async fetchConfig({ language }: { language: string }) {
    const lang = language.split('_')[0]
    try {
      const { data } = await this.request(
        {
          url: '/api/country',
          headers: {
            'X-app-country': this.country,
            'X-app-version': 'ANDROID_3.39.0',
            'X-app-language': lang,
          },
        },
        countryConfigResponse
      )
      const parsed = decryptMdwConfig(data)
      if (parsed) return await mdwCountry.parseAsync(parsed)
      return undefined
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(
        '🚀 ~ file: middleware.service.ts:60 ~ MiddlewareService ~ fetchConfig ~ error:',
        error
      )
      return undefined
    }
  }

  async fetchStaticCategories() {
    return this.request(
      {
        url: '/api/product/list/categories',
        headers: {
          'x-app-country': this.country,
        },
      },
      categoriesListMdwApiDto
    )
  }

  async fetchCategoryDetailList(category: CategoryListMdw) {
    const { data: products } = await this.request(
      {
        url: `/api/product/list/${category._id}`,
        headers: {
          'x-app-country': this.country,
        },
        params: {
          options: 'noSiblings,slugAllergens,noSubcategory,routeAbsoluteImage,comboName',
        },
      },
      categoryDetailListApiMdwDto
    )

    const categoryDetail: CategoryMdw = {
      ...category,
      products,
    }

    return categoryDetail
  }

  // combos endpoint - WIP
  // WIP - right now we take all product options into plain array - TBD
  async fetchCategoryDetailCombo(category: CategoryListMdw) {
    const { data } = await this.request(
      {
        url: `/api/product/combos/${category._id}`,
        headers: {
          'x-app-country': this.country,
        },
        params: {
          options: 'noSiblings,slugAllergens,noSubcategory,routeAbsoluteImage,comboName',
        },
      },
      categoryDetailComboApiMdwDto
    )

    const plainProducts: ProductMdw[] = []
    data.forEach(option => {
      plainProducts.push(...option.items.map(item => ({ ...item, subsection: option.name })))
    })

    const categoryDetail: CategoryMdw = {
      ...category,
      products: plainProducts,
    }

    return categoryDetail
  }

  async fetchRestauarnList(countryCode: string) {
    const { data } = await this.request(
      {
        url: '/api/restaurant/list',
        headers: {
          'x-app-country': countryCode,
        },
      },
      restaurantMdwDto
    )
    return data
  }

  async fetchRestaurantDetail({
    restaurantCode,
    countryCode,
  }: {
    restaurantCode: string
    countryCode: string
  }) {
    const { data } = await this.request(
      {
        url: `/api/restaurant/info/${restaurantCode}`,
        headers: {
          'x-app-country': countryCode,
        },
        params: {
          detail: true,
        },
      },
      restaurantDetailDto
    )
    return data
  }
}

export const MdwService = (country: string) => new MiddlewareService(country)
