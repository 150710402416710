
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46question_93uvvTGHfYzwQSY8klV_TeIcifRi2LriVLOCVc7UC5qLEMeta } from "/app/pages/faqs/[...question].vue?macro=true";
import { default as faqsC9SOoZ3LrPnDZl9hif9RS2443K_45lvA62w_45yUmj868X8Meta } from "/app/pages/faqs.vue?macro=true";
import { default as _91_46_46_46page_93ULmlFBrZHlZY4Pj7CUsBBUp_452tzuEsq7KGibC2mb3IMMeta } from "/app/pages/[...page].vue?macro=true";
import { default as indexknxF0kVj7_cFA_45cPRX2b7Ih3ctKooxPVBCFHygWs_ggMeta } from "/app/pages/menu/index.vue?macro=true";
import { default as _91_46_46_46home_93oAIg2B2vRdbJwW3lWGxUe3SSssuSizkCGWtYHmlS4UYMeta } from "/app/pages/home/[...home].vue?macro=true";
import { default as indexG0g0plfTWJxsJgbSbFb_45e1MqFb_45bLHiw_p_sZDqQ5wMMeta } from "/app/pages/my-account/index.vue?macro=true";
import { default as minecraft1mYH1MNTT1YKtCwc1X9mrf9YOH_4564vnS_3cT_450oUUy8Meta } from "/app/pages/landing/minecraft.vue?macro=true";
import { default as index4MxvsoCm2udizEcvGZaiFiyFR19amvh4wA5IBrcQUeoMeta } from "/app/pages/orders/cart/index.vue?macro=true";
import { default as indexcoBTTUqGSbjHj9W5Ub6nut3YMVQAjdYj_4556jqoRMQEsMeta } from "/app/pages/restaurants/index.vue?macro=true";
import { default as _91_46_46_46slug_93zZ3htgmXH_gZsa_45qUVFATIxXRAhXWjforcC55BJU75IMeta } from "/app/pages/products/[...slug].vue?macro=true";
import { default as indexZrbKKno7bNC4qKE3SkpvP0oHz3MDg5L54u1hFJCNm5sMeta } from "/app/pages/orders/checkout/index.vue?macro=true";
import { default as _91_46_46_46slug_93BCozQ8A199h_vdHgDgZcqi5rrpHng8dr_bRSJ8lj5d0Meta } from "/app/pages/orders/menu/[...slug].vue?macro=true";
import { default as indexXeeAPCq8l_455V8tBbnB9gGgqLWvrA4QR6P_45J9_P7A4hEMeta } from "/app/pages/menu/[categorySlug]/index.vue?macro=true";
import { default as indexVCN7inf97FQt3KBIqO18HY0i8SNy06nqhuRk68SUr70Meta } from "/app/pages/my-account/my-orders/index.vue?macro=true";
import { default as indexQLlEGehPS5Eu_F1pZNBrYXHPXBF_45bVAzpFvynW6rfJEMeta } from "/app/pages/restaurants/[citySlug]/index.vue?macro=true";
import { default as indexuKi6BFCyyNmEAsbpTzwJLxPy2GEj6on4jUjdna_45kpIUMeta } from "/app/pages/my-account/[sectionSlug]/index.vue?macro=true";
import { default as my_45devicesP5BuBOJLNjOpDBeR6PODUOwvyhp_45XeqOnOPr2IvAukkMeta } from "/app/pages/my-account/[sectionSlug]/my-devices.vue?macro=true";
import { default as indexZjdkgCmiUFF5YrWBddsMFepcOempRiamrWNcnCxSaKcMeta } from "/app/pages/my-account/my-orders/[orderId]/index.vue?macro=true";
import { default as indexnUKllq_45m2YLgt12vGnnl5AspMQ9v2Oo_6wHedc_45t5KgMeta } from "/app/pages/orders/cart/[cartProductIndex]/index.vue?macro=true";
import { default as indexw2_45bxYYDbC9wWqF3IHvkBpDhAW7VYDohg8fVcIJo9EAMeta } from "/app/pages/menu/[categorySlug]/[productSlug]/index.vue?macro=true";
import { default as index_1ffX2XanhlZ6VQKwFeBp3hGR_C4ssSf8qb8dMo3_A8Meta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/index.vue?macro=true";
import { default as indexUXYLB86ZvNZMBkhEt0SltLux1WozspUxqJv6CYHq82QMeta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/index.vue?macro=true";
import { default as indexpE_45dttjbAxwq4ELgaKMzuCh4TogWTAOn8I8ZHjNOdyoMeta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/index.vue?macro=true";
import { default as indexH6KStByWQALvPC66Zy_w_45EHolYRTnWRmyRabT65jnzAMeta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/[categorySlug]/index.vue?macro=true";
import { default as indexrxpIYA0Zd0zw8qdRJvRw1MltPBGutm8Y1KnjEBif0wIMeta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/[categorySlug]/[productSlug]/index.vue?macro=true";
export default [
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/app/pages/faqs.vue"),
    children: [
  {
    name: "faqs-question",
    path: ":question(.*)*",
    component: () => import("/app/pages/faqs/[...question].vue")
  }
]
  },
  {
    name: "page",
    path: "/:page(.*)*",
    component: () => import("/app/pages/[...page].vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: indexknxF0kVj7_cFA_45cPRX2b7Ih3ctKooxPVBCFHygWs_ggMeta || {},
    alias: ["/cardapio"],
    component: () => import("/app/pages/menu/index.vue")
  },
  {
    name: "home-home",
    path: "/home/:home(.*)*",
    meta: _91_46_46_46home_93oAIg2B2vRdbJwW3lWGxUe3SSssuSizkCGWtYHmlS4UYMeta || {},
    alias: ["/home"],
    component: () => import("/app/pages/home/[...home].vue")
  },
  {
    name: "my-account",
    path: "/my-account",
    meta: indexG0g0plfTWJxsJgbSbFb_45e1MqFb_45bLHiw_p_sZDqQ5wMMeta || {},
    alias: ["/mi-cuenta","/mon-compte","/minha-conta"],
    component: () => import("/app/pages/my-account/index.vue")
  },
  {
    name: "landing-minecraft",
    path: "/landing/minecraft",
    meta: minecraft1mYH1MNTT1YKtCwc1X9mrf9YOH_4564vnS_3cT_450oUUy8Meta || {},
    alias: ["/landing/minecraft"],
    component: () => import("/app/pages/landing/minecraft.vue")
  },
  {
    name: "orders-cart",
    path: "/orders/cart",
    meta: index4MxvsoCm2udizEcvGZaiFiyFR19amvh4wA5IBrcQUeoMeta || {},
    alias: ["/pedidos/carrito","/pedidos/carrinho","/commandes/panier"],
    component: () => import("/app/pages/orders/cart/index.vue")
  },
  {
    name: "restaurants",
    path: "/restaurants",
    meta: indexcoBTTUqGSbjHj9W5Ub6nut3YMVQAjdYj_4556jqoRMQEsMeta || {},
    alias: ["/restaurantes"],
    component: () => import("/app/pages/restaurants/index.vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug(.*)*",
    meta: _91_46_46_46slug_93zZ3htgmXH_gZsa_45qUVFATIxXRAhXWjforcC55BJU75IMeta || {},
    alias: ["/productos/:slug(.*)*","/produits/:slug(.*)*"],
    component: () => import("/app/pages/products/[...slug].vue")
  },
  {
    name: "orders-checkout",
    path: "/orders/checkout",
    meta: indexZrbKKno7bNC4qKE3SkpvP0oHz3MDg5L54u1hFJCNm5sMeta || {},
    alias: ["/pedidos/checkout","/commandes/checkout"],
    component: () => import("/app/pages/orders/checkout/index.vue")
  },
  {
    name: "orders-menu-slug",
    path: "/orders/menu/:slug(.*)*",
    meta: _91_46_46_46slug_93BCozQ8A199h_vdHgDgZcqi5rrpHng8dr_bRSJ8lj5d0Meta || {},
    alias: ["/pedidos/menu/:slug(.*)*","/commandes/menu/:slug(.*)*"],
    component: () => import("/app/pages/orders/menu/[...slug].vue")
  },
  {
    name: "menu-categorySlug",
    path: "/menu/:categorySlug()",
    meta: indexXeeAPCq8l_455V8tBbnB9gGgqLWvrA4QR6P_45J9_P7A4hEMeta || {},
    alias: ["/cardapio/:categorySlug"],
    component: () => import("/app/pages/menu/[categorySlug]/index.vue")
  },
  {
    name: "my-account-my-orders",
    path: "/my-account/my-orders",
    meta: indexVCN7inf97FQt3KBIqO18HY0i8SNy06nqhuRk68SUr70Meta || {},
    alias: ["/mi-cuenta/mis-pedidos","/mon-compte/mes-commandes","/minha-conta/meus-pedidos"],
    component: () => import("/app/pages/my-account/my-orders/index.vue")
  },
  {
    name: "restaurants-citySlug",
    path: "/restaurants/:citySlug()",
    meta: indexQLlEGehPS5Eu_F1pZNBrYXHPXBF_45bVAzpFvynW6rfJEMeta || {},
    alias: ["/restaurantes/:citySlug"],
    component: () => import("/app/pages/restaurants/[citySlug]/index.vue")
  },
  {
    name: "my-account-sectionSlug",
    path: "/my-account/:sectionSlug()",
    meta: indexuKi6BFCyyNmEAsbpTzwJLxPy2GEj6on4jUjdna_45kpIUMeta || {},
    alias: ["/mi-cuenta/:sectionSlug","/mon-compte/:sectionSlug","/minha-conta/:sectionSlug"],
    component: () => import("/app/pages/my-account/[sectionSlug]/index.vue")
  },
  {
    name: "my-account-sectionSlug-my-devices",
    path: "/my-account/:sectionSlug()/my-devices",
    meta: my_45devicesP5BuBOJLNjOpDBeR6PODUOwvyhp_45XeqOnOPr2IvAukkMeta || {},
    alias: ["/mi-cuenta/:sectionSlug/mis-dispositivos","/mon-compte/:sectionSlug/mes-appareils","/minha-conta/:sectionSlug/meus-dispositivos"],
    component: () => import("/app/pages/my-account/[sectionSlug]/my-devices.vue")
  },
  {
    name: "my-account-my-orders-orderId",
    path: "/my-account/my-orders/:orderId()",
    meta: indexZjdkgCmiUFF5YrWBddsMFepcOempRiamrWNcnCxSaKcMeta || {},
    alias: ["/mi-cuenta/mis-pedidos/:orderId","/mon-compte/mes-commandes/:orderId","/minha-conta/meus-pedidos/:orderId"],
    component: () => import("/app/pages/my-account/my-orders/[orderId]/index.vue")
  },
  {
    name: "orders-cart-cartProductIndex",
    path: "/orders/cart/:cartProductIndex()",
    meta: indexnUKllq_45m2YLgt12vGnnl5AspMQ9v2Oo_6wHedc_45t5KgMeta || {},
    alias: ["/pedidos/carrito/:cartProductIndex","/pedidos/carrinho/:cartProductIndex","/commandes/panier/:cartProductIndex"],
    component: () => import("/app/pages/orders/cart/[cartProductIndex]/index.vue")
  },
  {
    name: "menu-categorySlug-productSlug",
    path: "/menu/:categorySlug()/:productSlug()",
    meta: indexw2_45bxYYDbC9wWqF3IHvkBpDhAW7VYDohg8fVcIJo9EAMeta || {},
    alias: ["/cardapio/:categorySlug/:productSlug"],
    component: () => import("/app/pages/menu/[categorySlug]/[productSlug]/index.vue")
  },
  {
    name: "restaurants-citySlug-restaurantSlug",
    path: "/restaurants/:citySlug()/:restaurantSlug()",
    meta: index_1ffX2XanhlZ6VQKwFeBp3hGR_C4ssSf8qb8dMo3_A8Meta || {},
    alias: ["/restaurantes/:citySlug/:restaurantSlug"],
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/index.vue")
  },
  {
    name: "restaurants-citySlug-restaurantSlug-orders",
    path: "/restaurants/:citySlug()/:restaurantSlug()/orders",
    meta: indexUXYLB86ZvNZMBkhEt0SltLux1WozspUxqJv6CYHq82QMeta || {},
    alias: ["/restaurantes/:citySlug/:restaurantSlug/pedidos","/restaurants/:citySlug/:restaurantSlug/commandes"],
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/index.vue")
  },
  {
    name: "restaurants-citySlug-restaurantSlug-orders-areaSlug",
    path: "/restaurants/:citySlug()/:restaurantSlug()/orders/:areaSlug()",
    meta: indexpE_45dttjbAxwq4ELgaKMzuCh4TogWTAOn8I8ZHjNOdyoMeta || {},
    alias: ["/restaurantes/:citySlug/:restaurantSlug/pedidos/:areaSlug","/restaurants/:citySlug/:restaurantSlug/commandes/:areaSlug"],
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/index.vue")
  },
  {
    name: "restaurants-citySlug-restaurantSlug-orders-areaSlug-categorySlug",
    path: "/restaurants/:citySlug()/:restaurantSlug()/orders/:areaSlug()/:categorySlug()",
    meta: indexH6KStByWQALvPC66Zy_w_45EHolYRTnWRmyRabT65jnzAMeta || {},
    alias: ["/restaurantes/:citySlug/:restaurantSlug/pedidos/:areaSlug/:categorySlug","/restaurants/:citySlug/:restaurantSlug/commandes/:areaSlug/:categorySlug"],
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/[categorySlug]/index.vue")
  },
  {
    name: "restaurants-citySlug-restaurantSlug-orders-areaSlug-categorySlug-productSlug",
    path: "/restaurants/:citySlug()/:restaurantSlug()/orders/:areaSlug()/:categorySlug()/:productSlug()",
    meta: indexrxpIYA0Zd0zw8qdRJvRw1MltPBGutm8Y1KnjEBif0wIMeta || {},
    alias: ["/restaurantes/:citySlug/:restaurantSlug/pedidos/:areaSlug/:categorySlug/:productSlug","/restaurants/:citySlug/:restaurantSlug/commandes/:areaSlug/:categorySlug/:productSlug"],
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/[categorySlug]/[productSlug]/index.vue")
  }
]