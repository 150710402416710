import { StoreService } from '../store.service'
import { restaurantDto, restaurantDeliveryDto, restaurantsLiteDto } from './restaurant.dto'
import { RestaurantAreaType } from '~/lib/interfaces/restaurant'

export class RestaurantService extends StoreService {
  constructor(country: string) {
    super('restaurant', country)
  }

  async getStoreRestaurant({
    countryCode, // 'AR'
    area, // 'MOP'
    restaurantId, // "619772eea68daba8794622b4"
  }: {
    countryCode: string
    area: RestaurantAreaType
    restaurantId: string
  }) {
    const res = await this.request(
      {
        url: `/restaurants/${restaurantId}?area=${area}`,
        headers: { 'x-app-country': countryCode },
      },
      restaurantDto
    )
    return res
  }

  async getStoreRestaurantByCode({
    countryCode, // 'AR'
    area, // 'MOP'
    restaurantCode, // "OLI"
  }: {
    countryCode: string
    area: RestaurantAreaType
    restaurantCode: string
  }) {
    const code = restaurantCode.toUpperCase()
    const res = await this.request(
      {
        url: `/restaurants/code/${code}`,
        params: {
          area,
        },
        headers: { 'x-app-country': countryCode },
      },
      restaurantDto
    )
    return res
  }

  async getRestaurantDelivery({
    customerToken = '',
    area = '',
    latitude = '', // -34.5043749286146
    longitude = '', // -58.4945487976074
    countryCode = '', // 'AR'
  }: {
    customerToken?: string
    area?: string
    latitude: string
    longitude: string
    countryCode: string
  }) {
    const countryHeader = { 'x-app-country': countryCode }
    const tokenHeader = { Authorization: `Bearer ${customerToken}` }
    const headers = customerToken ? { ...countryHeader, ...tokenHeader } : countryHeader

    const res = await this.request(
      {
        url: `/restaurants/delivery`,
        params: {
          latitude,
          longitude,
          area,
        },
        headers,
      },
      restaurantDeliveryDto
    )

    return res
  }

  async getStoreListRestaurant({
    area,
    countryCode,
  }: {
    area: RestaurantAreaType
    countryCode: string
  }) {
    const res = await this.request(
      {
        url: `/restaurants/lite`,
        params: {
          area,
        },
        headers: { 'x-app-country': countryCode },
      },
      restaurantsLiteDto
    )
    return res
  }
}

export const restaurantService = (country: string) => new RestaurantService(country)
