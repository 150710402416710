export function useGeolocation() {
  const restaurantStore = useRestaurantStore()
  onMounted(async () => {
    if (process.client && navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then(geoPermission => {
        restaurantStore.setGeoPermissionStatus(geoPermission.state)
        geoPermission.onchange = () => {
          restaurantStore.setGeoPermissionStatus(geoPermission.state)
        }
      })
    }
  })
}
