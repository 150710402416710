import { Coords } from '~/lib/models/geolocation'
import { Address } from '~/lib/services/store/order/order.dto'

// Styles
export const MAP_STANDARD_GREY = [
  {
    featureType: 'landscape.man_made',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

export const getOverridenGoogleMapsKey = (): string | undefined => {
  const googleApiKeyOverride = localStorage.getItem('googleMapsApiKey')
  return googleApiKeyOverride ? JSON.parse(googleApiKeyOverride) : undefined
}

export const getValueFromAddressComponents = ({
  key,
  property = 'long',
  place,
}: {
  key: string
  property?: 'long' | 'short'
  place: google.maps.GeocoderResult
}) => {
  if (!place) return ''
  const keyProperty = property === 'long' ? 'long_name' : 'short_name'
  return place.address_components?.find((obj: any) => obj.types.includes(key))?.[keyProperty]
}

export async function getAddressInfo(placeId: string) {
  const geocoder = new google.maps.Geocoder()

  return new Promise<Address | undefined>(resolve => {
    geocoder.geocode({ placeId }).then(({ results }) => {
      if (results?.length) {
        const place = results[0]

        const city =
          getValueFromAddressComponents({ key: 'locality', place }) ||
          getValueFromAddressComponents({ key: 'administrative_area_level_2', place })

        const address = getValueFromAddressComponents({ key: 'route', place })
        const number = getValueFromAddressComponents({ key: 'street_number', place })
        const postalCode = getValueFromAddressComponents({ key: 'postal_code', place }) || ''
        const state =
          getValueFromAddressComponents({
            key: 'administrative_area_level_1',
            property: 'short',
            place,
          }) || ''
        const neighborhood =
          getValueFromAddressComponents({ key: 'sublocality_level_1', place }) || ''

        const { lat, lng } = place.geometry.location

        resolve({
          city,
          address,
          number,
          postalCode,
          state,
          neighborhood,
          latitude: lat(),
          longitude: lng(),
        })
      } else {
        resolve(undefined)
      }
    })
  })
}

export async function getCircleBounds({
  center,
  radius,
}: {
  center: {
    lat: number
    lng: number
  }
  radius: number
}) {
  const circle = new google.maps.Circle({
    center,
    radius,
  })
  return circle.getBounds()
}

export function checkIfInBounds({
  bounds,
  point,
}: {
  bounds: google.maps.LatLngBounds | null
  point: {
    lat: number
    lng: number
  }
}) {
  if (!bounds) return false
  return bounds.contains(point)
}

export const placePredictionSearch = async ({
  query,
  countryCode,
  googleMapsSessionToken,
  requestType = ['address'],
}: {
  query: string
  countryCode: string
  googleMapsSessionToken?: google.maps.places.AutocompleteSessionToken
  requestType?: string[]
}): Promise<google.maps.places.AutocompletePrediction[]> =>
  new Promise(resolve => {
    const service = new google.maps.places.AutocompleteService()

    const request = {
      input: query,
      types: requestType,
      componentRestrictions: { country: countryCode },
      ...(googleMapsSessionToken && {
        sessionToken: googleMapsSessionToken,
      }),
    }

    service.getPlacePredictions(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        resolve(results)
      }
      if (results === null) {
        resolve([])
      }
    })
  })

export const getGoogleMapsUrl = (
  center: { lat: number; lng: number },
  userCenter: { lat: number; lng: number } | undefined
) => {
  const url = new URL('https://maps.google.com/')
  url.searchParams.append('daddr', `${center.lat},${center.lng}`)

  if (userCenter) {
    url.searchParams.append('saddr', `${userCenter.lat},${userCenter.lng}`)
  }

  return url.toString()
}

export const extractGeocoderPlaceStr = (place: google.maps.GeocoderResult, number: string) => {
  const placeNumber = getValueFromAddressComponents({ key: 'street_number', place })
  // Numbers match so return formatted_address
  if (placeNumber && placeNumber === number) return place.formatted_address
  let replaceMatchShort = ''
  let replaceMatchLong = ''
  const streetShort = getValueFromAddressComponents({ key: 'route', property: 'short', place })
  const streetLong = getValueFromAddressComponents({ key: 'route', property: 'short', place })
  // no placeNumber on address_components or placeNumber !== number
  replaceMatchShort = `${streetShort}${!placeNumber ? '' : ', ' + placeNumber}`
  replaceMatchLong = `${streetLong}${!placeNumber ? '' : ', ' + placeNumber}`

  const strToReplace = `${streetShort}, ${number}`
  return place.formatted_address
    .replace(replaceMatchShort, strToReplace)
    .replace(replaceMatchLong, strToReplace)
}

export const getDistanceBetweenUserAndRestaurantRoute = async ({
  restaurantCoordinates,
  currentCoordinates,
}: {
  restaurantCoordinates: Coords
  currentCoordinates: Coords
}) => {
  // 1km by default returned on tests
  if (isCypressSpecialBehaviour()) return 1000
  try {
    const directionService = new window.google.maps.DistanceMatrixService()
    const travel = {
      origins: [restaurantCoordinates],
      destinations: [currentCoordinates],
      travelMode: window.google.maps.TravelMode.DRIVING,
    }

    const distance = await directionService
      .getDistanceMatrix(travel)
      .then(result => {
        if (!result || !result.rows?.length) return undefined

        const { distance } = result.rows[0].elements[0]
        return distance.value
      })
      .catch(() => undefined)

    return distance
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(
      '🚀 ~ file: googleMapsUtils.ts ~ getDistanceBetweenUserAndRestaurantRoute ~ error:',
      error
    )
    return undefined
  }
}
