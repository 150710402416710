import type { DynamicDialogInstance, DynamicDialogOptions } from 'primevue/dynamicdialogoptions'
import { ConfirmationConfig } from '~/lib/models/ui/confirmation'
import component from '~/components/ui/AlertDialog.vue'
import { DialogContent } from '~/lib/models/ui/dialog'

declare global {
  interface Window {
    cypressSpecialBehaviour?: boolean
  }
}

export const showConfirmation = (
  confirm: {
    require: (option: any) => void
    close: () => void
  },
  config: ConfirmationConfig
) => {
  return new Promise<boolean>(resolve => {
    confirm.require({
      ...config,
      accept: () => {
        resolve(true)
      },
      reject: () => {
        resolve(false)
      },
      onHide: () => {
        resolve(false)
      },
    })
  })
}

export const showDialogAlert = ({
  dialog,
  data,
  customCssClass,
}: {
  dialog: {
    open: (content: any, options?: DynamicDialogOptions) => DynamicDialogInstance
  }
  data: DialogContent
  customCssClass?: string
}) => {
  const { trackConfirmPopup } = useGTM()
  return new Promise<boolean | undefined>(resolve => {
    dialog.open(component, {
      data,
      props: {
        unstyled: true,
        showHeader: false,
        contentClass: `mcd-alert-dialog relative rounded-none md:rounded-xl md:mx-4 mx-0 md:!px-14 !p-0 !overflow-visible md:!w-[30rem] !w-[100svw] ${customCssClass}`,
        dismissableMask: true,
        modal: true,
        pt: {
          root: {
            class: 'bg-white backdrop-blur mt-auto md:mt-0',
          },
        },
      },
      onClose: value => {
        if (value?.data === undefined) resolve(undefined)
        const result = !!value?.data
        if (result) {
          trackConfirmPopup({ warningType: slugify(data.title), preOrder: !!data.preOrder })
        }
        resolve(result)
      },
    })
  })
}

export const isCypressSpecialBehaviour = () => {
  if (!process.client) return false
  return !!window.cypressSpecialBehaviour
}

/* Text transformations */

// Array to ordered string with translated separators
export const transformArrayToString = (arr: string[], separator = ',', endSeparator = 'and') => {
  const multipleItems = arr.length > 1
  let buildedString = arr
    .map((key: string, index: number) => {
      if (multipleItems && index === arr.length - 1) return `${endSeparator} ${key}`
      else if (!multipleItems) return key
      else return `${key}${separator} `
    })
    .join('')

  buildedString = buildedString.replace(`${separator} ${endSeparator}`, ` ${endSeparator}`)
  return buildedString
}

// Validate if the provided element is visible in the screen scroll
export const elementIsVisibleInScroll = (
  referenceElement: HTMLElement,
  overlayElement: HTMLElement
): boolean => {
  // visible element
  const elementView = referenceElement.getBoundingClientRect()
  // screen size
  const viewportHeight = window.innerHeight
  const viewportWidth = window.innerWidth
  // If there is an overlay element obtain the height
  const overlayElementHeight = overlayElement ? overlayElement.getBoundingClientRect().height : 0
  return (
    elementView.top >= 0 &&
    elementView.left >= 0 &&
    elementView.bottom <= viewportHeight - overlayElementHeight &&
    elementView.right <= viewportWidth
  )
}
