export const useAuth = async ({ path, fullPath }: { path: string; fullPath: string }) => {
  const authStore = useAuthStore()
  // Check first if the accesstoken exists in the url
  const accessToken = useGetToken(fullPath)

  // If doesn't exists (new query token and user is not logged), return and save it in the localStorage
  if (!accessToken && !authStore.token) return
  // new query path token
  if (accessToken) authStore.setToken(accessToken)
  // get profile of user logged or new token setted
  await authStore.getUser()

  // remove the accessTokens from the url location
  const router = useRouter()
  router.replace({ path, query: { a: undefined } })
}

const useGetToken = (fullPath: string) => {
  const queryString = fullPath?.split('?')?.[1]
  if (!queryString) return undefined
  const params = new URLSearchParams(queryString)
  const accessToken = params.get('a')
  return accessToken || undefined
}
