import round from 'lodash/round'
import { ComposerTranslation } from 'vue-i18n'
import { slugify } from './storeUtils'
import { getDistanceBetweenUserAndRestaurantRoute } from './googleMapsUtils'
import {
  OpenServicesState,
  RestaurantAreaType,
  RestaurantAreas,
  RestaurantLiteWithDistanceList,
  RestaurantScheduleTypes,
  RestaurantScheduleTypesUnion,
  StoreRestaurantAreas,
} from '~/lib/interfaces/restaurant'
import { distanceBetweenCoordinates, compareSameCoords } from '~/lib/geoUtils'
import { Coords } from '~/lib/models/geolocation'
import { Restaurant } from '~/lib/services/store/restaurant/restaurant.dto'
import { Address } from '~/lib/services/store/order/order.dto'

declare global {
  interface Window {
    localStorage: Storage
  }
}

type LatestsDistance = {
  searchedCoordinates: Coords
  restaurantCoordinates: Coords
  distance: number
}

const LOCAL_STORAGE_KEY = {
  LATEST_RESTAURANTS: 'historyLatestRestaurant',
  LATEST_ADDRESSES: 'historyLatestAddresses',
  LATEST_DISTANCE: 'historyLatestDistance',
} as const

export const getRestaurantDetailRoute = ({
  code,
  name,
  city,
  t,
}: {
  code: string
  name: string
  city: string
  t: ComposerTranslation
}) => {
  const citySlug = city ? slugify(city) : '_'
  const restNameSlug = slugify(name)
  const restaurantSlug = `${restNameSlug}-${code.toLowerCase()}`
  return `/${t('routes.restaurants')}/${citySlug}/${restaurantSlug}`
}

export const getRestaurantEcommerceHomeRoute = ({
  code,
  name,
  city,
  t,
}: {
  code: string
  name: string
  city: string
  t: ComposerTranslation
}) => {
  const restaurantDetailRoute = getRestaurantDetailRoute({ code, name, city, t })
  return `${restaurantDetailRoute}/${t('routes.ecommerceHome')}`
}

export const getRestaurantCategoryRoute = ({
  code,
  name,
  city,
  t,
  area,
  categoryTitle,
}: {
  code: string
  name: string
  city: string
  t: ComposerTranslation
  area: RestaurantAreaType
  categoryTitle: string
}) => {
  const restaurantHomeRoute = getRestaurantEcommerceHomeRoute({ code, name, city, t })
  const areaSlug = slugify(t(`common.${area}`))
  return `${restaurantHomeRoute}/${areaSlug}/${slugify(categoryTitle)}`
}

export const getRestaurantProductRoute = ({
  code,
  name,
  city,
  t,
  area,
  categoryTitle,
  productName,
}: {
  code: string
  name: string
  city: string
  t: ComposerTranslation
  area: RestaurantAreaType
  categoryTitle: string
  productName: string
}) => {
  const restaurantCategoryRoute = getRestaurantCategoryRoute({
    code,
    name,
    city,
    t,
    area,
    categoryTitle,
  })
  return `${restaurantCategoryRoute}/${slugify(productName)}`
}

export const getRestaurantStateMop = (openStates: OpenServicesState) => {
  const reducedArr = Object.entries(openStates).reduce((prev, curr) => {
    const [area, state] = curr
    if (area === StoreRestaurantAreas.DLV) return prev
    return [...prev, state]
  }, [] as RestaurantScheduleTypesUnion[])

  const isAnyOpen = reducedArr.includes(RestaurantScheduleTypes.OPEN)
  if (isAnyOpen) return RestaurantScheduleTypes.OPEN

  const isAnyNearToClose = reducedArr.includes(RestaurantScheduleTypes.NEAR_TO_CLOSE)
  if (isAnyNearToClose) return RestaurantScheduleTypes.NEAR_TO_CLOSE

  const isAnyOnlyLocal = reducedArr.includes(RestaurantScheduleTypes.ONLY_LOCAL)
  if (isAnyOnlyLocal) return RestaurantScheduleTypes.ONLY_LOCAL
  return RestaurantScheduleTypes.CLOSED
}

export const isUserFarFromCoords = async ({
  coordinates,
  maxDistance,
}: {
  coordinates: Coords
  maxDistance: number
}) => {
  const permissionStatus = await getPermissionStatus('geolocation')
  if (permissionStatus !== 'granted') return { isUserFarAway: false, coords: undefined }
  const userLocation = await getUserLocation()

  if (!userLocation) return { isUserFarAway: false, coords: undefined }
  const distanceCalculated = round(
    distanceBetweenCoordinates(
      userLocation.lat,
      userLocation.lng,
      coordinates.lat,
      coordinates.lng,
      'K'
    ),
    2
  )

  if (distanceCalculated / 1000 > maxDistance) {
    return { isUserFarAway: true, coords: userLocation }
  }

  return { isUserFarAway: false, coords: userLocation }
}

/* Latest restaurants helpers */
export const getLatestRestaurants = ({
  userMcId,
  userCountry,
}: {
  userMcId: string
  userCountry: string
}): RestaurantLiteWithDistanceList => {
  try {
    /* Get the restaurants local storage by country and userMcId */
    const latestRestaurantFromLocalStorage = window.localStorage.getItem(
      LOCAL_STORAGE_KEY.LATEST_RESTAURANTS
    ) as any
    if (latestRestaurantFromLocalStorage) {
      const latestRestaurantsByUSerFromLocalStorage = JSON.parse(latestRestaurantFromLocalStorage)
      if (!Array.isArray(latestRestaurantsByUSerFromLocalStorage) && userMcId !== undefined) {
        /* Get the user country index */
        const indexUserCurrent = latestRestaurantsByUSerFromLocalStorage[userMcId].findIndex(
          (user: any) => user.country === userCountry
        )
        /* Return restaurants by userMcId */
        if (indexUserCurrent !== -1) {
          const userCurrent = latestRestaurantsByUSerFromLocalStorage[userMcId]
          return [...userCurrent[indexUserCurrent].restaurants]
        }
        return []
      }
      return []
    } else {
      return []
    }
  } catch (e) {
    return []
  }
}

export const saveRestaurantLatestRestaurants = ({
  restaurant,
  isFavorite,
  userMcId,
  country,
}: {
  restaurant?: Restaurant
  isFavorite: boolean
  userMcId: string
  country: string
}) => {
  try {
    if (!restaurant || !process.client) return null
    const latestRestaurantFromLocalStorage = window.localStorage.getItem(
      LOCAL_STORAGE_KEY.LATEST_RESTAURANTS
    ) as any
    const latestUserRestaurantsLocalStorage = JSON.parse(latestRestaurantFromLocalStorage)
    let latestUserRestaurants: any = {}
    /* Verify if it is object / Array */
    if (
      Array.isArray(latestUserRestaurantsLocalStorage) ||
      latestUserRestaurantsLocalStorage === null
    ) {
      /* Save restaurante for the first time with new structure */
      latestUserRestaurants[userMcId] = [{ country, restaurants: [{ ...restaurant, isFavorite }] }]
    } else {
      /* Store restaurant by country and userMcId */
      latestUserRestaurants = latestUserRestaurantsLocalStorage
      const userCurrent = latestUserRestaurants[userMcId]
      /* User exists by userMcId */
      if (userCurrent !== undefined) {
        /* Search the current user country */
        const indexUserCurrent = latestUserRestaurants[userMcId].findIndex(
          (user: any) => user.country === country
        )
        if (indexUserCurrent !== -1) {
          /* Search for restaurants of the current user */
          let { restaurants: currentUserCountry } = userCurrent.find(
            (user: any) => user.country === country
          )
          if (currentUserCountry.length > 0) {
            const index = currentUserCountry.findIndex(({ id }) => id === restaurant.id)
            if (index > -1) currentUserCountry.splice(index, 1)
          }
          /* Save only four restaurants per user */
          if (currentUserCountry.length === 4) {
            currentUserCountry.pop()
          }
          /* Save restaurants in local storage by country and userMcId */
          currentUserCountry = [{ ...restaurant, isFavorite }, ...currentUserCountry]
          latestUserRestaurants[userMcId][indexUserCurrent].restaurants = currentUserCountry
        } else {
          /* If there is no user with the defined country, the user is added with his new country */
          latestUserRestaurants[userMcId].push({
            country,
            restaurants: [{ ...restaurant, isFavorite }],
          })
        }
      } else {
        /** userMcId does not exist at local storage is added for the first time */
        latestUserRestaurants[userMcId] = [
          { country, restaurants: [{ ...restaurant, isFavorite }] },
        ]
      }
    }
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY.LATEST_RESTAURANTS,
      JSON.stringify(latestUserRestaurants)
    )
  } catch (e) {
    return null
  }
}

/* Latest addresses helpers */
export const getLatestAddresses = (userMcId: string, userCountry: string): Address[] => {
  if (!process.client) return []
  try {
    /* Get the addresses local storage by country and userMcId */
    const addresses = window.localStorage.getItem(LOCAL_STORAGE_KEY.LATEST_ADDRESSES) as any
    const latestUserAddressesLocalStorage = JSON.parse(addresses)
    /* Get the user country index */
    if (!Array.isArray(latestUserAddressesLocalStorage) && userMcId !== undefined) {
      const indexUserCurrent = latestUserAddressesLocalStorage[userMcId].findIndex(
        (user: any) => user.country === userCountry
      )
      /* Return addresses by userMcId */
      if (indexUserCurrent !== -1) {
        const userCurrent = latestUserAddressesLocalStorage[userMcId]
        return [...userCurrent[indexUserCurrent].addresses]
      }
      return []
    } else {
      return []
    }
  } catch (e) {
    return []
  }
}

export const saveAddressLatestAddresses = (
  address: Address,
  userMcId: string,
  userCountry: string
) => {
  try {
    if (!process.client) return null

    const latestAddressesFromLocalStorage = window.localStorage.getItem(
      LOCAL_STORAGE_KEY.LATEST_ADDRESSES
    ) as any

    const lastestAddresses = JSON.parse(latestAddressesFromLocalStorage)
    let latestUserAddresses: any = {}
    /* Verify if it is object / Array */
    if (lastestAddresses === null || Array.isArray(lastestAddresses)) {
      /* Save addresses for the first time with new structure */
      latestUserAddresses[userMcId] = [
        { country: userCountry, addresses: [{ ...address, country: userCountry }] },
      ]
    } else {
      /* Store addresses by country and userMcId */
      latestUserAddresses = lastestAddresses
      const userCurrentAddress = latestUserAddresses[userMcId]
      /* User exists by userMcId */
      if (userCurrentAddress !== undefined) {
        /* Search the current user country */
        const indexUserCurrent = latestUserAddresses[userMcId].findIndex(
          (user: any) => user.country === userCountry
        )
        if (indexUserCurrent !== -1) {
          /* Search for addresses of the current user */
          let { addresses: currentUserAddresses } = userCurrentAddress.find(
            (user: any) => user.country === userCountry
          )
          if (currentUserAddresses.length > 0 && address.favoriteId) {
            const index = currentUserAddresses.findIndex(
              ({ favoriteId }) => favoriteId === address.favoriteId
            )
            if (index > -1) currentUserAddresses.splice(index, 1)
          }
          /* Save only four addresses per user */
          if (currentUserAddresses.length === 4) {
            currentUserAddresses.pop()
          }
          currentUserAddresses = [{ ...address }, ...currentUserAddresses]
          /* Save addresses in local storage by country and userMcId */
          latestUserAddresses[userMcId][indexUserCurrent].addresses = currentUserAddresses
        } else {
          latestUserAddresses[userMcId].push({
            country: userCountry,
            addresses: [{ ...address, country: userCountry }],
          })
        }
      } else {
        /** userMcId does not exist at local storage is added for the first time */
        latestUserAddresses[userMcId] = [
          { country: userCountry, addresses: [{ ...address, country: userCountry }] },
        ]
      }
    }

    window.localStorage.setItem(
      LOCAL_STORAGE_KEY.LATEST_ADDRESSES,
      JSON.stringify(latestUserAddresses)
    )
  } catch (e) {
    return null
  }
}

export const buildFormattedAddress = (addrs: Address, area: RestaurantAreaType) => {
  if (area === RestaurantAreas.MOP) return addrs.address || ''
  const { address, city, neighborhood } = addrs
  const groups = [address, neighborhood || '', city].filter(g => g)
  return groups.join(', ')
}

export const getRestaurantCodeFromSlug = ({ country, slug }: { country: string; slug: string }) => {
  // /name-mx-815/... => [name, mx, 815].reverse(), /name-xxx => [name, xxx].reverse()
  const [restCodeFinalSlug, prevSlug] = slug.split('-').reverse()
  const restaurantCode = restCodeFinalSlug.toUpperCase()

  if (prevSlug === country.toLowerCase()) return `${country}-${restaurantCode}`
  return restaurantCode
}

export const getRouteDistance = async ({
  restaurantCoordinates,
  currentCoordinates,
}: {
  restaurantCoordinates: Coords
  currentCoordinates: Coords
}) => {
  const latestDistanceFromLocalStorage = window.localStorage.getItem(
    LOCAL_STORAGE_KEY.LATEST_DISTANCE
  ) as any
  if (!latestDistanceFromLocalStorage) {
    const distance = await getDistanceBetweenUserAndRestaurantRoute({
      restaurantCoordinates,
      currentCoordinates,
    })

    window.localStorage.setItem(
      LOCAL_STORAGE_KEY.LATEST_DISTANCE,
      JSON.stringify({
        searchedCoordinates: currentCoordinates,
        restaurantCoordinates,
        distance,
      })
    )

    return distance
  }
  const latestsDistance: LatestsDistance = JSON.parse(latestDistanceFromLocalStorage)

  if (
    latestsDistance &&
    compareSameCoords({
      coord1: latestsDistance.searchedCoordinates,
      coord2: currentCoordinates,
    }) &&
    compareSameCoords({
      coord1: latestsDistance.restaurantCoordinates,
      coord2: restaurantCoordinates,
    })
  ) {
    return latestsDistance.distance
  }

  const distance = await getDistanceBetweenUserAndRestaurantRoute({
    restaurantCoordinates,
    currentCoordinates,
  })

  window.localStorage.setItem(
    LOCAL_STORAGE_KEY.LATEST_DISTANCE,
    JSON.stringify({
      searchedCoordinates: currentCoordinates,
      restaurantCoordinates,
      distance,
    })
  )

  return distance
}
