import axios from 'axios'
import { BaseService } from '../base.service'

import {
  checkCodeDto,
  tagListDto,
  validDto,
  promotionDto,
  PromoInput,
  PromoCheckPromoInput,
  checkPromosDto,
} from './promos.dto'
import { publicConfig } from '~/config/public'
import logRequests from '~/lib/axiosUtils'
import { CHECK_PROMO_ERROR } from '~/lib/models/promotions'

class PromosService extends BaseService {
  readonly client
  readonly baseURL

  constructor() {
    super()
    this.baseURL = publicConfig.services.store.promos.url
    this.client = axios.create({
      baseURL: this.baseURL,
      headers: {
        'x-app-version': 'web-2.0.0',
      },
    })
    logRequests('promos', this.client)
  }

  async getPromotionsTagList({
    customerToken,
    countryCode,
    area,
    restaurant,
    price,
    products,
  }: PromoInput) {
    const res = await this.request(
      {
        method: 'POST',
        url: `/api/promotions/tag-list`,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
        data: {
          area,
          restaurant,
          price,
          products,
        },
      },
      tagListDto
    )
    return res
  }

  async checkCode({
    customerToken,
    countryCode,
    area,
    restaurant,
    price,
    products,
    coupon,
  }: PromoInput & { coupon: string }) {
    const res = await this.request(
      {
        method: 'POST',
        url: `/api/promotions/check-code`,
        headers: {
          'Authorization': `Bearer ${customerToken}`,
          'x-app-country': countryCode,
        },
        data: {
          area,
          restaurant,
          price,
          products,
          coupon,
        },
      },
      checkCodeDto
    )

    if (!res.valid) {
      return validDto.parse(res)
    }

    return promotionDto.parse(res)
  }

  async checkPromos({
    customerToken,
    countryCode,
    area,
    restaurant,
    promotionsId,
    products,
  }: PromoCheckPromoInput) {
    const headers: {
      'x-app-country'?: string
      'Authorization'?: string
    } = {
      'x-app-country': countryCode,
    }
    if (customerToken) {
      headers.Authorization = `Bearer ${customerToken}`
    }
    const promotionsList = await this.request(
      {
        method: 'POST',
        url: `/api/promotions/check-promo`,
        headers,
        data: {
          area,
          restaurant,
          promotionsId,
          products,
        },
      },
      checkPromosDto
    )
    return promotionsList.filter(
      promo =>
        (promo.valid && promo.enabled) ||
        promo.status === CHECK_PROMO_ERROR.CumulativePromotionsNotAllowed ||
        promo.status === CHECK_PROMO_ERROR.AutoApplicablePromotionNotAllowed
    )
  }
}

export const promosService = new PromosService()
