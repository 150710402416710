/* eslint-disable camelcase */
import Debug from 'debug'
import {
  GTMEcommerceItem,
  GTMEvent,
  GTMEventType,
  GTMViewContentGroup,
  GtmEcommerce,
  GtmEventName,
  emptyDeliveryType,
  tagEmployedCoupon,
  CUSTOM_IM_ANALYTICS_ERRORS,
  GTMFormNameType,
  WalkthroughType,
  GTMFormNameTypes,
} from '~/lib/models/gtm'
import { CartProduct } from '~/lib/services/store/catalog/cart.dtos'
import {
  ProductEcommerce,
  ProductEcommerceLite,
  ProductInfo,
  ProductType,
} from '~/lib/services/store/catalog/catalog.dtos'
import { SaleType, StoreOrder, StoreOrderSaleType } from '~/lib/services/store/order/order.dto'
import {
  generatePageTitle,
  gtmEcommerceItemFromProduct,
  gtmEcommerceItemFromLite,
  gtmEcommerceItemFromCartProduct,
  gtmEcommerceItemFromOrderProduct,
  itemListNameFromProductType,
  extractCouponsFromPromotions,
  getItemListNameTypeFromProducts,
} from '~/utils/gtmUtils'
import { getOrderTotals } from '~/utils/storeUtils'
import {
  RestaurantAreas,
  RestaurantScheduleTypes,
  RestaurantScheduleTypesUnion,
} from '~/lib/interfaces/restaurant'
import { getOrderPromov2 } from '~/utils/promoUtils'

declare global {
  interface Window {
    dataLayer: any
  }
}

const debug = Debug('app:composable:useGTM')

export const useGTM = () => {
  const restaurantStore = useRestaurantStore()
  const layoutStore = useLayoutStore()
  const authStore = useAuthStore()
  const appStore = useAppStore()

  const deliveryType = computed(() => {
    if (!restaurantStore.areaSelected) return emptyDeliveryType
    return restaurantStore.areaSelected
  })

  const restaurantCode = computed(() => {
    if (!restaurantStore.selectedRestaurant?.restaurant) return undefined
    return restaurantStore.selectedRestaurant.restaurant.code
  })

  const viewContent = computed(() => {
    return layoutStore.viewGTMContent
  })

  const currency = computed(() => appStore.ecommerceConfig?.currencyAcronym || '')

  const appliedPromotion = computed(() => appStore.appliedPromotions?.[0] || undefined)

  const cartHasFreeShipping = computed(
    () => !!appStore.cart.products?.length && !!appStore.cartBackendAdditional?.isFreteFree
  )

  const openStateOptions: RestaurantScheduleTypesUnion[] = [
    RestaurantScheduleTypes.OPEN,
    RestaurantScheduleTypes.NEAR_TO_CLOSE,
  ]
  const restaurantClose = computed(() => {
    if (
      !restaurantStore.selectedRestaurant?.restaurant ||
      !restaurantStore.restaurantOpenState ||
      !restaurantStore.areaSelected
    )
      return undefined

    const openState = Object.entries(restaurantStore.restaurantOpenState)
      .filter(([key]) =>
        restaurantStore.areaSelected === RestaurantAreas.DLV
          ? key === RestaurantAreas.DLV.toLowerCase()
          : key !== RestaurantAreas.DLV.toLowerCase()
      )
      .map(([, value]) => value)
    return !openState.some(state => openStateOptions.includes(state))
  })

  const eventGeneralInfo = (evType: GTMEventType): GTMEvent => {
    const event = new GTMEvent(evType)
    event.country = useCountry()
    // view
    const content = viewContent.value
    if (content) {
      event.content_group = content.cg
      event.content_group2 = content.cg2
      event.content_group3 = content.cg3
      event.page_title = generatePageTitle(content) || undefined
      event.link_url = content.linkUrl
    }
    // user
    event.is_loyalty = authStore.isLoyaltyUser
    event.user_id = authStore.userMcId
    event.is_club_automac = authStore.isClubVipAutomacUser
    // ecommerce
    event.ecommerce.restaurant_code = restaurantCode.value
    event.ecommerce.delivery_type = deliveryType.value
    event.ecommerce.restaurant_close = restaurantClose.value

    return event
  }

  /* PageView */
  const trackPageView = (content: GTMViewContentGroup, modal = false) => {
    debug('Set new view gtm content', content)
    layoutStore.setViewGTMContent(content, modal)
    const page_view = eventGeneralInfo(GtmEventName.PAGE_VIEW)

    sendEvent(page_view)
  }

  const trackOnCloseModal = () => {
    const previousPageViewContent = layoutStore.pageViewGTMContent
    if (previousPageViewContent) {
      trackPageView(previousPageViewContent)
    }
  }

  /* Events */
  const trackEcommerceHome = () => {
    const ecommerce_home = eventGeneralInfo(GtmEventName.ECOMMERCE_HOME)
    // ecommerce
    ecommerce_home.ecommerce.loyalty = false

    sendEvent(ecommerce_home)
  }

  const trackSelectItem = (
    item: ProductEcommerceLite,
    categoryTitle: string,
    index: number,
    productType?: ProductType
  ) => {
    const select_item = eventGeneralInfo(GtmEventName.SELECT_ITEM)
    // ecommerce
    const currencyAcronym = currency.value
    select_item.ecommerce.currency = currencyAcronym
    const ecoItem = gtmEcommerceItemFromLite({
      product: item,
      categoryTitle,
      index,
      currency: currencyAcronym,
    })
    if (productType) {
      const gtmProductType = itemListNameFromProductType(productType)
      select_item.ecommerce.item_list_name = gtmProductType
      ecoItem.item_list_name = gtmProductType
    }
    select_item.ecommerce.items = [ecoItem]

    sendEvent(select_item)
  }

  const trackViewItemList = (items: GTMEcommerceItem[]) => {
    const view_item_list = eventGeneralInfo(GtmEventName.VIEW_ITEM_LIST)
    // ecommerce
    view_item_list.ecommerce.currency = currency.value
    const sortedItems = items.sort((a, b) => {
      if (a.index && b.index) return a.index - b.index
      return 0
    })
    view_item_list.ecommerce.items = sortedItems
    view_item_list.ecommerce.item_list_name = getItemListNameTypeFromProducts(sortedItems)

    sendEvent(view_item_list)
  }

  const trackViewItem = (item: ProductEcommerce, categoryTitle?: string) => {
    const view_item = eventGeneralInfo(GtmEventName.VIEW_ITEM)
    // ecommerce
    const currencyAcronym = currency.value
    const itemListName = itemListNameFromProductType(appStore.productTypeOnDetail)
    view_item.ecommerce.item_list_name = itemListName
    view_item.ecommerce.currency = currencyAcronym
    // item
    const ecoItem = gtmEcommerceItemFromProduct({
      product: item,
      categoryTitle,
      currency: currencyAcronym,
    })
    ecoItem.item_list_name = itemListName
    view_item.ecommerce.items = [ecoItem]
    view_item.ecommerce.pre_order = !!item.mcDia

    sendEvent(view_item)
  }

  const trackAddToCart = (cartProducts: CartProduct[], justApplyingCoupon?: string) => {
    const add_to_cart = eventGeneralInfo(GtmEventName.ADD_TO_CART)

    const promotion = appliedPromotion.value
    const coupon = promotion
      ? promotion?.code || tagEmployedCoupon
      : justApplyingCoupon || undefined

    // ecommerce
    const currencyAcronym = currency.value
    add_to_cart.ecommerce.currency = currencyAcronym
    const items = cartProducts.map(cP =>
      gtmEcommerceItemFromCartProduct({ cartProduct: cP, currency: currencyAcronym, coupon })
    )
    const itemListName = appStore.productTypeOnDetail
      ? itemListNameFromProductType(appStore.productTypeOnDetail)
      : getItemListNameTypeFromProducts(items)
    add_to_cart.ecommerce.item_list_name = itemListName
    add_to_cart.ecommerce.items = items
    add_to_cart.ecommerce.pre_order = cartProducts.some(cartProd => cartProd.isMcDia)
    sendEvent(add_to_cart)
  }

  const trackRemoveFromCart = (cartProducts: CartProduct[]) => {
    const remove_from_cart = eventGeneralInfo(GtmEventName.REMOVE_FROM_CART)

    const promotion = appliedPromotion.value
    const coupon = promotion ? promotion?.code || tagEmployedCoupon : undefined

    // ecommerce
    const currencyAcronym = currency.value
    remove_from_cart.ecommerce.currency = currencyAcronym
    const items = cartProducts.map(cP =>
      gtmEcommerceItemFromCartProduct({ cartProduct: cP, currency: currencyAcronym, coupon })
    )
    const itemListName = getItemListNameTypeFromProducts(items)
    remove_from_cart.ecommerce.item_list_name = itemListName
    remove_from_cart.ecommerce.items = items
    remove_from_cart.ecommerce.pre_order = cartProducts.some(cartProd => cartProd.isMcDia)
    sendEvent(remove_from_cart)
  }

  const trackBeginCheckout = (cartProducts: CartProduct[]) => {
    const begin_checkout = eventGeneralInfo(GtmEventName.BEGIN_CHECKOUT)

    const promotion = appliedPromotion.value
    const coupon = promotion ? promotion?.code || tagEmployedCoupon : undefined

    // ecommerce
    const currencyAcronym = currency.value
    begin_checkout.ecommerce.currency = currencyAcronym
    begin_checkout.ecommerce.points_spent = 0
    const items = cartProducts.map(cP =>
      gtmEcommerceItemFromCartProduct({ cartProduct: cP, currency: currencyAcronym, coupon })
    )
    begin_checkout.ecommerce.items = items
    begin_checkout.ecommerce.pre_order = cartProducts.some(cartProd => cartProd.isMcDia)

    sendEvent(begin_checkout)
  }

  const trackAddShippingInfo = (cartProducts: CartProduct[]) => {
    const add_shipping_info = eventGeneralInfo(GtmEventName.ADD_SHIPPING_INFO)

    const promotion = appliedPromotion.value
    const coupon = promotion ? promotion?.code || tagEmployedCoupon : undefined

    // ecommerce
    const currencyAcronym = currency.value
    add_shipping_info.ecommerce.currency = currencyAcronym
    add_shipping_info.ecommerce.points_spent = 0
    const items = cartProducts.map(cP =>
      gtmEcommerceItemFromCartProduct({ cartProduct: cP, currency: currencyAcronym, coupon })
    )
    add_shipping_info.ecommerce.items = items
    add_shipping_info.ecommerce.pre_order = cartProducts.some(cartProd => cartProd.isMcDia)

    sendEvent(add_shipping_info)
  }

  const trackAddPaymentInfo = ({
    cartProducts,
    order,
    paymentType,
    bill = false,
  }: {
    cartProducts?: CartProduct[]
    order?: StoreOrder
    paymentType: string
    bill: boolean
  }) => {
    const roundAmount = (amount: number) => (!amount ? 0 : formatAmountGTM(amount))
    const add_payment_info = eventGeneralInfo(GtmEventName.ADD_PAYMENT_INFO)

    const hasFreeShipping = order ? !!order.isFreteFree : cartHasFreeShipping.value

    const promotion = order ? getOrderPromov2(order) : appliedPromotion.value
    const { coupon, coupon2, coupon3 } = extractCouponsFromPromotions({
      promotions: promotion ? [promotion] : [],
      hasFreeShipping,
    })

    // ecommerce
    const currencyAcronym = currency.value
    add_payment_info.ecommerce.currency = currencyAcronym
    add_payment_info.ecommerce.points_spent = 0
    add_payment_info.ecommerce.payment_type = paymentType
    const totals = cartProducts ? appStore.cartTotals : getOrderTotals(order!)
    add_payment_info.ecommerce.tax = roundAmount(totals.tax)
    add_payment_info.ecommerce.shipping = roundAmount(totals.shipping)
    add_payment_info.ecommerce.tip = roundAmount(totals.tip)
    add_payment_info.ecommerce.bill = bill
    // promos
    add_payment_info.ecommerce.coupon = coupon
    add_payment_info.ecommerce.coupon2 = coupon2
    add_payment_info.ecommerce.coupon3 = coupon3
    add_payment_info.ecommerce.discount = promotion
      ? roundAmount(appStore.cartDiscount || order?.price.extras.discount || 0)
      : 0
    add_payment_info.ecommerce.promotion_id = promotion?.promotionId
    add_payment_info.ecommerce.promotion_name = promotion?.name

    const items = cartProducts
      ? cartProducts.map(cP =>
          gtmEcommerceItemFromCartProduct({
            cartProduct: cP,
            currency: currencyAcronym,
            coupon,
          })
        )
      : order?.products?.map(oP =>
          gtmEcommerceItemFromOrderProduct({
            orderProduct: oP,
            currency: currencyAcronym,
            coupon,
            isPreOrder: order.orderMcDia,
          })
        )
    add_payment_info.ecommerce.items = items
    add_payment_info.ecommerce.pre_order = cartProducts
      ? cartProducts.some(cartProd => cartProd.isMcDia)
      : !!order?.orderMcDia

    sendEvent(add_payment_info)
  }

  const trackPurchase = ({
    order,
    overridenEventOrigin = undefined,
  }: {
    order: StoreOrder
    overridenEventOrigin?: string
  }) => {
    const roundAmount = (amount: number) => (!amount ? 0 : formatAmountGTM(amount))

    const hasFreeShipping = !!order.isFreteFree
    const promotion = getOrderPromov2(order)
    const { coupon, coupon2, coupon3 } = extractCouponsFromPromotions({
      promotions: promotion ? [promotion] : [],
      hasFreeShipping,
    })

    const purchase = eventGeneralInfo(GtmEventName.PURCHASE)
    // ecommerce
    const currencyAcronym = currency.value
    purchase.ecommerce.currency = currencyAcronym
    purchase.ecommerce.points_spent = 0
    purchase.ecommerce.payment_type = order.paymentMethod
    const orderTotals = getOrderTotals(order)
    purchase.ecommerce.tax = roundAmount(orderTotals.tax)
    purchase.ecommerce.shipping = roundAmount(orderTotals.shipping)
    purchase.ecommerce.tip = roundAmount(orderTotals.tip)
    purchase.ecommerce.value = roundAmount(orderTotals.total)
    purchase.ecommerce.transaction_id = order.id
    purchase.ecommerce.bill = !!order.fiscalFields
    // promotions
    purchase.ecommerce.coupon = coupon
    purchase.ecommerce.coupon2 = coupon2
    purchase.ecommerce.coupon3 = coupon3
    purchase.ecommerce.discount = promotion ? roundAmount(order.price.extras.discount || 0) : 0
    purchase.ecommerce.promotion_id = promotion?.promotionId
    purchase.ecommerce.promotion_name = promotion?.name

    const items = order.products.map(oP =>
      gtmEcommerceItemFromOrderProduct({
        orderProduct: oP,
        currency: currencyAcronym,
        coupon,
        isPreOrder: order.orderMcDia,
      })
    )
    purchase.ecommerce.items = items
    // item list name for crossSelling purchases
    const itemListName = getItemListNameTypeFromProducts(items)
    purchase.ecommerce.item_list_name = itemListName
    purchase.ecommerce.pre_order = order.orderMcDia

    if (overridenEventOrigin) purchase.event_origin = overridenEventOrigin
    sendEvent(purchase)
  }

  const trackRefund = (order: StoreOrder) => {
    const roundAmount = (amount: number) => (!amount ? 0 : formatAmountGTM(amount))
    const refund = eventGeneralInfo(GtmEventName.REFUND)

    const hasFreeShipping = !!order.isFreteFree
    const promotion = getOrderPromov2(order)
    const { coupon, coupon2, coupon3 } = extractCouponsFromPromotions({
      promotions: promotion ? [promotion] : [],
      hasFreeShipping,
    })

    // ecommerce
    const currencyAcronym = currency.value
    refund.ecommerce.currency = currencyAcronym
    refund.ecommerce.points_spent = 0
    refund.ecommerce.payment_type = order.paymentMethod
    const orderTotals = getOrderTotals(order)
    refund.ecommerce.tax = roundAmount(orderTotals.tax)
    refund.ecommerce.shipping = roundAmount(orderTotals.shipping)
    refund.ecommerce.tip = roundAmount(orderTotals.tip)
    refund.ecommerce.value = roundAmount(orderTotals.total)
    refund.ecommerce.transaction_id = order.id
    refund.ecommerce.bill = false
    // cancellation
    const cancellationType = 'manual'
    refund.ecommerce.cancelation_type = cancellationType
    refund.ecommerce.cancelation_warning = cancellationType
    // promotions
    refund.ecommerce.coupon = coupon
    refund.ecommerce.coupon2 = coupon2
    refund.ecommerce.coupon3 = coupon3
    refund.ecommerce.discount = promotion ? roundAmount(order.price.extras.discount || 0) : 0
    refund.ecommerce.promotion_id = promotion?.promotionId
    refund.ecommerce.promotion_name = promotion?.name

    const items = order.products.map(oP =>
      gtmEcommerceItemFromOrderProduct({
        orderProduct: oP,
        currency: currencyAcronym,
        coupon,
        isPreOrder: order.orderMcDia,
      })
    )
    refund.ecommerce.items = items
    refund.ecommerce.pre_order = order.orderMcDia

    sendEvent(refund)
  }

  const trackNoRider = () => {
    const no_rider = eventGeneralInfo(GtmEventName.NO_RIDER)
    no_rider.warning_type = GtmEventName.NO_RIDER

    sendEvent(no_rider)
  }

  const trackNoCoverage = () => {
    const no_coverage = eventGeneralInfo(GtmEventName.NO_COVERAGE)
    no_coverage.warning_type = GtmEventName.NO_COVERAGE

    sendEvent(no_coverage)
  }

  const trackCustomError = ({ code, type }: { code?: string; type: string }) => {
    const custom_error = eventGeneralInfo(GtmEventName.CUSTOM_ERROR)
    if (code) {
      custom_error.error_code = code
    }
    custom_error.error_type = type

    sendEvent(custom_error)
  }

  const trackSelectRestaurant = (restCode: string) => {
    const select_restaurant = eventGeneralInfo(GtmEventName.SELECT_RESTAURANT)
    // ecommerce
    const ecommerce = new GtmEcommerce()
    ecommerce.delivery_type = RestaurantAreas.MOP
    ecommerce.restaurant_code = restCode
    select_restaurant.ecommerce = ecommerce
    sendEvent(select_restaurant)
  }

  const trackChangeDeliveryOptions = () => {
    const change_delivery_options = eventGeneralInfo(GtmEventName.CHANGE_DELIVERY_OPTIONS)

    sendEvent(change_delivery_options)
  }

  const trackConfirmAddress = () => {
    const confirm_address = eventGeneralInfo(GtmEventName.CONFIRM_ADDRESS)

    sendEvent(confirm_address)
  }

  const trackRepeatOrder = (orderId: string) => {
    const repeat_order = eventGeneralInfo(GtmEventName.REPEAT_ORDER)
    // ecommerce
    repeat_order.ecommerce.loyalty = false
    repeat_order.ecommerce.transaction_id = orderId

    sendEvent(repeat_order)
  }

  const trackRepeatOrderOK = (orderId: string) => {
    const repeat_order_ok = eventGeneralInfo(GtmEventName.REPEAT_ORDER_OK)
    // ecommerce
    repeat_order_ok.ecommerce.loyalty = false
    repeat_order_ok.ecommerce.transaction_id = orderId

    sendEvent(repeat_order_ok)
  }

  const trackClickArea = (area: string) => {
    const eventName =
      area === RestaurantAreas.DLV
        ? eventGeneralInfo(GtmEventName.CLICK_DLV)
        : eventGeneralInfo(GtmEventName.CLICK_MOP)

    sendEvent(eventName)
  }

  const trackGoEcommerce = () => {
    const go_ecommerce = eventGeneralInfo(GtmEventName.GO_ECOMMERCE)
    sendEvent(go_ecommerce)
  }

  const trackAddDiscount = () => {
    const add_discount = eventGeneralInfo(GtmEventName.ADD_DISCOUNT)

    sendEvent(add_discount)
  }

  const trackApplyCoupon = () => {
    const roundAmount = (amount: number) => (!amount ? 0 : formatAmountGTM(amount))
    const apply_coupon = eventGeneralInfo(GtmEventName.APPLY_COUPON)

    const hasFreeShipping = cartHasFreeShipping.value

    const promotion = appliedPromotion.value
    const { coupon, coupon2, coupon3 } = extractCouponsFromPromotions({
      promotions: promotion ? [promotion] : [],
      hasFreeShipping,
    })
    // ecommerce
    apply_coupon.ecommerce.coupon = coupon
    apply_coupon.ecommerce.coupon2 = coupon2
    apply_coupon.ecommerce.coupon3 = coupon3
    apply_coupon.ecommerce.discount = promotion ? roundAmount(appStore.cartDiscount || 0) : 0

    sendEvent(apply_coupon)
  }

  const trackSelectPaymentType = ({
    paymentType,
    preOrder,
  }: {
    paymentType: string
    preOrder: boolean
  }) => {
    const roundAmount = (amount: number) => (!amount ? 0 : formatAmountGTM(amount))
    const select_payment_type = eventGeneralInfo(GtmEventName.SELECT_PAYMENT_TYPE)

    const order = appStore.currentOrder
    const hasFreeShipping = order ? !!order.isFreteFree : cartHasFreeShipping.value

    const promotion = order ? getOrderPromov2(order) : appliedPromotion.value
    const { coupon, coupon2, coupon3 } = extractCouponsFromPromotions({
      promotions: promotion ? [promotion] : [],
      hasFreeShipping,
    })

    // ecommerce
    select_payment_type.ecommerce.payment_type = paymentType
    select_payment_type.ecommerce.pre_order = preOrder
    select_payment_type.ecommerce.coupon = coupon
    select_payment_type.ecommerce.coupon2 = coupon2
    select_payment_type.ecommerce.coupon3 = coupon3
    select_payment_type.ecommerce.discount = promotion
      ? roundAmount(appStore.cartDiscount || order?.price.extras.discount || 0)
      : 0

    sendEvent(select_payment_type)
  }

  const trackAddFavorites = () => {
    const add_favorites = eventGeneralInfo(GtmEventName.ADD_FAVORITES)

    sendEvent(add_favorites)
  }

  const trackRemoveFavorites = () => {
    const remove_favorites = eventGeneralInfo(GtmEventName.REMOVE_FAVORITES)

    sendEvent(remove_favorites)
  }

  const trackOrderCancelledBefore = () => {
    const order_cancelled_before = eventGeneralInfo(GtmEventName.ORDER_CANCELLED_BEFORE)

    sendEvent(order_cancelled_before)
  }

  const trackSearch = (searchTerm: string) => {
    const search = eventGeneralInfo(GtmEventName.SEARCH)
    search.search_term = searchTerm

    sendEvent(search)
  }

  const trackTrackMyOrder = () => {
    const track_my_order = eventGeneralInfo(GtmEventName.TRACK_MY_ORDER)

    sendEvent(track_my_order)
  }

  const trackOrderFailed = ({
    code,
    type,
    paymentType,
  }: {
    code: string
    type: string
    paymentType: string
  }) => {
    const order_failed = eventGeneralInfo(GtmEventName.ORDER_FAILED)
    // ecommerce
    order_failed.ecommerce.payment_type = paymentType
    order_failed.error_code = code
    order_failed.error_type = type

    sendEvent(order_failed)
  }

  const trackViewCart = (cartProducts: CartProduct[]) => {
    const view_cart = eventGeneralInfo(GtmEventName.VIEW_CART)

    const promotion = appliedPromotion.value
    const coupon = promotion ? promotion?.code || tagEmployedCoupon : undefined

    // ecommerce
    const currencyAcronym = currency.value
    view_cart.ecommerce.currency = currencyAcronym
    view_cart.ecommerce.points_spent = 0
    const items = cartProducts.map(cP =>
      gtmEcommerceItemFromCartProduct({ cartProduct: cP, currency: currencyAcronym, coupon })
    )
    view_cart.ecommerce.items = items
    view_cart.ecommerce.pre_order = cartProducts.some(cartProd => cartProd.isMcDia)

    sendEvent(view_cart)
  }

  const trackDeliveryOptions = () => {
    const delivery_options = eventGeneralInfo(GtmEventName.DELIVERY_OPTIONS)

    sendEvent(delivery_options)
  }

  const trackOnWay = () => {
    const on_way = eventGeneralInfo(GtmEventName.ON_WAY)

    sendEvent(on_way)
  }

  const trackSelectPickup = (pickupType: string) => {
    const select_pickup = eventGeneralInfo(GtmEventName.SELECT_PICKUP)
    // ecommerce
    select_pickup.ecommerce.pickup_type = pickupType

    sendEvent(select_pickup)
  }

  const trackTooFar = () => {
    const too_far = eventGeneralInfo(GtmEventName.TOO_FAR)

    sendEvent(too_far)
  }

  const trackDisplayMapList = (isMap: boolean) => {
    const eventType = isMap ? GtmEventName.DISPLAY_MAP : GtmEventName.DISPLAY_LIST
    const display = eventGeneralInfo(eventType)

    sendEvent(display)
  }

  const trackClickWhatsApp = () => {
    const click_whatsapp_consultas = eventGeneralInfo(GtmEventName.CLICK_WHATSAPP_CONSULTAS)

    sendEvent(click_whatsapp_consultas)
  }

  const trackClick = (linkUrl: string) => {
    const click = eventGeneralInfo(GtmEventName.CLICK)
    click.link_url = linkUrl
    click.outbound = true
    sendEvent(click)
  }

  const trackFormError = ({
    type,
    formName,
    code,
  }: {
    type: string
    formName: string | undefined
    code?: string
  }) => {
    const form_error = eventGeneralInfo(GtmEventName.FORM_ERROR)
    form_error.error_code = code ?? `${CUSTOM_IM_ANALYTICS_ERRORS.IM_API_ERROR}`
    form_error.error_type = type
    form_error.form_name = formName
    sendEvent(form_error)
  }

  const trackSelectRestaurantAutomatic = () => {
    const select_restaurant_automatic = eventGeneralInfo(GtmEventName.SELECT_RESTAURANT_AUTOMATIC)

    sendEvent(select_restaurant_automatic)
  }

  const trackSelectAddress = () => {
    const select_address = eventGeneralInfo(GtmEventName.SELECT_ADDRESS)

    sendEvent(select_address)
  }

  const trackClosedRestaurant = () => {
    const closed_restaurant = eventGeneralInfo(GtmEventName.CLOSED_RESTAURANT)

    sendEvent(closed_restaurant)
  }

  const trackGoToPay = () => {
    const go_to_pay = eventGeneralInfo(GtmEventName.GO_TO_PAY)

    sendEvent(go_to_pay)
  }

  const trackWithoutLogin = () => {
    const sin_login = eventGeneralInfo(GtmEventName.WITHOUT_LOGIN)

    sendEvent(sin_login)
  }

  const trackSavePayment = (paymentType: string) => {
    const save_payment = eventGeneralInfo(GtmEventName.SAVE_PAYMENT)

    const order = appStore.currentOrder
    const hasFreeShipping = order ? !!order.isFreteFree : cartHasFreeShipping.value

    const promotion = order ? getOrderPromov2(order) : appliedPromotion.value
    const { coupon, coupon2, coupon3 } = extractCouponsFromPromotions({
      promotions: promotion ? [promotion] : [],
      hasFreeShipping,
    })

    // ecommerce
    save_payment.ecommerce.payment_type = paymentType
    save_payment.ecommerce.coupon = coupon
    save_payment.ecommerce.coupon2 = coupon2
    save_payment.ecommerce.coupon3 = coupon3

    sendEvent(save_payment)
  }

  const trackClickInOrderListTabs = (inProgress: boolean) => {
    const eventType = inProgress ? GtmEventName.CLICK_IN_PROGRESS : GtmEventName.CLICK_FINISHED
    const click_in_ = eventGeneralInfo(eventType)

    sendEvent(click_in_)
  }

  const trackConfirmPopup = ({
    warningType,
    preOrder,
  }: {
    warningType: string
    preOrder: boolean
  }) => {
    const confirm_popup = eventGeneralInfo(GtmEventName.CONFIRM_POPUP)
    confirm_popup.warning_type = warningType
    confirm_popup.ecommerce.pre_order = preOrder
    sendEvent(confirm_popup)
  }

  const trackFormSubmit = (formName: GTMFormNameTypes | undefined, method?: 'email' | 'sms') => {
    const form_submit = eventGeneralInfo(GtmEventName.FORM_SUBMIT)
    form_submit.form_name = formName
    const formMethod = method || undefined
    form_submit.method = formMethod
    sendEvent(form_submit)
  }

  const trackSendCode = (method: 'sms' | 'email', formName: GTMFormNameTypes | undefined) => {
    const send_code = eventGeneralInfo(GtmEventName.SEND_CODE)
    send_code.method = method
    send_code.form_name = formName
    sendEvent(send_code)
  }

  const trackGeolocate = () => {
    const geolocate = eventGeneralInfo(GtmEventName.GEOLOCATE)

    sendEvent(geolocate)
  }

  const trackAddPaymentMethod = (paymentType = 'card') => {
    const add_payment_method = eventGeneralInfo(GtmEventName.ADD_PAYMENT_METHOD)
    // ecommerce
    add_payment_method.ecommerce.payment_type = paymentType

    sendEvent(add_payment_method)
  }

  const trackSurveySubmit = (comment: string) => {
    const survey_submit = eventGeneralInfo(GtmEventName.SURVEY_SUBMIT)
    // ecommerce
    survey_submit.comment = comment || undefined
    survey_submit.form_name = GTMFormNameType.RATE_ORDER

    sendEvent(survey_submit)
  }

  const trackCustomizeItem = (productIdenfier: { identifier: string; name: string }) => {
    const customize_item = eventGeneralInfo(GtmEventName.CUSTOMIZE_ITEM)

    // ecommerce
    const item = new GTMEcommerceItem()
    item.item_id = productIdenfier.identifier
    item.item_name = productIdenfier.name

    customize_item.ecommerce.items = [item]

    sendEvent(customize_item)
  }

  const trackAdditionalComponent = (productIdenfier: { identifier: string; name: string }) => {
    const additional_component = eventGeneralInfo(GtmEventName.ADDITIONAL_COMPONENT)

    // ecommerce
    const item = new GTMEcommerceItem()
    item.item_id = productIdenfier.identifier
    item.item_name = productIdenfier.name

    additional_component.ecommerce.items = [item]

    sendEvent(additional_component)
  }

  const trackSelectComponent = (
    productIdenfier: { identifier: string; name: string },
    component: { id: string; name: string }
  ) => {
    const select_component = eventGeneralInfo(GtmEventName.SELECT_COMPONENT)

    // ecommerce
    const item = new GTMEcommerceItem()
    item.item_id = productIdenfier.identifier
    item.item_name = productIdenfier.name
    item.component_id = component.id
    item.component_name = component.name

    select_component.ecommerce.items = [item]

    sendEvent(select_component)
  }

  const trackCustomizeIngredients = (
    productInfo: ProductInfo,
    component: { id: string; name: string }
  ) => {
    const customize_ingredients = eventGeneralInfo(GtmEventName.CUSTOMIZE_INGREDIENTS)

    // ecommerce
    const item = new GTMEcommerceItem()
    item.item_id = productInfo.identifier
    item.item_name = productInfo.name
    item.component_id = productInfo.combo ? component.id : undefined
    item.component_name = productInfo.combo ? component.name : undefined

    customize_ingredients.ecommerce.items = [item]

    sendEvent(customize_ingredients)
  }

  const trackSaveIngredients = (
    productInfo: ProductInfo,
    component: { id: string; name: string },
    ingredientsEdited: string
  ) => {
    const save_ingredients = eventGeneralInfo(GtmEventName.SAVE_INGREDIENTS)

    // ecommerce
    const item = new GTMEcommerceItem()
    item.item_id = productInfo.identifier
    item.item_name = productInfo.name
    item.component_id = productInfo.combo ? component.id : undefined
    item.component_name = productInfo.combo ? component.name : undefined
    item.ingredients_edited = ingredientsEdited || undefined

    save_ingredients.ecommerce.items = [item]

    sendEvent(save_ingredients)
  }

  const trackAddTip = (amount: number) => {
    const add_tip = eventGeneralInfo(GtmEventName.ADD_TIP)
    // ecommerce
    add_tip.ecommerce.tip = !amount ? 0 : formatAmountGTM(amount)

    sendEvent(add_tip)
  }

  const trackWalkthrough = (wtStep: string) => {
    const wt_type = WalkthroughType.ECOMMERCE

    const walkthrough = eventGeneralInfo(GtmEventName.WALKTHROUGH)
    walkthrough.wt_step = wtStep
    walkthrough.wt_type = wt_type
    sendEvent(walkthrough)
  }

  const trackWalkthroughClose = (wtStep: string) => {
    const wt_type = WalkthroughType.ECOMMERCE

    const walkthrough_close = eventGeneralInfo(GtmEventName.WALKTHROUGH_CLOSE)
    walkthrough_close.wt_step = wtStep
    walkthrough_close.wt_type = wt_type
    sendEvent(walkthrough_close)
  }

  const trackWalkthroughAccount = () => {
    const walkthrough_account = eventGeneralInfo(GtmEventName.WALKTHROUGH_ACCOUNT)
    sendEvent(walkthrough_account)
  }

  const trackGetBill = () => {
    const get_bill = eventGeneralInfo(GtmEventName.GET_BILL)

    const hasFreeShipping = cartHasFreeShipping.value

    const promotion = appliedPromotion.value
    const { coupon, coupon2, coupon3 } = extractCouponsFromPromotions({
      promotions: promotion ? [promotion] : [],
      hasFreeShipping,
    })

    // ecommerce
    get_bill.ecommerce.coupon = coupon
    get_bill.ecommerce.coupon2 = coupon2
    get_bill.ecommerce.coupon3 = coupon3

    sendEvent(get_bill)
  }

  const trackValidationWarning = (
    method: 'sms' | 'email',
    formName: GTMFormNameTypes | undefined
  ) => {
    const validation_warning = eventGeneralInfo(GtmEventName.VALIDATION_WARNING)
    validation_warning.method = method
    validation_warning.form_name = formName

    sendEvent(validation_warning)
  }

  const trackEnrollmentCompleted = () => {
    const enrollment_completed = eventGeneralInfo(GtmEventName.ENROLLMENT_COMPLETED)

    sendEvent(enrollment_completed)
  }

  const trackClickGetPreOrder = () => {
    const click_get_pre_order = eventGeneralInfo(GtmEventName.CLICK_GET_PRE_ORDER)
    click_get_pre_order.ecommerce.pre_order = true
    sendEvent(click_get_pre_order)
  }

  const trackGetPreOrder = () => {
    const get_pre_order = eventGeneralInfo(GtmEventName.GET_PRE_ORDER)
    get_pre_order.ecommerce.pre_order = true
    sendEvent(get_pre_order)
  }

  const trackDeclineGetPreOrder = () => {
    const decline_get_pre_order = eventGeneralInfo(GtmEventName.DECLINE_GET_PRE_ORDER)
    decline_get_pre_order.ecommerce.pre_order = true
    sendEvent(decline_get_pre_order)
  }

  const trackViewOrderDetail = ({ preOrder }: { preOrder: boolean }) => {
    const view_order_detail = eventGeneralInfo(GtmEventName.VIEW_ORDER_DETAIL)
    view_order_detail.ecommerce.pre_order = preOrder
    sendEvent(view_order_detail)
  }

  const trackViewOrders = ({ preOrder }: { preOrder: boolean }) => {
    const view_orders = eventGeneralInfo(GtmEventName.VIEW_ORDERS)
    view_orders.ecommerce.pre_order = preOrder
    sendEvent(view_orders)
  }

  const trackIncompatibleProduct = ({ preOrder }: { preOrder: boolean }) => {
    const incompatible_product = eventGeneralInfo(GtmEventName.INCOMPATIBLE_PRODUCT)
    incompatible_product.ecommerce.pre_order = preOrder
    incompatible_product.warning_type = GtmEventName.INCOMPATIBLE_PRODUCT
    sendEvent(incompatible_product)
  }

  const trackFreeShipping = ({
    cartProducts,
    order,
  }: {
    cartProducts?: CartProduct[]
    order?: StoreOrder
  }) => {
    const free_shipping = eventGeneralInfo(GtmEventName.FREE_SHIPPING)

    const hasFreeShipping = order ? !!order.isFreteFree : cartHasFreeShipping.value

    const promotion = order ? getOrderPromov2(order) : appliedPromotion.value
    const { coupon, coupon2, coupon3 } = extractCouponsFromPromotions({
      promotions: promotion ? [promotion] : [],
      hasFreeShipping,
    })

    // ecommerce
    const currencyAcronym = currency.value
    free_shipping.ecommerce.currency = currencyAcronym
    free_shipping.ecommerce.points_spent = 0
    free_shipping.ecommerce.loyalty = false
    // promos
    free_shipping.ecommerce.coupon = coupon
    free_shipping.ecommerce.coupon2 = coupon2
    free_shipping.ecommerce.coupon3 = coupon3

    const items = cartProducts
      ? cartProducts.map(cP =>
          gtmEcommerceItemFromCartProduct({
            cartProduct: cP,
            currency: currencyAcronym,
            coupon,
          })
        )
      : order?.products?.map(oP =>
          gtmEcommerceItemFromOrderProduct({
            orderProduct: oP,
            currency: currencyAcronym,
            coupon,
            isPreOrder: order.orderMcDia,
          })
        )
    free_shipping.ecommerce.items = items

    sendEvent(free_shipping)
  }

  const trackSelectPickupSelection = (saleType: SaleType) => {
    const eventName =
      saleType === StoreOrderSaleType.EATIN
        ? GtmEventName.SELECT_PICKUP_RESTAURANT
        : GtmEventName.SELECT_PICKUP_TAKE_AWAY
    const select_pickup_selection = eventGeneralInfo(eventName)

    sendEvent(select_pickup_selection)
  }

  const trackClickChangeRestaurant = () => {
    const click_change_restaurant = eventGeneralInfo(GtmEventName.CLICK_CHANGE_RESTAURANT)
    click_change_restaurant.warning_type = GtmEventName.TOO_FAR

    sendEvent(click_change_restaurant)
  }

  const trackClickDontChangeRestaurant = () => {
    const click_dont_change_restaurant = eventGeneralInfo(GtmEventName.CLICK_DONT_CHANGE_RESTAURANT)
    click_dont_change_restaurant.warning_type = GtmEventName.TOO_FAR

    sendEvent(click_dont_change_restaurant)
  }

  const trackClickCategory = (categoryTitle: string, categoryIndex: number) => {
    const click_category = eventGeneralInfo(GtmEventName.CLICK_CATEGORY)
    click_category.category_name = categoryTitle
    click_category.category_index = categoryIndex

    sendEvent(click_category)
  }

  const sendEvent = (ev: GTMEvent) => {
    if (process.client) {
      if (!window.dataLayer) window.dataLayer = []
      window.dataLayer.push(ev)
      debug(`New event pushed to datalayer [${ev.event}]`)
    } else debug(`Event on server, will not be sended [${ev.event}]`)
  }

  const trackLogout = ({ type }: { type: 'single' | 'multiple' }) => {
    const logout = eventGeneralInfo(GtmEventName.LOGOUT)
    logout.method = type
    sendEvent(logout)
  }

  return {
    // views
    trackPageView,
    trackOnCloseModal,
    // ecommerce events
    trackEcommerceHome,
    trackViewItemList,
    trackSelectItem,
    trackViewItem,
    trackAddToCart,
    trackRemoveFromCart,
    trackBeginCheckout,
    trackAddShippingInfo,
    trackAddPaymentInfo,
    trackPurchase,
    trackRefund,
    trackNoRider,
    trackNoCoverage,
    trackCustomError,
    trackSelectRestaurant,
    trackChangeDeliveryOptions,
    trackConfirmAddress,
    trackRepeatOrder,
    trackRepeatOrderOK,
    trackGoEcommerce,
    trackSelectPaymentType,
    trackClickArea,
    trackAddDiscount,
    trackApplyCoupon,
    trackAddFavorites,
    trackRemoveFavorites,
    trackOrderCancelledBefore,
    trackSearch,
    trackTrackMyOrder,
    trackOrderFailed,
    trackViewCart,
    trackDeliveryOptions,
    trackOnWay,
    trackSelectPickup,
    trackTooFar,
    trackDisplayMapList,
    trackClickWhatsApp,
    trackClick,
    trackFormError,
    trackSelectRestaurantAutomatic,
    trackSelectAddress,
    trackClosedRestaurant,
    trackGoToPay,
    trackWithoutLogin,
    trackSavePayment,
    trackClickInOrderListTabs,
    trackConfirmPopup,
    trackFormSubmit,
    trackSendCode,
    trackGeolocate,
    trackAddPaymentMethod,
    trackSurveySubmit,
    trackCustomizeItem,
    trackAdditionalComponent,
    trackSelectComponent,
    trackCustomizeIngredients,
    trackSaveIngredients,
    trackAddTip,
    trackWalkthrough,
    trackWalkthroughClose,
    trackWalkthroughAccount,
    trackGetBill,
    trackValidationWarning,
    trackEnrollmentCompleted,
    trackClickGetPreOrder,
    trackGetPreOrder,
    trackDeclineGetPreOrder,
    trackViewOrderDetail,
    trackViewOrders,
    trackIncompatibleProduct,
    trackFreeShipping,
    trackSelectPickupSelection,
    trackClickChangeRestaurant,
    trackClickDontChangeRestaurant,
    trackClickCategory,
    trackLogout,
  }
}
