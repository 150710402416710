import { GTMReducedPromo } from '~/lib/models/gtm'
import {
  PromotionType,
  type promotionTypeType,
  type Promotion,
} from '~/lib/services/promos/promos.dto'
import { StoreOrder } from '~/lib/services/store/order/order.dto'
import { ProductDetailPromotion, ProductPromotion } from '~/lib/services/store/common.dto'

export const promoErrorKey = (numberCode: number) => `promoError_${numberCode}`

export const parsePromotionForCart = (promo: Promotion) => {
  const applyDiscountPromotionOrder =
    promo.type === PromotionType.ORDER_AMOUNT || promo.type === PromotionType.ORDER_PERCENTAGE
  return {
    id: promo.promotionId,
    basicProperties: {
      enabled: promo.enabled,
    },
    criteria: {
      products: promo.productsRequired,
    },
    discount: {
      type: promo.type,
      amount: applyDiscountPromotionOrder ? promo.amount : promo.discount?.amount,
      products: promo.products?.length ? promo.products : undefined,
      montoMaxDiscount: promo.montoMaxDiscount ?? 0,
      all: promo.discount?.all,
      applyWithoutAllProducts: promo.discount?.applyWithoutAllProducts,
      qty: promo.discount?.qty,
    },
  }
}

export const getOrderPromoStr = (order: StoreOrder, onlyV2 = true) => {
  if (!onlyV2 && !order.promotions && !order?.promotionsV2) return undefined

  if (!onlyV2 && order.promotions?.length) {
    const promoLegacy = order.promotions?.[0]
    return promoLegacy.coupon
      ? `{shoppingCart.promotions.addPromo.prefix.coupon} <b>${promoLegacy.coupon}</b>`
      : `{shoppingCart.promotions.addPromo.prefix.employee}`
  }
  if (order.promotionsV2?.length) {
    const promoV2 = order.promotionsV2[0]
    return promoV2.code
      ? `{shoppingCart.promotions.addPromo.prefix.coupon} <b>${promoV2.code}</b>`
      : `{shoppingCart.promotions.addPromo.prefix.employee}`
  }
  return undefined
}

export const getOrderPromov2 = (order: StoreOrder): GTMReducedPromo | undefined => {
  if (order.promotionsV2?.length) {
    const { code, name, promotionSnapShotId: promotionId, type } = order.promotionsV2[0]
    return {
      code,
      name,
      promotionId,
      type,
    }
  }
  return undefined
}

export const VALID_PROMOS_BUBBLE: promotionTypeType[] = [
  PromotionType.PRODUCT_FIXED_PRICE,
  PromotionType.PRODUCT_PERCENTAGE,
  PromotionType.PRODUCT_AMOUNT,
]

export const getPromoLabel = (promo: ProductPromotion) => {
  const { format } = useFormatAmount()
  const { t } = useLocale()

  if (promo.prices?.priceWithDiscount === 0) return t('common.free')

  let label
  switch (promo.type) {
    case PromotionType.PRODUCT_AMOUNT:
      label = `${format(promo.amount!)} OFF`
      break
    case PromotionType.PRODUCT_FIXED_PRICE:
      label = t('common.offer')
      break
    case PromotionType.PRODUCT_PERCENTAGE:
      label = `${promo.amount}% OFF`
      break

    default:
      break
  }
  return label
}

const promoInSegmentation = (promoSegment: string[] | undefined, userSegmentation: string[]) => {
  if (!promoSegment || promoSegment.length === 0) return true
  const valid = arraysMatch<string>(promoSegment, userSegmentation)
  return valid
}

export const validPromoForBubble = (
  promotions: ProductPromotion[] | undefined,
  userSegmentation: string[]
): ProductPromotion | undefined | false => {
  if (!promotions || promotions.length === 0) return false
  const validPromo = promotions.find(
    promo =>
      VALID_PROMOS_BUBBLE.includes(promo.type as promotionTypeType) &&
      promoInSegmentation(promo.clientSegmentation ?? [], userSegmentation)
  )
  return validPromo
}

export const checkFreeShippingPromo = async (
  promotions: ProductPromotion[]
): Promise<ProductDetailPromotion | undefined> => {
  const { tryCheckPromos } = usePromotions()

  const promotion = promotions.find(promo => {
    const isShipping = promo.type === PromotionType.SHIPPING_PERCENTAGE
    // valid if clientSegmentation is undefined or empty
    const validSegmentation = !promo.clientSegmentation?.length
    return isShipping && validSegmentation
  })

  if (!promotion) return undefined

  const responseCheckPromos = await tryCheckPromos({ promotionsId: [promotion.id] })

  if (!responseCheckPromos || !responseCheckPromos[0]) return undefined

  const { promotionId: id, type } = responseCheckPromos[0]
  return { id, type: type!, amount: 100 }
}
