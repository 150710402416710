import termsESRP from '../markdown/vouchers/termsES_RP.md?raw'

export default {
  pickupMethods: {
    areaSelection: {
      areas: {
        MOP: {
          step: {
            title: '¿Cómo preferís tu pedido?',
            subtitle: 'Elegí la opción que desees para que podamos prepararlo',
          },
        },
      },
    },
  },
  errors: {
    alerts: {
      noCoverage: {
        title: 'McDelivery no está disponible ahora',
        body: 'Mientras tanto, podés hacer un pedido y retirarlo en el restaurante más cercano con Pedí y Retirá',
        accept: 'Pedí y Retirá',
        cancel: 'Intentar más tarde',
      },
      noRiders: {
        title: 'Nuestros repartidores están ocupados',
        body: 'Podés intentar más tarde o hacer un pedido y retirarlo con Pedí y Retirá',
        accept: 'Pedí y Retirá',
        cancel: 'Intentar más tarde',
      },
      areaNotFound: {
        title: 'El restaurante no está disponible para los productos seleccionados',
        body: 'Podés elegir otro restaurante o volver a tu pedido y cambiar los productos',
        accept: 'Volver a mi pedido',
        cancel: 'Cambiar restaurante',
      },
      productNotAvailable: {
        title: '¡La promoción ya finalizó!',
        body: 'Cambiá los productos de tu pedido para continuar con la compra',
        accept: 'Volver a mi pedido',
        cancel: '',
      },
      promotionNotFound: {
        title: '¡La promoción ya finalizó!',
        body: 'Cambiá los productos de tu pedido para continuar con la compra',
        accept: 'Volver a mi pedido',
        cancel: '',
      },
      limitPriceOrder: {
        title: 'El valor total de tu compra es demasiado alto',
        body: 'Has superado el límite permitido de {value}. Por favor, revisá los productos del carrito',
        accept: 'Volver a mi pedido',
        cancel: '',
      },
      platformNotValidForPromo: {
        title: 'Esta promoción no es compatible',
        body: 'Por favor, seleccioná otro método de pago para completar la compra',
        accept: 'Elegir otro método de pago',
        cancel: '',
      },
      restaurantNotLoyalty: {
        title: 'Este restaurante no participa de {value}',
        body: 'Para continuar, cambiá los productos de tu pedido o elegí otro restaurante. Si lo hacés, se vaciará tu carrito',
        accept: 'Volver a mi pedido',
        cancel: 'Cambiar restaurante',
      },
      limitedPromoProduct: {
        title: 'Tu pedido ya incluye productos con descuento',
        body: '¿Querés mantener los descuentos actuales o aplicar el nuevo código?',
        accept: 'Mantener descuentos',
        cancel: 'Aplicar nuevo código',
      },
      employeeDiscount: {
        title: 'Tu pedido ya tiene descuentos aplicados',
        body: '¿Querés reemplazar la promoción o agregar el producto sin descuento?',
        accept: 'Agregar sin descuento',
        cancel: 'Reemplazar promoción',
      },
      pickup_serviceNumberEmpty: {
        body: 'Tuvimos un problema al cargar el número que ingresaste. Por favor, intentalo de nuevo',
      },
      pickup_anyPickupAreaForThisOrderIsAvailable: {
        body: 'Podés cancelar el pedido para obtener un reembolso y elegir otra opción ¡Te pedimos disculpas!',
      },
      pickup_orderPickupNotAvailable: {
        body: 'Podés cancelar el pedido para obtener un reembolso y elegir otra opción ¡Te pedimos disculpas!',
      },
      pickup_areaNotAvailable: {
        body: 'Podés cancelar el pedido para obtener un reembolso y elegir otra opción ¡Te pedimos disculpas!',
      },
      cumulativePromotionsNotAllowed: {
        title: 'Tu pedido ya tiene descuentos aplicados',
        body: '¿Querés reemplazar la promoción o agregar el producto sin descuento?',
        accept: 'Agregar sin descuento',
        cancel: 'Reemplazar promoción',
      },
      autoApplicablePromotionNotAllowed: {
        title: 'Tu pedido ya incluye productos con descuento',
        body: '¿Querés mantener los descuentos actuales o aplicar el nuevo código?',
        accept: 'Mantener descuentos',
        cancel: 'Aplicar nuevo código',
      },
    },
  },
  orders: {
    delivery: {
      description: 'Informá el código al repartidor únicamente al recibir tu pedido',
    },
    messages: {
      yappy:
        'Si tenés dudas sobre tu pedido realizado con Yappy, contactanos a través de nuestro chat',
      flexCancelledReason: {
        FLEX_CANCELLED_REFUND:
          'Tu pedido ha sido cancelado. Pero no te preocupes, ya estamos gestionando el reembolso de tu dinero. <br/><br/>¡Te pedimos disculpas!',
      },
    },
    toast: {
      orderUnappliedProducts:
        'La promoción fue añadida, pero no aplica a los productos de tu pedido',
    },
  },
  restaurantTooltipAlert: {
    farFromRestaurantWithOrder: 'Notamos que estás lejos del restaurante donde hiciste el pedido',
    farFromRestaurantWithoutOrder:
      'Notamos que estás lejos del restaurante donde hiciste el pedido. Podés cambiarlo presionando sobre la dirección',
  },
  enrollment: {
    Document: {
      warning: {
        title: 'Documento ya registrado',
        body: 'Este número de documento ya está registrado para el email {email}. Para más información, ponete en contacto con ',
      },
    },
  },
  vouchers: {
    title: 'Tenés disponibles',
    description: 'Podés obtener un código Minecraft si tenés los puntos necesarios',
    redeemCoupon: {
      title: '¿Querés canjear <br/><b>{value} pts.</b> para obtener <br/>un código?',
      accept: 'Quiero obtener un código',
      cancel: 'Ahora no',
    },
    appliedCoupon: {
      title: '¡Felicitaciones! Acabás de obtener tu código Minecraft',
      useCodeInfo: '¿Cómo usar los códigos?',
      codeExpiration: 'Tu código vence el',
      accept: 'Volver',
    },
    codeList: {
      title: 'Ver mis códigos obtenidos',
      description: 'Códigos',
      code: 'Código',
      expiration: 'Caducidad',
    },
    notification: {
      title: 'Solo se permite activar un código por cuenta Minecraft',
      description:
        "Canjeá tus puntos desde el { startDate } al { endDate } o hasta agotar el stock de { stock } unidades. El código activa 25 complementos (Add-ons) de McDonald's en Minecraft: Bedrock Edition",
    },
    errors: {
      generic: 'Algo no salió como esperábamos. Intentalo de nuevo más tarde',
      maximumPurchasesExceeded: 'Superaste el número máximo de compras de códigos Minecraft',
      noPointsAvailable:
        'No tenés los puntos necesarios para obtener un código. Necesitás { value } pts. para conseguirlo',
    },
  },
  markdown: {
    vouchers: {
      terms: termsESRP,
    },
  },
}
