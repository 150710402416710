import { AxiosInstance, AxiosRequestConfig } from 'axios'
import Debug from 'debug'
import { z } from 'zod'
import { isZodError } from '~/types/typeUtils'

export abstract class BaseService {
  abstract readonly client: AxiosInstance
  protected debug: Debug.Debugger = Debug('app:base-service')

  protected async request<T extends z.ZodType>(
    config: AxiosRequestConfig,
    schema: T
  ): Promise<z.infer<T>> {
    try {
      const res = await this.client(config)
      return await schema.parseAsync(res.data)
    } catch (error: any) {
      if (isZodError(error)) {
        this.debug('ZodError ->', error)
      }
      throw error
    }
  }
}
