import { MdwService } from '~/lib/services/middleware/middleware.service'
import { catalogService } from '~/lib/services/store/catalog/catalog.service'
import { countryService } from '~/lib/services/store/country/country.service'
import { orderService } from '~/lib/services/store/order/order.service'
import { paymentService } from '~/lib/services/store/payment/payment.service'
import { restaurantService } from '~/lib/services/store/restaurant/restaurant.service'

export const useStoreService = () => {
  const contentStore = useContentStore()
  const country = computed(() => contentStore.countryCode)

  return {
    countryServiceRef: () => countryService(country.value),
    restaurantServiceRef: () => restaurantService(country.value),
    catalogServiceRef: () => catalogService(country.value),
    paymentServiceRef: () => paymentService(country.value),
    orderServiceRef: () => orderService(country.value),
    // middleware
    middlewareServiceRef: () => MdwService(country.value),
  }
}
