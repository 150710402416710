import { StorageSerializers } from '@vueuse/core'
import Debug from 'debug'
import { DateTime } from 'luxon'

const debug = Debug('app:composable:useApiSessionCache')
/**
 * A composable for caching request on client with the option to expire and revalidate requests or cache them indefinitely
 *
 * @param {string} key
 * key for caching in sessionStorage
 *
 * @param {() => Promise<T>} promise
 * The actual service function or custom function to fetch data
 * @example const promise async () => await catalogService.fetchSuggestive({...})
 *
 * @param {{secondsToRevalidate: number, clearCache: boolean}} options
 */
export default async <T>(
  key: string,
  promise: () => Promise<T>,
  options: {
    secondsToRevalidate?: number
    clearCache?: boolean
  } = {}
) => {
  // SSR prevention or Cypress
  if (process.server || isCypressSpecialBehaviour()) return await promise()

  debug(`Intent to get data for key = '${key}'`)

  // Use sessionStorage to cache data
  const cached = useSessionStorage<{ datetime: string; data: T }>(key, null, {
    serializer: StorageSerializers.object,
  })

  // Must revalidate data cached if any
  let mustRevalidate = !!options.clearCache

  // If not is clear cache, check data stalled time to know if mustRevalidate
  if (!options.clearCache && cached.value?.datetime) {
    const now = DateTime.utc()
    const nowMillis = now.valueOf()
    const revalidateMillis = DateTime.fromISO(cached.value.datetime, { zone: 'utc' }).valueOf()
    mustRevalidate = nowMillis >= revalidateMillis
  }

  if (cached.value && mustRevalidate) {
    // Directly set the cache to null to mimic no cache value
    cached.value = null
  }

  // No cache or has been cleared, fetch service promise
  if (!cached.value) {
    debug(`Fetching data for key = '${key}'`)
    const data = await promise()

    const datetimeToRevalidate = options.secondsToRevalidate
      ? DateTime.utc().plus({ seconds: options.secondsToRevalidate }).toISO()
      : ''

    // Update cache value
    cached.value = {
      datetime: datetimeToRevalidate,
      data,
    }
  } else {
    debug(`Getting value from cache for key = '${key}'`)
  }

  return cached.value.data
}
