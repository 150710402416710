export const profileMenuSection = () => {
  const { t } = useLocale()
  const appStore = useAppStore()

  const profile = [
    {
      title: t('profile.sections.personalData'),
      icon: '/images/icons/profileSections/profile.svg',
      url: `/${t('routes.profile')}/${t('routes.personalData')}`,
    },
    {
      title: t('profile.sections.historyOrders'),
      icon: '/images/icons/profileSections/listView.svg',
      url: `/${t('routes.profile')}/${t('routes.orders')}`,
    },
  ]

  if (appStore.canShowSignInAndSecurity)
    profile.push({
      title: t('profile.sections.signInSecurity'),
      icon: '/images/icons/profileSections/privacy.svg',
      url: `/${t('routes.profile')}/${t('routes.signInSecurity')}`,
    })

  return profile
}

export const getAsideTitle = (path: string) => {
  const currentSection = profileMenuSection().find(section => section.url === path)
  const sectionTitle = currentSection?.title || ''
  return sectionTitle
}
