import { defineStore } from 'pinia'
import Debug from 'debug'
import {
  Toast,
  ToastConfig,
  ToastLocation,
  ToastType,
  defaultToastDuration,
} from '~/lib/models/ui/toast'
import { GTMViewContentGroup } from '~/lib/models/gtm'
import {
  CloudFrontHeaders,
  CloudFrontUserAgents,
  PlatformType,
  getPlatformFromUserAgent,
} from '~/lib/models/smartbanners'
import { Smartbanner } from '~/lib/services/content/content.configuaration.dtos'

const debug = Debug('app:store:layout')

// Maybe in a future for special modal to be show (closedRestaurant ... etc), list of ordered alerts to be shown ..
export const useLayoutStore = defineStore('layout', {
  state: () => ({
    toasts: [] as Array<Toast>,
    isMenuSideBarOpen: false,
    // pageView
    viewGTMContent: undefined as GTMViewContentGroup | undefined,
    pageViewGTMContent: undefined as GTMViewContentGroup | undefined,
    // smartbanners
    platform: 'web' as PlatformType,
    smartbanner: undefined as Smartbanner | undefined,
    smartbannerExpirationDate: undefined as string | undefined,
    loaderOverlay: false,
    onboarding: false,
  }),
  actions: {
    getPlatform() {
      if (process.server) {
        let userAgent = ''
        const headers = useRequestHeaders()
        if (headers[CloudFrontHeaders.ANDROID] === 'true') userAgent = CloudFrontUserAgents.ANDROID
        else if (headers[CloudFrontHeaders.IOS] === 'true') userAgent = CloudFrontUserAgents.IOS
        else userAgent = headers['user-agent']
        debug('userAgent', userAgent)
        const platform = getPlatformFromUserAgent(userAgent || '')
        debug('platform', platform)
        this.platform = platform
      }
    },
    addToast(config: ToastConfig) {
      const location = config.location || ToastLocation.TOP
      const key = `${location}_${new Date().toISOString()}`
      // If once is true, remove all toasts before adding the new one.
      if (config.once) {
        this.toasts = []
      }
      const toast: Toast = {
        key,
        type: config.type || ToastType.SUCCESS,
        location,
        duration: config.duration || defaultToastDuration,
        content: config.content,
      }

      const toasts = [...this.toasts]
      toasts.unshift(toast)
      this.toasts = toasts
    },
    removeNotification(key: string) {
      const indexOfToast = this.toasts.findIndex(notif => notif.key === key)
      if (indexOfToast !== -1) {
        const toasts = [...this.toasts]
        toasts.splice(indexOfToast, 1)
        this.toasts = toasts
      }
    },
    clearAllToasts() {
      this.toasts = []
    },
    openMenuSideBar() {
      this.isMenuSideBarOpen = true
    },
    closeMenuSideBar() {
      this.isMenuSideBarOpen = false
    },
    setViewGTMContent(content: GTMViewContentGroup, modal = false) {
      this.viewGTMContent = content
      if (!modal) this.pageViewGTMContent = content
    },
    setSmartbanner(smartbanner: Smartbanner | undefined) {
      this.smartbanner = smartbanner
    },
    setLoaderOverlay(val: boolean) {
      this.loaderOverlay = val
    },
    setOnboarding(val: boolean) {
      this.onboarding = val
    },
  },
  persist: [
    {
      paths: ['smartbannerExpirationDate'],
      storage: persistedState.localStorage,
    },
  ],
})
