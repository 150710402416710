<template>
  <section
    id="loading-transition"
    class="fixed left-0 right-0 top-0 z-[51] flex h-[100svh] w-full flex-col items-center justify-center bg-[rgba(41,41,41,0.7)] backdrop-blur md:overflow-hidden"
  >
    <img
      src="/images/icons/checkout/yuno-loading-spinner.svg"
      class="h-auto w-6 animate-spin md:w-[6rem]"
    />
  </section>
</template>

<style>
.mcd-loading-transition__spinner {
  width: 24px;
  height: auto;
  -webkit-animation: mcdloader 1.4s infinite linear;
  animation: mcdloader 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.mcd-loading-transition__spinner {
  width: 96px;
  height: auto;
}
</style>
