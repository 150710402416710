import revive_payload_client_PeCEI1ZyW6yKvYbXxsxJSUy_kdUUN2gxuAQQH3cdeMY from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_encoding@0.1.13_eslint@8.57._dodylrhrl4rkex2mhf7qirzkcm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Wqz9ARphfaBhB6ICs0Yogmu6NTqGkmS9NFq23zq2oqg from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_encoding@0.1.13_eslint@8.57._dodylrhrl4rkex2mhf7qirzkcm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XoBSSLDa85nb2HSOvJgrCYS4JuidTvAMqrpo3oMmZBc from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_encoding@0.1.13_eslint@8.57._dodylrhrl4rkex2mhf7qirzkcm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_KGT1p973or4_smHalGW68am7_JvtN2_hJ8WArNITmGU from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_encoding@0.1.13_eslint@8.57._dodylrhrl4rkex2mhf7qirzkcm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_nyCaKgddWfscc9_pshtGtOU5UpCcIYLa7Kuzqs8Jn18 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_encoding@0.1.13_eslint@8.57._dodylrhrl4rkex2mhf7qirzkcm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_57H_sCaGeue6jAl30UR8orNefRVIyWIIkdBSV2qrKsE from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_encoding@0.1.13_eslint@8.57._dodylrhrl4rkex2mhf7qirzkcm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_B7Akldu9MOnFVA7M_cYKWigwFuU7PO909bQcFFiZfa8 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_encoding@0.1.13_eslint@8.57._dodylrhrl4rkex2mhf7qirzkcm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qSLzL9RM4Tp5exJoSa2P_Y9i_SSs_zyvpkSqsmsSv3A from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_lfK_OnB1hLWh5_YsosSUdJnjJlilhVCQ7q4QP7IEhNQ from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@18.19.86_db0@0.3.1_encoding@0.1.13_eslint@8.57._dodylrhrl4rkex2mhf7qirzkcm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_l4L52xHOZoqtuM6x7oIoaMaLHzN4QzMBHVTNyIFEoDA from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.4.11_magicast@0.3.5_typescript@5.8.2_vu_gd5fcmsbe72hak46ge2fhqz6wa/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _0_debug_Yor5j8u0pTQ3F6c8MU4rbozitbMZtWpVEmbRQ0bkQv8 from "/app/plugins/0.debug.ts";
import _1_primevue_8Ej7DUIML8zxkCQ_3UlpoMwhGeudq4eZXIFEN6S9nnE from "/app/plugins/1.primevue.ts";
import _2_i18n_Wi0uiSe_maKv3B9lbi_2kIgGr6C6QsA94NVhAvrVsG0 from "/app/plugins/2.i18n.ts";
import _3_toast_KlZcg4rDGT9HxFrFAgL_hCPGSyEpG4_PUL5mr4JERWI from "/app/plugins/3.toast.ts";
import _4_vueGoogleMaps_mO9r3ZcbtZ15LwWh1fzyyy__w4UYRU20sSio_uyoBxM from "/app/plugins/4.vueGoogleMaps.ts";
import _5_maska_YVwPvZSGKA8oRCcZLiK6Is23aS7RZnUToR4Wq6yGVl4 from "/app/plugins/5.maska.ts";
import _6_dompurify_nh1UEXCfY6T7J_n_imSe4tttzxWtUk89kzAnEn98tPk from "/app/plugins/6.dompurify.ts";
import _7_vue_popper_Ii8FDKhXcRMSdGhg4w9v9tDPN9hmRPNqa1ti1GOK3II from "/app/plugins/7.vue-popper.ts";
import _8_auth_client_O6L_oFq0AHJXZhzRjgsAKGWumup_kdyFsjDmuxuDZmI from "/app/plugins/8.auth.client.ts";
import _8_rum_1QX2WfMFy7VNRpNdiVGnTKsP5pYHVsLw1z9jumo0_go from "/app/plugins/8.rum.ts";
import plugin_client_lxWKvJVWQ7nxykTLpu__093p6rdFQhYd_ZKYo3UMtbc from "/app/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_PeCEI1ZyW6yKvYbXxsxJSUy_kdUUN2gxuAQQH3cdeMY,
  unhead_Wqz9ARphfaBhB6ICs0Yogmu6NTqGkmS9NFq23zq2oqg,
  router_XoBSSLDa85nb2HSOvJgrCYS4JuidTvAMqrpo3oMmZBc,
  payload_client_KGT1p973or4_smHalGW68am7_JvtN2_hJ8WArNITmGU,
  navigation_repaint_client_nyCaKgddWfscc9_pshtGtOU5UpCcIYLa7Kuzqs8Jn18,
  check_outdated_build_client_57H_sCaGeue6jAl30UR8orNefRVIyWIIkdBSV2qrKsE,
  chunk_reload_client_B7Akldu9MOnFVA7M_cYKWigwFuU7PO909bQcFFiZfa8,
  plugin_vue3_qSLzL9RM4Tp5exJoSa2P_Y9i_SSs_zyvpkSqsmsSv3A,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_lfK_OnB1hLWh5_YsosSUdJnjJlilhVCQ7q4QP7IEhNQ,
  plugin_l4L52xHOZoqtuM6x7oIoaMaLHzN4QzMBHVTNyIFEoDA,
  _0_debug_Yor5j8u0pTQ3F6c8MU4rbozitbMZtWpVEmbRQ0bkQv8,
  _1_primevue_8Ej7DUIML8zxkCQ_3UlpoMwhGeudq4eZXIFEN6S9nnE,
  _2_i18n_Wi0uiSe_maKv3B9lbi_2kIgGr6C6QsA94NVhAvrVsG0,
  _3_toast_KlZcg4rDGT9HxFrFAgL_hCPGSyEpG4_PUL5mr4JERWI,
  _4_vueGoogleMaps_mO9r3ZcbtZ15LwWh1fzyyy__w4UYRU20sSio_uyoBxM,
  _5_maska_YVwPvZSGKA8oRCcZLiK6Is23aS7RZnUToR4Wq6yGVl4,
  _6_dompurify_nh1UEXCfY6T7J_n_imSe4tttzxWtUk89kzAnEn98tPk,
  _7_vue_popper_Ii8FDKhXcRMSdGhg4w9v9tDPN9hmRPNqa1ti1GOK3II,
  _8_auth_client_O6L_oFq0AHJXZhzRjgsAKGWumup_kdyFsjDmuxuDZmI,
  _8_rum_1QX2WfMFy7VNRpNdiVGnTKsP5pYHVsLw1z9jumo0_go,
  plugin_client_lxWKvJVWQ7nxykTLpu__093p6rdFQhYd_ZKYo3UMtbc
]