<script setup lang="ts">
import get from 'lodash/get'
import { OnClickOutside } from '@vueuse/components'
import OverlayPanel from 'primevue/overlaypanel'

const props = withDefaults(
  defineProps<{
    isTop?: boolean
    dismissable?: boolean
    appendValue?: string
    landingClass?: string
  }>(),
  {
    isTop: false,
    dismissable: true,
    appendValue: undefined,
    landingClass: undefined,
  }
)
const isMobile = inject<boolean>('isMobile')

const op = ref<OverlayPanel>()
const ignoreElRef = ref()
const heightBaseY = ref<number>(0)

const isVisible = computed(() => {
  if (op?.value) {
    const hasVisible = get(op.value, 'visible', undefined)
    return !!hasVisible
  }
  return false
})

const toggle = (event: any) => {
  op.value?.toggle(event)
  extraComputeAfterShowOrToggle(event)
}

const show = (event: any) => {
  if (!isVisible.value) op.value?.show(event)
  extraComputeAfterShowOrToggle(event)
}

const extraComputeAfterShowOrToggle = (event: any) => {
  if (event && props.isTop) {
    const { offsetHeight, offsetTop } = event.target
    // page Y - h of action element
    const distanceBaseY = offsetTop - offsetHeight
    heightBaseY.value = distanceBaseY
  } else {
    heightBaseY.value = 0
  }
}

const onShow = (isMobile = false) => {
  if (props.isTop) {
    const overlay: HTMLElement | null = document.querySelector('.p-overlaypanel')
    if (overlay) {
      const overlayHeight = overlay.clientHeight
      const marginBottom = isMobile ? 0 : 10
      // Base Y from Top - overlay height + a bit of margin for desktop
      const fromTop = heightBaseY.value - overlayHeight + marginBottom
      overlay.style.top = `${fromTop}px`
      overlay.style.bottom = `inherit`
      // overlay.style.marginTop = marginTop
      overlay.classList.add('overlay-arrow-bottom')
    }
  }
}

const onClickedOutside = () => {
  if (props.dismissable && isVisible.value) {
    op?.value?.hide()
  }
}
</script>

<template>
  <div class="card justify-content-center flex">
    <div ref="ignoreElRef">
      <slot name="actionElement" :toggle="toggle" :show="show"></slot>
    </div>

    <OverlayPanel
      ref="op"
      :class="landingClass"
      :pt="{
        content: { class: '!p-0 !z-10 speedee-font-family' },
      }"
      :dismissable="false"
      :append-to="appendValue"
      @show="onShow(isMobile)"
    >
      <OnClickOutside :options="{ ignore: [ignoreElRef] }" @trigger="onClickedOutside">
        <slot name="tooltipContent" :overlay-closed="toggle"></slot>
      </OnClickOutside>
    </OverlayPanel>
  </div>
</template>

<style>
.overlay-arrow-bottom::before {
  bottom: initial;
  top: 100%;
  transform: rotate(180deg);
}
.overlay-arrow-bottom::after {
  bottom: initial;
  top: 100%;
  transform: rotate(180deg);
}
</style>
