import { z } from 'zod'

/**
 * Image
 */
export const imageDto = z.object({
  id: z.number(),
  height: z.number(),
  width: z.number(),
  url: z.string(),
  alternativeText: z.string().nullable().optional(),
})
export type StrapiImage = z.infer<typeof imageDto>

/**
 * Link
 */
export const linkDto = z.object({
  title: z.string().nullable(),
  url: z.string().nullable(),
  blank: z.boolean(),
  download: z.boolean(),
  action: z.enum(['user-login', 'user-register']).nullable(),
  icon: imageDto.nullable(),
  page: z
    .object({
      id: z.number(),
      name: z.string(),
      path: z.string(),
    })
    .nullable(),
})
export type Link = z.infer<typeof linkDto>

/**
 * Button With Image (Social Media, Download Button)
 */
export const buttonImageDto = z.object({
  image: imageDto,
  open_new_window: z.boolean().nullable(),
  download: z.boolean().nullable(),
  title: z.string().nullable(),
  url: z.string().nullable(),
})
export type ButtonImage = z.infer<typeof buttonImageDto>
