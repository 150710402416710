import { z } from 'zod'
import { imageDto, linkDto } from '../content.shared.dtos'

/**
 * Download Section (Android, iOS Buttons)
 */
export const downloadSectionDto = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  button: linkDto.nullable().optional(),
  image: imageDto.nullable().optional(),
})

export type DownloadSection = z.infer<typeof downloadSectionDto>

/**
 * Menu Item
 *  */
export const menuItemsDto = z.object({
  id: z.number(),
  title: z.string(),
  icon: imageDto.nullable(),
  link: linkDto.nullable(),
})
export type MenuItems = z.infer<typeof menuItemsDto>

/**
 * Menu Section
 *
 * */
export const menuSectionsDto = z.object({
  id: z.number(),
  title: z.string(),
  linkPages: z.array(linkDto.nullable().optional()),
})
export type MenuSection = z.infer<typeof menuSectionsDto>

/**
 * Items Social
 *  */
export const menuItemSocialDto = z.object({
  id: z.number(),
  title: z.string().optional(),
  linkSocial: z.array(linkDto.optional()),
  content: z.string().optional(),
})
export type MenuItemSocial = z.infer<typeof menuItemSocialDto>

/**
 * Menu Section Social
 *  */
export const menuSectionSocialDto = z.object({
  id: z.number(),
  title: z.string(),
  specialButtonContact: linkDto.nullable().optional(),
  menuItemsSocial: z.array(menuItemSocialDto),
})
export type MenuSectionSocial = z.infer<typeof menuSectionSocialDto>

/**
 * Legal Brand
 */
export const legalBrandDto = z.object({
  brand: z.string().nullable(),
  legal: linkDto.nullable(),
  privacy: linkDto.nullable(),
})
export type LegalBrand = z.infer<typeof legalBrandDto>
