import { DialogContent } from '~/lib/models/ui/dialog'
import { PromoInput, Promotion, PromotionKindType } from '~/lib/services/promos/promos.dto'
import { promosService } from '~/lib/services/promos/promos.service'
import { PromoCustomError } from '~/lib/services/store/country/country.dtos'
import { promoErrorKey } from '~/utils/promoUtils'

export const usePromotions = () => {
  const appStore = useAppStore()
  const authStore = useAuthStore()
  const restaurantStore = useRestaurantStore()
  const { trackApplyCoupon } = useGTM()

  const arePromotionsActive = computed(() => !!appStore.ecommerceConfig?.promotion?.engineIsOnline)
  const userLogged = computed(() => !!authStore.isUserLogged)
  const appliedPromotions = computed(() => appStore.appliedPromotions)
  const discountAmount = computed(() =>
    appliedPromotions.value?.length ? appStore.cartDiscount : 0
  )

  const buildPromoCustomErrors = () => {
    if (!arePromotionsActive.value || !appStore.ecommerceConfig?.promotion?.customErrors.length)
      return {}
    const obj: Record<string, PromoCustomError> = {}
    for (const err of appStore.ecommerceConfig?.promotion?.customErrors) {
      const key = promoErrorKey(err.numberCode)
      obj[key] = err
    }
    return obj
  }

  const promoCustomErrors = ref(buildPromoCustomErrors())

  const getPromotionsBody = (): PromoInput | undefined => {
    if (!authStore.token || !restaurantStore.areaSelected || !restaurantStore.restaurant)
      return undefined
    return {
      customerToken: authStore.token,
      countryCode: appStore.countryCode,
      area: restaurantStore.areaSelected,
      restaurant: restaurantStore.restaurant.id,
      price: {
        total: appStore.cartTotal,
        tax: appStore.cartTax,
        tip: appStore.cartTipOption,
      },
      products: appStore.cart.products.map(p => ({
        id: p.identifier,
        qty: p.unit,
        isPromo: !!p.isPromo,
      })),
    }
  }

  const getCheckPromosInput = ({
    promotionsId,
    products,
  }: {
    promotionsId: string[]
    products?: string[]
  }) => {
    if (!authStore.token || !restaurantStore.areaSelected || !restaurantStore.restaurant)
      return undefined
    return {
      customerToken: authStore.token,
      countryCode: appStore.countryCode,
      area: restaurantStore.areaSelected,
      restaurant: restaurantStore.restaurant.id,
      promotionsId,
      products,
    }
  }

  const getCheckPromoIds = ({ productId, promoId }: { productId: string; promoId: string }) => {
    const products = [productId]
    let promotionsId = [promoId]
    for (const product of appStore.cart.products) {
      if (!product.promotions) continue
      const validPromos = product.promotions.filter(promo => promo.active).map(promo => promo.id)
      if (validPromos.length === 0) continue
      products.push(product.id)
      promotionsId = promotionsId.concat(validPromos)
    }
    return getCheckPromosInput({ promotionsId, products })
  }

  const promoConfigCustomError = (status: number) => {
    // promotion.customErrors
    const errorKey = promoErrorKey(status)
    const customError = promoCustomErrors.value[errorKey]

    useGTM().trackCustomError({
      code: `${status}`,
      type: customError.code || '',
    })

    const hasBothButtons = !!(customError.btn1Text && customError.btn2Text)
    const config: DialogContent = {
      title: customError.title || '',
      body: customError.text || '',
      btnPrimary: customError.btn1Text || '',
      btnSecondary: !hasBothButtons ? undefined : customError.btn2Text || '',
    }
    return config
  }

  const verifyCheckCodeResponse = (response: Promotion): DialogContent | undefined => {
    const { valid, status } = response

    if (!valid && status) {
      return promoConfigCustomError(status)
    } else return undefined
  }

  const applyPromotion = async ({
    promo,
    kind,
    promotionIdToRemove,
    userInteraction = true,
  }: {
    promo?: Promotion
    kind?: PromotionKindType
    promotionIdToRemove?: string
    userInteraction?: boolean
  }) => {
    await appStore.setPromotion({ promo, kind, promotionIdToRemove })
    appStore.promotionsEditedByUser = userInteraction
    if (promo) trackApplyCoupon()
  }

  const tryCheckPromos = async ({ promotionsId }: { promotionsId: Array<string> }) => {
    const input = getCheckPromosInput({ promotionsId })
    if (!input) return undefined

    try {
      return await promosService.checkPromos(input)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error while tryCheckPromos ', error)
      return undefined
    }
  }

  return {
    // ref
    promoCustomErrors,
    // computed
    arePromotionsActive,
    userLogged,
    appliedPromotions,
    discountAmount,
    // value
    areaSelected: restaurantStore.areaSelected,
    orderType: appStore.currentOrder?.type,
    // helper methods
    getPromotionsBody,
    getCheckPromosInput,
    getCheckPromoIds,
    verifyCheckCodeResponse,
    promoConfigCustomError,
    applyPromotion,
    tryCheckPromos,
  }
}
