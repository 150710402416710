/* Delivery order errors */

// WIP - add more errors for payment, select pickup area ... etc
export const StoreErrorType = {
  status401: 'tokenExpired',
  status403: 'tokenExpired',
  status601: 'productNotAvailable',
  status602: 'platformNotValidForPromo',
  status603: 'orderErrorPrice',
  status604: 'orderPickupNotAvailable',
  status605: 'noCoverage',
  status607: 'areaNotFound',
  status609: 'limitPriceOrder',
  status611: 'orderNotExist',
  status613: 'noRiders',
  status615: 'countryNotValid',
  status616: 'serviceNumberEmpty',
  status618: 'noRestaurantsOpened',
  status619: 'orderAlreadyPaid',
  status627: 'promotionNotFound',
  status628: 'countryNotValid',
  status629: 'paymentPendingApproval',
  status630: 'orderProductMismatch',
  status631: 'promotionNotAvailable',
  status633: 'anyPickupAreaForThisOrderIsAvailable',
  status634: 'outOfDayPart',
  status636: 'orderMcdia',
  status642: 'restaurantNotLoyalty',
  status651: 'orderMcdiaOutOfDates',
  status653: 'areaNotAvailable',
  status668: 'antifraudTemporalBlock',
  status669: 'antifraudLimitTransactionsReached',
  status673: 'orderInProcess',
  status674: 'fiscalFieldsRequired',
  status682: 'phoneValidationRequired',
  status687: 'promoExclusivePaymentUsed',
  status688: 'limitProductExclusivePayment',
  status690: 'deviceValidationRequired',
  ORDER_GENERIC: 'orderGeneric',
  UNHANDLED: 'unhandled',
  PROMO_ERROR: 'PROMO_ERROR',
} as const

export type StoreError = (typeof StoreErrorType)[keyof typeof StoreErrorType]

export const PICKUP_ERRORS: string[] = [
  StoreErrorType.UNHANDLED,
  StoreErrorType.status604,
  StoreErrorType.status616,
  StoreErrorType.status618,
  StoreErrorType.status633,
  StoreErrorType.status653,
]

// Image error
export const storeImageError = {
  noCoverage: '/images/error/error-delivery.svg',
  noRiders: '/images/error/error-delivery.svg',
  areaNotFound: '/images/error/error-restaurant.svg',
  platformNotValidForPromo: '/images/error/error-promo.svg',
  restaurantNotLoyalty: '/images/error/error-redemption.svg',
  productNotAvailable: '/images/error/error-promotion-finished.svg',
  promotionNotFound: '/images/error/error-promotion-finished.svg',
  promoExclusivePaymentUsed: '/images/error/error-promotion-finished.svg',
  limitPriceOrder: '/images/error/error-limit-order.svg',
  pickup_serviceNumberEmpty: '/images/error/error-restaurant.svg',
  pickup_anyPickupAreaForThisOrderIsAvailable: '/images/error/error-restaurant.svg',
  pickup_orderPickupNotAvailable: '/images/error/error-restaurant.svg',
  pickup_areaNotAvailable: '/images/error/error-restaurant.svg',
} as const

type StoreImageErrorKey = typeof storeImageError

export const getErrorImage = (key: string): string | undefined => {
  return storeImageError[key as keyof StoreImageErrorKey]
}

// McdViewError

export class McdViewError extends Error {
  statusCode = 500
  redirectPath: string | undefined = undefined

  constructor(message: string, statusCode?: number, redirectPath?: string) {
    super(message)
    // because we are extending a built-in class
    Object.setPrototypeOf(this, McdViewError.prototype)
    if (statusCode) this.statusCode = statusCode
    if (redirectPath) this.redirectPath = redirectPath
  }

  getErrorInfo() {
    return {
      statusCode: this.statusCode,
      message: this.message,
      name: this.name,
      stack: this.stack || undefined,
      redirectPath: this.redirectPath,
    }
  }
}
