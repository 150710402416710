import { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios'
import Debug from 'debug'
import { publicConfig } from '~/config/public'

const reqId = new WeakMap<InternalAxiosRequestConfig, number>()
let reqIdCounter = 0

export default function logRequests(serviceName: string, client: AxiosInstance) {
  if (!publicConfig.debug.requests) return

  const debug = Debug(`app:service:${serviceName}`)

  const logRequest = function logRequest(config: InternalAxiosRequestConfig) {
    reqId.set(config, reqIdCounter++)
    debug(
      '%f %creq %s %s',
      reqId.get(config),
      'color:#990;font-weight:bold',
      config.method?.toUpperCase(),
      config.url,
      config.data || ''
    )

    return config
  }

  const logResponse = function logResponse(res: AxiosResponse): AxiosResponse {
    debug('%f %cres %s', reqId.get(res.config), 'color:#090;font-weight:bold', res.status)
    return res
  }

  const logResponseError = function logResponseError(err: AxiosError) {
    const res = err?.response
    debug(
      '%f %cres %s %s',
      err.config && reqId.get(err.config),
      'color:#900;font-weight:bold',
      res?.status || -1,
      err.message,
      res?.data
    )
    return Promise.reject(err)
  }

  client.interceptors.request.use(logRequest)
  client.interceptors.response.use(logResponse, logResponseError)
}
