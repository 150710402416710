import { StoreService } from '../store.service'
import {
  yunoMethodsCheckoutDto,
  yunoInitEnrollmentDto,
  yunoUnenrollDto,
  YunoCheckoutSessionInput,
  yunoCheckoutSessionDto,
  yunoStartPaymentDto,
  YunoStartPaymentInput,
  yunoMethodsCustomerDto,
} from './payment.dtos'
class PaymentService extends StoreService {
  constructor(country: string) {
    super('payment', country)
  }

  async getInitEnrollment({
    token,
    country,
    paymentMethodType,
  }: {
    token: string
    country: string
    paymentMethodType: string
  }) {
    const res = await this.request(
      {
        method: 'POST',
        url: `/payments/enrollment`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-app-country': country,
        },
        data: {
          paymentMethodType,
        },
      },
      yunoInitEnrollmentDto
    )
    return res
  }

  async unenrollCard({
    token,
    country,
    vaultedToken,
  }: {
    token: string
    country: string
    vaultedToken: string
  }) {
    const res = await this.request(
      {
        method: 'POST',
        url: '/payments/unenroll',
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-app-country': country,
        },
        data: {
          paymentMethodId: vaultedToken,
        },
      },
      yunoUnenrollDto
    )
    return res
  }

  async getCheckoutSession({
    token,
    country,
    input,
  }: {
    token: string
    country: string
    input: YunoCheckoutSessionInput
  }) {
    const res = await this.request(
      {
        method: 'POST',
        url: `/payments/checkout-session`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-app-country': country,
        },
        data: input,
      },
      yunoCheckoutSessionDto
    )
    return res
  }

  async getPaymentMethodsForCheckout({
    token,
    country,
    price,
    products,
  }: {
    token: string
    country: string
    price: number
    products: string[]
  }) {
    const res = await this.request(
      {
        method: 'POST',
        url: `/payments/checkout-payment-methods`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-app-country': country,
        },
        data: { price, products },
      },
      yunoMethodsCheckoutDto
    )
    return res
  }

  async getCustomerPaymentMethods({ token, country }: { token: string; country: string }) {
    const res = await this.request(
      {
        url: `/payments/customer-payment-methods`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-app-country': country,
        },
      },
      yunoMethodsCustomerDto
    )
    return res
  }

  async startPayment({
    token,
    country,
    data,
  }: {
    token: string
    country: string
    data: YunoStartPaymentInput
  }) {
    const res = await this.request(
      {
        method: 'POST',
        url: '/payments/start-payment',
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-app-country': country,
        },
        data,
      },
      yunoStartPaymentDto
    )
    return res
  }
}

export const paymentService = (country: string) => new PaymentService(country)
