import { z } from 'zod'
import { optionsGroupsTypeSchema, productPromotion } from '../common.dto'

export interface ProductInfo {
  identifier: string
  name: string
  combo: boolean
}
export const productInfoKey: InjectionKey<ProductInfo> = Symbol('productInfo')

/* Common */
export const taxConfig = z.object({
  id: z.string(),
  rule: z.string(),
  type: z.string(),
})

const priceSchema = z.object({
  amount: z.number(),
  tax: z.number().optional(),
  taxConfig: taxConfig.optional(),
  formatted: z.string(),
})

export type ProductPrice = z.infer<typeof priceSchema>

/* McDia (Gran Día) */
export const mcdiaSettingsDto = z.object({
  dateFrom: z.string(),
  dateTo: z.string(),
  titleBeforeDate: z.string(),
  titleInDate: z.string(),
  messageBeforeDate: z.string(),
  messageInDate: z.string(),
  linkText: z.string(),
  linkUrl: z.string(),
})

/* Suggestive productType */
export const ProductTypes = {
  CROSSSELLING: 'crossSelling',
  CROSSSELLING_NBO: 'crossSellingNBO',
  REGULAR: 'regular',
} as const

export type ProductType = (typeof ProductTypes)[keyof typeof ProductTypes] | undefined

export const OptionsGroupsChoiceGroupType = {
  MAIN: 'MAIN',
  COMBO: 'COMBO',
} as const

export const ProductSizeType = {
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
  NONE: 'NONE',
} as const

const productSizeUnion = z.union([
  z.literal(ProductSizeType.NONE),
  z.literal(ProductSizeType.SMALL),
  z.literal(ProductSizeType.MEDIUM),
  z.literal(ProductSizeType.LARGE),
])

const productSizeSchema = z.object({
  id: productSizeUnion,
  code: z.string(),
})

export type ProductSize = z.infer<typeof productSizeSchema>

const optionBaseSchema = z
  .object({
    identifier: z.string(),
    name: z.string(),
    min: z.number(),
    max: z.number(),
    qty: z.number(),
    available: z.boolean(),
    price: priceSchema,
    imageUrl: z.string().optional(),
    hide: z.boolean(),
    // inmutable initialQty
    initialQty: z.number().optional(),
  })
  .transform(optionChild => {
    // set inmutable initialQty on parse response
    return { ...optionChild, initialQty: optionChild.qty }
  })

export type OptionChild = z.infer<typeof optionBaseSchema>

const optGroupBase = z.object({
  identifier: z.string(),
  title: z.string(),
  type: optionsGroupsTypeSchema,
  choiceGroup: z.string(),
  hide: z.boolean().optional(),
  min: z.number(),
  max: z.number(),
})

/* OptionsGroups */
const optionsGroupsChildSchema = optGroupBase.extend({
  options: z.array(
    z.intersection(
      optionBaseSchema,
      z.object({
        // tricky logic here for view on detail v2 when combo has 6º levels of deep
        optionsGroups: z.array(z.any()).transform(optGsGrandChild => {
          // set inmutable initialQty on parse response
          return optGsGrandChild.map(optGGrandChild => ({
            ...optGGrandChild,
            options: optGGrandChild.options.map((optGranChild: OptionChild) => ({
              ...optGranChild,
              initialQty: optGranChild.qty,
            })),
          }))
        }),
      })
    )
  ),
})

export type OptionsGroupsChild = z.infer<typeof optionsGroupsChildSchema>

const optionSchema = z
  .intersection(
    optionBaseSchema,
    z.object({
      optionsGroups: z.array(optionsGroupsChildSchema),
      // inmutable initialQty
      initialQty: z.number().optional(),
    })
  )
  .transform(option => {
    // set inmutable initialQty on parse response
    return { ...option, initialQty: option.qty }
  })

export type OptionMain = z.infer<typeof optionSchema>

const optionsGroupsMainSchema = optGroupBase.extend({
  options: z.array(optionSchema),
})

export type OptionsGroupsMain = z.infer<typeof optionsGroupsMainSchema>

/**
 * Product detail dto (Ecommerce)
 **/
const productEcommerceLiteSchema = z.object({
  id: z.string(),
  name: z.string(),
  identifier: z.string(),
  imageUrl: z.string().optional(),
  description: z.string().optional(),
  price: priceSchema,
  unifiedPrice: priceSchema.optional(),
  originalPrice: priceSchema.optional(),
  customization: z.boolean().optional(), // TODO: MCDSQB-2830 remove optional
  // promos
  promotions: z.array(productPromotion),
  productType: z.string().nullable().optional(),
})

export type ProductEcommerceLite = z.infer<typeof productEcommerceLiteSchema>

const exclusivePayEvent = z.object({
  title: z.string().optional(),
  message: z.string().optional(),
  linkText: z.string().optional(),
  link: z.string().optional(),
  imageUrl: z.string().optional(),
  type: z.string().optional(),
})

export type ExclusivePayEvent = z.infer<typeof exclusivePayEvent>

export const productEcommerceDto = productEcommerceLiteSchema
  .extend({
    active: z.boolean().optional(),
    combo: z.boolean(),
    optionsGroups: z.array(optionsGroupsMainSchema),
    areas: z.array(z.string()),
    isRedeemable: z.boolean(),
    sizes: z.array(productSizeSchema).optional(),
    // mcDia
    mcDia: mcdiaSettingsDto.optional(),
    // loyalty
    points: z.number().optional(),
    loyaltyOfferId: z.string().optional(),
    loyaltyImg: z.string().optional(),
    // exclusivePayment
    exclusivePayment: z.boolean().optional(),
    // productType
    productType: z.string().nullable().optional(),
    // promos
    promotions: z.array(productPromotion),
    exclusivePayEvent: exclusivePayEvent.optional(),
  })
  .omit({
    customization: true,
  })

export type ProductEcommerce = z.infer<typeof productEcommerceDto>

/* Categories */
const categorySchema = z.object({
  id: z.string(),
  idRfm: z.string(),
  title: z.string(),
  imageUrl: z.string().optional(),
  products: z.array(productEcommerceLiteSchema),
  active: z.boolean(),
})

export type Category = z.infer<typeof categorySchema>

/**
 * Catalog lite dto
 **/
export const catalogLiteDto = z.array(categorySchema)

/* Crosseling */
const suggestiveSchema = z.object({
  uniqueId: z.string().optional(),
  productType: z.string().optional(),
})

export type ProductSuggestive = z.infer<typeof suggestiveSchema>

export const suggestiveDto = z.array(suggestiveSchema)
