import { z } from 'zod'
import {
  restaurantLiteSchema,
  servicesRestaurantSchema,
  generalHourSchema,
} from '../store/restaurant/restaurant.dto'

/**
 * Config
 *
 */

export const MaskConfigFieldName = {
  DOCUMENT_TYPE: 'Type',
  DOCUMENT: 'Document',
  PHONE_SUFFIX: 'Phone',
} as const

export const countryConfigResponse = z.object({
  status: z.boolean(),
  data: z.string(),
})

export const mdwConfigurationDto = z.object({
  phoneValidationActive: z.boolean().optional(),
  validationPhoneChange: z.boolean().optional(),
  showDevicesList: z.boolean().optional().default(false),
})

export const mdwMaskConfigType = z.object({
  mask: z.string().optional(),
  name: z.string(),
  regex: z.string().optional(),
  integrationIMID: z.string().optional(),
})

export type MaskConfigType = z.infer<typeof mdwMaskConfigType>

export const mdwMaskConfig = z.object({
  fieldName: z.string(),
  isCombo: z.boolean(),
  types: z.array(mdwMaskConfigType),
})

export const enrollmentScreensDto = z.object({
  index: z.number(),
  title: z.string(),
  fields: z.array(z.string()),
})

export const screensDto = enrollmentScreensDto.extend({
  active: z.boolean().optional(),
  completed: z.boolean().optional(),
})

export type ScreensType = z.infer<typeof screensDto>

export const enrollmentFlowsDto = z.object({
  enrollAutoLoyalty: z.boolean().optional(),
  enrollPurchase: z.boolean().optional(),
})

export const mdwEnrollmentDto = z.object({
  screens: z.array(enrollmentScreensDto).optional(),
  flows: enrollmentFlowsDto.optional(),
})

export const dynamicFieldsItem = z.object({
  code: z.string(),
  isCombo: z.boolean(),
  mask: z.string(),
  name: z.string(),
  options: z.array(z.string()),
  referenceCode: z.string(),
  regex: z.string(),
  required: z.boolean(),
  validatePhone: z.boolean().optional(),
  defaultValue: z.boolean().optional(),
  type: z.string(),
  values: z.array(z.string()),
  min: z.string().optional(),
})

export const mdwDynamicFieldstDto = z.array(dynamicFieldsItem)

export const brand = z.object({
  name: z.string(),
})

export const mdwCountry = z.object({
  configuration: mdwConfigurationDto.optional(),
  maskConfig: z.array(mdwMaskConfig),
  dlvActive: z.boolean(),
  mopActive: z.boolean(),
  enrollment: mdwEnrollmentDto.optional(),
  dynamicFields: mdwDynamicFieldstDto.optional(),
  brand: brand.optional(),
})

export type MdwCountry = z.infer<typeof mdwCountry>
/**
 * Products
 */

// allergens

export const productMdwAllergensDTO = z.object({
  // allergens
  twilight: z.boolean().optional(),
  allura: z.boolean().optional(),
  fish: z.boolean().optional(),
  milk: z.boolean().optional(),
  soy: z.boolean().optional(),
  almonds: z.boolean().optional(),
  lactose: z.boolean().optional(),
  pepper: z.boolean().optional(),
  tartrazine: z.boolean().optional(),
  egg: z.boolean().optional(),
  gluten: z.boolean().optional(),
  walnuts: z.boolean().optional(),
  sulphites: z.boolean().optional(),
  cereals: z.boolean().optional(),
  mustard: z.boolean().optional(),
  sesame: z.boolean().optional(),
  nuts: z.boolean().optional(),
  crustaceans: z.boolean().optional(),
  peanut: z.boolean().optional(),
  latex: z.boolean().optional(),
  wheat: z.boolean().optional(),
  hazelnuts: z.boolean().optional(),
  cashewnuts: z.boolean().optional(),
  brazilnuts: z.boolean().optional(),
  macadamias: z.boolean().optional(),
  pecans: z.boolean().optional(),
  pistachios: z.boolean().optional(),
  pinoli: z.boolean().optional(),
  chestnuts: z.boolean().optional(),
})

export type ProductMdwAllergens = z.infer<typeof productMdwAllergensDTO>

export const productMdwDto = z.object({
  _id: z.string(),
  category: z.string(),
  subsection: z.string().optional(), // created on the fly for combo categories
  name: z.string(),
  description: z.string(),
  image: z.string(),
  allergens: productMdwAllergensDTO,
  // nutritional information
  weight: z.string().nullable().optional(),
  calories: z.string().nullable().optional(),
  caloriesPercentage: z.string().nullable().optional(),
  caloriesPercentageSuffix: z.string().nullable().optional(),
  sugarTotals: z.string().nullable().optional(),
  sugarTotalsPercentage: z.string().nullable().optional(),
  sugarTotalsPercentageSuffix: z.string().nullable().optional(),
  sugarAdded: z.string().nullable().optional(),
  sugarAddedPercentage: z.string().nullable().optional(),
  sugarAddedPercentageSuffix: z.string().nullable().optional(),
  lipids: z.string().nullable().optional(),
  lipidsPercentage: z.string().nullable().optional(),
  lipidsPercentageSuffix: z.string().nullable().optional(),
  carbohydrates: z.string().nullable().optional(),
  carbohydratesPercentage: z.string().nullable().optional(),
  carbohydratesPercentageSuffix: z.string().nullable().optional(),
  proteins: z.string().nullable().optional(),
  proteinsPercentage: z.string().nullable().optional(),
  proteinsPercentageSuffix: z.string().nullable().optional(),
  sodium: z.string().nullable().optional(),
  sodiumPercentage: z.string().nullable().optional(),
  sodiumPercentageSuffix: z.string().nullable().optional(),
  transFats: z.string().nullable().optional(),
  transFatsPercentage: z.string().nullable().optional(),
  transFatsPercentageSuffix: z.string().nullable().optional(),
  saturatedFats: z.string().nullable().optional(),
  saturatedFatsPercentage: z.string().nullable().optional(),
  saturatedFatsPercentageSuffix: z.string().nullable().optional(),
  fiber: z.string().nullable().optional(),
  fiberPercentage: z.string().nullable().optional(),
  fiberPercentageSuffix: z.string().nullable().optional(),
  // composition - html richText if exists shows as another section on productDetail
  productComposition: z.string().nullable().optional(),
})

export type ProductMdw = z.infer<typeof productMdwDto>
/**
 * Categories
 */

// List of categories
export const categoryListMdwDto = z.object({
  _id: z.string(),
  image: z.string(),
  combo: z.boolean().optional(),
  name: z.string(),
  // SEO
  infoDescription: z.string().optional(),
})

export type CategoryListMdw = z.infer<typeof categoryListMdwDto>

export const categoriesListMdwApiDto = z.object({
  status: z.boolean(),
  data: z.array(categoryListMdwDto),
})

// Category Detail : LIST
// category detail response (list)
export const categoryDetailListApiMdwDto = z.object({
  status: z.boolean(),
  data: z.array(productMdwDto),
})

export const categoryDetailDto = categoryListMdwDto.extend({
  products: z.array(productMdwDto),
})

// Category Detail : COMBOS
export const categoryDetailComboDto = z.object({
  name: z.string(),
  items: z.array(productMdwDto),
})

// category detail response (combos)
export const categoryDetailComboApiMdwDto = z.object({
  status: z.boolean(),
  data: z.array(categoryDetailComboDto),
})

export type CategoryMdw = z.infer<typeof categoryDetailDto>

// Restaurants
export const restaurantMdwDto = z.object({
  status: z.boolean(),
  data: z.array(
    restaurantLiteSchema.extend({
      ecommerce: z.boolean().optional(),
    })
  ),
})

export const restaurantDetailDto = z.object({
  status: z.boolean(),
  data: z.array(
    restaurantLiteSchema.extend({
      generalHour: generalHourSchema.optional(),
      services: servicesRestaurantSchema,
    })
  ),
})
export type RestaurantMdwResponse = z.infer<typeof restaurantDetailDto>
