import { AsyncDataOptions, NuxtApp } from 'nuxt/app'

// TODO: optional first prop string

export const useWrapperAsyncData = <T, E = any>(
  ...args: [handler: (ctx?: NuxtApp) => Promise<T>, AsyncDataOptions<T>?]
) => useAsyncData<T, E>(...args)

export const useWrapperKeyAsyncData = <T, E = any>(
  ...args: [string, handler: (ctx?: NuxtApp) => Promise<T>, AsyncDataOptions<T>?]
) => useAsyncData<T, E>(...args)
