import { RateOrderBody } from '../services/store/order/order.dto'

export type RatingIntentData = {
  token: string
  orderId: string
  type: string
  status?: number
}

export const QueryParamsRating = {
  STATUS: 'status',
  TOKEN: 'token',
  ORDER_ID: 'orderid',
  TYPE: 'type',
  REGISTER: 'register',
} as const

export type RateOrderInput = RateOrderBody & { buttonSatisfaction?: boolean }

export const RatingErrorStatus = {
  NOT_FOUND: 'NOT_FOUND',
  ALREADY_RATED: 'ALREADY_RATED',
  RATING_EXPIRED: 'RATING_EXPIRED',
  UNKNOWN: 'UNKNOWN',
}
