import { z } from 'zod'

/* OptionGroups Type: Type and Schema */
export const OptionsGroupsType = {
  CHOICE: 'CHOICE',
  COMMENT: 'COMMENT',
  CONDIMENT: 'CONDIMENT',
  CANADD: 'CANADD',
  COMPONENT: 'COMPONENT',
  SUGGESTIVE: 'SUGGESTIVE',
} as const

export const optionsGroupsTypeSchema = z.union([
  z.literal(OptionsGroupsType.CHOICE),
  z.literal(OptionsGroupsType.COMMENT),
  z.literal(OptionsGroupsType.CONDIMENT),
  z.literal(OptionsGroupsType.CANADD),
  z.literal(OptionsGroupsType.COMPONENT),
  z.literal(OptionsGroupsType.SUGGESTIVE),
])

/* Product detail promotions: promotions on catalog, product detail, cart response in product, on order detail product, repeat order product */
export const productDetailPromotion = z.object({
  amount: z.number(),
  id: z.string(),
  type: z.string(),
})

export type ProductDetailPromotion = z.infer<
  typeof productDetailPromotion
> /* Product detail promotions: promotions on catalog, product detail, cart response in product, on order detail product, repeat order product */

export const productPromotion = z.object({
  id: z.string(),
  active: z.boolean(),
  amount: z.number(),
  type: z.string(),
  clientSegmentation: z.array(z.string()).nullable().optional(),
  dateFrom: z.string().nullable().optional(), // Is string date
  dateTo: z.string().nullable().optional(), // Is string date
  prices: z
    .object({
      price: z.number().nullable(),
      priceWithDiscount: z.number().nullable(),
    })
    .nullable()
    .optional(),
})

export type ProductPromotion = z.infer<typeof productPromotion>

/* Product DTO: in and out (for example POST /orders, POST /cart) */

export const optionsChildDTOSchema = z.object({
  identifier: z.string(),
  name: z.string(), // Not needed only for debug
  unit: z.number(),
})

export const optionsGroupsChildDTOSchema = z.object({
  identifier: z.string(),
  title: z.string(), // Not needed only for debug
  type: optionsGroupsTypeSchema.optional(), // later in cart response is not coming!, must come to send later to createOrder
  options: z.array(optionsChildDTOSchema),
})

export const optionsDTOSchema = z.object({
  identifier: z.string(),
  name: z.string(), // Not needed only for debug
  unit: z.number(),
  optionsGroups: z.array(optionsGroupsChildDTOSchema),
})

export type OptionDTO = z.infer<typeof optionsDTOSchema>

export const optionsGroupsMainDTOSchema = z.object({
  identifier: z.string(),
  title: z.string(), // Not needed only for debug
  type: optionsGroupsTypeSchema.optional(), // later in cart response is not coming!, must come to send later to createOrder
  options: z.array(optionsDTOSchema),
})

export type OptionsGroupsMainDTO = z.infer<typeof optionsGroupsMainDTOSchema>

export const productDTOschema = z.object({
  id: z.string(),
  identifier: z.string(),
  unit: z.number(),
  optionsGroups: z.array(optionsGroupsMainDTOSchema),
  // promotions
  isPromo: z.boolean().optional(),
  isPromoApplied: z.boolean().optional(),
  // productType
  productType: z.string().optional(),
  promotions: z.array(productPromotion).optional(),
  // limit reached
  limitReached: z.boolean().optional(),
})

export type ProductDTO = z.infer<typeof productDTOschema>

export const zAny = z.any()
