type ParsedRestaurantMetadata = Array<{ country: string; restaurants: { code: string }[] }>

export const addRestaurantsToFavorite = ({
  favoriteRestaurantsMetadata,
  countryCode,
  restaurantCode,
}: {
  favoriteRestaurantsMetadata?: string
  countryCode: string
  restaurantCode: string
}) => {
  if (!favoriteRestaurantsMetadata) {
    return [
      {
        country: countryCode,
        restaurants: [{ code: restaurantCode }],
      },
    ]
  }
  const parsed = JSON.parse(favoriteRestaurantsMetadata) as ParsedRestaurantMetadata
  if (!parsed.length) {
    return [
      {
        country: countryCode,
        restaurants: [{ code: restaurantCode }],
      },
    ]
  }
  return parsed.reduce((acc, countryFavs) => {
    if ({ ...countryFavs }.country === countryCode) {
      countryFavs.restaurants = [...countryFavs.restaurants, { code: restaurantCode }]
      return [...acc, countryFavs]
    } else {
      return [
        ...acc,
        countryFavs,
        { country: countryCode, restaurants: [{ code: restaurantCode }] },
      ]
    }
  }, [] as ParsedRestaurantMetadata)
}

export const removeRestaurantsFromFavorite = ({
  favoriteRestaurantsMetadata,
  countryCode,
  restaurantCode,
}: {
  favoriteRestaurantsMetadata?: string
  countryCode: string
  restaurantCode: string
}) => {
  if (!favoriteRestaurantsMetadata) {
    return []
  }
  const parsed = JSON.parse(favoriteRestaurantsMetadata) as ParsedRestaurantMetadata
  return parsed.reduce((acc, countryFavs) => {
    if ({ ...countryFavs }.country === countryCode) {
      countryFavs.restaurants = countryFavs.restaurants.filter(
        ({ code }) => code !== restaurantCode
      )
      return [...acc, countryFavs]
    } else {
      return [...acc, countryFavs]
    }
  }, [] as ParsedRestaurantMetadata)
}

export const isFavoriteRestaurant = ({
  favoriteRestaurantsMetadata,
  countryCode,
  restaurantCode,
}: {
  favoriteRestaurantsMetadata?: string
  countryCode: string
  restaurantCode?: string
}) => {
  if (!favoriteRestaurantsMetadata || !restaurantCode) {
    return false
  }
  const parsed = JSON.parse(favoriteRestaurantsMetadata) as ParsedRestaurantMetadata
  const countryFavs = parsed.find(({ country }) => country === countryCode)
  return countryFavs?.restaurants.some(favs => favs.code === restaurantCode) || false
}
