import axios from 'axios'
import { BaseService } from '../base.service'
import { siteDto, pageDto, contactFormDto, ecommerceBlocksDto } from './content.dtos'
import { publicConfig } from '~/config/public'
import logRequests from '~/lib/axiosUtils'
import { ContactForm } from '~/lib/models/contact'

class ContentService extends BaseService {
  readonly client

  constructor() {
    super()
    this.client = axios.create({
      baseURL: publicConfig.services.content.url,
    })
    logRequests('content', this.client)
  }

  async getSiteByDomain(domain: string) {
    const res = await this.request({ url: '/sites/by-domain', params: { q: domain } }, siteDto)
    return res
  }

  async getPageByPath(site: number, path: string) {
    const res = await this.request({ url: '/pages/by-path', params: { q: path, site } }, pageDto)
    return res
  }

  async getEcommerceBlocksBySite(site: number) {
    const res = await this.request(
      { url: '/ecommerce-blocks/by-site', params: { site } },
      ecommerceBlocksDto
    )
    return res
  }

  async sendContactForm(contactFormBlockId: number, data: ContactForm) {
    const res = await this.request(
      { method: 'POST', url: `/form-submission/contact/${contactFormBlockId}`, data },
      contactFormDto
    )
    return res
  }
}

export const contentService = new ContentService()
