import { z } from 'zod'
import {
  legalBrandDto,
  downloadSectionDto,
  menuSectionsDto,
  menuSectionSocialDto,
} from './footer/footer.dtos'
import { imageDto, linkDto } from './content.shared.dtos'
import { blockDto } from './content.blocks.dtos'
import { sideBarSection } from './sideBar/sideBar.dtos'
import { configurationDto } from './content.configuaration.dtos'

/**
 * Section Header
 */
export const sectionHeaderDto = z.object({
  buttonText: z.string(),
  mainLinks: z.array(linkDto),
  heroImage: imageDto.nullable(),
  mobileHeroImage: imageDto.nullable(),
})
export type SectionHeader = z.infer<typeof sectionHeaderDto>

/**
 * Section Footer
 */
export const sectionFooterDto = z.object({
  downloadSection: downloadSectionDto,
  footerLogo: imageDto,
  footerSections: z.array(menuSectionsDto),
  footerSectionsSocial: menuSectionSocialDto,
  legalBrand: legalBrandDto,
})
export type SectionFooter = z.infer<typeof sectionFooterDto>

export const storeConfiguration = z.object({
  ecommerceApiUrl: z.string(),
})

/**
 * Section sidebar
 */
export const sectionSidebarDto = z.object({
  sections: z.array(sideBarSection),
})
export type SectionSidebar = z.infer<typeof sectionSidebarDto>

/**
 * SEO
 */
export const SeoDto = z.object({
  id: z.number().nullable().optional(),
  title: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  preventIndexing: z.boolean().nullable().optional(),
  imageAlt: z.string().nullable().optional(),
  canonical: z.string().nullable().optional(), // Could be removed in strapi, actually is not been readed
  image: imageDto.nullable(),
  metaTags: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
        content: z.string(),
      })
    )
    .nullable(),
})

export type Seo = z.infer<typeof SeoDto>
export type SeoKey = keyof Seo

/**
 * Site
 */
export const siteDto = z.object({
  id: z.number(),
  name: z.string(),
  publishedAt: z.string(),
  header: sectionHeaderDto.nullable(),
  footer: sectionFooterDto.nullable(),
  sideBar: sectionSidebarDto.nullable(),
  configuration: z.array(configurationDto),
  SEO: SeoDto.nullable().optional(),
  favicon: imageDto.nullable(),
  ssoUrl: z.string().nullable().optional(),
})
export type Site = z.infer<typeof siteDto>

/**
 * Page
 */
export const pageDto = z.object({
  id: z.number(),
  name: z.string(),
  path: z.string(),
  blocks: z.array(blockDto),
  // GA
  pageAnalyticsTag: z.string().nullable().optional(),
  SEO: SeoDto.nullable().optional(),
})
export type Page = z.infer<typeof pageDto>

/**
 * ContactForm
 */
export const contactFormDto = z.any()

/**
 * Ecommerce blocks
 */
export const ecommerceBlocksDto = z.object({
  id: z.number(),
  name: z.string(),
  blocks: z.array(blockDto),
})
