<script setup lang="ts">
const emit = defineEmits(['on-add'])
const appStore = useAppStore()
const { format } = useFormatAmount()

const animationActive = ref(false)
const cartBag = ref<HTMLElement>()

const cartProductsLength = computed(() =>
  appStore.cart.products.reduce((acc, product) => acc + product.unit, 0)
)

const timeoutAnimatedBag = async () => {
  animationActive.value = true
  await new Promise(resolve => setTimeout(resolve, 301))
  animationActive.value = false
}

const onAddedProduct = () => {
  const target = cartBag.value
  if (target) emit('on-add', target)
}

useListen('cart:add-product', onAddedProduct)

watch(cartProductsLength, (curr, prev) => {
  if (curr > prev) {
    timeoutAnimatedBag()
  }
})
</script>

<template>
  <div ref="cartBag" class="flex w-fit cursor-pointer flex-row items-center gap-4 md:my-2 md:mr-6">
    <p class="text-lg font-bold md:self-end">{{ format(appStore.cartTotal) }}</p>
    <div id="onboarding-step-4" class="relative" :class="{ 'mcd-animated-bag': animationActive }">
      <img
        src="/images/icons/cart.svg"
        class="block max-h-[1.5rem] min-h-[1.5rem] min-w-[1.25rem] max-w-[1.25rem] md:min-h-[2.25rem] md:min-w-[1.75rem]"
        alt="cart"
      />
      <div
        class="absolute -right-2 -top-2 grid h-4 w-4 place-content-center rounded-full bg-mcd-red p-[1px] text-xs text-white"
      >
        <span>{{ cartProductsLength }}</span>
      </div>
    </div>
  </div>
</template>

<style>
.mcd-animated-bag {
  animation: cart-grow-shake 300ms;
  transition: all 200ms;
}
</style>
