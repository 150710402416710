import { z } from 'zod'
import { menuItemsDto } from './footer/footer.dtos'
import { linkDto } from './content.shared.dtos'

export type Locales = 'es' | 'pt' | 'en' | 'fr' | 'es_ar' | 'es_uy' | 'es_pe' | 'es_ec'

const baseConfigurationDto = z.object({ id: z.number() })

const countriesSchema = z.union([
  z.literal('AR'),
  z.literal('AW'),
  z.literal('BR'),
  z.literal('CL'),
  z.literal('CO'),
  z.literal('CR'),
  z.literal('CW'),
  z.literal('EC'),
  z.literal('GF'),
  z.literal('GP'),
  z.literal('MQ'),
  z.literal('MX'),
  z.literal('PA'),
  z.literal('PE'),
  z.literal('PR'),
  z.literal('TT'),
  z.literal('UY'),
  z.literal('VE'),
  z.literal('VI'),
])

const languagesSchema = z.union([
  z.literal('es'),
  z.literal('es_variant'),
  z.literal('pt'),
  z.literal('fr'),
  z.literal('en'),
])

/**
 * App stores
 */
const appStoreDto = baseConfigurationDto.extend({
  __component: z.literal('configuration.app-stores'),
  items: z.array(menuItemsDto),
})
export type AppStore = z.infer<typeof appStoreDto>

/**
 * Localization stores
 *
 */
const localizationStoreDto = baseConfigurationDto.extend({
  __component: z.literal('configuration.localization'),
  country: countriesSchema,
  language: languagesSchema,
  googleMapsKey: z.string(),
  latitude: z.number(),
  longitude: z.number(),
})
export type LocalizationStore = z.infer<typeof localizationStoreDto>

/**
 * Smartbanner configuration
 *
 */
const smartbannerDto = baseConfigurationDto.extend({
  __component: z.literal('configuration.smartbanner'),
  title: z.string(),
  description: z.string(),
  androidUrl: z.string(),
  iosUrl: z.string(),
  cookieExpirationDays: z.number(),
})
export type Smartbanner = z.infer<typeof smartbannerDto>

/**
 * Products configuration
 *
 */
const productsConfigDto = baseConfigurationDto.extend({
  __component: z.literal('configuration.products'),
  allergensText: z.string(),
})
export type ProductsConfig = z.infer<typeof productsConfigDto>

/**
 * Mobile Bottom Navigation configuration
 *
 */

export const MobileBottomNavLinkBehaviour = {
  UNLOGGED: 'UNLOGGED',
  LOGGED: 'LOGGED',
  BOTH: 'BOTH',
} as const

const mobileBottomNavLinkDto = z.object({
  position: z.number(),
  behaviour: z.union([
    z.literal(MobileBottomNavLinkBehaviour.BOTH),
    z.literal(MobileBottomNavLinkBehaviour.LOGGED),
    z.literal(MobileBottomNavLinkBehaviour.UNLOGGED),
  ]),
  link: linkDto,
})

export type MobileBottomNavLink = z.infer<typeof mobileBottomNavLinkDto>

const mobileBottomNavDto = baseConfigurationDto.extend({
  __component: z.literal('configuration.mobile-bottom-navigation'),
  links: z.array(mobileBottomNavLinkDto),
})

export type MobileBottomNav = z.infer<typeof mobileBottomNavDto>

/**
 * Union
 */
export const configurationDto = z.discriminatedUnion('__component', [
  appStoreDto,
  localizationStoreDto,
  smartbannerDto,
  productsConfigDto,
  mobileBottomNavDto,
])

export type Configuration = z.infer<typeof configurationDto>

export type ConfigurationKeyUnion = Configuration['__component'] extends `${string}.${infer Rest}`
  ? Rest
  : never
