export const RepeatOrderStatus = {
  SUCCESS: 'success',
  GENERIC_ERROR: 'genericError',
  NO_RIDERS: 'noRiders',
  MOP_NOT_AVAILABLE: 'mopNotAvailable',
  DLV_NOT_AVAILABLE: 'dlvNotAvailable',
  RESTAURANT_NOT_FOUND: 'restaurantNotFound',
  CLOSED_RESTAURANT: 'closedRestaurant',
  PERMITTED_AREAS_PRODUCTS: 'permittedAreasProducts',
  OUTOF_DAYPART_PRODUCTS: 'outOfDaypartProducts',
  NOT_EXISTING_PRODUCTS: 'notExistingProducts',
} as const

export type RepeatOrderState = (typeof RepeatOrderStatus)[keyof typeof RepeatOrderStatus]
