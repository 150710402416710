const removeGoogleMapsScript = () => {
  const googleMapsScripts = Array.from(document.getElementsByTagName('script'))

  window.google = undefined as any

  googleMapsScripts.forEach(script => {
    if (script.src.includes('maps.googleapis.com')) {
      script.parentNode?.removeChild(script)
    }
  })
}

export const loadGoogleAPI = ({ apiKey, region }: { apiKey?: string; region?: string }) => {
  return new Promise<void>((resolve, reject) => {
    if (!apiKey || !region) {
      reject(new Error('Google Maps API key is missing'))
    }

    removeGoogleMapsScript()

    /**
     * Had to add &callback=vueGoogleMapsInit to the script.src for the maps library to work.
     * Normally we should add the key in 4.vueGoogleMaps.ts but we were getting the following error
     *"You have included the Google Maps JavaScript API multiple times on this page. This may cause unexpected errors.".
     * This way we only add the script once and it works for the autocomplete and for the map library
     **/
    const mapsUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${apiKey}&region=${region}&libraries=geometry,places&callback=vueGoogleMapsInit`

    const mapsScript = document.createElement('script')

    mapsScript.src = mapsUrl
    mapsScript.async = true
    mapsScript.defer = true

    mapsScript.onload = () => {
      resolve()
    }

    mapsScript.onerror = () => {
      reject(new Error('Failed to load Google Maps Places API'))
    }

    document.head.appendChild(mapsScript)
  })
}
