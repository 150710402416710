import { z } from 'zod'
import { productEcommerceDto } from '../catalog/catalog.dtos'
import { tipOptionDto } from '../country/country.dtos'
import { productDTOschema, productDetailPromotion } from '../common.dto'
// --------------
// Schemas
// --------------

/**
 * Price schemas
 */

export const basePriceWithTotalSchema = z.object({
  total: z.number(),
  tax: z.number(),
  tip: tipOptionDto.optional(),
})

const priceWithExtra = basePriceWithTotalSchema.extend({
  subTotal: z.number(),
  extras: z.object({
    fees: z.number(),
    delivery: z.number(),
    deliveryFreeShipping: z.boolean().optional(), // WIP - MCDSQB-2929 - TBD
    serviceFee: z.number().optional(),
    tip: z.number(),
    order: z.number(),
    discount: z.number(),
  }),
  points: z.number(),
})

const repeatOrderPriceSchema = basePriceWithTotalSchema
  .extend({
    totalProductNoPromo: z.number(),
    subtotal: z.number(),
    discount: z.number(),
    extras: z.object({
      tax: z.number(),
      delivery: z.number(),
      tip: z.number(),
      discount: z.number(),
    }),
  })
  .omit({ tax: true })

/**
 * Product schemas
 */

const baseProductSchema = z.object({
  id: z.string(),
  identifier: z.string(),
  unit: z.number(),
  categoryId: z.string(),
  categoryName: z.string(),
  combo: z.boolean(),
  imageUrl: z.string(),
  name: z.string(),
  price: z.number(),
})

const productByIdSchema = z.array(
  baseProductSchema
    .extend({
      optionsGroups: z.array(
        z.object({
          identifier: z.string(),
          options: z.array(
            z.object({
              identifier: z.string(),
              unit: z.number(),
              optionsGroups: z.array(
                z.object({
                  identifier: z.string(),
                  title: z.string(),
                  options: z.array(z.any()),
                })
              ),
              name: z.string().optional(),
              imageUrl: z.string().optional(),
              price: z.number().optional(),
            })
          ),
        })
      ),
      discount: z.number(),
      isPromo: z.boolean(),
      priceUnit: z.number(),
      priceWithDiscount: z.number(),
      productType: z.string().nullable().optional(),
      promotions: z.array(productDetailPromotion).nullable().optional(),
    })
    .omit({
      identifier: true,
    })
)

export type ProductOrder = z.infer<typeof productByIdSchema>[0]

const repeatOrderProductSchema = z.array(
  baseProductSchema.extend({
    optionsGroups: z.array(
      z.object({
        identifier: z.string(),
        title: z.string(),
        options: z.array(
          z.object({
            identifier: z.string(),
            name: z.string(),
            unit: z.number(),
            optionsGroups: z.array(
              z.object({
                identifier: z.string(),
                title: z.string(),
                options: z.array(z.any()),
              })
            ),
          })
        ),
      })
    ),
    promotions: z.array(productDetailPromotion).optional(),
  })
)

export type RepeatOrderProduct = z.infer<typeof repeatOrderProductSchema>[0]

// const cancelOrderProductSchema = z.array(
//   baseProductSchema
//     .extend({
//       optionsGroups: z.array(
//         z.object({
//           identifier: z.string(),
//           options: z.array(z.any()),
//         })
//       ),
//       discount: z.number(),
//       isPromo: z.boolean(),
//       priceUnit: z.number(),
//       priceWithDiscount: z.number(),
//     })
//     .omit({
//       identifier: true,
//     })
// )

/**
 * Restaurant schemas
 */

const baseRestaurantSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  address: z.string(),
  postalCode: z.string(),
  city: z.string(),
  state: z.string(),
  serviceFee: z
    .object({
      name: z.string().nullable(),
    })
    .optional(),
})

export type BaseRestaurantOrder = z.infer<typeof baseRestaurantSchema>

const repeatOrderRestaurantSchema = baseRestaurantSchema
  .extend({
    neighborhood: z.string(),
    cep: z.string(),
    generalHour: z.object({
      daysOfWeek: z.array(
        z.object({
          day: z.string(),
          timePeriods: z.array(
            z.object({
              start: z.string(),
              end: z.string(),
            })
          ),
        })
      ),
    }),
    timeSlotsService: z.array(
      z.object({
        type: z.string(),
        daysOfWeek: z.array(
          z.object({
            day: z.string(),
            timePeriods: z.array(
              z.object({
                hourOpen: z.string(),
                hourClose: z.string(),
              })
            ),
          })
        ),
      })
    ),
    phone: z.string(),
    phoneMc: z.string(),
    coordinates: z.object({
      longitude: z.number(),
      latitude: z.number(),
    }),
    services: z.object({
      breakfast: z.boolean(),
      mcCafe: z.boolean(),
      driveThru: z.boolean(),
      dcOut: z.boolean(),
      dcIn: z.boolean(),
      mcDelivery: z.boolean(),
      timeExtended: z.boolean(),
      mcParty: z.boolean(),
      playPlace: z.boolean(),
      parking: z.boolean(),
      wifi: z.boolean(),
      loyalty: z.boolean(),
      wheelchairAccess: z.boolean(),
      dessertCenter: z.boolean(),
      shoppingCenter: z.boolean(),
    }),
    active: z.boolean(),
    timezone: z.string(),
    enabledYuno: z.boolean(),
    delivery: z.object({
      deliveryFee: z.number(),
    }),
  })
  .omit({
    postalCode: true,
    state: true,
  })

/**
 * Delivery place
 */
const deliveryPlaceSchema = z.object({
  address: z.string(),
  postalCode: z.string(),
  city: z.string(),
  state: z.string(),
  neighborhood: z.string().optional(),
  favoriteId: z.string(),
  alias: z.string(),
  latitude: z.number(),
  longitude: z.number(),
})

/**
 * Delivery POS
 *  */

export const deliveryPosSchema = z.object({
  trackingUrl: z.string().optional(),
  pinCode: z.string().optional(),
})

/**
 * MOP pos
 *  */
const orderPos = z.object({
  requestId: z.string().optional(),
  tenderCode: z.number().optional(),
  orderNumber: z.number().optional(),
})

export const StoreOrderStatus = {
  VALIDATION_PENDING: 'VALIDATION_PENDING',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_DONE: 'PAYMENT_DONE',
  FLEX_SENDING: 'FLEX_SENDING',
  FLEX_ERROR: 'FLEX_ERROR',
  FLEX_RECEIVED: 'FLEX_RECEIVED',
  FLEX_ACCEPTED: 'FLEX_ACCEPTED',
  FLEX_STAGING: 'FLEX_STAGING',
  FLEX_PREPARING: 'FLEX_PREPARING',
  FLEX_READY: 'FLEX_READY',
  FLEX_DELIVERING: 'FLEX_DELIVERING',
  FLEX_DELIVERED: 'FLEX_DELIVERED',
  FLEX_CANCELLED: 'FLEX_CANCELLED',
  CANCELLED: 'CANCELLED',
  FINISHED: 'FINISHED',
  PICKUP_EXPIRED: 'PICKUP_EXPIRED',
} as const

export const StoreOrderSubstatus = {
  PAYMENT_PENDING_COUNTER: 'PAYMENT_PENDING_COUNTER',
  PAYMENT_DONE_MCDIA: 'PAYMENT_DONE_MCDIA',
  PAYMENT_DONE_MCDIA_3DAYS: 'PAYMENT_DONE_MCDIA_3DAYS',
  PAYMENT_DONE_MCDIA_INDATE: 'PAYMENT_DONE_MCDIA_INDATE',
  PAYMENT_DONE_MCDIA_EXPIRED: 'PAYMENT_DONE_MCDIA_EXPIRED',
} as const

const storeOrderStatusUnion = z.union([
  z.literal(StoreOrderStatus.VALIDATION_PENDING),
  z.literal(StoreOrderStatus.PAYMENT_PENDING),
  z.literal(StoreOrderStatus.PAYMENT_DONE),
  z.literal(StoreOrderStatus.FLEX_SENDING),
  z.literal(StoreOrderStatus.FLEX_ERROR),
  z.literal(StoreOrderStatus.FLEX_RECEIVED),
  z.literal(StoreOrderStatus.FLEX_ACCEPTED),
  z.literal(StoreOrderStatus.FLEX_STAGING),
  z.literal(StoreOrderStatus.FLEX_PREPARING),
  z.literal(StoreOrderStatus.FLEX_READY),
  z.literal(StoreOrderStatus.FLEX_DELIVERING),
  z.literal(StoreOrderStatus.FLEX_DELIVERED),
  z.literal(StoreOrderStatus.FLEX_CANCELLED),
  z.literal(StoreOrderStatus.CANCELLED),
  z.literal(StoreOrderStatus.FINISHED),
  z.literal(StoreOrderStatus.PICKUP_EXPIRED),
])

const storeOrderSubStatusUnion = z.union([
  storeOrderStatusUnion.describe(''),
  z.literal(StoreOrderSubstatus.PAYMENT_PENDING_COUNTER),
  z.literal(StoreOrderSubstatus.PAYMENT_DONE_MCDIA),
  z.literal(StoreOrderSubstatus.PAYMENT_DONE_MCDIA_3DAYS),
  z.literal(StoreOrderSubstatus.PAYMENT_DONE_MCDIA_INDATE),
  z.literal(StoreOrderSubstatus.PAYMENT_DONE_MCDIA_EXPIRED),
])

export type OrderSubStatus = z.infer<typeof storeOrderSubStatusUnion>
/* Promos in order detail */

export const promoOrderLegacy = z.object({
  id: z.string(),
  name: z.string(),
  coupon: z.string().optional(),
})

export const promoOrder = z.object({
  promotionSnapShotId: z.string(),
  name: z.string(),
  type: z.string(),
  code: z.string().optional(),
})

/* Cancellation details flex */
export const FlexCancelledReason = {
  FLEX_CANCELLED_REFUND: 'FLEX_CANCELLED_REFUND',
  FLEX_CANCELLED_ERR_ADDRESS: 'FLEX_CANCELLED_ERR_ADDRESS',
  FLEX_CANCELLED_ERR_INFO: 'FLEX_CANCELLED_ERR_INFO',
} as const

export const cancellationDetailsDto = z.object({
  errorMessageKey: z.string().optional(),
})

/* SaleType */
export const StoreOrderSaleType = {
  EATIN: 'EATIN',
  TAKEOUT: 'TAKEOUT',
} as const

const saleTypeUnion = z.union([
  z.literal(StoreOrderSaleType.EATIN),
  z.literal(StoreOrderSaleType.TAKEOUT),
])

export type SaleType = z.infer<typeof saleTypeUnion>

/**
 * Store order schema
 */

export const storeOrderSchema = z.object({
  id: z.string(),
  status: storeOrderStatusUnion,
  subStatus: storeOrderSubStatusUnion.optional(),
  area: z.any(),
  type: z.string(),
  restaurant: baseRestaurantSchema,
  price: priceWithExtra,
  products: productByIdSchema,
  paymentMethod: z.string(),
  paymentYuno: z.boolean(),
  deliveryPlace: deliveryPlaceSchema,
  deliveryPos: deliveryPosSchema.optional(),
  pos: orderPos.optional(),
  permittedAreas: z.array(z.string()),
  promotions: z.array(promoOrderLegacy),
  promotionsV2: z.array(promoOrder),
  isFreteFree: z.boolean().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  canCancel: z.boolean(),
  canPickup: z.boolean(),
  mailConfirmOrder: z.boolean(),
  canRepeat: z.boolean(),
  orderMcDia: z.boolean(),
  configMcDia: z
    .object({
      type: z.string(),
      dateFrom: z.string(),
      dateTo: z.string(),
    })
    .nullable() // nullable because in legacy old orders could be null
    .optional(),
  loyalty: z.boolean(),
  onlyLoyalty: z.boolean(),
  confirmLoyalty: z.boolean().optional(),
  serviceNumber: z.string().optional(),
  webRateUrl: z.string().optional(),
  fiscalFields: z.record(z.string()).optional(),
  // cancellation details
  cancellationDetailsFlex: cancellationDetailsDto.optional(),
  saleType: saleTypeUnion.optional(),
  exclusiveEvent: z.boolean().optional(),
})

export type StoreOrder = z.infer<typeof storeOrderSchema>

// --------------
// Dtos
// --------------

export const addressId = z.object({
  // favorite
  alias: z.string().optional(),
  favoriteId: z.string().optional(),
  // properties
  address: z.string().optional(),
  number: z.string().optional(),
  complement: z.string().optional(),
  postalCode: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  neighborhood: z.string().optional(),
  specialInstructions: z.string().optional(),
  // coords
  latitude: z.number(),
  longitude: z.number(),
  // poi
  poiName: z.string().optional(),
  // distance
  distance: z.number().optional(),
})

export type Address = z.infer<typeof addressId>

/**
 * Create order dto body
 **/
const createOrderBodyDto = z.object({
  paymentMethod: z.string(),
  paymentYuno: z.boolean(),
  origin: z.string(),
  restaurant: z.string(),
  area: z.string(),
  price: basePriceWithTotalSchema,
  products: z.array(productDTOschema),
  fiscalFields: z.record(z.string()),
  addressId: addressId.optional(),
  originalOrderID: z.string().optional(),
  // promotions
  coupon: z.string().optional(),
  promotionsList: z.array(z.string()).optional(),
  // device
  deviceId: z.string().optional(),
  // purchase info back
  mcId: z.string().optional(),
  analyticsFields: z.object({
    clientIdWeb: z.string().optional(),
  }),
})
export type CreateOrderBody = z.infer<typeof createOrderBodyDto>

/**
 * Create order dto
 **/
// WIP - promos??
export const createOrderDto = z.object({
  id: z.string(),
  status: z.string(),
  paymentUrl: z.string().optional(),
  createdAt: z.string(),
  permittedAreas: z.array(z.string()),
})
export type CreateOrder = z.infer<typeof createOrderDto>

/**
 * set pickup customer on restaurant dto body
 **/
export const setPickupCustomerOnRestaurantBodyDto = z.object({
  orderId: z.string(),
  serviceNumber: z.string().optional(),
  area: z.string(),
  saleType: saleTypeUnion.optional(),
})
export type SetPickupCustomerOnRestaurantBody = z.infer<typeof setPickupCustomerOnRestaurantBodyDto>

/**
 * get order by id dto
 **/
export const getOrderByIdDto = storeOrderSchema
export type GetOrderById = z.infer<typeof getOrderByIdDto>

/**
 * pending orders dto
 * */
export const pendingOrdersDto = z.array(getOrderByIdDto)
export type PendingOrders = z.infer<typeof pendingOrdersDto>

/**
 *  repeat order dto
 */

export const repeatOrderDto = z.object({
  price: repeatOrderPriceSchema,
  products: repeatOrderProductSchema,
  restaurant: repeatOrderRestaurantSchema,
  area: z.string(),
  deliveryPlace: deliveryPlaceSchema,
  catalogProduct: z.array(productEcommerceDto),
  originalOrderID: z.string(),
})
export type RepeatOrderDto = z.infer<typeof repeatOrderDto>

/**
 * pay pending order body dto
 */

export const payPendingOrderBodyDto = z.object({
  orderId: z.string(),
  paymentMethod: z.string(),
})
export type PayPendingOrderBodyDto = z.infer<typeof payPendingOrderBodyDto>

/**
 * pay pending order response dto
 */
export const payPendingOrderResponseDto = z.object({
  createdAt: z.string(),
  id: z.string(),
  paymentUrl: z.string().optional(),
  permittedAreas: z.array(z.string()),
  status: z.string(),
})
export type PayPendingOrderResponseDto = z.infer<typeof payPendingOrderResponseDto>

/**
 * finished orders dto
 */
export const finishedOrdersDto = z.object({
  hasNextPage: z.boolean(),
  nextPage: z.number().nullable(),
  orders: z.array(storeOrderSchema),
})
export type FinishedOrdersDto = z.infer<typeof finishedOrdersDto>

/**
 * get pickup permitted areas dto
 */
export const getPickupPermittedAreasDto = z.object({
  permittedAreas: z.array(
    z.object({
      name: z.string(),
      disabled: z.boolean(),
    })
  ),
  dualPoint: z.boolean(),
  saleTypeActive: z.boolean(),
})
export type GetPickupPermittedAreasDto = z.infer<typeof getPickupPermittedAreasDto>

/**
 * cancel order dto
 **/
export const cancelOrderDto = storeOrderSchema
export type CancelOrder = z.infer<typeof cancelOrderDto>

/**
 * Rate order body dto
 */
export const rateOrderBodyDto = z.object({
  orderRating: z.number(),
  orderDetails: z.array(
    z.object({
      key: z.string(),
      value: z.boolean(),
    })
  ),
  orderCommentsKoDetail: z.string().optional(),
  orderCommentsGeneral: z.string().optional(),
  token: z.string(),
  type: z.string(),
})
export type RateOrderBody = z.infer<typeof rateOrderBodyDto>

/**
 * Rate order response dto
 * */
export const rateOrderResponseDto = z.any()
export type RateOrderResponse = z.infer<typeof rateOrderResponseDto>

/* Helper internal types */

// OrderState
export type OrderState = {
  status: string
  isPending: boolean
  isPaymentDone: boolean
  canPickup: boolean
  canCancel: boolean
  canRepeat: boolean
  isTrackable: boolean
}

// smartcards
export const SmartCardStatus = {
  MULTIPLE: 'multiple',
  PENDING: 'pending',
  PENDING_PAYMENT_COUNTER: 'pendingPaymentCounter',
  AFTER_PAYMENT: 'afterPayment',
  ACCEPTED: 'accepted',
  ACCEPTED_PAYMENT_COUNTER: 'acceptedPaymentCounter',
  PREPARING: 'preparing',
  DELIVERING: 'delivering',
  READY: 'ready',
  DELIVERED: 'delivered',
  // advance sales
  PAYMENT_DONE_MCDIA_3DAYS: 'advanceSale3days',
  PAYMENT_DONE_MCDIA_INDATE: 'advanceSaleInDate',
} as const

export type SmartCardState = (typeof SmartCardStatus)[keyof typeof SmartCardStatus]

/* Receive order */
export const requestReceiveOrderDto = z.any()

export type ReceiveOrderError = 'generic' | 'no-riders' | 'rest-closed' | 'no-stock'
