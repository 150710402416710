const constantSteps: { [key: string]: string } = {
  stepRestaurant: 'delivery_type',
  stepMOP: 'mop',
  stepDLV: 'dlv',
  stepCart: 'cart',
  stepAccount: 'my_account',
  stepQR: 'qr',
}

const getAnalyticsStep = (currentStep: string) => {
  return constantSteps[currentStep] || 'step'
}

export const useEcommerceOnboarding = () => {
  const appStore = useAppStore()
  const restaurantStore = useRestaurantStore()
  const layoutStore = useLayoutStore()
  const { profileRoute } = useMcdRoutes()
  const { trackWalkthrough, trackWalkthroughClose, trackWalkthroughAccount } = useGTM()

  const currentStep = ref()

  const steps = computed(() => {
    const stepsArr = ['stepRestaurant', 'stepMOP']
    if (appStore.mdwConfig?.dlvActive) stepsArr.push('stepDLV')
    stepsArr.push('stepCart', 'stepAccount', 'stepQR')
    return stepsArr
  })

  const getNextStep = (currentStep: string) => {
    const idx = steps.value.indexOf(currentStep)
    return steps.value?.[idx + 1] || 'no_more'
  }

  useListen('popper:event', async ev => {
    if (!currentStep.value) return
    const { type } = ev
    if (type === 'next') {
      const nextStep = getNextStep(currentStep.value)
      switch (nextStep) {
        case 'stepMOP':
          restaurantStore.openChangeRestaurantModal()
          await new Promise(resolve => setTimeout(resolve, 500))
          break
        case 'stepCart':
          restaurantStore.closeChangeRestaurantModal()
          break
        case 'stepQR':
          await navigateTo(profileRoute())
          await new Promise(resolve => setTimeout(resolve, 250))
      }
      show(nextStep)
    } else if (type === 'finish') {
      currentStep.value = undefined
      closeOnboarding()
    } else {
      // trackWalkthroughClose
      trackWalkthroughClose(getAnalyticsStep(currentStep.value))
      currentStep.value = undefined
      closeOnboarding()
    }
  })

  const show = (step: string) => {
    const popper = document.querySelector(`[data-popper="${step}"]`) as HTMLElement
    if (popper) {
      popper.dataset.show = 'show'
      currentStep.value = step
      // trackWalkthrough + trackWalkthroughAccount (when needed)
      if (step === 'stepQR') trackWalkthroughAccount()
      trackWalkthrough(getAnalyticsStep(step))
    }
  }

  const init = () => {
    show('stepRestaurant')
  }

  const closeOnboarding = async () => {
    layoutStore.setOnboarding(false)
    const expiryDate = new Date()
    expiryDate.setFullYear(expiryDate.getFullYear() + 1)
    document.cookie = `mcd-onboarding=${new Date().toISOString()}; expires=${expiryDate.toUTCString()}; path=/; Secure`
    if (restaurantStore.modalChangeRestaurant) restaurantStore.closeChangeRestaurantModal()
    await navigateTo('/')
  }

  return { init }
}
