import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum'
import { publicConfig } from '~/config/public'

export default defineNuxtPlugin(() => {
  if (!('datadogRum' in publicConfig.sdk)) return
  const { enabled, ddApplicationId, ddApplicationToken } = publicConfig.sdk.datadogRum

  if (!enabled) return

  datadogRum.init({
    applicationId: ddApplicationId,
    clientToken: ddApplicationToken,
    site: 'datadoghq.com',
    service: 'mcd-landings-live',
    env: 'live',
    sessionSampleRate: 100, // 0 - 100
    sessionReplaySampleRate: 20, // 0 - 100
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
  })

  datadogRum.startSessionReplayRecording()
})
