import debounce from 'lodash/debounce'
import { RestaurantAreas } from '~/lib/interfaces/restaurant'
import { getOverridenGoogleMapsKey } from '~/utils/googleMapsUtils'

export const useGoogleSessionToken = () => {
  const restaurantStore = useRestaurantStore()
  const sessionToken = new google.maps.places.AutocompleteSessionToken()
  restaurantStore.setGoogleMapsSessionToken(sessionToken)
}

export const useGoogleMapsKey = () => {
  const appStore = useAppStore()
  const restaurantStore = useRestaurantStore()
  const googleKeys = {
    [RestaurantAreas.MOP]: appStore.ecommerceConfig?.googleMapsApiKeyPickup,
    [RestaurantAreas.DLV]: appStore.ecommerceConfig?.googleMapsApiKeyDelivery,
  }

  const googleApiKeyOverride = getOverridenGoogleMapsKey()
  const key = googleKeys[restaurantStore.candidateArea!] || ''

  return {
    key: googleApiKeyOverride || key,
    country: appStore.countryCode,
  }
}

export const useGooglePlaces = () => {
  const countryCode = useCountry()
  const restaurantStore = useRestaurantStore()
  // RefererNotAllowedMapError https://developers.google.com/maps/documentation/javascript/error-messages#referer-not-allowed-map-error
  // Your site URL to be authorized: http://localhost:3600/

  const places = ref<google.maps.places.AutocompletePrediction[]>([])

  const resetPlaces = () => {
    places.value = []
  }

  const searchPlaces = async (query: string) => {
    const { trackSearch } = useGTM()

    if (!query) return
    const searchResults = await placePredictionSearch({
      query,
      countryCode,
      googleMapsSessionToken: restaurantStore.googleMapsSessionToken,
    })

    trackSearch(query)

    if (searchResults.length > 0) places.value = searchResults
    return searchResults
  }

  return {
    places,
    searchPlaces: debounce(searchPlaces, 500),
    searchPlacesWithoutDebounce: searchPlaces,
    resetPlaces,
  }
}
