import get from 'lodash/get'
import { StoreError, StoreErrorType } from '~/lib/models/errors'
import { RepeatOrderState, RepeatOrderStatus } from '~/lib/models/repeat-order'
import { CHECK_PROMO_ERROR, PromotionErrorType, PromoError } from '~/lib/models/promotions'

export const useStoreResponse = () => {
  const handleStoreError = (
    error: any,
    defaultType: StoreError | PromoError = StoreErrorType.ORDER_GENERIC
  ): { key: StoreError | PromoError; status: number; message: string } => {
    const status = get(error, 'response.status', 500)
    const message = get(error, 'response.data.message', 'Internal server error')

    if (status === 400) {
      const statusPromo = get(error, 'response.data.status', 500)
      const { promoCustomErrors } = usePromotions()

      const errorKey = promoErrorKey(statusPromo)
      const customError = promoCustomErrors.value[errorKey]
      if (customError)
        return { key: StoreErrorType.PROMO_ERROR, status: statusPromo, message: customError.code }
    } else if (status === CHECK_PROMO_ERROR.CumulativePromotionsNotAllowed) {
      return { key: PromotionErrorType.status835, status, message }
    } else if (status === CHECK_PROMO_ERROR.AutoApplicablePromotionNotAllowed) {
      return { key: PromotionErrorType.status836, status, message }
    }

    const errorMappedKey: StoreError | PromoError =
      get(StoreErrorType, `status${status}`) || defaultType
    return { key: errorMappedKey, status, message }
  }

  const handleRepeatStatus = (error: any): RepeatOrderState => {
    let key: RepeatOrderState = RepeatOrderStatus.GENERIC_ERROR
    const status = get(error, 'response.status', 500)

    if (status === 604) key = RepeatOrderStatus.MOP_NOT_AVAILABLE
    else if (status === 605) key = RepeatOrderStatus.DLV_NOT_AVAILABLE
    else if (status === 606) key = RepeatOrderStatus.RESTAURANT_NOT_FOUND
    else if (status === 613) key = RepeatOrderStatus.NO_RIDERS
    else if (status === 618) key = RepeatOrderStatus.CLOSED_RESTAURANT
    else if (status === 633) key = RepeatOrderStatus.PERMITTED_AREAS_PRODUCTS
    else if (status === 634) key = RepeatOrderStatus.OUTOF_DAYPART_PRODUCTS
    else if (status === 652) key = RepeatOrderStatus.NOT_EXISTING_PRODUCTS

    return key
  }

  return {
    handleStoreError,
    handleRepeatStatus,
  }
}
