import { z } from 'zod'

export const loyaltyDto = z.object({
  optedIn: z.boolean(),
})

export const loyaltyPointsDto = z.object({
  currentPoints: z.number(),
  maxPoints: z.number(),
  maxPointsDivider: z.number(),
  nextExpirationPoints: z.number(),
  nextExpirationDays: z.number(),
})
