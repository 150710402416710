// WIP: TODO: add same logic from legacy website into restaurantScheduleUtils https://bitbucket.org/gigigo/mcd-latam-landings-front-vue/commits/11b0a4980e8a02bf361b3f6cbd183a8d817d52c1 for interval between dates
import Debug from 'debug'
import { DateTime, Interval } from 'luxon'
import {
  RestaurantWeeklyDaysUnion,
  OpenServices,
  RestaurantSchedule,
  RestaurantWeeklyDaysOrder,
  RestaurantScheduleTypesUnion,
  RestaurantScheduleTypes,
  StoreRestaurantAreas,
  StoreRestaurantAreasUnion,
  OpenServicesState,
  StoreRestaurantOpenState,
  ServiceHourDayOfWeek,
  GeneralHoursByDay,
  GeneralHourTimeSlots,
  GeneralServiceHourDayOfWeek,
  RestaurantGeneralSchedule,
  structureRestaurantWeeklyDays,
  OpeningHoursSpecificationRestaurant,
} from '~/lib/interfaces/restaurant'
import { RestauranOpenStateDTO, TimeSlotService } from '~/lib/interfaces/timeSlot'

const debug = Debug('app:restaurant-schedule')

export const NEAR_TO_CLOSE_MINUTES = 60

/* Helpers */

const OPEN_24H: RestaurantSchedule = { hourOpen: '00:00', hourClose: '24:00' }
const OPEN_24H_FROM_MODEL: RestaurantSchedule = { hourOpen: '00:00', hourClose: '23:59' }

const getMinutes = (hours: number, minutes: number): number => {
  return hours * 60 + minutes
}

const getMinutesFromRestaurantTime = (restaurantTime: string): number => {
  const times: number[] = restaurantTime.split(':').map(str => Number(str))
  return getMinutes(times[0], times[1])
}

const getWeeklyDay = (dateTime: DateTime): RestaurantWeeklyDaysUnion => {
  const day = dateTime.weekday
  return RestaurantWeeklyDaysOrder[day - 1]
}

/* OPEN STATE COMPUTING METHODS - Methods */

/**
 * getIntervalFromSchedule
 * Execution order: 5º
 * Create interval from hourOpen and hourClose
 * @param dateTime
 * @param schedule
 * @param pickupTime
 * @param type
 * @returns Interval
 */
const getIntervalFromSchedule = (
  dateTime: DateTime,
  schedule: RestaurantSchedule,
  pickupTime: number,
  type: RestaurantScheduleTypesUnion = RestaurantScheduleTypes.OPEN
) => {
  const is24hPeriod =
    schedule?.hourOpen === OPEN_24H.hourOpen && schedule?.hourClose === OPEN_24H.hourClose
  const openTime = getMinutesFromRestaurantTime(schedule.hourOpen || '')
  const closeTime = getMinutesFromRestaurantTime(schedule.hourClose || '')
  if (isNaN(openTime) && isNaN(closeTime))
    return Interval.invalid('No schedule', 'No hourOpen or hourClose in timePeriod')

  const start: DateTime = generateDateTimeFromRestaurantTime({
    dateTime,
    schedule,
    pickupTime,
    type,
    is24h: is24hPeriod,
  })
  const end: DateTime = generateDateTimeFromRestaurantTime({
    dateTime,
    schedule,
    isEnd: true,
    pickupTime,
    type,
    is24h: is24hPeriod,
  })

  return Interval.fromDateTimes(start, end)
}

const getTimeObjectFromRestaurantTime = (restaurantTime: string) => {
  const values = restaurantTime ? restaurantTime.split(':') : []
  return {
    hour: (values[0] && Number(values[0])) || 0,
    minutes: (values[1] && Number(values[1])) || 0,
  }
}

/**
 * generateDateTimeFromRestaurantTime
 * Execution order: 6º
 * From a dateTime and schedule {hOpen, hClose} return computed datetime*** to evaluate after
 * *** computed datetime, takes into account if is calculating for start time o end of interval (isEnd),
 * the type of the interval (type, substracting 1h or pickupTime if is needed)
 * 
 * #COMPUTED DIFERENT STATES#
  * schedule = {hourOpen, hourClose}
  * >> OPEN
  * start = hourOpen to 
  * end = hourClose - NEAR_TO_CLOSE_MINUTES
  * >> NEAR_TO_CLOSE
  * start = hourClose - NEAR_TO_CLOSE_MINUTES
  * end = hourClose - pickupTime
  * >> ONLY_LOCAL
  * start = hourClose - pickupTime
  * end = hourClose
 * ##
 * 
 * ### SPECIAL CORNER CASES ###
  * >> CORNER CASE 1
  * Is a small period of time configured like 23:00 - 23:15, pickupTime = 30
  * if (hClose - (NEAR_TO_CLOSE_MINUTES || pickUptime)) < hourOpen => take hourOpen
  * Prevent cases when minus - x min can be lower than hourOpen
  *  >> CORNER CASE 2
  * time configured like 23:00 - 23:15, pickupTime = 90 (pickupTime > NEAR_TO_CLOSE_MINUTES)
  * if (pickupTime > NEAR_TO_CLOSE_MINUTES) => take NEAR_TO_CLOSE_MINUTES
  * Prevent cases when NEAR_TO_CLOSE can be a fake interval as (22:00 - 21:30)
  * and prevent cases when ONLY_LOCAL interval overlaps OPEN interval
 * ### 
 * @param object  {
      dateTime: DateTime
      schedule: RestaurantSchedule
      isEnd?: boolean = false
      pickupTime: number
      type: RestaurantScheduleTypes = RestaurantScheduleTypes.OPEN
      is24h: boolean
    }
 * @returns DateTime
 */
const generateDateTimeFromRestaurantTime = ({
  dateTime,
  schedule,
  isEnd = false,
  pickupTime,
  type = RestaurantScheduleTypes.OPEN,
  is24h = false,
}: {
  dateTime: DateTime
  schedule: RestaurantSchedule
  isEnd?: boolean
  pickupTime: number
  type: RestaurantScheduleTypesUnion
  is24h: boolean
}): DateTime => {
  // if restaurant schedule is 24h - skip computing for NEAR_TO_CLOSE AND ONLY_LOCAL
  // the restaurant is full open today 24h and is not closing
  if (
    is24h &&
    (RestaurantScheduleTypes.NEAR_TO_CLOSE === type || RestaurantScheduleTypes.ONLY_LOCAL === type)
  ) {
    const time = schedule.hourClose
    const timeObject = getTimeObjectFromRestaurantTime(time)
    const { year, month, day, zone } = dateTime
    const dateObject = { ...{ year, month, day, seconds: 0, milliseconds: 0 }, ...timeObject }
    const datetime = DateTime.fromObject(dateObject, { zone })
    return datetime
  }

  let time = isEnd ? schedule.hourClose : schedule.hourOpen
  if (
    !isEnd &&
    (type === RestaurantScheduleTypes.NEAR_TO_CLOSE || type === RestaurantScheduleTypes.ONLY_LOCAL)
  )
    time = schedule.hourClose
  const timeObject = getTimeObjectFromRestaurantTime(time)
  const { year, month, day, zone } = dateTime
  const dateObject = { ...{ year, month, day, seconds: 0, milliseconds: 0 }, ...timeObject }
  let datetime = DateTime.fromObject(dateObject, { zone })

  // >> CORNER CASE 1 - calculate hourOpen DateTime
  const hourOpenObject = getTimeObjectFromRestaurantTime(schedule.hourOpen)
  const { year: yearS, month: monthS, day: dayS, zone: zoneS } = dateTime
  const hourOpenDateObject = {
    ...{ year: yearS, month: monthS, day: dayS, seconds: 0, milliseconds: 0 },
    ...hourOpenObject,
  }
  const hourOpenDatetime = DateTime.fromObject(hourOpenDateObject, { zone: zoneS })

  switch (type) {
    case RestaurantScheduleTypes.OPEN:
      // only in case for hourClose when the period is not 24h full open
      if (isEnd && !is24h) {
        const calculatedDatetime = datetime.minus({ minutes: NEAR_TO_CLOSE_MINUTES }) // hClose: hClose - 1h
        // >> CORNER CASE 1
        if (calculatedDatetime.valueOf() < hourOpenDatetime.valueOf()) {
          datetime = hourOpenDatetime
        } else {
          datetime = calculatedDatetime
        }
      }
      break
    case RestaurantScheduleTypes.NEAR_TO_CLOSE: {
      // >> CORNER CASE 2
      const maxPickupTime = pickupTime > NEAR_TO_CLOSE_MINUTES ? NEAR_TO_CLOSE_MINUTES : pickupTime
      const minutes = !isEnd ? NEAR_TO_CLOSE_MINUTES : maxPickupTime // hOpen: hClose - 1h , end: hClose - pickuptime
      const calculatedDatetime = datetime.minus({ minutes })
      // >> CORNER CASE 1
      if (calculatedDatetime.valueOf() < hourOpenDatetime.valueOf()) {
        datetime = hourOpenDatetime
      } else {
        datetime = calculatedDatetime
      }
      break
    }
    case RestaurantScheduleTypes.ONLY_LOCAL:
      if (!isEnd) {
        // >> CORNER CASE 2
        const maxPickupTime =
          pickupTime > NEAR_TO_CLOSE_MINUTES ? NEAR_TO_CLOSE_MINUTES : pickupTime
        const calculatedDatetime = datetime.minus({ minutes: maxPickupTime }) // start: h.Close - pickupTime, end: h.Close
        // >> CORNER CASE 1
        if (calculatedDatetime.valueOf() < hourOpenDatetime.valueOf()) {
          datetime = hourOpenDatetime
        } else {
          datetime = calculatedDatetime
        }
      }
  }
  return datetime
}

/**
 * getServiceIntervals
 * Execution order: 4º
 * From a dateTime, for a type,area  take timePeriods for that weeklyDay and return a Interval from that schedule
 * @param dateTime
 * @param restaurant
 * @param pickupTime
 * @param type
 * @param area
 * @returns Interval[]
 */
const getServiceIntervals = (
  dateTime: DateTime,
  timePeriods: TimeSlotService,
  pickupTime: number,
  type: RestaurantScheduleTypesUnion = RestaurantScheduleTypes.OPEN,
  area?: StoreRestaurantAreasUnion
): Interval[] => {
  if (!timePeriods) return []
  const weeklyDay = getWeeklyDay(dateTime)
  const logLabelExtra = `${area ? area.toUpperCase() : 'SCHEDULE'}:${weeklyDay.toUpperCase()}`
  debug('START__getServiceIntervals:' + logLabelExtra)
  // Get all periods for this area and weekly day
  const timePeriodsArr = area
    ? timePeriods
        .filter(serviceHour => serviceHour.type === area)
        .map(sh => sh.daysOfWeek)
        .flat()
        .filter(daysOfWeek => daysOfWeek.day === weeklyDay)
        .map(dOW => dOW.timePeriods || [])
        .flat()
        .map(timePeriod =>
          timePeriod.hourOpen === OPEN_24H_FROM_MODEL.hourOpen &&
          timePeriod.hourClose === OPEN_24H_FROM_MODEL.hourClose
            ? OPEN_24H
            : timePeriod
        )
    : []
  debug('timePeriods', timePeriodsArr)
  debug('END__getServiceIntervals:' + logLabelExtra)
  return timePeriodsArr.map(periods => getIntervalFromSchedule(dateTime, periods, pickupTime, type))
}

/**
 * getServiceIntervalsByDateTimes
 * Execution order: 3º
 * From dateTimes return Intervals[] from a type in a particular area
 * @param dateTimes DateTime[]
 * @param timePeriods TimeSlotService
 * @param pickupTime
 * @param type
 * @param area
 * @returns Interval[]
 */
const getServiceIntervalsByDateTimes = (
  dateTimes: DateTime[],
  timePeriods: TimeSlotService,
  pickupTime: number,
  type: RestaurantScheduleTypesUnion = RestaurantScheduleTypes.OPEN,
  area?: StoreRestaurantAreasUnion
): Interval[] => {
  return dateTimes
    .map(dateTime => getServiceIntervals(dateTime, timePeriods, pickupTime, type, area))
    .filter(interval => interval !== undefined)
    .flat()
    .map(i => i as Interval)
}

/**
 * isOpenService
 * Execution order: 2º
 * Take datetime as today and return boolean if today is contained on intervals of one state for one area,
 * taking into account yesterday, today, and tomorrow ***??
 * @param today
 * @param timePeriods TimeSlotService[]
 * @param pickupTime
 * @param type
 * @param area
 * @returns boolean
 */
const isOpenService = (
  today: DateTime,
  timePeriods: TimeSlotService,
  pickupTime: number,
  type: RestaurantScheduleTypesUnion = RestaurantScheduleTypes.OPEN,
  area?: StoreRestaurantAreasUnion
) => {
  // eslint-disable-next-line no-console
  console.groupCollapsed(
    'START isOpenService:' + (area?.toUpperCase() || 'DEFAULT') + ':type=' + type
  )
  const dateTimes: DateTime[] = [today]
  const intervals: Interval[] = getServiceIntervalsByDateTimes(
    dateTimes,
    timePeriods,
    pickupTime,
    type,
    area
  )
  debug('today (timezoned by restaurant.timezone)', today.toString())
  debug(
    'intervals',
    intervals.map((i: Interval) => ({ start: i?.start?.toString(), end: i?.end?.toString() }))
  )
  // eslint-disable-next-line no-console
  console.groupEnd()
  return intervals.some(interval => interval.contains(today))
}

/**
 * Helper function called from getRestaurantOpenState
 * Takes OpenServices from each type calculated (OPEN,NEAR_TO_CLOSE,ONLY_LOCAL)
 * and return OpenServicesState
 * @param open
 * @param nearClosed
 * @param onlyLocal
 * @returns OpenServicesState
 */
const reduceRestaurantOpenServices = (
  open: OpenServices,
  nearClosed: OpenServices,
  onlyLocal: OpenServices
): OpenServicesState => {
  const openServices: OpenServicesState = {
    [StoreRestaurantAreas.DLV]: RestaurantScheduleTypes.CLOSED,
    [StoreRestaurantAreas.MOP]: RestaurantScheduleTypes.CLOSED,
    [StoreRestaurantAreas.EALM]: RestaurantScheduleTypes.CLOSED,
    [StoreRestaurantAreas.AUT]: RestaurantScheduleTypes.CLOSED,
    [StoreRestaurantAreas.CURB]: RestaurantScheduleTypes.CLOSED,
  }
  for (const area in StoreRestaurantAreas) {
    const areaKey = area.toLowerCase() as StoreRestaurantAreasUnion
    const isOpen = open[areaKey]
    const isNearClosed = nearClosed[areaKey]
    const isOnlyLocal = onlyLocal[areaKey]
    let state = null
    if (isOpen) state = RestaurantScheduleTypes.OPEN
    if (isNearClosed) state = RestaurantScheduleTypes.NEAR_TO_CLOSE
    if (isOnlyLocal) state = RestaurantScheduleTypes.ONLY_LOCAL
    if (state) openServices[areaKey] = state
  }

  return openServices
}

/**
 * Helper function called from getRestaurantOpenState
 * Build object with computed opened states for type (OPEN,NEAR_TO_CLOSE,ONLY_LOCAL) for all areas and return it
 * @param localeDateTime
 * @param timePeriods TimeSlotService[]
 * @param pickupTime
 * @param type
 * @returns RestaurantStateByArea
 */
const buildServicesByTypeAndArea = (
  localeDateTime: DateTime,
  timePeriods: TimeSlotService,
  pickupTime: number,
  type: RestaurantScheduleTypesUnion
): OpenServices => {
  const state: OpenServices = {
    [StoreRestaurantAreas.DLV]: false,
    [StoreRestaurantAreas.MOP]: false,
    [StoreRestaurantAreas.EALM]: false,
    [StoreRestaurantAreas.AUT]: false,
    [StoreRestaurantAreas.CURB]: false,
  }
  for (const area in StoreRestaurantAreas) {
    const areaKey = area.toLowerCase() as StoreRestaurantAreasUnion
    state[areaKey] = isOpenService(localeDateTime, timePeriods, pickupTime, type, areaKey)
  }
  return state
}

/**
 * getGeneralHoursPeriodsByDay
 * For computing general schedule for restaurant
 * @param generalHour: GeneralHourTimeSlots
 * @returns ServiceHourDayOfWeek[]
 */
export const getGeneralHoursPeriodsByDay = (
  generalHour: GeneralHourTimeSlots
): ServiceHourDayOfWeek[] => {
  if (generalHour.daysOfWeek?.length) {
    const generalHoursPeriodsByDay: ServiceHourDayOfWeek[] = generalHour.daysOfWeek.map(
      (daysOfWeek: GeneralServiceHourDayOfWeek) => {
        // map periods taking into account 24H open periods
        const timePeriods = daysOfWeek.timePeriods.map((timePeriod: RestaurantGeneralSchedule) =>
          timePeriod.start === OPEN_24H_FROM_MODEL.hourOpen &&
          timePeriod.end === OPEN_24H_FROM_MODEL.hourClose
            ? OPEN_24H
            : { hourOpen: timePeriod.start, hourClose: timePeriod.end }
        )
        return { day: daysOfWeek.day, timePeriods }
      }
    )
    return generalHoursPeriodsByDay
    // daysOfWeek comes empty
  } else return []
}

/**
 * Main function
 * Execution order: 1º
 * Set CLOSED, OPEN, NEAR_TO_CLOSE, ONLY_LOCAL state for each Area
 * @param data :RestauranOpenStateDTO ({datetime, active, code, timezone, timePeriods, pickupTime})
 * @returns StoreRestaurantOpenState
 */
export const getRestaurantOpenState = (data: RestauranOpenStateDTO): StoreRestaurantOpenState => {
  const { datetime, active, code, timezone, timePeriods, pickupTime = 0 } = data

  // eslint-disable-next-line no-console
  console.groupCollapsed('getRestaurantOpenState:' + code || '')
  const localeDateTime = datetime.setZone(timezone)
  const weeklyDay = getWeeklyDay(localeDateTime)
  debug('timezone,localeDateTime,weeklyDay', {
    timezone,
    localeDateTime,
    weeklyDay,
    pickupTime,
  })

  if (!active) {
    debug('restaurant is inactive skipping schedule computing')
    const openState = {
      [StoreRestaurantAreas.DLV]: RestaurantScheduleTypes.CLOSED,
      [StoreRestaurantAreas.MOP]: RestaurantScheduleTypes.CLOSED,
      [StoreRestaurantAreas.EALM]: RestaurantScheduleTypes.CLOSED,
      [StoreRestaurantAreas.AUT]: RestaurantScheduleTypes.CLOSED,
      [StoreRestaurantAreas.CURB]: RestaurantScheduleTypes.CLOSED,
    }
    debug('fixed restaurant open state', openState)
    // eslint-disable-next-line no-console
    console.groupEnd()
    return openState
  }

  const openServices = buildServicesByTypeAndArea(
    localeDateTime,
    timePeriods,
    pickupTime,
    RestaurantScheduleTypes.OPEN
  )

  const nearClosedServices = buildServicesByTypeAndArea(
    localeDateTime,
    timePeriods,
    pickupTime,
    RestaurantScheduleTypes.NEAR_TO_CLOSE
  )

  const onlyLocalServices = buildServicesByTypeAndArea(
    localeDateTime,
    timePeriods,
    pickupTime,
    RestaurantScheduleTypes.ONLY_LOCAL
  )

  const openState = reduceRestaurantOpenServices(
    openServices,
    nearClosedServices,
    onlyLocalServices
  )
  debug('calculated restaurant open state', openState)
  // eslint-disable-next-line no-console
  console.groupEnd()
  return openState
}

export const getGlobalScheduleHoursByDay = (
  restaurantName: string,
  generalHour: GeneralHourTimeSlots | undefined
): GeneralHoursByDay[] | undefined => {
  // logs open collapsed group
  // eslint-disable-next-line no-console
  console.groupCollapsed('getGlobalScheduleHoursByDay:', restaurantName || '')

  let schedules: ServiceHourDayOfWeek[] = []

  if (!generalHour) {
    // Store restaurant with no generalHour configured
    schedules = []
  } else {
    const generalHoursPeriodsByDay = getGeneralHoursPeriodsByDay(generalHour)
    schedules = generalHoursPeriodsByDay
  }

  // logs
  debug('general schedules', schedules)
  if (schedules.length === 0)
    debug('is a store restaurant with no generalHour or generalHour.daysOfWeek is empty')
  // eslint-disable-next-line no-console
  console.groupEnd()

  if (schedules.length === 0) return undefined // is a store restaurant with no generalHour or generalHour.daysOfWeek is empty

  return schedules.map((dayOfWeek: ServiceHourDayOfWeek) => ({
    day: dayOfWeek.day,
    schedule: dayOfWeek.timePeriods.reduce(
      (prev, cur) =>
        `${prev}${prev ? ', ' : ''}${
          cur.hourOpen === OPEN_24H.hourOpen &&
          (cur.hourClose === OPEN_24H.hourClose || cur.hourClose === OPEN_24H_FROM_MODEL.hourClose)
            ? '24 hs'
            : `${cur.hourOpen} a ${cur.hourClose}`
        }`,
      ''
    ),
  }))
}

export const selectedRestaurantSchedule = (
  schedules: GeneralHoursByDay[]
): OpeningHoursSpecificationRestaurant[] => {
  const restaurantSchedules = schedules
  const scheduleStructure: OpeningHoursSpecificationRestaurant[] = []
  if (restaurantSchedules === undefined) return []
  restaurantSchedules.forEach((hour: any) => {
    const rangeHour = hour.schedule
    // Structure openingHoursSpecification
    const openingHoursSpecification: OpeningHoursSpecificationRestaurant = {
      '@type': 'OpeningHoursSpecification',
      'dayOfWeek': '',
      'opens': '',
      'closes': '',
    }
    // Add day
    openingHoursSpecification.dayOfWeek = structureRestaurantWeeklyDays[hour.day]
    // If there are ranges of schedules in the day the opening and closing schedule is taken only
    if (rangeHour.includes(',')) {
      const openingHours = rangeHour.split(',')[0]
      const closingSchedule = rangeHour.split(',')[1]
      openingHoursSpecification.opens = openingHours.slice(0, 5)
      openingHoursSpecification.closes = closingSchedule.slice(-5)
    } else if (rangeHour.includes('24')) {
      // -If schedule is 24 hours add the following structure
      openingHoursSpecification.opens = OPEN_24H_FROM_MODEL.hourOpen
      openingHoursSpecification.closes = OPEN_24H_FROM_MODEL.hourClose
    } else if (rangeHour === '') {
      // -If restaurant is closed, the following format is added
      openingHoursSpecification.opens = OPEN_24H_FROM_MODEL.hourOpen
      openingHoursSpecification.closes = OPEN_24H_FROM_MODEL.hourOpen
    } else {
      // -If there is opening and closing schedule
      openingHoursSpecification.opens = rangeHour.slice(0, 5)
      openingHoursSpecification.closes = rangeHour.slice(-5)
    }
    scheduleStructure.push(openingHoursSpecification)
  })
  return scheduleStructure
}
