import { useDialog } from 'primevue/usedialog'
import Debug from 'debug'
import {
  Restaurant,
  RestaurantDelivery,
  RestaurantInfo,
} from '~/lib/services/store/restaurant/restaurant.dto'
import { RestaurantAreaType, RestaurantAreas } from '~/lib/interfaces/restaurant'
import { Category } from '~/lib/services/store/catalog/catalog.dtos'
import { McdViewError } from '~/lib/models/errors'
import {
  EcommerceAreaData,
  EcommerceHomeData,
  EcommerceCategoryData,
  EcommerceProductData,
} from '~/lib/models/async-data'

const debug = Debug('app:composable:useRestaurantActions')

export const useRestaurantActions = () => {
  const dialog = useDialog()
  const restaurantStore = useRestaurantStore()
  const appStore = useAppStore()
  const { t } = useLocale()
  const { navigateToRestaurantHome, getMenuAreaRoute, getCategoryRoute, staticMenuRoute } =
    useMcdRoutes()
  const { catalogServiceRef, restaurantServiceRef } = useStoreService()
  const catalogService = catalogServiceRef()
  const restaurantService = restaurantServiceRef()

  const showOverlay: Function = inject('showOverlay')!

  const handleErrorSettingRestaurant = async () => {
    await showDialogAlert({
      dialog,
      // TODO: change with appropiate message when design decide what we should show
      data: {
        title: 'No puedes seleccionar este restaurante',
        body: 'Este restaurante no está disponible en estos momentos. Por favor, selecciona otro restaurante.',
        btnPrimary: 'Aceptar',
        headerImage: '/images/icons/area/sign.svg',
      },
    })
  }

  const handleChangeRestaurant = async () => {
    const cart = appStore.cart
    const filledCart = cart.products && cart.products.length > 0

    if (!filledCart) return true
    return await showDialogAlert({
      dialog,
      data: {
        title: t(`alertDialog.changeRestaurant.title${restaurantStore.areaSelected || 'MOP'}`),
        body: t('alertDialog.changeRestaurant.body'),
        btnPrimary: t('alertDialog.changeRestaurant.btnPrimary'),
        btnSecondary: t('alertDialog.changeRestaurant.btnSecondary'),
        headerImage: '/images/icons/area/sign.svg',
      },
    })
  }

  const setRestaurant = async (restaurant: Restaurant | RestaurantDelivery) => {
    try {
      const result = await handleChangeRestaurant()
      if (!result) return
      // open overlay
      showOverlay(true)
      // Set the restaurant info
      restaurantStore.setRestaurant(restaurant)
      // load this restaurant categories for area selected
      await loadRestaurantCategories(restaurant.id, restaurantStore.areaSelected)
      // navigate to home ecommerce of this restaurant
      const { code, name, city } = restaurant
      restaurantStore.closeModal()
      restaurantStore.closeChangeRestaurantModal()
      appStore.clearCart()
      await navigateToRestaurantHome({ code, name, city })
    } catch (err) {
      handleErrorSettingRestaurant()
    } finally {
      // close overlay
      showOverlay(false)
    }
  }

  const setCrewStatus = (isCrew: boolean) => {
    restaurantStore.setIsCrew(isCrew)
  }

  const setDeliveryDistance = (distance: number) => {
    restaurantStore.setInitialDeliveryAddressDistance(distance)
  }

  const loadRestaurantCategories = async (restaurantId: string, area?: RestaurantAreaType) =>
    await appStore.loadCategories(restaurantId, area)

  const menuProductsPath = computed(() => {
    if (!restaurantStore.restaurant) return staticMenuRoute()
    const { code, id, name, city } = restaurantStore.restaurant
    // Menu products dinamic link menu
    const pathTo = getMenuAreaRoute({
      restaurantInfo: { id, code, name, city },
      routeArea: restaurantStore.areaSelected,
    })
    return pathTo
  })

  /* Validate routes */

  // Ecommerce home
  const getEcommerceHomeData = async (restaurantCode: string): Promise<EcommerceHomeData> => {
    let area

    // Load ecommerce blocks
    const contentStore = useContentStore()
    if (!contentStore.ecommerceBlocks) await contentStore.loadEcommerceBlocks()
    if (
      !restaurantStore.isRestaurantSelected ||
      restaurantStore?.selectedRestaurant?.restaurant?.code !== restaurantCode.toUpperCase()
    ) {
      try {
        area = RestaurantAreas.MOP
        let featured: Category[] = []

        const restaurant = await restaurantService.getStoreRestaurantByCode({
          countryCode: appStore.countryCode,
          area,
          restaurantCode,
        })

        const categories = await catalogService.getStoreCategories({
          countryCode: appStore.countryCode,
          restaurant: restaurant.id,
          area,
        })
        try {
          featured = await catalogService.getFeaturedCategories({
            countryCode: appStore.countryCode,
            restaurant: restaurant.id,
            area,
          })
        } catch (error) {
          debug('Error fetching featured on other restaurant or area', error)
        }
        const { name, code, id, city } = restaurant
        return { categories, featured, restaurant: { id, code, name, city }, area }
      } catch (error: any) {
        debug(
          '🚀 ~ file: useRestaurantActions.ts:148 ~ getEcommerceHomeData ~ error:',
          error?.response || error.message
        )
        throw new McdViewError(error.message, 500)
      }
    } else {
      const restaurant = restaurantStore.selectedRestaurant!.restaurant!
      let featured: Category[] = []
      try {
        area = restaurantStore.areaSelected || RestaurantAreas.MOP
        featured = await catalogService.getFeaturedCategories({
          countryCode: appStore.countryCode,
          restaurant: restaurant.id,
          area,
        })
      } catch (error: any) {
        debug('🚀 ~ file: useRestaurantActions.ts:166 ~ getEcommerceHomeData ~ error:', error)
      }

      return {
        categories: appStore.categories || [],
        featured,
        restaurant,
        area,
      }
    }
  }

  // Ecommerce restaurant menu within area
  const getEcommerceAreaMenuData = async (
    restaurantCode: string,
    areaSlug: string
  ): Promise<EcommerceAreaData> => {
    const areas: { [key: string]: string } = {
      MOP: slugify(t('common.MOP')),
      DLV: slugify(t('common.DLV')),
    }
    // Area is not permitted
    if (!Object.values(areas).includes(areaSlug)) {
      throw new McdViewError('Area not mapped', 404)
    }
    const routeArea = areas.MOP === areaSlug ? RestaurantAreas.MOP : RestaurantAreas.DLV
    const matchingArea = restaurantStore.areaSelected === routeArea
    const matchingRestaurant =
      restaurantStore.selectedRestaurant?.restaurant?.code === restaurantCode.toUpperCase()

    try {
      if (matchingArea && matchingRestaurant) {
        // return categories y tal
        const { name, code, id, city } = restaurantStore.selectedRestaurant!.restaurant!
        return {
          categories: appStore.categories || [],
          area: routeArea,
          restaurant: { id, name, code, city },
        }
      } else {
        let restaurant: RestaurantInfo | undefined = restaurantStore.selectedRestaurant?.restaurant
        let categories: Category[] = []
        // no restaurant selected or is visiting another restaurant route
        if (!matchingRestaurant) {
          const rest = await restaurantService.getStoreRestaurantByCode({
            countryCode: appStore.countryCode,
            area: routeArea,
            restaurantCode,
          })
          const { name, code, id, city } = rest
          restaurant = { name, code, id, city }
        }
        if (!matchingArea || (matchingArea && !matchingRestaurant)) {
          categories = await catalogService.getStoreCategories({
            countryCode: appStore.countryCode,
            restaurant: restaurant!.id,
            area: routeArea,
          })
        }

        return { categories, area: routeArea, restaurant }
      }
    } catch (error: any) {
      debug(
        '🚀 ~ file: useRestaurantActions.ts:230 ~ getEcommerceAreaMenuData ~ error:',
        error?.response || error.message
      )
      throw new McdViewError(error.message, 404)
    }
  }

  // Ecommerce restaurant category within area
  const getEcommerceAreaCategoryData = async (
    restaurantCode: string,
    areaSlug: string,
    categorySlug: string
  ) => {
    const data = await getEcommerceAreaMenuData(restaurantCode, areaSlug)
    const { categories } = data
    const categoryFounded = categories?.find(cat => slugify(cat.title) === categorySlug)
    if (!categoryFounded) {
      throw new McdViewError(
        'Category not found',
        404,
        data.restaurant
          ? getMenuAreaRoute({ restaurantInfo: data.restaurant, routeArea: data.area })
          : undefined
      )
    }
    return {
      categories, // For CategoryView
      category: categoryFounded,
      restaurant: data.restaurant,
      area: data.area,
    }
  }

  // Ecommerce restaurant productDetail within area and category
  const getEcommerceAreaCategoryProductData = async (
    restaurantCode: string,
    areaSlug: string,
    categorySlug: string,
    productSlug: string
  ): Promise<EcommerceProductData> => {
    let categoryRoute = ''
    let data: EcommerceCategoryData
    try {
      data = await getEcommerceAreaCategoryData(restaurantCode, areaSlug, categorySlug)
    } catch (error: any) {
      debug('🚀 ~ file: useRestaurantActions.ts:276 ~ useRestaurantActions ~ error:', error.message)
      throw error
    }

    try {
      const { category, restaurant, area } = data!
      const productLite = category.products.find(p => slugify(p.name) === productSlug)
      categoryRoute = restaurant
        ? getCategoryRoute({
            restaurantInfo: restaurant,
            categoryTitle: category.title,
            routeArea: area,
          })
        : ''
      if (!productLite) throw new McdViewError('Product not found', 404, categoryRoute || undefined)
      const fetchProductInput = {
        countryCode: appStore.countryCode,
        restaurantId: restaurant?.id || '',
        categoryId: category.idRfm,
        productId: productLite.identifier,
        area: area!,
      }

      const product = await catalogService.getProductDetail(fetchProductInput)

      return {
        category,
        restaurant: data!.restaurant,
        area: data!.area,
        product,
      }
    } catch (error: any) {
      debug(
        '🚀 ~ file: useRestaurantActions.ts:312 ~ useRestaurantActions ~ error:',
        error?.response || error.message
      )
      throw new McdViewError(error.message, 404, categoryRoute || undefined)
    }
  }

  return {
    setRestaurant,
    setCrewStatus,
    setDeliveryDistance,
    loadRestaurantCategories,
    // validateRoutes
    getEcommerceHomeData,
    getEcommerceAreaMenuData,
    getEcommerceAreaCategoryData,
    getEcommerceAreaCategoryProductData,
    // computed
    menuProductsPath,
  }
}
