<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    url?: string
    alt: string
    loading?: string
    fallbackImage?: string
  }>(),
  {
    url: '/images/icons/placeholder.svg',
    loading: undefined,
    fallbackImage: '/images/icons/placeholder.svg',
  }
)

const onLoadError = (ev: any) => {
  if (ev.target?.src) ev.target.src = props.fallbackImage
}
</script>

<template>
  <img :src="url" :alt="alt" :loading="loading" @error="onLoadError" />
</template>
