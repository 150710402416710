<script setup lang="ts">
/* 
  Basic collapsable to have custom elements inside with slots
  1. Showed on header is part of the content to be expanded keepHeaderStatic: false
  2. Header is a fixed content separate from content keepHeaderStatic: true
  3. Invert arrows invertArrows: true
*/
const props = withDefaults(
  defineProps<{ keepHeaderStatic?: boolean; invertArrows?: boolean; showCollapse?: boolean }>(),
  {
    keepHeaderStatic: false,
    invertArrows: false,
    showCollapse: undefined,
  }
)
const emit = defineEmits(['show-change'])

const isOpen = ref(false)

const collapseIcon = computed(() => {
  const value = props.invertArrows ? !isOpen.value : isOpen.value
  if (value) return '/images/icons/arrowUp.svg'
  else return '/images/icons/arrowDown.svg'
})

const changeVisibility = () => {
  isOpen.value = !isOpen.value
  emit('show-change', isOpen.value)
}
</script>

<template>
  <div>
    <div
      data-test="mcd-collapsable__element__visible"
      class="flex cursor-pointer flex-row items-center justify-between"
      @click="changeVisibility"
    >
      <!-- Showed on header is part of the content that will be expanded -->
      <template v-if="!keepHeaderStatic">
        <template v-if="isOpen">
          <slot name="content"></slot>
        </template>
        <template v-else>
          <slot name="header"></slot>
        </template>
      </template>

      <!-- Header is always fixed -->
      <template v-else>
        <slot name="header"></slot>
      </template>
      <img
        v-if="showCollapse === undefined || showCollapse"
        class="ml-2"
        :class="{ '-mt-1 self-start': !keepHeaderStatic && isOpen }"
        :src="collapseIcon"
        alt="collapsable"
      />
    </div>
    <Transition name="slide-in" appear>
      <section data-test="mcd-collapsable__element__content">
        <div v-show="keepHeaderStatic && isOpen" class="mt-2">
          <slot name="content"></slot>
        </div>
      </section>
    </Transition>
  </div>
</template>
