export type ButtonNavigation = {
  title: string
  path: string
  icon: string
}

export const excludedRoutesBottomNavigation = [
  'orders-cart',
  'orders-cart-cartProductIndex',
  'orders-checkout',
  'restaurants-citySlug-restaurantSlug-orders-areaSlug-categorySlug-productSlug',
  'menu-categorySlug-productSlug',
  'restaurants-citySlug-restaurantSlug',
  'restaurants-citySlug',
  'restaurants',
  'profile-sectionSlug',
  'my-account-my-orders',
  'my-account-my-orders-orderId',
  'my-account-sectionSlug',
  'faqs',
  'faqs-question',
]
