export function useIsTablet() {
  const isTablet = ref(false)

  const handleResize = () => {
    isTablet.value = window.innerWidth >= 768 && window.innerWidth < 1024
  }

  onMounted(() => {
    if (typeof window !== 'undefined') {
      isTablet.value = window.innerWidth >= 768 && window.innerWidth < 1024
      window.addEventListener('resize', handleResize)
    }
  })

  onUnmounted(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', handleResize)
    }
  })

  return isTablet
}
