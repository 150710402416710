import { defineStore } from 'pinia'
import { Block } from '~/lib/services/content/content.blocks.dtos'
import {
  Locales,
  LocalizationStore,
  Smartbanner,
} from '~/lib/services/content/content.configuaration.dtos'
import { Page, Site } from '~/lib/services/content/content.dtos'
import { contentService } from '~/lib/services/content/content.service'

export const useContentStore = defineStore('content', {
  state: () => ({
    site: undefined as Site | undefined,
    page: undefined as Page | undefined,
    ecommerceBlocks: undefined as Block[] | undefined,
    countryCode: 'UY',
  }),
  actions: {
    async loadSite(domain: string) {
      this.site = await contentService.getSiteByDomain(domain)
      // Get localization config
      const config = this.site?.configuration
      const localization = config?.find(
        config => config.__component === 'configuration.localization'
      ) as LocalizationStore

      const language =
        localization?.language === 'es_variant'
          ? (`es_${localization?.country.toLowerCase()}` as Locales)
          : localization?.language || 'es'
      this.countryCode = localization?.country || 'UY'

      const smartbannerConfig = config?.find(
        config => config.__component === 'configuration.smartbanner'
      ) as Smartbanner | undefined

      return {
        site: this.site,
        countryCode: this.countryCode,
        language,
        smartbanner: smartbannerConfig,
      }
    },
    async loadPage(path: string) {
      if (!this.site) throw new Error('Site not loaded')
      const restaurantStore = useRestaurantStore()
      const { navigateToRestaurantHome } = useMcdRoutes()
      const route = useRoute()
      // Redirect to ecommerce restaurant home in case has restaurant selected entering home
      if (route.path === '/' && restaurantStore.selectedRestaurant?.restaurant) {
        const { name, city, code } = restaurantStore.selectedRestaurant!.restaurant
        return await navigateToRestaurantHome({ name, city, code, query: route.query || undefined })
      }
      this.page = await contentService.getPageByPath(this.site.id, path)
      return this.page
    },
    async loadEcommerceBlocks() {
      try {
        if (!this.site) throw new Error('Site not loaded')
        const { blocks } = await contentService.getEcommerceBlocksBySite(this.site.id)
        this.ecommerceBlocks = blocks
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(
          '🚀 ~ file: content.store.ts:54 ~ loadEcommerceBlocks ~ error:',
          error?.response || error
        )
        this.ecommerceBlocks = []
      }
    },
  },
})
