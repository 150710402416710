import { Coords } from './models/geolocation'

export const distanceBetweenCoordinates = (
  lat1: number | undefined,
  lon1: number | undefined,
  lat2: number,
  lon2: number,
  unit: 'M' | 'K' | 'N' = 'K'
) => {
  if (!lat1 || !lon1) return 0
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  } else {
    let dist = 0
    if (unit === 'K') {
      dist = getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2)
    }

    return dist
  }
}

export function getDistanceFromLatLonInMeters(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d * 1000
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180)
}

export const separateNumberFromDirection = ({ mainText = '' }) => {
  const splitedDirection = mainText.split(' ')
  const last = splitedDirection[splitedDirection.length - 1]
  const isNumber = !isNaN(Number(last))
  const number = isNumber ? last : ''
  return { number, direction: mainText }
}

export const compareSameCoords = ({ coord1, coord2 }: { coord1: Coords; coord2: Coords }) => {
  return coord1.lat === coord2.lat && coord1.lng === coord2.lng
}
