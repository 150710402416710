import { z } from 'zod'
import { deliverySchema } from '../restaurant/restaurant.dto'
import { promotionsCartDto } from '../../promos/promos.dto'
import { tipOptionDto } from '../country/country.dtos'
import { productDTOschema, productPromotion } from '../common.dto'
import { taxConfig } from './catalog.dtos'
import { RestaurantAreas } from '~/lib/interfaces/restaurant'

/* Cart models */
export const computedPrice = z.object({
  priceUnit: z.number(),
  price: z.number(),
  // promoted products
  discount: z.number().optional(),
  discountByUnit: z.number().optional(),
  priceWithDiscount: z.number().optional(),
  priceWithDiscountByUnit: z.number().optional(),
})
export type ComputedProductPrice = z.infer<typeof computedPrice>

export const cartProductSchema = productDTOschema.extend({
  name: z.string(),
  image: z.string().optional(),
  categoryTitle: z.string(),
  categoryIdRfm: z.string(),
  computedPrice,
  customization: z.string(),
  isMcDia: z.boolean().optional(),
})
export type CartProduct = z.infer<typeof cartProductSchema>

export const cartDTO = z.object({
  products: z.array(cartProductSchema),
})
export type Cart = z.infer<typeof cartDTO>

export const cartTotalsSchema = z.object({
  subtotal: z.number(),
  tip: z.number(),
  tax: z.number(),
  shipping: z.number(),
  serviceFee: z.number(),
  discount: z.number(),
  total: z.number(),
})

export type CartTotals = z.infer<typeof cartTotalsSchema>

/* Cart endpoint */

const restaurantDataSchema = z.object({
  id: z.string(),
  freeTax: z.boolean().optional(),
  delivery: deliverySchema.optional(),
  inPersonPayment: z.record(z.string(), z.boolean()).optional(),
  serviceFee: z
    .object({
      serviceFeeAmount: z.number(),
    })
    .optional(),
})

const productDBPrice = z.object({
  amount: z.number(),
  tax: taxConfig.optional(),
})

export type ProductDBPrice = z.infer<typeof productDBPrice>

const optionsGroupsDBBase = z.object({
  id: z.string(),
})

const optionDBBase = z.object({
  id: z.string(),
  available: z.boolean(),
  max: z.number(),
  min: z.number(),
  price: productDBPrice,
})

const optionsGroupsDBChild = optionsGroupsDBBase.extend({
  options: z.array(optionDBBase),
})

const optionDBMain = optionDBBase.extend({
  optionsGroups: z.array(optionsGroupsDBChild),
})

const optionsGroupsDBMain = optionsGroupsDBBase.extend({
  options: z.array(optionDBMain),
})

export type OptionsGroupsDBMain = z.infer<typeof optionsGroupsDBMain>

const productDBSchema = z.object({
  _id: z.string(),
  id: z.string(),
  // flags
  available: z.boolean(),
  combo: z.boolean(),
  isRedeemable: z.boolean(),
  // properties
  areas: z.array(z.string()), // TODO - better model
  points: z.number().optional(),
  loyaltyOfferId: z.string().optional(),
  loyaltyImg: z.string().optional(),
  // mcDia: z.any(), // TODO
  // prices
  price: productDBPrice,
  originalPrice: productDBPrice.optional(),
  unifiedPrice: productDBPrice.optional(),
  // optionsGroups
  optionsGroups: z.array(optionsGroupsDBMain),
})

export type ProductDB = z.infer<typeof productDBSchema>

const cartInputProductDto = productDTOschema.extend({
  catalogProduct: productDBSchema,
})
export type CartInputProductDTO = z.infer<typeof cartInputProductDto>

export const cartInputDto = z.object({
  areaCode: z.union([z.literal(RestaurantAreas.MOP), z.literal(RestaurantAreas.DLV)]),
  restaurantData: restaurantDataSchema,
  tip: tipOptionDto.optional(),
  promotionsList: promotionsCartDto.optional(),
  products: z.array(cartInputProductDto),
  isCreateOrder: z.boolean().default(false),
  distance: z.number().optional(),
})

export type CartInputDto = z.infer<typeof cartInputDto>

const cartAdditionalDataDto = z.object({
  paymentMethodsAvailable: z
    .object({
      inPerson: z.boolean(),
    })
    .optional(),
  isFreteFree: z.boolean().optional(),
  anyPromotionApplies: z.boolean().optional().nullable(),
})

export const cartProductDto = productDTOschema
  .extend({
    // its id is actually identifier!
    price: z.number(), // price of all units
    priceWithExtras: z.number(), // priceUnit with extras
    // promotions
    isPromo: z.boolean().optional(),
    isPromoApplied: z.boolean().optional(),
    discount: z.number().optional(),
    discountByUnit: z.number().optional(),
    priceWithDiscount: z.number().optional(),
    priceWithDiscountByUnit: z.number().optional(),
    promotions: z.array(productPromotion).optional(),
  })
  .omit({ identifier: true })

export const cartDto = z.object({
  extras: z.object({
    tax: z.number(),
    tip: z.number(),
    delivery: z.number().optional(),
    serviceFee: z.number().optional(),
  }),
  total: z.number(),
  subtotal: z.number(),
  products: z.array(cartProductDto),
  discount: z.number(),
  additionalData: cartAdditionalDataDto,
  exclusivePayment: z.boolean(),
})

export type CartDTO = z.infer<typeof cartDto>

export type CartBackendAdditional = {
  inPersonPayment: boolean
  isFreteFree: boolean
  exclusivePayment: boolean
  anyPromotionApplies: boolean
}
