<script lang="ts" setup>
/* We can here add slots for icon (like loading button or whatever) 
  or we can wrap primeVue button with pt options
*/
const props = withDefaults(
  defineProps<{
    isPrimary?: boolean
  }>(),
  {
    isPrimary: false,
  }
)
const emit = defineEmits(['on-click'])

const MCD_BUTTON_CLASS = {
  PRIMARY: 'btn-primary',
  SECONDARY: 'btn-secondary',
} as const

const computedClass = computed(
  () => `${props.isPrimary ? MCD_BUTTON_CLASS.PRIMARY : MCD_BUTTON_CLASS.SECONDARY}`
)
</script>

<template>
  <button v-bind="$attrs" :class="computedClass" @click="$ev => emit('on-click', $ev)">
    <slot></slot>
  </button>
</template>
