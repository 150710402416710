import { publicConfig } from '~/config/public'
import { RestaurantAreaType, RestaurantAreas } from '~/lib/interfaces/restaurant'
import { RestaurantInfo } from '~/lib/services/store/restaurant/restaurant.dto'
import { getRestaurantEcommerceHomeRoute, getRestaurantDetailRoute } from '~/utils/restaurantUtils'

export const useMcdRoutes = () => {
  const restaurantStore = useRestaurantStore()
  const { t } = useLocale()

  /* SSO */
  const ssoRoute = ({ register = false }: { register: boolean }) => {
    const route = useRoute()
    const contentStore = useContentStore()
    const currentUrl = route.fullPath
    const ssoUrl = contentStore.site?.ssoUrl || publicConfig.services.sso.url
    const targetUrl = new URL(`${ssoUrl}${register ? '/register' : ''}`)
    targetUrl.searchParams.append('redirect', currentUrl)
    return targetUrl.toString()
  }

  /* Home */
  const navigateToHome = async () => await navigateTo('/')

  /* MDW static */
  const staticMenuRoute = () => `/${t('routes.staticMenu.slug')}`
  const staticCategoryRoute = (catName: string) => `${staticMenuRoute()}/${slugify(catName)}`
  const staticProductRoute = (catName: string, productName: string) =>
    `${staticCategoryRoute(catName)}/${slugify(productName)}`

  /* Restaurant */
  const navigateToRestaurantHome = async ({
    code,
    name,
    city,
    query,
  }: {
    code: string
    name: string
    city: string
    query?: Record<string, any>
  }) => {
    const route = getRestaurantEcommerceHomeRoute({ code, name, city, t })
    if (isCypressSpecialBehaviour()) await useRouter().push({ path: route, query })
    await navigateTo({
      path: route,
      query,
    })
  }

  const restaurantsRoute = () => `/${t('routes.restaurants')}`
  const restaurantsCityRoute = (city: string) => `${restaurantsRoute()}/${city || '_'}`

  const restaurantDetailRoute = (code: string, name: string, city: string) =>
    getRestaurantDetailRoute({ code, name, city: city || '_', t })

  const navigateToRestaurantDetails = async (code: string, name: string, city: string) => {
    restaurantStore.closeModal()
    restaurantStore.closeChangeRestaurantModal()
    await navigateTo({ path: getRestaurantDetailRoute({ code, name, city: city || '_', t }) })
  }

  const navigateToCityRestaurantList = async (city: string) =>
    await navigateTo(restaurantsCityRoute(city))

  const navigateToRestaurants = async () => await navigateTo(restaurantsRoute())

  /* Ecommerce catalog */
  const getMenuAreaRoute = ({
    restaurantInfo,
    routeArea,
  }: {
    restaurantInfo: RestaurantInfo
    routeArea?: RestaurantAreaType
  }) => {
    const { code, name, city } = restaurantInfo
    const restaurantHomeRoute = getRestaurantEcommerceHomeRoute({ code, name, city, t })
    const area = routeArea || restaurantStore.areaSelected || RestaurantAreas.MOP
    const areaSlug = slugify(t(`common.${area}`))
    return `${restaurantHomeRoute}/${areaSlug}`
  }

  const getCategoryRoute = ({
    restaurantInfo,
    categoryTitle,
    routeArea,
  }: {
    restaurantInfo: RestaurantInfo
    categoryTitle: string
    routeArea?: RestaurantAreaType
  }) => {
    const area = routeArea || restaurantStore.areaSelected || RestaurantAreas.MOP
    return getRestaurantCategoryRoute({ ...restaurantInfo, t, area, categoryTitle })
  }

  const getProductRoute = ({
    restaurantInfo,
    categoryTitle,
    productName,
    routeArea,
  }: {
    restaurantInfo: RestaurantInfo
    categoryTitle: string
    productName: string
    routeArea?: RestaurantAreaType
  }) => {
    const area = routeArea || restaurantStore.areaSelected || RestaurantAreas.MOP
    return getRestaurantProductRoute({ ...restaurantInfo, t, area, categoryTitle, productName })
  }

  /* Cart */
  const cartRoute = () => `/${t('routes.ecommerceHome')}/${t('routes.cart')}`
  const editProductRoute = (cartProductIndex: number) => `${cartRoute()}/${cartProductIndex}`

  /* Checkout */
  const checkoutRoute = () => `/${t('routes.ecommerceHome')}/${t('routes.checkout')}`

  /* Profile */
  const profileRoute = () => `/${t('routes.profile')}`
  const personalDataRoute = () => `${profileRoute()}/${t('routes.personalData')}`

  /* Orders */
  const myOrdersRoute = () => `${profileRoute()}/${t('routes.orders')}`
  const orderDetailRoute = (orderId: string) => `${myOrdersRoute()}/${orderId}`

  /* ProfileSecurity */
  const signInAndSecurityRoute = () => `${profileRoute()}/${t('routes.signInSecurity')}`
  const myDevicesRoute = () => `${signInAndSecurityRoute()}/${t('routes.myDevices')}`

  /* Download Page */
  const downloadAppRoute = () => `/${t('routes.downloadApp')}` /* Profile */

  return {
    ssoRoute,
    navigateToHome,
    staticMenuRoute,
    staticCategoryRoute,
    staticProductRoute,
    restaurantsRoute,
    restaurantsCityRoute,
    restaurantDetailRoute,
    navigateToRestaurantHome,
    navigateToRestaurantDetails,
    getMenuAreaRoute,
    getCategoryRoute,
    getProductRoute,
    cartRoute,
    editProductRoute,
    checkoutRoute,
    profileRoute,
    personalDataRoute,
    myOrdersRoute,
    orderDetailRoute,
    signInAndSecurityRoute,
    myDevicesRoute,
    downloadAppRoute,
    navigateToCityRestaurantList,
    navigateToRestaurants,
  }
}
