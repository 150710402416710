// En back PromotionStatusNumberEnum
export const CHECK_PROMO_ERROR = {
  AcceptedCode: 816,
  AddressNotSelectedDlv: 818,
  AddressNotSelectedMop: 819,
  AddressPRESelected: 805,
  ChangeCode: 815,
  ExceededGlobalLimit: 801,
  ExceededUserLimit: 802,
  ExpiredCode: 806,
  InvalidBirthday: 810,
  InvalidCode: 800,
  InvalidCountry: 803,
  InvalidDlv: 823,
  InvalidFirstTime: 809,
  InvalidMaxAmount: 814,
  InvalidMinAmount: 813,
  InvalidPickup: 822,
  InvalidRestaurant: 804,
  MissedPromotedProduct: 812,
  MissedRequiredProduct: 811,
  NotAppliedCode: 817,
  NewUserAddressNotSelectedDlv: 820,
  NewUserAddressNotSelectedMop: 821,
  NotStartedCode: 807,
  OutOfTimeCode: 808,
  RemovedCode: 824,
  PromotionEngineIsDisabled: 825,
  PromotionHasChanged: 826,
  PromotionNotAcumulative: 827,
  InvalidAreaPickup: 828,
  ExceededGlobalDailyLimit: 829,
  ExceededUserDailyLimit: 830,
  ExceededGlobalLimitAmount: 831,
  ExceededUserLimitAmount: 832,
  PromotionNotMatchPaymentMethod: 833,
  SegmentIsRequired: 834,
  CumulativePromotionsNotAllowed: 835,
  AutoApplicablePromotionNotAllowed: 836,
} as const

export const PromotionErrorType = {
  status835: 'cumulativePromotionsNotAllowed',
  status836: 'autoApplicablePromotionNotAllowed',
} as const

export const SECTION_NAME_PROMOTION = {
  CART: 'Cart',
  PRODUCT_DETAIL: 'ProductDetail',
} as const

export type PromoError = (typeof PromotionErrorType)[keyof typeof PromotionErrorType]
