/* Constants */

export const pageAnalyticsTagPlaceholder = '<subpage>'
export const emptyDeliveryType = 'Ninguno'

// promos
export const tagEmployedCoupon = 'EMPLOYEE'
export const freeShippingCoupon = 'free_shipping'

export const EventOrigin = {
  DATALAYER: 'dl',
  FRONT_YUNO: 'front_yuno',
  FRONT: 'front',
} as const

export const CUSTOM_IM_ANALYTICS_ERRORS = {
  NO_COVERAGE: 605,
  NO_RIDERS: 613,
  TOO_FAR: 3000,
  IM_API_ERROR: 3001,
  FORM_ERROR: 3002,
} as const

export const CUSTOM_ERRORS_TYPES = {
  NO_RIDERS: 'Riders not available',
  NO_COVERAGE: 'Delivery not available',
  TOO_FAR: 'too_far',
}

export const WalkthroughType = {
  LOYALTY: 'loyalty',
  ECOMMERCE: 'ecommerce',
  NO_ECOMMERCE: 'no_ecommerce',
  COUPON: 'coupon',
} as const

export const GtmEventName = {
  PAGE_VIEW: 'page_view',
  SELECT_ITEM: 'select_item',
  VIEW_ITEM: 'view_item',
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_PAYMENT_INFO: 'add_payment_info',
  PURCHASE: 'purchase',
  REFUND: 'refund',
  ECOMMERCE_HOME: 'ecommerce_home',
  SELECT_PAYMENT_TYPE: 'select_payment_type',
  NO_RIDER: 'sin_rider',
  NO_COVERAGE: 'sin_cobertura',
  LOGIN: 'login',
  SIGN_UP: 'sign_up',
  ORDER_CANCELLED_BEFORE: 'order_cancelled_before',
  CUSTOM_ERROR: 'custom_error',
  ADD_FAVORITES: 'add_favorites',
  REMOVE_FAVORITES: 'remove_favorites',
  SEARCH: 'search',
  ADD_DISCOUNT: 'add_discount',
  ADD_TIP: 'add_tip',
  TRACK_MY_ORDER: 'track_my_order',
  REPEAT_ORDER: 'repeat_order',
  REPEAT_ORDER_OK: 'repeat_order_ok',
  WITHOUT_LOGIN: 'sin_login',
  REMOVE_FROM_CART: 'remove_from_cart',
  ORDER_FAILED: 'order_failed',
  LOGIN_ERROR: 'login_error',
  SIGN_UP_ERROR: 'sign_up_error',
  FORM_ERROR: 'form_error',
  SELECT_RESTAURANT: 'select_restaurant',
  CHANGE_RESTAURANT: 'change_restaurant',
  CONFIRM_ADDRESS: 'confirm_address',
  APPLY_COUPON: 'apply_coupon',
  GET_BILL: 'get_bill',
  VIEW_ITEM_LIST: 'view_item_list',
  MENU: 'menu_',
  SUBMENU_MORE: 'submenu_more_',
  GO_TO_PAY: 'go_to_pay',
  CLICK: 'click',
  CLICK_MOP: 'click_mop',
  CLICK_DLV: 'click_dlv',
  MY_ORDERS: 'my_orders',
  CLICK_IN_PROGRESS: 'click_in_progress',
  CLICK_FINISHED: 'click_finished',
  MYM_HOME: 'mym_home',
  SAVE_PAYMENT: 'save_payment',
  CLOSED_RESTAURANT: 'closed_restaurant',
  SHOW_DELIVERY_CODE: 'show_delivery_code',
  FORM_SUBMIT: 'form_submit',
  SEND_CODE: 'send_code',
  CONFIRM_POPUP: 'confirm_popup',
  GET_OFFER: 'get_offer',
  CHANGE_DELIVERY_OPTIONS: 'change_delivery_options',
  GO_ECOMMERCE: 'go_ecommerce',
  VIEW_CART: 'view_cart',
  DELIVERY_OPTIONS: 'delivery_options',
  ON_WAY: 'on_way',
  SELECT_PICKUP: 'select_pickup',
  TOO_FAR: 'too_far',
  DISPLAY_LIST: 'display_list',
  DISPLAY_MAP: 'display_map',
  CLICK_WHATSAPP_CONSULTAS: 'click_whatsapp_consultas',
  SELECT_RESTAURANT_AUTOMATIC: 'select_restaurant_automatic',
  SELECT_ADDRESS: 'select_address',
  GEOLOCATE: 'geolocate',
  ADD_PAYMENT_METHOD: 'add_payment_method',
  SURVEY_SUBMIT: 'survey_submit',
  CUSTOMIZE_ITEM: 'customize_item',
  ADDITIONAL_COMPONENT: 'additional_component',
  SELECT_COMPONENT: 'select_component',
  CUSTOMIZE_INGREDIENTS: 'customize_ingredients',
  SAVE_INGREDIENTS: 'save_ingredients',
  WALKTHROUGH: 'walkthrough',
  WALKTHROUGH_CLOSE: 'walkthrough_close',
  WALKTHROUGH_ACCOUNT: 'walkthrough_account',
  VALIDATION_WARNING: 'validation_warning',
  ENROLLMENT_COMPLETED: 'enrollment_completed',
  CLICK_GET_PRE_ORDER: 'click_get_pre_order',
  GET_PRE_ORDER: 'get_pre_order',
  DECLINE_GET_PRE_ORDER: 'decline_get_pre_order',
  VIEW_ORDER_DETAIL: 'view_order_detail',
  VIEW_ORDERS: 'view_orders',
  INCOMPATIBLE_PRODUCT: 'incompatible_product',
  FREE_SHIPPING: 'free_shipping',
  SELECT_PICKUP_RESTAURANT: 'select_pickup_restaurant',
  SELECT_PICKUP_TAKE_AWAY: 'select_pickup_take_away',
  CLICK_CHANGE_RESTAURANT: 'click_change_restaurant',
  CLICK_DONT_CHANGE_RESTAURANT: 'click_dont_change_restaurant',
  CLICK_CATEGORY: 'click_category',
  LOGOUT: 'logout',
} as const

export const GTMContentGroup = {
  primary: {
    HOME: 'home',
    ECOMMERCE: 'ecommerce',
    ERROR: 'error',
    MENU: 'menu',
    OUR_MENU: 'our_menu',
    USER: 'user',
    REDIRECTION: 'redirection',
  },
  secondary: {
    LOCATOR: 'locator',
    ITEM_LIST: 'item_list',
    ITEM_DETAIL: 'item_detail',
    CART: 'cart',
    PAYMENT: 'payment',
    CONFIRMATION: 'confirmation',
    ORDER: 'order',
    ORDERS: 'orders',
    PROFILE: 'profile',
    PHONE: 'phone',
    DEVICE: 'device',
    EMAIL: 'email',
    ADD_DISCOUNT: 'add_discount',
    NAME: 'name',
    BIRTHDATE: 'birthdate',
    IDENTIFICATION: 'identification',
    TOO_FAR: 'too_far',
    ENROLLMENT: 'enrollment',
    SECURITY_AND_LOGIN: 'security_and_login',
  },
  tertiary: {
    RESTAURANT: 'restaurant',
    RESTAURANT_MAP: 'restaurant/map',
    RESTAURANT_LIST: 'restaurant/list',
    RESTAURANT_DETAIL: (code: string) => `restaurant/detail_${code}`,
    ADDRESS: 'address',
    ADDRESS_ADD: 'address/add',
    ADDRESS_MAP: 'address/map',
    EMPTY_CART: 'empty',
    DETAIL: 'detail',
    FINISHED: 'finished',
    METHODS: 'methods',
    METHOD_ADD: 'method/add',
    PICKUP_METHODS: 'delivery/mop',
    PICKUP_METHOD_AREA: (area: string) => {
      const prefix = 'delivery/mop'
      switch (area) {
        case 'AUT':
          return `${prefix}/drive-through`
        case 'EALM':
          return `${prefix}/table`
        case 'CURB':
          return `${prefix}/parking`
        case 'MOP':
          return `${prefix}/counter`
      }
    },
    VERIFY: 'verify',
    RATING: 'rating',
    BLOCKED: 'blocked',
    BILL: 'bill',
    NAME: 'name',
    BIRTHDATE: 'birthdate',
    PHONE: 'phone',
    IDENTIFICATION: 'identification',
    PRE_ORDER_CONFIRMATION: 'pre_order_confirmation',
    NO_RIDER: 'sin_rider',
    NO_INGREDIENT: 'no_ingredient',
    RESTAURANT_CLOSE: 'restaurant_close',
    ERROR: 'error',
    DEVICES: 'devices',
  },
} as const

export const GTM_ERRORS: { url: string; type: string }[] = [
  {
    url: '/profile/close-device-sessions',
    type: GtmEventName.LOGOUT,
  },
]

export type GTMEventType = (typeof GtmEventName)[keyof typeof GtmEventName]

/**
 * Types
 *  */

/* PageView */

export type GTMViewContentGroup = {
  cg?: string
  cg2?: string
  cg3?: string
  pageTitle?: string
  linkUrl?: string
}

export class GTMEcommerceItem {
  index?: number = undefined
  currency?: string
  item_name?: string
  item_id?: string
  price?: number
  quantity?: number
  coupon?: string = undefined
  discount?: number = 0
  loyalty?: boolean = undefined
  points?: number = 0
  item_list_name?: string = undefined
  item_category?: string = undefined
  pre_order?: boolean = undefined
  /* Customization */
  // Choice option inside CHOICE group
  component_name?: string = undefined
  component_id?: string = undefined
  // string of ingredients edited
  ingredients_edited?: string = undefined
}
export class GtmEcommerce {
  transaction_id?: string = undefined
  value?: number = undefined
  payment_type?: string = undefined
  payment_type2?: string = undefined
  tax?: number = undefined
  shipping?: number = undefined
  discount?: number = undefined
  tip?: number = undefined
  bill?: boolean = undefined
  currency?: string = undefined
  coupon?: string = undefined
  coupon2?: string = undefined
  coupon3?: string = undefined
  delivery_type?: string = undefined
  points_spent?: number = undefined
  restaurant_code?: string = undefined
  promotion_id?: string = undefined
  promotion_name?: string = undefined
  loyalty?: boolean = undefined
  item_name?: string = undefined
  items?: GTMEcommerceItem[] = undefined
  pickup_type?: string = undefined
  item_list_name?: string = undefined
  cancelation_type?: string = undefined
  cancelation_warning?: string = undefined
  restaurant_close?: boolean = undefined
  pre_order?: boolean = undefined
}
export class GTMEvent {
  event: GTMEventType
  country?: string
  event_origin?: string = EventOrigin.DATALAYER
  // page view
  content_group?: string = undefined
  content_group2?: string = undefined
  content_group3?: string = undefined
  page_title?: string = undefined
  // user
  is_loyalty?: boolean = undefined
  is_club_automac?: boolean = undefined
  user_id?: string = undefined
  // other properties
  content_type?: string = undefined
  method?: string = undefined
  error_code?: string = undefined
  error_type?: string = undefined
  form_name?: string = undefined
  search_term?: string = undefined
  outbound?: boolean = undefined
  link_url?: string = undefined
  warning_type?: string = undefined
  offer_name?: string = undefined
  comment?: string = undefined
  wt_step?: string = undefined
  wt_type?: string = undefined
  category_name?: string = undefined
  category_index?: number = undefined
  // ecommerce
  ecommerce: GtmEcommerce = new GtmEcommerce()
  constructor(ev: GTMEventType) {
    this.event = ev
  }
}

export const GTMFormNameType = {
  PROFILE: 'profile',
  CONTACT: 'contact',
  COUPON: 'coupon',
  ONBOARDING: 'payment_mandatory',
  PHONE_VALIDATION: 'telefono',
  PHONE_CONFIRM: 'verificar_telefono',
  DEVICE: 'verificar_dispositivo',
  EMAIL: 'verificar_email',
  BILL: 'bill',
  RATE_ORDER: 'rate_order',
  VERIFY_DEVICE: 'verify_device',
  VERIFY_PHONE: 'verify_phone',
  PHONE_CHANGE: 'verify_phone_change',
  EMAIL_CHANGE: 'verify_email_change',
  ENROLL_PHONE: 'verify_enroll_phone',
} as const

export type GTMFormNameTypes = (typeof GTMFormNameType)[keyof typeof GTMFormNameType]

export const GTMProductTypes = {
  CROSS_SELLING: 'cross_selling',
  CROSS_SELLING_NBO: 'cross_selling_nbo',
  CROSS_SELLING_CMS: 'cross_selling_cms',
  CROSS_SELLING_MIX: 'cross_selling_mix',
} as const

export type GtmProductType = (typeof GTMProductTypes)[keyof typeof GTMProductTypes]

export const GTMMethod = {
  SMS: 'sms',
  FORM: 'form',
  NONE: 'Ninguno',
  DATABASE: 'database',
  EMAIL: 'email',
  GMAIL: 'gmail',
  FACEBOOK: 'facebook',
  APPLE: 'apple',
} as const

/* Promo reduced type */
export interface GTMReducedPromo {
  code?: string
  name: string
  promotionId: string
  type: string
}
export const GTMLocationType = {
  CART: 'cart',
  PROFILE_PHONE: 'profile_phone',
  PROFILE_EMAIL: 'profile_email',
  ENROLL_PHONE: 'enroll_phone',
} as const

export type GTMLocationTypes = (typeof GTMLocationType)[keyof typeof GTMLocationType]

export const GTMValidationType = {
  DEVICE: 'device',
  PHONE: 'phone',
  EMAIL: 'email',
} as const

export type GTMLValidationTypes = (typeof GTMValidationType)[keyof typeof GTMValidationType]
