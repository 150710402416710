import termsES from './markdown/vouchers/termsES.md?raw'

export default {
  routes: {
    restaurants: 'restaurantes',
    ecommerceHome: 'pedidos',
    cart: 'carrito',
    staticMenu: {
      linkTitle: 'Productos',
      slug: 'menu',
    },
    checkout: 'checkout',
    profile: 'mi-cuenta',
    personalData: 'datos-personales',
    orders: 'mis-pedidos',
    signInSecurity: 'inicio-sesion-y-seguridad',
    myDevices: 'mis-dispositivos',
    downloadApp: 'descarga_app',
  },
  auth: {
    login: 'Iniciar sesión',
    register: 'Registrarme',
    logOnTo: 'Entrar',
  },
  common: {
    seeAll: 'Ver todo',
    priceFrom: 'Desde ',
    seeMore: 'Ver más',
    and: 'y',
    continue: 'Continuar',
    return: 'Volver',
    accept: 'Aceptar',
    MOP: 'PickUp',
    DLV: 'McDelivery',
    seeDetail: 'Ver detalles',
    startOrder: 'Comenzar pedido',
    start: 'Seleccionar',
    mon: 'Lunes',
    tue: 'Martes',
    wed: 'Miércoles',
    thu: 'Jueves',
    fri: 'Viernes',
    sat: 'Sábado',
    sun: 'Domingo',
    areas: {
      curb: 'En estacionamiento',
      aut: 'AutoMac',
      ealm: 'En la mesa',
      mop: 'En mostrador',
      dlv: 'McDelivery',
    },
    time: {
      hour: 'hora',
      hours: 'horas',
      minute: 'minuto',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
    },
    toast: {
      clipboard: 'Copiado al portapapeles',
    },
    o: 'o',
    freeShippingLabel: 'Envío gratis',
    free: 'Gratis',
    offer: 'Oferta',
  },
  home: {
    title: 'Inicio',
    products: 'Productos',
    categories: 'Categorías',
    search: 'Busca una dirección',
    searchButton: 'Comenzar',
    searchTitle: 'Cómo quieres hoy tu pedido',
    searchSubtitle:
      'Selecciona {pickup} si vas a pasar a recoger tu pedido o McDelivery si prefieres que te lo enviemos a una dirección',
    searchLocateMe: 'Usar mi ubicación actual',
    smartcards: {
      multiple: {
        title: 'Tienes varios pedidos en curso',
        secondary: 'Ver pedidos',
      },
      pending: {
        title: 'Pedido pendiente de pago',
        secondary: 'Ver detalle',
        primary: 'Pagar',
      },
      pendingPaymentCounter: {
        title: 'Pagar en mostrador',
        secondary: 'Ver detalle',
        primary: 'Llegué',
      },
      afterPayment: {
        pretitle: "Ir a McDonald's",
        title: 'Pedido en curso',
        secondary: 'Ver detalle',
        primary: 'Llegué',
      },
      advanceSale3days: {
        title: 'Pronto llegará el día de tu entrega',
        secondary: 'Saber más',
      },
      advanceSaleInDate: {
        title: 'Pedido listo para ser entregado',
        secondary: 'Saber más',
        primary: 'Recibir mi pedido',
      },
      accepted: {
        title: 'Tu pedido fue aceptado',
        secondary: 'Ver detalle',
      },
      acceptedPaymentCounter: {
        title: 'Pagar en mostrador',
        secondary: 'Ver detalle',
      },
      preparing: {
        title: 'Pedido en preparación',
        secondary: 'Ver detalle',
      },
      delivering: {
        title: 'Tu pedido está en camino',
        secondary: 'Ver detalle',
        primary: 'Seguir mi pedido',
      },
      ready: {
        title: 'Pedido listo para entregar',
        secondary: 'Ver detalle',
      },
      delivered: {
        title: 'Ya entregamos tu pedido',
        secondary: 'Ver detalle',
        primary: 'Repetir pedido',
      },
    },
  },
  sidebar: {
    title: {
      normal: 'Disfruta de la',
      bold: 'mejor experiencia',
    },
  },
  onboarding: {
    next: 'Continuar',
    stepRestaurant: {
      title: '¡Elige cómo lo prefieres!',
      description:
        '<ul><li><b>McDelivery</b> pide y recibe en cualquier lugar</li><li><b>{pickup}</b> busca un restaurante para retirar tu pedido</li></ul>',
    },
    stepRestaurantNoDlv: {
      title: '¡Elige cómo lo prefieres!',
      description: '<ul><li><b>{pickup}</b> busca un restaurante para retirar tu pedido</li></ul>',
    },
    stepMOP: {
      title: '{pickup}',
      description: 'Selecciona {pickup} si vas a pasar a recoger tu pedido',
    },
    stepDLV: {
      title: 'McDelivery',
      description: 'Selecciona McDelivery si prefieres que te lo enviemos a una dirección',
    },
    stepCart: {
      title: 'Mira tu pedido desde aquí',
      description: 'Encuentra todos los productos que elegiste y finaliza tu compra',
    },
    stepAccount: {
      title: 'Descubre “Mi cuenta”',
      description:
        'Completa tu perfil. Revisa tus preferencias ¡Y asegúrate de tener una experiencia única!',
    },
    stepQR: {
      title: 'Súmate a McID',
      description: "Escanea el código QR para identificarte rápidamente y unirte a McDonald's",
    },
  },
  dataOnboarding: {
    emailValidated: 'Correo validado con éxito',
    succeeded: 'Registro exitoso',
  },
  profile: {
    title: 'Mi Cuenta',
    account: 'Cuenta',
    downloadApp: 'Descargar App',
    help: 'Ayuda',
    sections: {
      personalData: 'Datos personales',
      fav: 'Favoritos',
      addressSaved: 'Direcciones guardadas',
      favRestaurants: 'Restaurantes favoritos',
      favProducts: 'Productos favoritos',
      historyOrders: 'Historial de pedidos',
      orderDetail: 'Detalle de pedido',
      paymentMethods: 'Métodos de pago',
      signInSecurity: 'Inicio de sesión y seguridad',
      manageDevices: 'Administrar dispositivos',
      billData: 'Datos de facturación',
      notification: 'Notificaciones',
      config: 'Configuración',
      logout: 'Cerrar sesión',
    },
    notLogged: {
      title: 'Te damos la bienvenida a <b>McDonald’s</b>',
      subtitle: 'Ingresa para disfrutar de nuestros productos, beneficios y más.',
    },
    subSections: {
      personalData: {
        fullName: 'Nombre y Apellido',
        email: 'Correo electrónico',
        phone: 'Teléfono',
        password: 'Contraseña',
        birthday: 'Fecha de nacimiento',
        identityCard: 'Documento de identidad',
        dataNotProvide: 'Datos no proporcionados',
        formHeaders: {
          fullName: {
            title: 'Nombre y Apellido',
            subtitle: '¿Cómo quisieras que te llamemos?',
            button: 'Guardar',
          },
          phone: {
            title: 'Número de telefono',
            subtitle:
              'Con el fin de asegurar que solo tú tengas acceso a tu cuenta, por favor ingresa tu numero de teléfono',
            button: 'Guardar',
            buttonAlt: 'Validar y guardar',
            verified: 'Teléfono validado',
            notVerified: 'Teléfono no validado',
          },
          birthday: {
            title: 'Fecha de nacimiento',
            subtitle:
              'Introduce tu fecha de nacimiento. De esta manera, podremos garantizar la seguridad de tu información.',
            button: 'Continuar',
          },
          identityCard: {
            title: 'Documento de identidad',
            subtitle:
              'Introduce tu número de identidad. De esta manera, podremos garantizar la seguridad de tu información.',
            button: 'Continuar',
          },
        },
      },
      manageDevices: {
        text: 'Accediste a tu cuenta desde estos dispositivos. Si no reconoces alguno, puedes cerrar la sesión o cambiar los datos de acceso',
        thisDevice: 'En este dispositivo',
        logout: 'Cerrar sesión',
        logoutAll: 'Cerrar todas las sesiones',
        closeModals: {
          title: '¿Confirmas que quieres cerrar esta sesión?',
          body: '{name} - {appName}{location}, {timeAgo}',
          btnPrimary: 'Cerrar sesión',
          btnSecondary: 'Cancelar',
        },
        closeAllModals: {
          title: '¿Confirmas que quieres cerrar todas las sesiones abiertas?',
          body: 'Se cerrarán todas las sesiones menos la de este dispositivo',
          btnPrimary: 'Cerrar sesiones',
          btnSecondary: 'Cancelar',
        },
        toast: {
          success: 'Sesión cerrada con éxito',
          successAll: 'Sesiones cerradas con éxito',
          error: 'Ha habido un error. Por favor, inténtalo de nuevo',
        },
        errorModal: {
          title: 'Ocurrió un error al intentar eliminar este dispositivo',
          titleAll: 'Ocurrió un error al intentar eliminar todos los dispositivos',
          body: 'Verifica tu conexión e inténtalo de nuevo',
          bodyRetry: 'Puedes intentar de nuevo o cancelar',
          button: 'Intentar de nuevo',
        },
      },
    },
    VError: {
      required: 'Campo requerido',
      customPattern: 'El campo no tiene un formato correcto',
      strongPassword:
        'La contraseña debe contener un mínimo de 8 caracteres, 1 letra en mayúscula, 1 número y 1 carácter especial.',
      passwordMatch: 'La contraseñas deben coincidir.',
      email: 'Email no válido',
      nameValidation:
        'Nombre no válido. Los nombres válidos sólo contienen letras, guiones (-) y espacios.',
      ageValidation: 'Debes ser mayor de {minAge} años para poder usar la app',
      minLength: 'El campo no cumple con el mínimo de caracteres',
      maxLength: 'El campo no cumple con el máximo de caracteres',
      cpfValidation: 'El documento no tiene un formato correcto',
      cpfVerified: 'Número ya registrado',
      phoneValidation: 'El número no tiene un formato correcto',
      invalidAge: 'No cumples con la edad requerida',
      duplicatePhone: 'Teléfono ya registrado',
    },
    inputs: {
      dropdownPlaceholder: 'Elige uno',
      documentNumberPlaceholder: 'Nº de documento',
      phoneNumberPlaceholder: 'Nº de teléfono',
      labelName: 'Nombre',
      labelLastName: 'Apellido',
      labelPrefix: 'Prefijo',
      labelSufix: 'Número',
      labelCpfType: 'Tipo documento',
      labelCpf: 'Documento',
    },
    tooltipProfile: {
      greeting: 'Hola',
    },
  },
  productDetail: {
    customization: {
      title: 'Personaliza tu producto',
      customizingTitle: '¿Cómo quieres personalizar?',
      extra: 'Extra:',
      actions: {
        save: 'Guardar',
        customize: 'Personalizar',
        select: 'Seleccionar',
        change: 'Cambiar',
        add: 'Agregar',
      },
      chooseOne: 'Elige uno ',
      mandatory: '(Obligatorio)',
    },
    sizes: {
      title: 'Selecciona un tamaño',
      types: {
        SMALL: {
          letter: 'P',
          title: 'Pequeño',
        },
        MEDIUM: {
          letter: 'M',
          title: 'Mediano',
        },
        LARGE: {
          letter: 'G',
          title: 'Grande',
        },
      },
    },
    actions: {
      payNow: 'Pagar ahora',
      addToCart: 'Añadir al carrito',
      save: 'Guardar',
    },
    alerts: {
      outdaypart: 'Este producto no se encuentra disponible en este momento',
      mcDia: {
        expired: {
          title: 'Producto Gran Día fuera de fecha',
          content: 'Este producto ya no está disponible',
        },
      },
      notAvailableAreas: {
        title: 'Zona de entrega no disponible: {value}',
        titleMultiple: 'Zonas de entrega no disponibles: {value}',
      },
      mismatching: {
        NO_RESTAURANT_SELECTED: 'No tienes restaurante seleccionado',
        MISMATCHING_AREA: {
          MOP: 'Este producto sólo está disponible para recoger en local',
          DLV: 'Este producto sólo está disponible para envio a domicilio',
        },
        MISMATCHING_RESTAURANT: 'Este producto no está disponible en tu restaurante',
        MISMATCHING_AREA_RESTAURANT: 'Este producto no está disponible en tu restaurante',
      },
    },
    mismatching: {
      alerts: {
        NO_RESTAURANT_SELECTED: {
          title: 'No tienes restaurante seleccionado',
          body: 'Este producto está asociado al restaurante {restaurantName}',
          accept: 'Continuar',
          cancel: 'Seleccionar otro restaurante',
        },
        MISMATCHING_AREA: {
          titleMOP: 'Este producto sólo está disponible para recoger en local',
          titleDLV: 'Este producto sólo está disponible para envio a domicilio',
          body: '',
          accept: 'Seleccionar otro restaurante',
          cancel: '',
        },
        MISMATCHING_RESTAURANT: {
          title: 'Este producto no está disponible en tu restaurante',
          body: '',
          accept: 'Buscar en mi restaurante',
          cancel: 'Seleccionar otro restaurante',
        },
        MISMATCHING_AREA_RESTAURANT: {
          title: 'Este producto no está disponible en tu restaurante',
          body: '',
          accept: 'Continuar comprando',
          cancel: 'Seleccionar otro restaurante',
        },
        notFoundProductOnCatalog: {
          title: 'Este producto no se ha podido encontrar en tu restaurante',
          body: '',
          accept: 'Ver catálogo',
        },
      },
    },
  },
  productDetailStatic: {
    menu: "Menú Mcdonald's",
    moreInfo: 'Más información',
    nutritional: {
      title: 'Información nutricional',
      type: 'Información Nutricional',
      byProduct: 'Por Producto',
      idr: 'I.D.R',
      empty: 'Este producto no tiene información nutricional',
      items: {
        weight: 'Peso',
        calories: 'Calorías (Kcal)',
        sugarTotals: 'Azúcares totales',
        sugarAdded: 'Azúcares agregados',
        lipids: 'Grasas',
        saturatedFats: 'Grasas Saturadas',
        transFats: 'Grasas trans',
        fiber: 'Fibra',
        carbohydrates: 'Carbohidratos Totales',
        proteins: 'Proteínas',
        sodium: 'Sodio',
      },
    },
    allergens: {
      title: 'Información sobre alérgenos',
      tableTitle: 'Este producto contiene',
      empty: 'Este producto no contiene alérgenos',
      items: {
        twilight: 'Tinte amarillo crepúsculo',
        allura: 'Colorante rojo allura',
        fish: 'Pescado',
        milk: 'Leche',
        soy: 'Soja',
        almonds: 'Almendras',
        lactose: 'Lactosa',
        pepper: 'Pimienta',
        grain: 'Trigo',
        tartrazine: 'Colorante amarillo tartrazina',
        egg: 'Huevo',
        gluten: 'Gluten',
        walnuts: 'Nueces',
        sulphites: 'Sulfitos',
        cereals: 'Cereales',
        mustard: 'Mostaza',
        nuts: 'Frutos secos',
        sesame: 'Sésamo',
        crustaceans: 'Crustáceos',
        peanut: 'Maní',
        latex: 'Latex Natural',
        wheat: 'Trigo, avena, cebada y centeno',
        hazelnuts: 'Avellanas',
        cashewnuts: 'Anacardos',
        brazilnuts: 'Nueces de Brasil',
        macadamias: 'Nueces de macadamia',
        pecans: 'Nueces de pecan',
        pistachios: 'Pistachos',
        pinoli: 'Piñones',
        chestnuts: 'Castañas',
      },
    },
  },
  shoppingCart: {
    title: 'Mi pedido',
    products: 'Productos',
    total: 'Total',
    subtotal: 'Subtotal',
    shipping: 'Envío',
    serviceFee: {
      name: 'Tarifa de servicio',
      info: 'Tarifa que cubre costos de operación de la plataforma y el seguimiento de pedidos',
    },
    tip: 'Propina',
    tax: 'Impuestos',
    points: 'Puntos consumidos',
    extras: 'Extras',
    summary: 'Resumen de tu pedido',
    empty: {
      title: 'Aún no has añadido ningún producto',
      text: 'Elige lo que te apetezca de nuestro menú o de entre nuestras ofertas, y disfrútalo en casa o en el restaurante McDonald’s que prefieras',
    },
    suggestive: {
      title: '¿Quieres agregar algo más?',
    },
    actions: {
      back: 'Continuar comprando',
      next: 'Siguiente',
      clear: 'Limpiar',
      edit: 'Editar',
      seeCart: 'Ver carrito',
    },
    promotions: {
      addPromo: {
        title: 'Códigos de descuento y promociones',
        add: 'Agregar',
        prefix: {
          coupon: 'Código',
          employee: 'Descuento de empleado',
        },
        terms: {
          prefix: 'Para más información consulta los',
          link: 'términos y condiciones',
          sufix: 'del código de descuento.',
        },
      },
      modal: {
        title: 'Descuentos y promociones',
        disclaimer: 'Recuerda que los descuentos y promociones no son acumulables entre si.',
        fields: {
          coupon: {
            label: 'Código',
          },
          employee: 'Descuento de empleado',
        },
        codeError: 'Lo sentimos. El código de descuento no es válido',
      },
    },
    alerts: {
      delete: {
        title: '¿Quieres eliminar este producto?',
        body: 'Al eliminarlo se perderá también la personalización que hayas realizado.',
        cancel: 'Cancelar',
        accept: 'Eliminar',
      },
      login: {
        title: 'Es necesario estar registrado para poder continuar',
        body: 'Pulsa en ingresar para disfrutar de la experiencia completa y muchos otros beneficios',
        cancel: 'Ahora no',
        accept: 'Ingresar',
      },
      clear: {
        title: 'Vas a eliminar todos los productos del carrito',
        body: '¿Estás seguro?, se vaciarán todos los items que tienes actualmente en tu carrito',
        cancel: 'Cancelar',
        accept: 'Vaciar',
      },
      cancelOrderConfirmation: {
        title: '¿Estás seguro que deseas cancelar tu pedido?',
        accept: 'Cancelar Pedido',
        body: '¡No hay problema! Tu dinero sera reintegrado en 1 o 2 dias hábiles segun la entidad bancaria.',
        cancel: 'Volver',
      },
      deletePromotedConfirmation: {
        title: '¿Quieres eliminarlo?',
        body: 'Al hacerlo se eliminarán todos los productos que correspondan a la promoción.',
        accept: 'Eliminar',
        cancel: 'Cancelar',
      },
    },
    toast: {
      orderMcdia: 'Esta promoción no es compatible con los productos de tu pedido',
    },
  },
  checkout: {
    title: 'Detalle del pago',
    checkoutAlert: 'Mensaje',
    actions: {
      pay: 'Pagar',
    },
    sections: {
      address: {
        title: 'Dirección de entrega',
      },
      tip: {
        title: 'Propina para tu repartidor',
        description:
          'Esta propina es voluntaria y la recibe tu repartidor como reconocimiento por su trabajo',
        chips: {
          custom_amount: 'Otra',
          custom_percent: 'Otro',
        },
        toast: {
          max: 'El porcentaje máximo de la propina es {max} %',
        },
      },
      paymentMethods: {
        title: 'Método de pago',
        add: 'Agregar nueva tarjeta',
        modify: 'Modificar tarjetas',
        methodTypes: {
          credit: 'Crédito',
          debit: 'Débito',
          inPerson: 'En mostrador',
          MERCADO_PAGO_CHECKOUT_PRO: 'Dinero en cuenta',
        },
        extraMethods: {
          MERCADO_PAGO_CHECKOUT_PRO: 'Mercado Pago',
        },
        expired: 'Expirada',
        toast: {
          cardSave: {
            success: 'Tarjeta guardada correctamente',
            error: 'No fue posible guardar la tarjeta',
          },
          yappy:
            'Recuerda que al hacer tu pedido por Yappy, este no se podrá cancelar desde nuestra app',
        },
        modifyCards: {
          disclaimer: 'Recuerda que puedes guardar hasta un máximo de {value} tarjetas.',
          actions: {
            add: 'Añadir tarjeta',
          },
          expiration: 'Expiración',
          alerts: {
            deleteCard: {
              title: '¿Quieres eliminar esta tarjeta?',
              accept: 'Eliminar',
              cancel: 'Cancelar',
            },
          },
        },
        transition: {
          title: 'Recibimos tu pedido :)',
          subtitle: 'Aguardamos la confirmación de pago.',
        },
        exclusivePayment:
          'Ten en cuenta que sólo tienes disponible para esta promoción el método de pago seleccionado a continuación',
      },
      fiscal: {
        title: 'Datos de facturación',
        filled: {
          delete: 'No quiero factura',
          action: 'Cambiar',
        },
        unfilled: {
          subtitle: '¿Quieres solicitar factura?',
          subtitleMandatory:
            'Para compras igual o superiores a {price} debes introducir tus datos en la factura.',
          subtitleMandatoryAlways: 'La factura es obligatoria',
          action: 'Solicitar',
        },
        modal: {
          action: 'Confirmar',
          checkbox: 'Recordar para futuras compras',
        },
        alert: {
          mandatory: {
            title: 'Ingresa tus datos de facturación para completar la compra',
            bodyMandatoryAlways: 'Datos obligatorios',
            bodyMandatory: 'Datos obligatorios para compras mayores o iguales a {price}',
            accept: 'Aceptar',
            cancel: '',
          },
        },
      },
    },
    paymentResult: {
      yuno: {
        SUCCESS: {
          MOP: {
            title: 'Pago realizado con éxito',
            description:
              'Cuando te encuentres en el restaurante elige una opción para retirar tu pedido',
            actions: {
              accept: 'Opciones de retiro',
              acceptMcdia: 'Ver mis pedidos',
              alternative: 'Elegir después',
            },
          },
          DLV: {
            title: 'Pago realizado con éxito',
            description: 'El pedido está siendo preparado. En breve lo recibirás en tu dirección.',
            actions: {
              accept: 'Seguir mi pedido',
              acceptMcdia: 'Volver a la home',
              alternative: 'Ver detalles',
            },
          },
        },
        ERROR: {
          paymentDidNotHappen: {
            title: 'Pago no realizado',
            description:
              'Hubo un problema al procesar tu pago. Puedes intentar de nuevo o elegir otra tarjeta. Si el problema continúa contacta a tu banco.',
            actions: {
              accept: 'Intentar de nuevo',
              alternative: 'Cambiar tarjeta',
            },
          },
          genericError: {
            title: 'Algo salió mal 🙁',
            description:
              'Verifica si los datos fueron ingresados correctamente. Puedes intentar de nuevo o elegir otra tarjeta. Si el problema continúa contacta a tu banco.',
            actions: {
              accept: 'Cambiar tarjeta',
              alternative: 'Intentar de nuevo',
            },
          },
        },
      },
      legacy: {
        success: {
          title: 'Tu pago fue realizado con éxito',
          description:
            'Sigue las instrucciones en la próxima pantalla y podrás retirar tu pedido como quieras.',
          actions: {
            accept: 'Entendido',
            acceptMcdia: 'Ver mis pedidos',
          },
        },
        restaurantOrderError: {
          title: '¡Lo sentimos! Tu orden no pudo ser creada',
          description: 'Ha ocurrido un error creando la orden en el restaurante',
          actions: {
            accept: 'Ver pedido',
          },
        },
        paymentError: {
          title: '¡Lo sentimos! Tu pago no pudo ser realizado',
          description:
            'Hubo un problema al procesar la operación. Reintenta o elige otro medio de pago.',
          actions: {
            accept: 'Ver pedido',
            alternative: 'Elegir otro medio de pago',
          },
        },
      },
    },
  },
  pickupMethods: {
    farAway: {
      title: 'Estás lejos del restaurante',
      subtitle:
        'En algunos minutos tu pedido estará listo. ¿Está seguro de que desea elegir una opción de entrega y empezar la preparación?',
      actions: {
        continue: 'Opciones de entrega',
        imOnWay: 'Estoy en camino',
      },
    },
    areaSelection: {
      backTitle: 'Métodos de retiro',
      info: 'La preparación del pedido comienza aquí. En algunos minutos tu pedido quedará listo',
      areas: {
        title: '¿Cómo deseas retirar tu pedido?',
        MOP: {
          title: 'En mostrador',
          description: 'Dirígete a la zona de entrega',
          step: {
            title: '¿Cómo prefieres tu pedido?',
            subtitle: 'Elige la opción que desees para que podamos prepararlo',
            type: {
              EATIN: 'Comer en el restaurante',
              TAKEOUT: 'Envolver para llevar',
            },
          },
          area: {
            value: 'Número',
            title: 'Anúnciate en la zona de entrega con tú número',
            titleDualPoint: 'Retira tu pedido cuando veas tu número en pantalla',
            titleInPerson: 'Dirígete al mostrador para pagar tu pedido',
            subtitle: 'Estamos preparando tu pedido',
            subtitleInPerson:
              'La preparación del pedido comenzará una vez que se haya completado el pago.',
            alerts: {
              noOrderNumber: {
                title: 'No se ha podido obtener el número de retiro',
                accept: 'Ir al detalle del pedido',
                body: '',
                cancel: 'Cancelar',
              },
            },
          },
        },
        AUT: {
          title: 'Por AutoMac',
          description: 'Ingresa hasta el parlante y anúnciate',
          step: {
            title: 'Tu pedido será entregado por AutoMac',
            subtitle:
              'Se iniciará la preparación al confirmar el método de retiro y el pedido no podrá ser cancelado',
            action: 'Confirmar',
          },
          area: {
            value: 'Número',
            title: 'Ingresa y avanza hasta el parlante para anunciarte y retira tu pedido',
            subtitle: '',
          },
        },
        EALM: {
          title: 'En la mesa',
          description: 'Llevamos el pedido a tu mesa',
          step: {
            title: 'Escribe el número de tu mesa',
            subtitle:
              'Se iniciará la preparación al confirmar el método de retiro y el pedido no podrá ser cancelado',
            help: 'Lo verás pegado en uno de los extremos',
            action: 'Confirmar',
          },
          area: {
            value: 'Mesa',
            title: '¡Ahora sólo queda esperar!',
            subtitle: 'Pronto recibirás tu pedido listo para disfrutar',
          },
        },
        CURB: {
          title: 'En el estacionamiento',
          description: 'Llevamos el pedido a tu cochera',
          step: {
            title: 'Escribe el número de tu cochera',
            subtitle:
              'Se iniciará la preparación al confirmar el método de retiro y el pedido no podrá ser cancelado',
            help: 'Busca las cocheras señalizadas para que podamos llevar tu pedido',
            helpLink: '¿Las cocheras estan ocupadas?',
            action: 'Confirmar',
          },
          area: {
            value: 'Cochera',
            title: '¡Ahora sólo queda esperar!',
            subtitle: 'Pronto recibirás tu pedido listo para disfrutar',
          },
        },
        disabled: {
          title: 'El servicio {areaTranslated} no está disponible para este pedido.',
          subtitle: 'Elija otra opción de entrega',
        },
      },
      formErrors: {
        required: 'El valor es requerido',
        minValue: 'El valor mínimo es {minimum}',
        maxValue: 'El valor máximo es {maximum}',
      },
    },
  },
  errors: {
    page: {
      title: 'Ooops...',
      subtitle: 'Algo ha salido mal',
      description: 'Parece que la página que buscas no existe',
    },
    toast: {
      geo: {
        denied:
          'El permiso de geolocalización ha sido denegado. Por favor, habilítelo si desea utilizar el botón para localizarle.',
        unsupported: 'La geolocalización no es soportada por este navegador',
        geocoderError: 'Ha ocurrido un error al obtener la dirección',
        unexpectedError: 'Ha ocurrido un error inesperado. Inténtalo de nuevo en unos instantes',
      },
      generic: 'Lo sentimos, ha habido un error. Por favor, inténtalo de nuevo',
    },
    alerts: {
      generic: {
        title: 'Ha ocurrido un error inesperado',
        accept: 'Aceptar',
        body: 'Inténtalo de nuevo pasados unos minutos.',
        cancel: '',
      },
      tokenExpired: {
        title: 'Información',
        body: 'Tu tiempo para realizar el pedido ha finalizado, debes volver a identificarte para terminarlo.',
        accept: 'Aceptar',
        cancel: '',
      },
      noCoverage: {
        title: 'McDelivery no está disponible ahora',
        body: 'Mientras tanto, puedes hacer un pedido y retirarlo en el restaurante más cercano con Pide y Retira',
        accept: 'Pide y Retira',
        cancel: 'Intentar más tarde',
      },
      noRestaurantsOpened: {
        title: 'Lo sentimos, el restaurante se encuentra cerrado',
        body: 'Puedes elegir otra dirección o mira si hay un restaurante cerca para recoger tu pedido',
        accept: 'Buscar restaurantes cercanos',
        cancel: 'Cancelar',
      },
      noRiders: {
        title: 'Nuestros repartidores están ocupados',
        body: 'Puedes intentar más tarde o hacer un pedido y retirarlo con Pide y Retira',
        accept: 'Pide y Retira',
        cancel: 'Intentar más tarde',
      },
      productNotAvailable: {
        title: '¡La promoción ya finalizó!',
        body: 'Cambia los productos de tu pedido para continuar con la compra',
        accept: 'Volver a mi pedido',
        cancel: '',
      },
      orderErrorPrice: {
        title: 'No se ha podido tramitar su pedido',
        accept: 'Aceptar',
        body: 'Inténtalo de nuevo pasados unos minutos.',
        cancel: 'Cancelar',
      },
      platformNotValidForPromo: {
        title: 'Esta promoción no es compatible',
        body: 'Por favor, selecciona otro método de pago para completar la compra',
        accept: 'Elegir otro método de pago',
        cancel: '',
      },
      orderInProgressMultiple: {
        title: 'Has alcanzado el máximo de pedidos en curso',
        accept: 'Mis pedidos',
        body: 'Hasta que no retires al menos uno de tus pedidos en curso, no podrás iniciar otra compra.',
        cancel: 'Cancelar',
      },
      orderPickupNotAvailable: {
        title:
          'El restaurante seleccionado en un pedido anterior no se encuentra disponible en este momento',
        accept: 'Aceptar',
        body: 'Por favor, seleccione un nuevo restaurante.',
        cancel: 'Cancelar',
      },
      orderGeneric: {
        title: 'No se ha podido tramitar su pedido',
        accept: 'Aceptar',
        body: 'Inténtalo de nuevo pasados unos minutos.',
        cancel: 'Cancelar',
      },
      orderInProcess: {
        title: 'Error',
        body: 'El pedido ya está en camino',
        accept: 'Aceptar',
        cancel: 'Cancelar',
      },
      promotionNotFound: {
        title: '¡La promoción ya finalizó!',
        body: 'Cambia los productos de tu pedido para continuar con la compra',
        accept: 'Volver a mi pedido',
        cancel: '',
      },
      orderProductMismatch: {
        title: 'Error al crear su pedido',
        body: 'Ha habido un error con la creación de su pedido y no puede ser realizado.',
        accept: 'Aceptar',
        cancel: '',
      },
      countryNotValid: {
        title: 'Ha habido un error con el pago',
        body: 'El país seleccionado no corresponde con el país en el que se realizó el pedido. Cambia de país para proceder al pago.',
        accept: 'Aceptar',
        cancel: '',
      },
      paymentPendingApproval: {
        title: 'Ya ha intentado un pago para este pedido',
        body: 'El pago de su pedido está pendiente de aprobación. Consulte el estado de su pedido en unos minutos',
        accept: 'Aceptar',
        cancel: '',
      },
      orderAlreadyPaid: {
        title: 'El pedido ya ha sido pagado',
        accept: 'Aceptar',
        body: '',
        cancel: 'Cancelar',
      },
      promotionNotAvailable: {
        title: 'La promoción ya no está disponible y no podrá ser aplicada en este pedido.',
        body: '',
        accept: 'Aceptar',
        cancel: '',
      },
      orderMcdia: {
        title: '¡Tu compra no puede ser realizada!',
        body: 'Los productos McDia Feliz no se pueden comprar con otros productos. Para continuar con la compra debes eliminar los productos que no correspondan con la campaña.',
        accept: 'Eliminar productos',
        cancel: '',
      },
      fiscalFieldsRequired: {
        title: 'Ingresa tus datos de facturación para completar la compra',
        body: 'Datos obligatorios para compras mayores o iguales a {value}',
        accept: 'OK',
        cancel: '',
      },
      antifraudTemporalBlock: {
        title: 'Bloqueo temporal',
        body: 'Por la seguridad de tu cuenta, bloqueamos temporalmente la función de pago.',
        accept: 'Volver a la home',
        cancel: '',
      },
      antifraudLimitTransactionsReached: {
        title: 'Límite diario de transacciones alcanzado',
        body: 'Identificamos demasiadas operaciones seguidas de pago. Tu cuenta ha sido bloqueada temporalmente por razones de seguridad.',
        accept: 'Volver a la home',
        cancel: '',
      },
      phoneValidationRequired: {
        title: 'Valida tu teléfono',
        body: 'Necesitas validar tu teléfono para realizar un pedido',
        accept: 'Aceptar',
        cancel: '',
      },
      deviceValidationRequired: {
        title: 'Valida tu dispositivo',
        body: 'Necesitas validar tu dispositivo para realizar un pedido',
        accept: 'Aceptar',
        cancel: '',
      },
      outOfDayPart: {
        title: 'Producto fuera de horario. No olvides cancelar el pedido',
        body: 'Tu compra no puede ser entregada, porque uno de los productos no se encuentra dentro de su horario de entrega. Una vez cancelado, tu dinero será reintegrado en 1 o 2 días hábiles',
        accept: 'Cancelar pedido',
        cancel: '',
      },
      orderMcdiaOutOfDates: {
        title: "McDonald's",
        body: 'Plazo expirado, el pedido no puede ser retirado',
        accept: 'OK',
        cancel: '',
      },
      orderNotExist: {
        title: 'El pedido no existe',
        body: 'No se encuentra el pedido solicitado',
        accept: 'Ir a productos',
        cancel: 'Cancelar',
      },
      pickup_unhandled: {
        title: 'No se ha podido establecer el método de retiro de tu pedido',
        body: '',
        accept: 'Inténtalo de nuevo',
        cancel: 'Cancelar',
      },
      pickup_noRestaurantsOpened: {
        title: 'Restaurante cerrado',
        body: 'Lo sentimos, el restaurante está cerrado o próximo a cerrar y ya no admite pedidos. Podrás recoger tu pedido cuando esté abierto de nuevo',
        accept: 'Continuar',
        cancel: 'Cancelar',
      },
      pickup_serviceNumberEmpty: {
        title: '¡Ups! Sucedió algo inesperado',
        body: 'Tuvimos un problema al cargar el número que ingresaste. Por favor, inténtalo de nuevo',
        accept: 'Reintentar',
        cancel: '',
      },
      pickup_anyPickupAreaForThisOrderIsAvailable: {
        title: 'El restaurante ya no está disponible',
        body: 'Puedes cancelar el pedido para obtener un reembolso y elegir otra opción ¡Te pedimos disculpas!',
        accept: 'Ir a mi pedido',
        cancel: '',
      },
      pickup_orderPickupNotAvailable: {
        title: 'El restaurante ya no está disponible',
        body: 'Puedes cancelar el pedido para obtener un reembolso y elegir otra opción ¡Te pedimos disculpas!',
        accept: 'Ir a mi pedido',
        cancel: '',
      },
      pickup_areaNotAvailable: {
        title: 'El restaurante ya no está disponible',
        body: 'Puedes cancelar el pedido para obtener un reembolso y elegir otra opción ¡Te pedimos disculpas!',
        accept: 'Ir a mi pedido',
        cancel: '',
      },
      promoExclusivePaymentUsed: {
        title: '¡Ups! Ya has aprovechado esta promoción',
        body: 'Vuelve al carrito y cambia los productos para poder continuar con el pedido',
        accept: 'Volver a mi pedido',
        cancel: '',
      },
      limitProductExclusivePayment: {
        title: 'Solo permitimos un artículo promocional por compra',
        body: 'Puedes conservar otros productos del menú en esta compra, pero sólo puedes agregar la oferta promocional una vez',
        accept: 'Comprendí',
        cancel: '',
      },
      limitedPromoProduct: {
        title: 'Tu pedido ya incluye productos con descuento',
        body: '¿Quieres mantener los descuentos actuales o aplicar el nuevo código?',
        accept: 'Mantener descuentos',
        cancel: 'Aplicar nuevo código',
      },
      employeeDiscount: {
        title: 'Tu pedido ya tiene descuentos aplicados',
        body: '¿Quieres reemplazar la promoción o agregar el producto sin descuento?',
        accept: 'Agregar sin descuento',
        cancel: 'Reemplazar promoción',
      },
      areaNotFound: {
        title: 'El restaurante no está disponible para los productos seleccionados',
        body: 'Puedes elegir otro restaurante o volver a tu pedido y cambiar los productos',
        accept: 'Volver a mi pedido',
        cancel: 'Cambiar restaurante',
      },
      limitPriceOrder: {
        title: 'El valor total de tu compra es demasiado alto',
        body: 'Has superado el límite permitido de {value}. Por favor, revisa los productos del carrito',
        accept: 'Volver a mi pedido',
        cancel: '',
      },
      restaurantNotLoyalty: {
        title: 'Este restaurante no participa de {value}',
        body: 'Para continuar, cambia los productos de tu pedido o elige otro restaurante. Si lo haces, se vaciará tu carrito',
        accept: 'Volver a mi pedido',
        cancel: 'Cambiar restaurante',
      },
      cumulativePromotionsNotAllowed: {
        title: 'Tu pedido ya tiene descuentos aplicados',
        body: '¿Quieres reemplazar la promoción o agregar el producto sin descuento?',
        accept: 'Agregar sin descuento',
        cancel: 'Reemplazar promoción',
      },
      autoApplicablePromotionNotAllowed: {
        title: 'Tu pedido ya incluye productos con descuento',
        body: '¿Quieres mantener los descuentos actuales o aplicar el nuevo código?',
        accept: 'Mantener descuentos',
        cancel: 'Aplicar nuevo código',
      },
    },
  },

  selectRestaurant: {
    DLV: {
      noNumber: 'Sin número',
      addAddressError: 'Es necesario completar este campo',
      additionlInfoError: 'Es necesario completar este campo',
      completeDirection: 'Completa tu dirección',
      street: 'Calle',
      number: 'Número',
      additionalInfo: 'Indicaciones adicionales para el repartidor',
      additionalInfoPlaceholder: 'Casa, piso, dpto...',
      additionalInfoDeliveryPlaceholder: 'Ayúdanos a entregar tu pedido',
      willSendTo: 'Enviaremos tu pedido a',
      confirmLocation: 'Confirmar localización',
      favorite: {
        saveTitle: 'Guardar como dirección favorita',
        formTitle: '¿Quieres darle un nombre?',
        fields: {
          alias: {
            label: '*Alias',
            required: 'El alias para la dirección es obligatorio',
          },
        },
      },
    },
    MOP: {
      findYourMcDonalds: 'Encuentra tu McDonald’s',
      seeList: 'Ver listado',
      seeMap: 'Ver mapa',
      emptyList: 'No hemos encontrado restaurantes cercanos en esa ubicación.',
    },
    map: {
      adjustPin: 'Ajusta el pin en la posición adecuada',
    },
    lupap: {
      city: 'Ciudad',
      form: {
        address: 'Dirección',
        complement: 'Edificio, torre, apartamento ...',
      },
      continue: 'Continuar',
    },
  },
  alertDialog: {
    mcDeliveryError: {
      title: 'Lo sentimos, aun no tenemos servicio McDelivery en tu zona',
      body: 'Estamos trabajando para poder ofrecertelo, pero de momento elige otra dirección o mira si hay un restaurante cerca para recoger tu pedido',
      btnPrimary: 'Buscar restaurantes cercanos',
      btnSecondary: 'Buscar otra dirección',
    },
    ridersError: {
      title: 'Lo sentimos, en estos momentos no hay repartidores disponibles en tu zona',
      body: 'Puedes elegir otra dirección o mira si hay un restaurante cerca para recoger tu pedido',
      btnPrimary: 'Buscar restaurantes cercanos',
      btnSecondary: 'Buscar otra dirección',
    },
    restaurantClosedError: {
      title: 'Lo sentimos, el restaurante se encuentra cerrado',
      body: 'Puedes elegir otra dirección o mira si hay un restaurante cerca para recoger tu pedido',
      btnPrimary: 'Buscar restaurantes cercanos',
      btnSecondary: 'Buscar otra dirección',
    },
    genericError: {
      title: 'Lo sentimos, ha ocurrido un error',
      body: 'Error al obtener los restaurantes disponibles',
      btnPrimary: '',
      btnSecondary: '',
    },
    changeRestaurant: {
      titleMOP: '¿Estás seguro que deseas cambiar el restaurante de recogida?',
      titleDLV: '¿Estás seguro que deseas cambiar el restaurante de entrega?',
      body: 'Tendrás que comenzar tu pedido de nuevo y volver a seleccionar desde el menú.',
      btnPrimary: 'Cambiar restaurante',
      btnSecondary: 'Cancelar',
    },
  },
  restaurantTooltipAlert: {
    closed:
      'Recuerda que el restaurante seleccionado está cerrado y no acepta pedidos en este momento',
    riders: 'Parece que no hay riders en estos momentos',
    farFromRestaurant:
      'Parece que estas lejos del restaurante. Siempre puede cambiarlo pinchando sobre el.',
    farFromRestaurantOptions: {
      withoutGeo: {
        title: 'Verifica si esta dirección es correcta',
        subtitle:
          'Si la cambias, activa tu ubicación para que sea más precisa.<br/><b>¿Quieres elegir otra dirección?</b>',
      },
      withGeo: {
        title: '¡Notamos que estás lejos de este restaurante!',
        subtitle: '¿Quieres elegir otro?',
      },
    },
    actions: {
      dontChange: 'No, continuar',
      change: 'Sí, cambiar',
    },
    farFromRestaurantWithOrder: 'Notamos que estás lejos del restaurante donde hiciste el pedido',
    farFromRestaurantWithoutOrder:
      'Notamos que estás lejos del restaurante donde hiciste el pedido. Puedes cambiarlo presionando sobre la dirección',
  },
  restaurant: {
    status: {
      closed: 'Cerrado',
      nearToClose: 'Próximo a cerrar',
      onlyLocal: 'Sólo pedidos en local',
    },
    detail: {
      schedule: 'Horario',
      header: {
        MOP: 'Recoger en',
        DLV: 'Enviar a',
      },
      howToArrive: 'Cómo llegar',
      mop: 'Métodos de retiro',
      closed: 'Cerrado',
      services: {
        title: 'Servicios',
        breakfast: 'Desayuno',
        mcCafe: 'McCafé',
        driveThru: '',
        dcOut: '',
        dcIn: '',
        mcDelivery: '',
        timeExtended: '24 hs',
        mcParty: 'McParty',
        playPlace: 'PlaySpace',
        parking: 'Parking',
        wifi: 'WIFI',
        loyalty: '',
        wheelchairAccess: 'Acccesibilidad',
        dessertCenter: 'Dessert Center',
        shoppingCenter: 'Shopping Center',
      },
    },
  },
  help: {
    links: {
      faqs: {
        title: '¿Alguna duda?',
        linkText: 'Ayuda y FAQ’s',
      },
      whatsapp: {
        title: '¿Necesitas ayuda?',
        titleAlt: 'También estamos en',
        linkText: 'Whatsapp',
      },
      sac: {
        title: 'Atención al cliente',
        linkText: 'Teléfono',
      },
    },
  },
  orders: {
    orderNumber: 'Nº de pedido',
    delivery: {
      deliveryCode: 'Código de entrega',
      sentFrom: 'Enviado desde',
      description: 'Informa el código al repartidor únicamente al recibir tu pedido',
    },
    date: {
      today: 'Hoy',
      yesterday: 'Ayer',
    },
    historyOrders: {
      onGoing: 'No tienes ningún pedido en curso',
      finished: 'No tienes ningún pedido finalizado',
    },
    status: {
      pending: 'Pendiente de pago',
      pendingCounter: 'Pagar en mostrador',
      paymentDone: 'Pago Completado',
      paymentDoneMcdia: 'Preparándonos para el día',
      paymentDoneMcdia3days: 'Preparándonos para el día',
      paymentDoneMcdiaInDate: 'Listo para ser entregado',
      paymentDoneMcdiaExpired: 'Plazo expirado',
      goRestaurant: 'Ir al restaurante',
      received: 'Pedido Recibido',
      accepted: 'Aceptado',
      inProgress: 'En preparación',
      ready: 'Preparado',
      delivering: 'Entregando',
      cancelled: 'Cancelado',
      error: 'Error en el pedido',
      delivered: 'Entregado',
      finished: 'Finalizado',
      pickupExpired: 'Tiempo de retiro expirado',
    },
    areas: {
      aut: 'Entrega en AutoMac',
      ealm: 'Entrega en la mesa',
      mop: 'Entrega en mostrador',
      curb: 'Entrega en estacionamiento',
    },
    buttons: {
      onGoing: 'En curso',
      finished: 'Finalizado',
      cancelOrder: 'Cancelar pedido',
      pay: 'Pagar',
      reorder: 'Repetir pedido',
      rate: 'Calificar',
      arrival: 'Llegué',
      track: 'Seguir pedido',
      receive: 'Recibir mi pedido',
    },
    toast: {
      orderCanceled: 'Pedido cancelado',
      orderCanceledError: 'El pedido no pudo ser cancelado',
      orderCanceledMOP: 'El pedido está siendo preparado y no es posible cancelarlo',
      orderCanceledDLV: 'El repartidor ya esta en camino a tu domicilio y no es posible cancelarlo',
      orderUnappliedProducts:
        'La promoción fue añadida, pero no aplica a los productos de tu pedido',
    },
    messages: {
      yappy:
        'Si tienes dudas sobre tu pedido realizado con Yappy, contáctanos a través de nuestro chat',
      flexCancelledReason: {
        FLEX_CANCELLED_REFUND: {
          title: 'Pedido cancelado.',
          text: 'Ocurrió un problema pero, ¡no te preocupes! Recibirás el reembolso en 1 o 2 días hábiles',
          button: 'Saber más',
        },
        FLEX_CANCELLED_ERR_ADDRESS:
          'Pedido cancelado. <br/><br/>Hubo un problema con la dirección de envío. El dinero no podrá ser reembolsado. Disculpa las molestias.',
        FLEX_CANCELLED_ERR_INFO: 'Tu pedido ha sido cancelado, te pedimos disculpas',
      },
    },
    alert: {
      receiveOrder: {
        title: 'Estamos listos para preparar tu pedido',
        body: 'Recuerda que es importante estar en la dirección que elegiste para recibir tu pedido. ¿Ya estás en <b>{address}</b> para recibirlo?',
        accept: 'Confirmar',
        cancel: 'Todavía no',
      },
    },
    receiveOrder: {
      texts: {
        'no-riders': {
          title: 'Lo sentimos, no encontramos repartidores disponibles para tu pedido',
          body: 'Estamos trabajando para resolverlo a la brevedad. Intenta solicitar el envío de nuevo en unos minutos.',
        },
        'no-stock': {
          title: 'Lo sentimos, no pudimos empezar tu pedido por falta de ingredientes',
          body: 'Estamos trabajando para resolverlo cuanto antes. Intenta solicitar el envío de nuevo en unos minutos.',
        },
        'rest-closed': {
          title: 'En este momento el restaurante está cerrado',
          body: 'Para que puedas recibir tu pedido, asegurate de solicitar el envío en nuestros horarios de atención.',
        },
        'generic': {
          title: 'Lo sentimos, algo ha fallado y no hemos podido empezar tu pedido',
          body: 'Estamos trabajando para resolverlo a la brevedad. Intenta solicitar el envío de nuevo o espera unos minutos para volver a reintentarlo.',
        },
      },
      actions: {
        primary: 'Intentar más tarde',
        secondary: 'Ir a mi pedido',
      },
    },
  },

  SEO: {
    restaurants: {
      title: "Encuentra tu local McDonald's más cercano",
      description:
        "Encuentra el restaurante McDonald's más cercano, estés donde estés {'|'} Horarios, direcciones y menús para que disfrutes de tus favoritos: delivery o {pickup}.",
      city: {
        title: "Encuentra tu local McDonald's más cercano en {city}",
        description:
          "Descubre el restaurante McDonald's más cercano de {city}. Tu hamburguesa favorita cerca de ti {'|'} Horarios, direcciones y menús.",
        restaurant: {
          title: '{restaurant} {city}: Descubre nuestras ofertas',
          description: `Haz que te entreguen tus favoritos de McDonald's estés donde estés. Pide a domicilio tu comida favorita con {restaurant} {city} al mejor precio✓.`,
          orders: {
            title: `Mis pedidos en {restaurant} {'|'} McDonald's`,
            description: `Pide en {restaurant} en {city} {'|'} Explora nuestro menú y disfruta de la comodidad de la entrega a domicilio o la recogida en el restaurante.`,
            area: {
              title: `⇨{area} McDonald's {city}`,
              description: `Encuentra y personaliza el menú que más te gusta con guarnición a elegir, patatas y bebida. Elige el tamaño y pide en McDonald's al mejor precio✓.`,
              category: {
                title: `Pedir {category} {restaurant}`,
                description: `Ahora tu pedido McDonald's más cerca que nunca. Escoge tu {category} disponible para delivery o {pickup} en {restaurant}.`,
                product: {
                  title: `Pedir {product} {restaurant}`,
                  description: `Ahora tu pedido McDonald's más cerca que nunca. Disfruta de {product} disponible para delivery o {pickup} en {restaurant}.`,
                },
              },
            },
          },
        },
      },
    },
    menu: {
      title: `Menú McDonald's - Todos los productos`,
      description: `Encuentra y personaliza el menú que más te gusta con guarnición a elegir, patatas y bebida. Elige el tamaño y pide en McDonald's al mejor precio✓.`,
      category: {
        title: `{category} McDonald's {'|'} Completa tu menú`,
        description: `¿Tienes hambre? En McDonald's disponemos de diferentes ofertas de {category} en McDonald's para recoger o pedir a domicilio al mejor precio ✓. Escoge tu menú favorito.`,
        product: {
          title: `{product} - {category} {'|'} McDonald's`,
          description: `Disfruta de tu {product}, delivery o {pickup}. 24h en tu McDonald's más cercano ✓ Descubre nuestras novedades y ofertas en la web oficial.`,
        },
      },
    },

    orders: {
      cart: {
        title: `Revisa y Completa tus Pedidos {'|'} McDonald's`,
        description: `Completa tu pedido en McDonald's {'|'} Encuentra los productos que deseas y procede al pago online de forma fácil y segura{'|'} ¡A disfrutar!`,
      },
      cartProductIndex: {
        title: `Revisa y Completa tus Pedidos {'|'} McDonald's`,
        description: `Revisa tu pedido, puedes completarlo o editarlo de forma fácil | Encuentra los productos que deseas y procede al pago online de forma fácil y segura {'|'} ¡A disfrutar!`,
      },
      checkout: {
        title: `Checkout {'|'} McDonald's`,
        description: `Completa tu pedido. {'|'} Revisa tu selección e ingresa tus datos ¡listo para disfrutar de una comida deliciosa! Pago seguro✓`,
      },
    },

    my: {
      account: {
        title: `Mis datos personales {'|'} McDonald's`,
        description: `Gestiona tus datos personales de forma fácil y segura en McDonald's {'|'} Actualiza información de perfil, dirección y favoritos {'|'} Mantén tu cuenta al día.`,
        section: {
          title: `{section} {'|'} McDonald's`,
          description: `Gestiona tus datos personales de forma fácil y segura en McDonald's {'|'} Actualiza información de perfil, dirección y favoritos {'|'} Mantén tu cuenta al día.`,
        },
        my: {
          orders: {
            title: `Mis pedidos {'|'} McDonald's`,
            description: `Consulta tus pedidos, historial de compras y detalles de tus órdenes anteriores {'|'} Realiza un seguimiento de tus entregas y realiza un nuevo pedido si lo deseas.`,
            orderId: {
              title: `{orderId} {'|'} McDonald's`,
              description: `Consulta los detalles de tu pedido {orderId} {'|'} Información detallada de tu compra, estado de entrega y productos adquiridos en McDonald's.`,
            },
          },
        },
      },
    },
  },
  phone: {
    phoneValidation: {
      title: 'Número de teléfono',
      subtitle:
        'Con el fin de asegurar que solo tú tengas acceso a tu cuenta, por favor ingresa tu número de teléfono.',
      continue: 'Validar y guardar',
      duplicatePhone: 'El número de teléfono ya está registrado, por favor ingresa uno diferente',
    },
    phoneVerification: {
      title: 'Validar tu teléfono',
      subtitle: 'Acabamos de enviarte el código de validación de seis dígitos a tu teléfono',
      notSended: '¿No te llega el código?',
      notSendedDisclaimerCountdown: 'Puedes solicitarlo nuevamente en {time}.',
      notSendedDisclaimer: 'Puedes solicitarlo nuevamente ahora.',
      sendAgain: 'Enviar código de nuevo',
      errorCode: {
        CODE_FIELD_ERROR: 'Código erróneo',
        CODE_FIELD_ERROR_EXPIRED:
          'El código ha expirado.<br/>Para validar tu teléfono pulsa en “enviar código de nuevo”',
      },
    },
    alert: {
      phoneAlreadyValidated: {
        title: 'Lo sentimos, ha habido un error en el proceso',
        body: 'Por favor, inténtalo de nuevo',
        accept: 'Continuar',
      },
    },
    toast: {
      generic: 'Lo sentimos, ha habido un error. Por favor, inténtalo de nuevo',
      warning: 'Por favor, espera {time} antes de volver a solicitar un código nuevo',
      success: 'Teléfono verificado',
    },
    block: {
      title: 'Límite de envíos superados',
      subtitle: 'No pudimos validar tu teléfono debido a un exceso de envíos.',
      tryAgainCountdown: 'Por favor, inténtalo nuevamente en {time}.',
      tryAgainNow: 'Por favor, inténtalo nuevamente ahora.',
      action: 'Volver a la home',
    },
  },
  device: {
    deviceVerification: {
      title: 'Valida tu dispositivo',
      subtitle:
        'Acabamos de enviarte el <b>código de validación de cuatro dígitos</b> a tu correo {email}, ingrésalo para continuar tu pedido.',
      notSended: '¿No te llega el código?',
      notSendedDisclaimerCountdown:
        'Recuerda revisar tu carpeta de spam. Puedes solicitarlo nuevamente en {time}.',
      notSendedDisclaimer:
        'Recuerda revisar tu carpeta de spam. Puedes solicitarlo nuevamente ahora.',
      sendAgain: 'Enviar código de nuevo',
      errorCode: {
        CODE_FIELD_ERROR: 'Código erróneo',
        CODE_FIELD_ERROR_EXPIRED:
          'El código ha expirado.<br/>Para validar tu dispositivo pulsa en “enviar código de nuevo”',
      },
    },
    alert: {
      deviceAlreadyValidated: {
        title: 'Lo sentimos, ha habido un error en el proceso',
        body: 'Por favor, inténtalo de nuevo',
        accept: 'Continuar',
      },
    },
    toast: {
      generic: 'Lo sentimos, ha habido un error. Por favor, inténtalo de nuevo',
      success: 'Dispositivo validado con éxito ',
    },
    block: {
      title: 'Límite de envíos superados',
      subtitle: 'No pudimos validar tu dispositivo debido a un exceso de envíos.',
      tryAgainCountdown: 'Por favor, inténtalo nuevamente en {time}.',
      tryAgainNow: 'Por favor, inténtalo nuevamente ahora.',
      action: 'Volver a la home',
    },
  },
  email: {
    emailVerification: {
      title: 'Revisa tu correo electrónico',
      subtitle:
        'Acabamos de enviarte el <b>código de validación de cuatro dígitos</b> a tu correo {email}, ingrésalo para continuar tu pedido.',
      notSended: '¿No te llega el código?',
      notSendedDisclaimerCountdown:
        'Recuerda revisar tu carpeta de spam. Puedes solicitarlo nuevamente en {time}.',
      notSendedDisclaimer:
        'Recuerda revisar tu carpeta de spam. Puedes solicitarlo nuevamente ahora.',
      sendAgain: 'Enviar código de nuevo',
      errorCode: {
        CODE_FIELD_ERROR: 'Código erróneo',
        CODE_FIELD_ERROR_EXPIRED:
          'El código ha expirado.<br/>Para validar tu email pulsa en “enviar código de nuevo”',
      },
    },
    alert: {
      emailAlreadyValidated: {
        title: 'Lo sentimos, ha habido un error en el proceso',
        body: 'Por favor, inténtalo de nuevo',
        accept: 'Continuar',
      },
      validationInProgress: {
        title: '¿Estás seguro de que quieres volver?',
        body: 'Recuerda que al hacerlo, podrías perder los datos que has ingresado hasta ahora.',
        accept: 'Volver atrás',
        cancel: 'Cancelar',
      },
    },
    toast: {
      generic: 'Lo sentimos, ha habido un error. Por favor, inténtalo de nuevo',
      success: 'Datos guardados con éxito',
      successAlt: 'Correo confirmado con éxito',
    },
    block: {
      title: 'Límite de envíos superados',
      subtitle: 'No pudimos validar tu email debido a un exceso de envíos.',
      tryAgainCountdown: 'Por favor, inténtalo nuevamente en {time}.',
      tryAgainNow: 'Por favor, inténtalo nuevamente ahora.',
      action: 'Volver a la home',
    },
  },
  requiredFields: {
    title: 'Completa tus datos',
    subtitle:
      'Ingresa los datos que faltan para poder continuar con tu compra. Estos datos serán guardados de manera segura y no te los volveremos a solicitar.',
    actions: {
      continue: 'Continuar',
    },
    alerts: {
      genericError: {
        title: 'Lo sentimos, ha habido un error en el proceso',
        body: 'Por favor, inténtalo de nuevo',
        accept: 'OK',
      },
      duplicatePhone: {
        title: 'Teléfono ya registrado',
        body: 'El número de teléfono ya está registrado, por favor ingresa uno diferente',
        accept: 'OK',
      },
    },
  },
  repeatOrder: {
    success: {
      title: '¿Estás seguro de que quieres repetir este pedido?',
      body: 'La orden se creará en el mismo restaurante donde realizaste el pedido. Si tienes productos en tu carrito serán sustituidos por los productos de esta compra. Los precios de los productos pueden haber variado.',
      accept: 'Continuar',
      cancel: 'Cancelar',
    },
    genericError: {
      title: '¡Ups! En este momento no será posible repetir el pedido',
      body: 'Mientras tanto, echa un vistazo a nuestro catálogo o inténtalo de nuevo',
      accept: 'Intentar de nuevo',
      cancel: 'Ver Catálogo',
    },
    noRiders: {
      title: 'Lo sentimos, en estos momentos no hay repartidores disponibles en tu zona',
      body: 'Estamos con alta demanda de pedidos. Por favor, vuelve a intentarlo en unos minutos.',
      accept: 'Aceptar',
      cancel: '',
    },
    mopNotAvailable: {
      title: 'Ups! No será posible repetir el pedido',
      body: 'En este momento este restaurante no tiene disponible {pickup}. Vuelve a intentarlo en unos minutos.',
      accept: 'Aceptar',
      cancel: '',
    },
    dlvNotAvailable: {
      title: 'Ups! No será posible repetir el pedido',
      body: 'En este momento este restaurante no tiene disponible McDelivery. Vuelve a intentarlo en unos minutos.',
      accept: 'Aceptar',
      cancel: '',
    },
    restaurantNotFound: {
      title: 'Oh no, no hemos podido encontrar tu restaurante.',
      body: 'Te recomendamos que intentes hacer un nuevo pedido en otro restaurante',
      accept: 'Entendido',
      cancel: '',
    },
    closedRestaurant: {
      title: 'Ahora mismo nuestro local se encuentra cerrado',
      body: 'No será posible repetir este pedido. Te sugerimos que elijas otro restaurante para hacer tu compra.',
      accept: 'Entendido',
      cancel: '',
    },
    permittedAreasProducts: {
      title: 'Oh no, este pedido ya no se encuentra disponible',
      body: 'Algunos de los productos de este pedido no se pueden retirar por ninguno de los métodos de retiro disponibles',
      accept: 'Entendido',
      cancel: '',
    },
    outOfDaypartProducts: {
      title: '¡Ups! En este momento no será posible repetir el pedido',
      body: 'Algunos productos no están dentro de su horario de preparación. Por favor, intente repetir otro pedido, o inicie una nueva compra.',
      accept: 'Entendido',
      cancel: '',
    },
    notExistingProducts: {
      title: '¡Ups! En este momento no será posible repetir el pedido',
      body: 'Algunos productos de esta compra ya no son parte del catálogo. Por favor, intente repetir otro pedido, o inicie una nueva compra.',
      accept: 'Entendido',
      cancel: '',
    },
  },
  contact: {
    title: 'Completa el formulario con tus datos',
    inputs: {
      firstname: 'Nombre',
      lastname: 'Apellidos ',
      email: 'Email',
      phone: 'Teléfono',
      city: 'Ciudad',
      local: 'Restaurante',
      date: 'Día',
      time: 'Hora',
      message: 'Mensaje',
      newsletterAccepted:
        'Acepto recibir información sobre las novedades y promociones de McDonald’s',
      subject: {
        label: 'Motivo',
        placeholder: 'Elige uno',
        option: {
          suggestion: 'Sugerencia',
          inquire: 'Consulta',
          openDoors: 'Puertas abiertas',
          criticism: 'Crítica',
          claim: 'Reclamo',
          praise: 'Elogio',
          personalData: 'Datos personales',
          other: 'Otros',
        },
      },
    },
    toast: {
      error: 'Lo sentimos, ha ocurrido un error inesperado. Vuelve a intentarlo más tarde',
      success: 'El formulario de contacto se ha enviado con éxito',
    },
    action: {
      save: 'Guardar',
    },
  },
  rating: {
    stars: {
      help: 'Elija de 1 a 5 estrellas para evaluar su pedido',
      new: {
        title: '¿Cómo fue tu experiencia con este pedido?',
      },
      edit: {
        title: '¿Continuar evaluando el último pedido?',
        actions: {
          notNow: 'Ahora no',
          continue: 'Continuar',
        },
      },
    },
    form: {
      rate: 'Valora tu satisfacción con:',
      anyProblem: '¿Tuvo algún problema con el pedido?',
      send: 'Enviar',
    },
    finalStep: {
      title: "¡Gracias por elegir McDonald's!",
      ok: {
        subtitle:
          "Valoramos tu opinión y queremos asegurarnos de que tu experiencia en McDonald's sea la mejor posible.",
      },
      ko: {
        subtitle: "Queremos asegurarnos de que tu experiencia en McDonald's sea la mejor posible.",
        subtitle2:
          'Si tienes alguna duda o necesitas asistencia, ponte en contacto con nuestro centro de atención al cliente. ¡Estamos aquí para ayudarte!',
      },
    },
    toasts: {
      NOT_FOUND: 'Lo sentimos, no hemos encontrado el pedido',
      ALREADY_RATED: 'Este pedido ya ha sido valorado',
      RATING_EXPIRED: 'Lo sentimos, el tiempo para valorar este pedido ha finalizado',
      UNKNOWN: 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtelo de nuevo mas tarde',
    },
  },
  overrideRestaurant: {
    autoselectRestaurantAndArea: {
      title: '¿Está seguro que desea cambiar el restaurante?',
      body: 'Si desea continuar, se procederá a eliminar los productos del carrito actual',
      accept: 'Continuar',
      cancel: 'Cancelar',
    },
    autoselectRestaurantAndAreaError: {
      title: 'Ha ocurrido un error al seleccionar el restaurante',
      body: 'El restaurante seleccionado no está disponible. Lo sentimos, inténtalo de nuevo más tarde',
      accept: 'Continuar',
      cancel: 'Cancelar',
    },
  },
  redirection: {
    error: {
      title: 'Algo ha fallado',
      fallbackLink: 'Haga click aquí para continuar',
    },
  },
  enrollment: {
    BirthDate: {
      title: 'Fecha de nacimiento',
      body: 'Es necesario completar tu fecha de nacimiento para poder comprobar que nuestros usuarios tienen la edad mínima requerida para utilizar la aplicación',
      dateLabel: 'Fecha de nacimiento',
    },
    Name: {
      title: 'Nombre y apellido',
      body: '¿Cómo te gustaría que te llamemos?',
      label: {
        name: 'Nombre',
        lastName: 'Apellido',
      },
    },
    Document: {
      title: 'Documento de identidad ',
      body: 'Introduce tu número de identidad. De esta manera podremos garantizar la seguridad de tu información',
      labelCpfType: 'Tipo documento',
      dropdownPlaceholder: 'Elige uno',
      documentLabel: 'Nº del documento',
      optInCpfLabel:
        "Acepto que se pueda utilizar mi CPF, también indicado en mis compras en Restaurantes McDonald's, para ofrecer productos, servicios y ofertas personalizadas, todo ello de acuerdo con la",
      warningTitle: 'Consulta más información',
      warningBody: 'Atención al cliente',
      warning: {
        title: 'Documento ya registrado',
        body: 'Este número de documento ya está registrado para el email {email}. Para más información, ponte en contacto con ',
      },
    },
    Phone: {
      title: 'Número de teléfono',
      body: 'Con el fin de asegurar que solo tú tengas acceso a tu cuenta, por favor ingresa tu número de teléfono',
      label: {
        prefix: 'Prefijo',
        number: 'Número',
      },
    },
  },
  vouchers: {
    title: 'Tienes disponibles',
    description: 'Puedes obtener un código Minecraft si tienes los puntos necesarios',
    redeemCoupon: {
      title: '¿Quieres canjear <br/><b>{value} pts.</b> para obtener <br/>un código?',
      accept: 'Quiero obtener un código',
      cancel: 'Ahora no',
    },
    appliedCoupon: {
      title: '¡Felicitaciones! Acabas de obtener tu código Minecraft',
      useCodeInfo: '¿Cómo usar los códigos?',
      codeExpiration: 'Tu código vence el',
      accept: 'Volver',
    },
    codeList: {
      title: 'Ver mis códigos obtenidos',
      description: 'Códigos',
      code: 'Código',
      expiration: 'Caducidad',
    },
    notification: {
      title: 'Solo se permite activar un código por cuenta Minecraft',
      description:
        "Canjea tus puntos desde el { startDate } al { endDate } o hasta agotar el stock de { stock } unidades. El código activa 25 complementos (Add-ons) de McDonald's en Minecraft: Bedrock Edition",
    },
    errors: {
      generic: 'Algo no salió como esperábamos. Inténtalo de nuevo más tarde',
      maximumPurchasesExceeded: 'Superaste el número máximo de compras de códigos Minecraft',
      noPointsAvailable:
        'No tienes los puntos necesarios para obtener un código. Necesitas { value } pts. para conseguirlo',
    },
  },
  stepsReference: 'Paso {current} de {total}',
  cancellationPolicy: {
    titleClose: 'Detalle del pedido',
    title: 'Política de Cancelación',
    subtext:
      'Lamentablemente pueden ocurrir imprevistos y puede ser que necesites cancelar tu pedido. A continuación, se presentan los casos en los que la cancelación puede realizarse directamente desde la app de Mc Donald’s y otras indicaciones:',
    first: {
      title: 'Pedido realizado por Pide y Retira',
      text1:
        "Puedes solicitar la cancelación antes de confirmar que estás en el restaurante y de que el pedido sea enviado a prepararse. En el caso de que el pedido haya sido realizado por canje de puntos Mi McDonald 's, los puntos serán devueltos automáticamente.",
      text2:
        'Si enviaste un pedido a preparar y tuviste algún imprevisto, por favor entra en contacto con nuestro SAC, por medio de los canales disponibles en la app de McDonald’s - teléfono: {phone}  WhatsApp: {whatsApp} y e-mail: ',
    },
    second: {
      title: 'Pedido realizado por Delivery',
      text1:
        'Después de la confirmación del pedido por medio de la app, comenzará la preparación y el pedido no podrá ser cancelado o modificado.',
      text2:
        'Si después de la entrega del pedido tienes alguna duda, crítica o sugerencia, por favor ponte en contacto con nuestro SAC.',
      text3:
        'Si tu pedido no ha sido entregado y por alguna razón todavía no fue cancelado, por favor, ponte en contacto con nuestro SAC.',
    },
    important: {
      title: 'AVISO IMPORTANTE',
      text1:
        'Para finalizar el pedido, el repartidor SIEMPRE solicitará un código de validación de entrega.',
      text2:
        'No informes el código antes de recibir el pedido. Si informaste el código antes de tener el pedido en mano, y por alguna razón no lo recibiste, el reembolso no será realizado.',
      text3:
        'En el caso  de que tu pedido sea cancelado, no te preocupes, solicitaremos el reembolso del valor del pedido automáticamente. El tiempo estimado para el reembolso puede variar de acuerdo a la modalidad de pago, como se detalle a continuación:',
      listItem1:
        'Para compras realizadas con débito, el reembolso se efectuará en: Hasta 7 días hábiles a partir de la fecha de cancelación.',
      listItem2:
        'Para compras realizadas con crédito, el reembolso se efectuará en: Hasta 30 días hábiles a partir de la fecha de cancelación y de acuerdo con la fecha de cierre de la factura del cliente.',
      listItem3:
        'Para compras realizadas con Mercado Pago el reembolso se efectuará en: Hasta 2  días hábiles a partir de la fecha de cancelación.',
      listItem4:
        'Si ocurre algún otro problema, para que podamos evaluar tu solicitud, por favor no dudes en contactarnos a través de los siguientes canales:',
      listItem5: '',
      listItem6: '',
      listItem7: '',
      lastText: '',
      phone: 'Teléfono: {phone}',
      whatsApp: 'WhatsApp: {whatsApp}',
      email: 'E-mail:',
    },
  },
  markdown: {
    vouchers: {
      terms: termsES,
    },
  },
}
