import { RestaurantAreas } from '~/lib/interfaces/restaurant'
import lupapDaneCodes from '~/data/lupap-dane-codes.json'
import { DaneCode } from '~/lib/models/lupap'

const LUPAP_COUNTRY_CODE = 'CO'
const daneCodes: DaneCode[] = lupapDaneCodes

export const useLupap = () => {
  const restaurantStore = useRestaurantStore()
  const country = useCountry()

  const isLupapMaps = computed(() => country === LUPAP_COUNTRY_CODE)
  const isDLVSelected = computed(() => restaurantStore.candidateArea === RestaurantAreas.DLV)

  const canShowLupapSearch = computed(() => !!(isLupapMaps.value && isDLVSelected.value))

  return {
    isLupapMaps,
    canShowLupapSearch,
    daneCodes,
  }
}
