import get from 'lodash/get'
import { Coords } from '~/lib/models/geolocation'

export const getPermissionStatus = async (type: string, callback?: Function) => {
  try {
    if (!get(navigator, type)) {
      // eslint-disable-next-line no-console
      console.log('Navigator API ' + type + ' not available on this browser')
      return null
    }

    if (navigator.permissions && navigator.permissions.query) {
      const result = await navigator.permissions.query({ name: type as PermissionName })
      if (callback) result.onchange = async () => await callback(result.state)
      return result.state
    }
    return 'prompt'
  } catch (error) {
    return 'denied'
  }
}

export const getUserLocation = async (): Promise<
  | {
      lat: number
      lng: number
    }
  | undefined
> => {
  const options = { enableHighAccuracy: true, timeout: 20000 }

  return new Promise((resolve, _reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const userLocation: {
            lat: number
            lng: number
          } = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          return resolve(userLocation)
        },
        error => {
          // eslint-disable-next-line no-console
          console.log('Error in getUserLocation -> ', error)
          return resolve(undefined)
        },
        options
      )
    } else {
      // eslint-disable-next-line no-console
      console.log('Geolocation is not supported by this browser.')
      return resolve(undefined)
    }
  })
}

export const getCityLocation = async ({
  cityName,
  countryCode,
}: {
  cityName: string
  countryCode: string
}) => {
  const geocoder = new google.maps.Geocoder()
  return new Promise<Coords | undefined>(resolve => {
    geocoder.geocode({ address: cityName, region: countryCode }).then(({ results }) => {
      if (!results.length) resolve(undefined)
      const cityGeocoded = results?.[0]
      const { lat, lng } = cityGeocoded.geometry.location
      resolve({
        lat: lat(),
        lng: lng(),
      })
    })
  })
}

export const distanceToString = (distance: number | undefined): string => {
  if (!distance) return ''
  if (distance <= 100) {
    return `${Math.floor(distance)} m`
  } else if (distance <= 1000) {
    const count = (distance / 1000).toFixed(2)
    return `${count} km`
  } else return `${Math.floor(distance / 1000)} km`
}

export const getUserLocationForRestaurants = async ({
  cityName,
  countryCode,
}: {
  cityName?: string
  countryCode: string
}) => {
  if (cityName) {
    const cityLocation = await getCityLocation({ cityName, countryCode })
    return cityLocation
  }

  const permissionStatus = await getPermissionStatus('geolocation')
  if (permissionStatus === 'granted') {
    try {
      const userLocation = await getUserLocation()
      return userLocation
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ file: geolocationUtils.ts:82 ~ getUserLocation ~ error:', e)
    }
  }

  return undefined
}
