import redirectsData from './redirects.json'

interface RecordString extends Record<string, string> {}

const data: Record<
  string,
  {
    exactPaths: RecordString
    regexpPaths: Array<{ regexString: string; nextPath: string; captureGroup: boolean }>
  }
> = redirectsData

export default defineNuxtRouteMiddleware((to, _from) => {
  const domain = useSiteDomain()
  const redirectPaths = data[domain]

  // Return when domain is not in redirects.json
  if (!redirectPaths) return

  const { exactPaths, regexpPaths } = redirectPaths
  const { path } = to

  // Handle exact paths redirections
  const exactValues = exactPaths
  const matchedValue = exactValues[path]
  if (matchedValue) return navigateTo(matchedValue, { redirectCode: 301, external: true })

  // Handle regexp paths redirections
  const redirectRule = regexpPaths.find(rule => {
    const regex = new RegExp(rule.regexString)
    return regex.test(path)
  })

  if (redirectRule?.captureGroup) {
    const { regexString, nextPath } = redirectRule
    const regex = new RegExp(regexString)
    const captureGroup = regex.exec(path)?.[1] ?? ''
    const newPath = `${nextPath}${captureGroup}`
    return navigateTo(newPath, { redirectCode: 301 })
  } else if (redirectRule) {
    return navigateTo(redirectRule.nextPath, { redirectCode: 301 })
  }
})
