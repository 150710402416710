export const removeDuplicates = <T>(arr: Array<T>) => [...new Set(arr)]

export const arraysMatch = <T>(a: T[], b: T[]) => {
  return a.find(c => b.includes(c))
}

export const extractItem: <T extends Record<string, any>>(
  value: string,
  arr: T[],
  field?: string
) => { item: T | undefined; remaining: T[] } = (value, arr, field = 'id') => {
  const index = arr.findIndex(item => item[field] === value)
  if (index !== -1) {
    const item = arr[index]
    const remaining = [...arr.slice(0, index), ...arr.slice(index + 1)]
    return { item, remaining }
  }
  return { item: undefined, remaining: arr }
}
