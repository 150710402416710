import { z } from 'zod'

export type CurrencyConfig = {
  countryCode: string
  language: string
  currencyAcronym: string
  currencySymbol: string
  allowDecimals: boolean
  decimalsQty: number
  decimalBehaviour: string
}

export const DecimalBehaviour = {
  ROUND_UP: 'roundUp',
  ROUND_GENERIC: 'roundGeneric',
  TRUNCATE: 'trunc',
} as const

export const LegacyPaymentBehaviour = {
  WEBVIEW: 'webview',
  WEBVIEW_FULL: 'webviewFull',
  BROWSER: 'browser',
} as const

export const LegacyPaymentType = {
  CREDIT_CARD: 'creditCard',
  DEBIT_CARD: 'debitCard',
  CASH: 'cash',
  CARD: 'card',
} as const

export const paymentMethodsDynamicSchema = z.object({
  code: z.string(), // LegacyPaymentType
  name: z.string(),
  behaviour: z.string(), // LegacyPaymentBehaviour
  svgLogo: z.string().nullable().optional(),
  pngLogo: z.string().optional().nullable(),
  svgUrl: z.string().nullable().optional(),
  pngUrl: z.string().optional().nullable(),
  /* sentPurchaseBackend: z.boolean(),
  antiFraudRules: z.boolean(),
  platform: z.array(z.string()), */
})

export type LegacyPaymentMethod = z.infer<typeof paymentMethodsDynamicSchema>

export const customErrorSchema = z.object({
  code: z.string(),
  numberCode: z.number(),
  title: z.string(),
  text: z.string(),
  type: z.string(),
  btn1Text: z.string(),
  btn2Text: z.string(),
})

export type PromoCustomError = z.infer<typeof customErrorSchema>

export const promotionTypeSchema = z.object({
  name: z.string(),
  np6Type: z.string(),
})

export const optionTipsSchema = z.object({
  custom: z.boolean(),
  type: z.string(),
  amount: z.number(),
})

export const dynamicFiscalFieldOptionsSchema = z.object({
  integrationFlexId: z.string(),
  integrationImId: z.string(),
  name: z.string(),
  regex: z.string(),
  mask: z.string(),
})

export const cartSchema = z.object({
  beforeDate: z.string(),
  inDate: z.string(),
})

export const orderDetailSchema = z.object({
  beforeDate: z.string(),
  inDate: z.string(),
  outDate: z.string(),
})

export const paymentSchema = z.object({
  beforeDate: z.string(),
  inDate: z.string(),
})

export const productDetailSchema = z.object({
  warningCartHasMcDiaProducts: z.string(),
  warningCartHasNoMcDiaProducts: z.string(),
})

export const promotionSchema = z.object({
  engineIsOnline: z.boolean(),
  customErrors: z.array(customErrorSchema),
  promotionType: z.array(promotionTypeSchema),
})

export const tipsOptionsSchema = z.object({
  enabled: z.boolean(),
  options: z.array(optionTipsSchema),
})

export const dynamicFiscalFieldSchema = z.object({
  referenceCode: z.string().optional(),
  name: z.string(),
  code: z.string(),
  mask: z.string().optional(),
  regex: z.string().optional(),
  isCombo: z.boolean(),
  options: z.array(dynamicFiscalFieldOptionsSchema),
  required: z.boolean(),
})

const messagesAdvanceSales = z.object({
  cart: cartSchema,
  orderDetail: orderDetailSchema,
  payment: paymentSchema,
  productDetail: productDetailSchema,
})
export const messagesAdvanceSalesDatesSchema = z.object({
  mop: messagesAdvanceSales,
  dlv: messagesAdvanceSales,
})

export type AdvanceSalesMessages = z.infer<typeof messagesAdvanceSales>

/* Rating */
export const ratingDetailsDto = z.object({
  key: z.string(),
  value: z.string(),
})
export type Details = z.infer<typeof ratingDetailsDto>

export const ratingConfigurationDto = z.object({
  isNumberOfLikesOrButtonMop: z.boolean(),
  isNumberOfLikesOrButtonDlv: z.boolean(),
  numberOfLikesMop: z.number(),
  numberOfLikesDlv: z.number(),
  buttonTextOkMop: z.string(),
  buttonTextOkDlv: z.string(),
  buttonTextKoMop: z.string(),
  buttonTextKoDlv: z.string(),
  msgOkMop: z.string(),
  msgKoMop: z.string(),
  msgOkDlv: z.string(),
  msgKoDlv: z.string(),
  detailsDelivery: z.array(ratingDetailsDto),
  detailsMop: z.array(ratingDetailsDto),
})
export type RatingConfiguration = z.infer<typeof ratingConfigurationDto>

/* CrossSelling */
export const crosssellingDto = z.object({
  maxLines: z.number(),
})

/* Tips */
export const TipType = {
  AMOUNT: 'amount',
  PERCENT: 'percent',
} as const
const tipTypeUnion = z.union([z.literal(TipType.AMOUNT), z.literal(TipType.PERCENT)])

export const tipOptionDto = z.object({
  type: tipTypeUnion,
  amount: z.number(),
  custom: z.boolean().optional(),
})
export type TipOption = z.infer<typeof tipOptionDto>

const tipsOptionsDto = z.object({
  enabled: z.boolean(),
  options: z.array(tipOptionDto),
})

const dynamicFiscalFieldsSchema = z.array(dynamicFiscalFieldSchema)

export const countryConfigSchemaDto = z.object({
  // global
  id: z.string(),
  code: z.string(),
  name: z.string(),
  // currency
  currencySymbol: z.string(),
  currencyAcronym: z.string(),
  allowDecimals: z.boolean(),
  decimalsQty: z.number(),
  decimalBehaviour: z.string(),
  // products
  limitProductOrder: z.number(),
  limitPriceOrder: z.number(),
  limitOrder: z.number(),
  /* deliveryFee: z.number(),
  dlvActive: z.boolean(),
  deliveryFreeAmount: z.number().optional(),
  deliveryTime: z.number(), */
  // areas
  pickupTime: z.number(),
  // product flags
  activeProductDetailV2: z.boolean(),
  crossselling: crosssellingDto.optional(),
  // restaurant flags
  allowOrderRestaurantClosed: z.boolean(),
  displayRestaurantConfirmation: z.number(),
  allowPinDrag: z.boolean(),
  keepMapLocation: z.boolean(),
  // restaurant addresses properties
  pinDragRadius: z.number(),
  addressLite: z.boolean(),
  hideNeighborhood: z.boolean(),
  // country flags
  tax: z.boolean(),
  activeCategoriesImages: z.boolean(),
  activePreauthCapture: z.boolean().optional(),
  activeDeviceVerification: z.boolean().optional(),
  restaurantDistanceAcknowledge: z.boolean().optional(),
  // rating
  ratingOrdersV2: ratingConfigurationDto,
  // promotions
  promotion: promotionSchema,
  /* Tips */
  tipsOptions: tipsOptionsDto,
  /* Maps */
  googleMapsApiKeyPickup: z.string(),
  googleMapsApiKeyDelivery: z.string(),
  lupapWebToken: z.string().nullable().optional(),
  /* Payment legacy methods */
  paymentMethodsDynamic: z.array(paymentMethodsDynamicSchema),
  // mcDia (AdvanceSales)
  messagesAdvanceSalesDates: messagesAdvanceSalesDatesSchema,
  /*
  locale: z.string(),
  urlCallback: z.string(), */
  // user help customer links
  urlFaqs: z.string(),
  activeSalesforceSupport: z.boolean().optional(),
  urlWhatsApp: z.string(),
  logoChatSupport: z.string().optional(),
  nameChatSupport: z.string().optional(),
  sacPhoneNumber: z.string().optional(),
  prefixNumberPhone: z.string(),
  requiredPaymentFields: z.array(z.string()),
  /*
  mopDaypart: z.boolean(),
  checkoutAlert: z.boolean(), */
  /*
  tipsOptions: tipsOptionsSchema,
  calculateCartBackend: z.boolean(),
  calculateCartBackendV2: z.boolean(), */
  loyalty: z.boolean(),
  limitEnrollment: z.number(),
  // activeMinInvoiceAmount: z.boolean(),
  // predictivePoi: z.boolean(),
  // phoneValidationActive: z.boolean(),
  /* showQr: z.boolean(), */
  // Fiscal data
  activeFiscalFields: z.boolean(),
  dynamicFiscalFields: dynamicFiscalFieldsSchema,
  minInvoice: z.array(
    z.object({
      area: z.string(),
      minInvoiceAmount: z.number(),
    })
  ),
  checkoutAlert: z.boolean().optional(),
  emailWhitelist: z.string().optional(),
  serviceFeeName: z.string().optional(),
})

export type EcommerceConfig = z.infer<typeof countryConfigSchemaDto>

export const dynamicFiscalKey: InjectionKey<z.infer<typeof dynamicFiscalFieldsSchema>> =
  Symbol('dynamicFiscalFields')
