export function useIsMobile() {
  const isMobile = ref(false)

  const handleResize = () => {
    isMobile.value = window.innerWidth < 768
  }

  onMounted(() => {
    if (typeof window !== 'undefined') {
      isMobile.value = window.innerWidth < 768
      window.addEventListener('resize', handleResize)
    }
  })

  onUnmounted(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', handleResize)
    }
  })

  return isMobile
}
