import { defineStore } from 'pinia'
import round from 'lodash/round'
import {
  RestaurantLiteWithDistanceList,
  RestaurantAreas,
  RestaurantAreaType,
  SelectedLocationWithCoords,
  OpenServicesState,
} from '~/lib/interfaces/restaurant'
import { distanceBetweenCoordinates } from '~/lib/geoUtils'
import {
  geoPermissionStatus,
  Restaurant,
  RestaurantDelivery,
  selectedRestaurantSchema,
  SelectRestaurantType,
} from '~/lib/services/store/restaurant/restaurant.dto'
import { loadGoogleAPI } from '~/lib/clients/googleMaps'
import { Address } from '~/lib/services/store/order/order.dto'
import { Coords } from '~/lib/models/geolocation'

interface State {
  restaurant?: Restaurant
  modalMOP: boolean
  modalDLV: boolean
  geoPermissionStatus: geoPermissionStatus
  selectedGoogleLocation: SelectedLocationWithCoords | undefined
  modalChangeRestaurant: boolean
  candidateArea?: RestaurantAreaType
  selectedRestaurant: SelectRestaurantType
  restauranListMop: RestaurantLiteWithDistanceList
  googleMapsSessionToken: google.maps.places.AutocompleteSessionToken | undefined
  restaurantTips?: boolean
  restaurantOpenState?: OpenServicesState
}

export const useRestaurantStore = defineStore('restaurant', {
  state: (): State => ({
    restaurant: undefined,
    modalMOP: false,
    modalDLV: false,
    geoPermissionStatus: 'prompt',
    selectedGoogleLocation: undefined,
    modalChangeRestaurant: false,
    candidateArea: undefined,
    selectedRestaurant: undefined,
    restauranListMop: [],
    googleMapsSessionToken: undefined,
    restaurantTips: undefined,
    restaurantOpenState: undefined,
  }),
  actions: {
    async getRestaurantByCode({ code, area }: { code: string; area: RestaurantAreaType }) {
      try {
        const { restaurantServiceRef } = useStoreService()
        const restaurantService = restaurantServiceRef()
        const restaurant = await restaurantService.getStoreRestaurantByCode({
          restaurantCode: code,
          area,
          countryCode: useCountry(),
        })
        this.restaurant = restaurant
      } catch (err) {
        throw new Error('Error fetching restaurant by code')
      }
    },
    async getRestaurant() {
      try {
        if (this.selectedRestaurant?.restaurant?.code) {
          const { restaurantServiceRef } = useStoreService()
          const restaurantService = restaurantServiceRef()
          const restaurant =
            this.areaSelected === RestaurantAreas.MOP
              ? await restaurantService.getStoreRestaurantByCode({
                  restaurantCode: this.selectedRestaurant.restaurant.code,
                  area: this.areaSelected,
                  countryCode: useCountry(),
                })
              : await restaurantService.getRestaurantDelivery({
                  area: this.areaSelected,
                  latitude: String(this.selectedRestaurant?.addressDLV?.latitude),
                  longitude: String(this.selectedRestaurant?.addressDLV?.longitude),
                  countryCode: useCountry(),
                })
          this.setRestaurant(restaurant)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('🚀 ~ file: restaurant.store.ts:70 ~ getRestaurant ~ error:', error)
      }
    },
    async fetchListRestaurants() {
      const countryCode = useCountry()
      const authStore = useAuthStore()
      const appStore = useAppStore()
      if (appStore.isEcommerceDisabled) {
        this.restauranListMop = []
        return this.restauranListMop
      }
      if (this.restauranListMop.length > 0) return this.restauranListMop
      const { restaurantServiceRef } = useStoreService()
      const restaurantService = restaurantServiceRef()
      const restauranList = await restaurantService.getStoreListRestaurant({
        area: RestaurantAreas.MOP,
        countryCode, // Todo: Change once we add country to config
      })

      this.restauranListMop = restauranList.map(res => ({
        ...res,
        ecommerce: true,
        isFavorite: isFavoriteRestaurant({
          countryCode,
          favoriteRestaurantsMetadata: authStore?.userMetadata?.favoriteRestaurants,
          restaurantCode: res.code,
        }),
      }))
      return this.restauranListMop
    },
    setRestaurant(restaurant: Restaurant | RestaurantDelivery) {
      this.restaurant = restaurant

      this.selectedRestaurant = {
        ...this.selectedRestaurant,
        selectedArea: this.getSelectedArea,
        restaurant: {
          id: restaurant?.id,
          code: restaurant?.code,
          name: restaurant?.name,
          city: restaurant.city,
          address: restaurant?.address,
        },
        serviceFee: restaurant.serviceFee,
      }
      if (
        !this.areaSelected ||
        this.areaSelected === RestaurantAreas.MOP ||
        this.restaurantTips === undefined
      )
        this.restaurantTips = false
    },
    setIsCrew(isCrew: boolean) {
      this.selectedRestaurant = {
        ...this.selectedRestaurant,
        isCrew,
      }
    },
    setAddress(place: Address) {
      this.selectedRestaurant = {
        ...this.selectedRestaurant,
        deliveryAddress: {
          ...place,
        },
      }

      this.restauranListMop = this.restauranListMop.map(res => {
        return {
          ...res,
          distance: round(
            distanceBetweenCoordinates(
              place.latitude,
              place.longitude,
              res.coordinates.latitude,
              res.coordinates.longitude,
              'K'
            ),
            2
          ),
        }
      })
    },
    setGoogleLocation(place: SelectedLocationWithCoords) {
      this.selectedGoogleLocation = place
    },
    setGeoPermissionStatus(status: geoPermissionStatus) {
      this.geoPermissionStatus = status
    },
    updateAddress(obj: {
      number: string | undefined
      additionalInfo: string
      additionalInfoDelivery: string
      favoriteAlias?: string
    }) {
      const actualDeliveryAddress = this.selectedRestaurant?.deliveryAddress
      this.selectedRestaurant = {
        ...this.selectedRestaurant,
        deliveryAddress: {
          ...actualDeliveryAddress,
          number: obj.number,
          complement: obj.additionalInfo || undefined,
          specialInstructions: obj.additionalInfoDelivery || undefined,
          latitude: actualDeliveryAddress?.latitude || 0,
          longitude: actualDeliveryAddress?.longitude || 0,
          alias: obj.favoriteAlias,
        },
      }
    },

    addAddressDLV() {
      if (this.selectedRestaurant?.deliveryAddress) {
        this.selectedRestaurant.addressDLV = this.selectedRestaurant?.deliveryAddress
      }
    },
    openModal() {
      if (this.candidateArea) {
        this[`modal${this.candidateArea}`] = true
      }
    },
    openChangeRestaurantModal() {
      this.modalChangeRestaurant = true
    },
    closeModal() {
      this.modalDLV = false
      this.modalMOP = false
    },
    async setArea({
      area,
      updateGoogleMaps,
    }: {
      area: RestaurantAreaType
      updateGoogleMaps?: boolean
    }) {
      const { trackClickArea } = useGTM()
      this.candidateArea = area
      if (updateGoogleMaps) {
        const { key, country } = useGoogleMapsKey()
        await loadGoogleAPI({ apiKey: key, region: country })
        useGoogleSessionToken()
      }
      trackClickArea(area)
    },
    setGoogleMapsSessionToken(token: google.maps.places.AutocompleteSessionToken | undefined) {
      this.googleMapsSessionToken = token
    },
    closeChangeRestaurantModal() {
      this.modalChangeRestaurant = false
    },
    async autoSelectRestaurant(
      code: string,
      area: RestaurantAreaType = RestaurantAreas.MOP
    ): Promise<boolean> {
      try {
        const appStore = useAppStore()
        const { trackSelectRestaurantAutomatic } = useGTM()
        await this.getRestaurantByCode({
          code,
          area,
        })
        this.setRestaurant(this.restaurant!)
        appStore.clearCart()
        const { id } = this.restaurant!
        await appStore.loadCategories(id, area)
        trackSelectRestaurantAutomatic()
        return true
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(
          '🚀 ~ file: restaurant.store.ts:207 ~ autoSelectRestaurant ~ error:',
          error?.response || error
        )
        return false
      }
    },
    setRestaurantOpenState(state: OpenServicesState) {
      this.restaurantOpenState = state
    },
    setInitialDeliveryAddressDistance(deliveryAddressDistance: number) {
      this.selectedRestaurant = {
        ...this.selectedRestaurant,
        deliveryAddressDistance,
      }
    },
    async setDeliveryAddressDistance() {
      const currentCoordinates = this.getDeliveryCoords
      const restaurantCoordinates = this.getRestaurantCoords
      const deliveryAddressDistance = await getRouteDistance({
        currentCoordinates,
        restaurantCoordinates,
      })

      if (!deliveryAddressDistance) return

      if (this.selectedRestaurant?.addressDLV) {
        this.setAddress({
          ...this.selectedRestaurant?.addressDLV,
          distance: deliveryAddressDistance,
        })
        this.addAddressDLV()
      }

      this.selectedRestaurant = {
        ...this.selectedRestaurant,
        deliveryAddressDistance,
      }
    },
  },
  getters: {
    isRestaurantSelected(): boolean {
      return !!this.selectedRestaurant?.restaurant?.code && !!this.restaurant
    },
    areaSelected(): RestaurantAreaType | undefined {
      return this.selectedRestaurant?.selectedArea
    },
    isAreaDLV(): boolean {
      return this.areaSelected === RestaurantAreas.DLV
    },
    tipsVisible(): boolean {
      return !!(this.isAreaDLV && this.restaurantTips)
    },
    isGeoLocationActive(): boolean {
      return this.geoPermissionStatus === 'granted'
    },
    deliveryAddressDistance(): number | undefined {
      return this.selectedRestaurant?.deliveryAddressDistance
    },
    isCrew(): boolean | undefined {
      return this.selectedRestaurant?.isCrew
    },
    getServiceFeeName(): string | undefined {
      return this.selectedRestaurant?.serviceFee?.name
    },
    getServiceFeeAmount(): number | undefined {
      return this.selectedRestaurant?.serviceFee?.serviceFeeAmount
    },
    getDeliveryCoords(): Coords {
      return {
        lat: this.selectedRestaurant?.deliveryAddress?.latitude || 0,
        lng: this.selectedRestaurant?.deliveryAddress?.longitude || 0,
      }
    },
    getRestaurantCoords(): Coords {
      return {
        lat: this.restaurant?.coordinates.latitude || 0,
        lng: this.restaurant?.coordinates.longitude || 0,
      }
    },
    getSelectedArea(): RestaurantAreaType {
      if (!this.candidateArea && this.areaSelected) return this.areaSelected

      return this.candidateArea === RestaurantAreas.DLV ? RestaurantAreas.DLV : RestaurantAreas.MOP
    },
  },
  persist: {
    serializer: {
      // Parse persisted cookie paths selectedRestaurant
      deserialize: (value: string) => {
        const onError = () => {
          if (process.client) {
            // toast error
            const { $toast } = useNuxtApp()
            $toast.show({
              type: 'error',
              content: 'An error has ocurred while initializing app',
              duration: 5,
            })
            document.cookie = `restaurant=${encodeURIComponent(JSON.stringify({}))}; path=/; Secure`
          }
        }
        try {
          const json = JSON.parse(value)
          const key = 'selectedRestaurant'
          const keyValue = json[key]
          const validZod = selectedRestaurantSchema.safeParse(keyValue)
          if (!validZod.success) {
            onError()
            return undefined
          }
          return json
        } catch (error) {
          onError()
          return undefined
        }
      },
      serialize: JSON.stringify,
    },
    paths: ['selectedRestaurant'],
    storage: persistedState.cookies,
  },
})
