import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import Skeleton from 'primevue/skeleton'
import { defineNuxtPlugin } from '#app'
import ConfirmationService from 'primevue/confirmationservice'
import DialogService from 'primevue/dialogservice'
import DynamicDialog from 'primevue/dynamicdialog'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(PrimeVue)

  // Sync with to ~/types/primevue.dts
  nuxtApp.vueApp.component('Sidebar', Sidebar)
  nuxtApp.vueApp.component('Btn', Button)
  nuxtApp.vueApp.component('DynamicDialog', DynamicDialog)
  nuxtApp.vueApp.component('Skeleton', Skeleton)

  // PrimeVue Services
  nuxtApp.vueApp.use(ConfirmationService)
  nuxtApp.vueApp.use(DialogService)
})
