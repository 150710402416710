import { z } from 'zod'
import { addressId } from '../order/order.dto'

const nameDaysOfWeekSchema = z.union([
  z.literal('mon'),
  z.literal('tue'),
  z.literal('wed'),
  z.literal('thu'),
  z.literal('fri'),
  z.literal('sat'),
  z.literal('sun'),
])

const dayOfWeekSchemaGeneral = z.object({
  day: nameDaysOfWeekSchema,
  timePeriods: z.array(
    z.object({
      start: z.string(),
      end: z.string(),
    })
  ),
})

const dayOfWeekSchemaTimeSlots = z.object({
  day: nameDaysOfWeekSchema,
  timePeriods: z.array(
    z.object({
      hourOpen: z.string(),
      hourClose: z.string(),
    })
  ),
})

export const servicesRestaurantSchema = z.object({
  breakfast: z.boolean().optional(),
  mcCafe: z.boolean().optional(),
  // driveThru: z.boolean().optional(),
  // dcOut: z.boolean().optional(),
  // dcIn: z.boolean().optional(),
  // mcDelivery: z.boolean().optional(),
  timeExtended: z.boolean().optional(),
  mcParty: z.boolean().optional(),
  playPlace: z.boolean().optional(),
  parking: z.boolean().optional(),
  wifi: z.boolean().optional(),
  // loyalty: z.boolean().optional(),
  wheelchairAccess: z.boolean().optional(),
  dessertCenter: z.boolean().optional(),
  shoppingCenter: z.boolean().optional(),
})

const coordinatesSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
})

const timeSlotServiceSchema = z.object({
  type: z.string(),
  daysOfWeek: z.array(dayOfWeekSchemaTimeSlots),
})

export const deliverySchema = z.object({
  deliveryFee: z.number(),
  deliveryFreeAmount: z.number().optional(),
  deliveryFeeTax: z.number().nullable().optional(),
})

export const generalHourSchema = z.object({
  daysOfWeek: z.array(dayOfWeekSchemaGeneral),
})

const infoSeoSchema = z.object({
  metaTitle: z.string(),
  metaDescription: z.string(),
  metaTags: z.array(
    z.object({
      key: z.string(),
      content: z.string(),
      name: z.string(),
    })
  ),
  ogTitle: z.string(),
  ogDescription: z.string(),
  ogImage: z.string(),
  ogImageText: z.string(),
})

// Service fee
export const serviceFeeDto = z.object({
  serviceFeeAmount: z.number(),
  name: z.string(),
})

export const restaurantLiteSchema = z.object({
  active: z.boolean(),
  address: z.string(),
  cep: z.string(),
  city: z.string(),
  code: z.string(),
  coordinates: coordinatesSchema,
  id: z.string(),
  loyalty: z.boolean(),
  name: z.string(),
  neighborhood: z.string(),
  timeSlotsService: z.array(timeSlotServiceSchema),
  timezone: z.string(),
})

/**
 * Restaurant lite dto
 **/
export const restaurantsLiteDto = z.array(restaurantLiteSchema)
export type RestaurantsLiteList = z.infer<typeof restaurantsLiteDto>
export type RestaurantLite = z.infer<typeof restaurantLiteSchema>

const restaurantInPersonPaymentDto = z.object({
  mop: z.boolean(),
  ealm: z.boolean(),
  curb: z.boolean(),
  aut: z.boolean(),
  dlv: z.boolean(),
})

/**
 * Restaurant dto
 **/
export const restaurantDto = restaurantLiteSchema.extend({
  botMaker: z.boolean(),
  delivery: deliverySchema,
  ecommerce: z.boolean(),
  enabledYuno: z.boolean(),
  freeTax: z.boolean().nullable(),
  generalHour: generalHourSchema,
  phone: z.string(),
  phoneMc: z.string(),
  services: servicesRestaurantSchema,
  inPersonPayment: restaurantInPersonPaymentDto.optional(),
  serviceFee: serviceFeeDto.nullable().optional(),
})
export type Restaurant = z.infer<typeof restaurantDto>

/**
 * Restaurant delivery dto
 **/
export const restaurantDeliveryDto = restaurantDto.extend({
  distance: z.number(),
  infoSeo: infoSeoSchema.optional(),
  tips: z.boolean(),
  isCrew: z.boolean().default(false),
  active: z.boolean().default(true),
})
export type RestaurantDelivery = z.infer<typeof restaurantDeliveryDto>

// RestaurantInfo
export type RestaurantInfo = {
  id: string
  code: string
  name: string
  city: string
}

export const selectedRestaurantSchema = z
  .object({
    selectedArea: z.union([z.literal('MOP'), z.literal('DLV')]).optional(),
    restaurant: z
      .object({
        id: z.string(),
        code: z.string(),
        name: z.string(),
        city: z.string(),
        address: z.string(),
      })
      .optional(),
    deliveryAddress: addressId.optional(),
    deliveryAddressDistance: z.number().optional(),
    addressDLV: addressId.optional(),
    config: z.object({ code: z.string() }).optional(),
    isCrew: z.boolean().optional(),
    serviceFee: serviceFeeDto.nullable().optional(),
  })
  .optional()

export type SelectRestaurantType = z.infer<typeof selectedRestaurantSchema>
export type geoPermissionStatus = 'granted' | 'denied' | 'prompt'
