export function useLinkHandler() {
  const { trackClick } = useGTM()

  const linkHandler = (event: Event) => {
    const target = (event.target || event.srcElement) as HTMLElement
    const isTargetAnchor = target && target.tagName === 'A'
    const isTargetParentAnchor =
      target.parentNode && (target.parentNode as HTMLElement).tagName === 'A'

    if (
      // Only target or parent is an anchor tag
      isTargetAnchor ||
      isTargetParentAnchor
    ) {
      const targetHRef =
        (target as HTMLAnchorElement).href || (target.parentNode as HTMLAnchorElement).href
      const isExternalLink = targetHRef && !targetHRef.includes(window.location.host)

      if (isExternalLink) {
        event.preventDefault()

        const anchorTarget = isTargetAnchor
          ? (target as HTMLAnchorElement).target || '_self'
          : (target.parentNode as HTMLAnchorElement).target || '_self'

        trackClick(targetHRef)

        window.open(targetHRef, anchorTarget, 'noopener')
      }
    }
  }

  onMounted(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('click', linkHandler, false)
    }
  })

  onUnmounted(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('click', linkHandler, false)
    }
  })
}
