import Debug from 'debug'
import { defineStore } from 'pinia'
import {
  CurrencyConfig,
  EcommerceConfig,
  TipOption,
} from '~/lib/services/store/country/country.dtos'
import {
  CategoryListMdw,
  MdwCountry,
  MaskConfigFieldName,
  MaskConfigType,
} from '~/lib/services/middleware/middleware.dtos'
import {
  Category,
  ProductEcommerce,
  ProductTypes,
  ProductType,
} from '~/lib/services/store/catalog/catalog.dtos'
import {
  Cart,
  CartProduct,
  CartTotals,
  CartInputProductDTO,
  CartDTO,
  CartBackendAdditional,
} from '~/lib/services/store/catalog/cart.dtos'
import { RestaurantAreaType, RestaurantAreas } from '~/lib/interfaces/restaurant'
import {
  StoreOrder,
  RepeatOrderDto,
  RepeatOrderProduct,
} from '~/lib/services/store/order/order.dto'
import { Restaurant } from '~/lib/services/store/restaurant/restaurant.dto'
import { RateOrderInput, RatingIntentData } from '~/lib/models/rating'
import { useStoreService } from '~/composables/useStoreService'
import {
  PromoProduct,
  Promotion,
  PromotionKind,
  PromotionKindType,
  PromotionType,
} from '~/lib/services/promos/promos.dto'
import { tagEmployedCoupon } from '~/lib/models/gtm'
import { parsePromotionForCart } from '~/utils/promoUtils'
import { autoSelectMandatoryOptionsGroups } from '~/utils/storeUtils'
import { StoreErrorType } from '~/lib/models/errors'
import { ToastType } from '~/lib/models/ui/toast'
import { ProductPromotion } from '~/lib/services/store/common.dto'

const debug = Debug('app:store')
interface State {
  // global
  appVersion: string
  language: string
  countryCode: string
  initialFetchLoaded: boolean
  // mdw
  mdwConfig?: MdwCountry
  staticCategories?: CategoryListMdw[]
  // ecommerce
  categories?: Category[]
  ecommerceConfig?: EcommerceConfig
  // cart
  cart: Cart
  cartCatalogProducts: ProductEcommerce[]
  cartBackendTotals?: CartTotals
  cartBackendAdditional?: CartBackendAdditional
  fetchingCart: boolean
  // checkout
  originalOrderID?: string
  currentOrder?: StoreOrder
  // pickupModal
  pickupMethodModal: boolean
  pickupMethodRefresh: boolean
  // rating
  ratingIntentData?: RatingIntentData
  ratingBackupRecord: Record<string, RateOrderInput>
  // mcdia
  mcDiaCartToastMessage?: string
  // promotions
  appliedPromotions?: Array<Promotion & { kind: PromotionKindType }>
  promotionsEditedByUser: boolean
  // tips
  cartTipOption?: TipOption
  // purchase
  sendPurchaseFront?: boolean
  // crossselling
  productTypeOnDetail: ProductType
  // Error code
  errorCode?: string
}

export const useAppStore = defineStore('app', {
  state: (): State => ({
    appVersion: 'web-2.0.0',
    language: 'es',
    countryCode: 'AR',
    initialFetchLoaded: false,
    mdwConfig: undefined,
    staticCategories: undefined,
    categories: undefined,
    ecommerceConfig: undefined,
    cart: { products: [] },
    cartCatalogProducts: [],
    cartBackendTotals: undefined,
    cartBackendAdditional: undefined,
    fetchingCart: false,
    originalOrderID: undefined,
    currentOrder: undefined,
    pickupMethodModal: false,
    ratingIntentData: undefined,
    ratingBackupRecord: {},
    mcDiaCartToastMessage: undefined,
    appliedPromotions: undefined,
    cartTipOption: undefined,
    // behaviour flags
    pickupMethodRefresh: false,
    promotionsEditedByUser: false,
    sendPurchaseFront: undefined,
    productTypeOnDetail: undefined,
    // Error code
    errorCode: undefined,
  }),
  actions: {
    async fetchSite(domain: string) {
      const contentStore = useContentStore()
      const layoutStore = useLayoutStore()
      const { middlewareServiceRef } = useStoreService()

      layoutStore.getPlatform()

      // load site
      const { language, countryCode, smartbanner } = await contentStore.loadSite(domain)
      this.countryCode = countryCode
      this.language = language
      layoutStore.setSmartbanner(smartbanner)

      // load mdw config (on SSR)
      const middlewareService = middlewareServiceRef()
      // mdwConfig
      this.mdwConfig = await middlewareService.fetchConfig({
        language: this.language,
      })
    },
    async initialFetch() {
      try {
        const restaurantStore = useRestaurantStore()
        const { countryServiceRef, middlewareServiceRef } = useStoreService()
        const middlewareService = middlewareServiceRef()

        // Request this on client if not loaded previously on server
        if (!this.staticCategories || !this.staticCategories.length) {
          // mdw static categories
          const { data: categoriesListMdw } = await middlewareService.fetchStaticCategories()
          this.staticCategories = categoriesListMdw
        }

        const countryService = countryServiceRef()
        // only load if country has ecommerce available
        if (!this.isEcommerceDisabled) {
          this.ecommerceConfig = await countryService.getConfigByCountry({
            countryCode: this.countryCode,
          })
        }

        // has restaurant selected
        if (restaurantStore.selectedRestaurant?.restaurant) {
          await restaurantStore.getRestaurant()
        }
        if (restaurantStore.isRestaurantSelected) {
          // catalog .. etc
          const restaurant = restaurantStore.selectedRestaurant!.restaurant!
          await this.loadCategories(restaurant.id, restaurantStore.areaSelected)
        }
        this.checkCartIntegrity()
        this.initialFetchLoaded = true
      } catch (error: any) {
        debug('Error inside initialFetch > ', error?.response || error)
        throw error
      }
    },
    async loadCategories(
      restaurantId: string,
      area: RestaurantAreaType | undefined = RestaurantAreas.MOP
    ) {
      const { catalogServiceRef } = useStoreService()
      const catalogService = catalogServiceRef()
      try {
        this.categories = await catalogService.getStoreCategories({
          countryCode: this.countryCode,
          restaurant: restaurantId,
          area,
        })
      } catch (error) {
        debug('Error fetching catalog > ', error)
      }
    },
    async addCartProduct(product: CartProduct, catalogProduct: ProductEcommerce) {
      this.addToCartCatalogProducts(catalogProduct)
      this.cart.products.push(product)
      useEvent('cart:add-product')
      // call to /cart
      await this.fetchCart()
    },
    clearCart(trackEvent = false) {
      if (trackEvent) {
        const { trackRemoveFromCart } = useGTM()
        trackRemoveFromCart(this.cart.products)
      }
      this.deleteCartCatalogProducts({ clearAll: true })
      this.cart.products = []
      this.cartBackendTotals = undefined
      // reset promotions
      this.appliedPromotions = undefined
      this.clearPromotedProducts()
      this.promotionsEditedByUser = false
      this.cartTipOption = undefined
    },
    async removeCartProduct(index: number) {
      const identifier = this.cart.products?.[index]?.identifier
      if (identifier) {
        const { trackRemoveFromCart } = useGTM()
        const cartProduct = this.cart.products[index]
        trackRemoveFromCart([cartProduct])
        this.deleteCartCatalogProducts({ identifiers: [identifier] })
        this.cart.products.splice(index, 1)
      }
      if (!this.cart.products.length) this.cartBackendTotals = undefined
      // call to /cart
      await this.fetchCart()
    },
    async editCartProduct(index: number, cartProduct: CartProduct) {
      this.cart.products.splice(index, 1, cartProduct)
      // call to /cart
      await this.fetchCart()
    },
    async updateCartProductUnits(index: number, unit: number) {
      if (this.cart.products?.[index]) {
        this.cart.products[index].unit = unit
        const unitPrice = this.cart.products[index].computedPrice.priceUnit
        this.cart.products[index].computedPrice.price = unitPrice * unit
        // call to /cart
        await this.fetchCart()
      }
    },
    addToCartCatalogProducts(product: ProductEcommerce) {
      const presentInArrayProdIndex = this.cartCatalogProducts.findIndex(
        p => p.identifier === product.identifier
      )
      // add to array
      if (presentInArrayProdIndex === -1) {
        this.cartCatalogProducts.push(product)
      } else {
        // replace product in cartCatalogProducts with new product
        this.cartCatalogProducts.splice(presentInArrayProdIndex, 1, product)
      }
    },
    deleteCartCatalogProducts({
      identifiers = [],
      clearAll = false,
    }: {
      identifiers?: string[]
      clearAll?: boolean
    }) {
      const catalogProducts = this.cartCatalogProducts
      if (clearAll) {
        this.cartCatalogProducts = []
      } else {
        const cartProducts = this.cart.products || []
        for (const prodIdentifier of identifiers) {
          const prodCatalogProductsIndex = catalogProducts.findIndex(
            p => p.identifier === prodIdentifier
          )
          if (prodCatalogProductsIndex !== -1) {
            // check if is present still in cart.products to not delete it!
            const prodCartProductsIndex = cartProducts.findIndex(
              cartP => cartP.identifier === prodIdentifier
            )
            if (prodCartProductsIndex === -1) {
              catalogProducts.splice(prodCatalogProductsIndex, 1)
            }
          }
        }
        this.cartCatalogProducts = catalogProducts
      }
    },
    // update only products that must be on cart
    // products without cartCatalogProducts, or products not on current categories (id of category and id of product changes on import catalog)
    checkCartIntegrity() {
      if (!this.cart.products.length) return
      const validCartProducts = []

      this.clearPromotedProducts()

      for (const cartProd of this.cart.products) {
        try {
          const { identifier: productIdentifier, categoryIdRfm } = cartProd
          // check exists on cartCatalogProducts
          const catalogProduct = this.cartCatalogProducts.find(
            ({ identifier }) => identifier === productIdentifier
          )
          if (catalogProduct) {
            const activeCategories = this.categories?.filter(category => category.active) || []
            let category
            // get category by id (store on current cart product)
            const categoryById = activeCategories.find(({ idRfm }) => idRfm === categoryIdRfm)

            if (!categoryById) {
              // get category finding product inside categories (catalog could have changed cat.id)
              category = activeCategories.find(({ products }) =>
                products.some(({ identifier }) => identifier === productIdentifier)
              )
            } else category = categoryById

            if (category) {
              let productId = cartProd.id
              const categoryProdId = category.idRfm
              const categoryProdTitle = category.title
              // May be posible the product has changed id (not identifier)
              const productNewId = category.products.find(
                p => p.identifier === productIdentifier
              )?.id
              // cartProd.categoryId !== category.id founded
              if (
                productNewId &&
                (categoryIdRfm !== categoryProdId || productId !== productNewId)
              ) {
                // catalog has changed product identifier is the same but id not
                if (productNewId) productId = productNewId
              }
              const cartProduct = {
                ...cartProd,
                id: productId,
                categoryTitle: categoryProdTitle,
                categoryId: categoryProdId,
              }

              validCartProducts.push(cartProduct)
            }
          }
        } catch (error) {
          debug('🚀 ~ file: app.store.ts:200 ~ checkCartIntegrity ~ error:', error)
        }
      }

      this.cart.products = validCartProducts
    },
    async fetchCart() {
      const { handleStoreError } = useStoreResponse()
      const { $toast } = useNuxtApp()

      try {
        if (!this.cart.products.length) return
        const restaurantStore = useRestaurantStore()
        const authStore = useAuthStore()
        const { catalogServiceRef } = useStoreService()
        const catalogService = catalogServiceRef()
        if (!authStore.token || !restaurantStore.restaurant) return
        this.setFetchingCart(true)
        const areaCode = restaurantStore.areaSelected!
        const {
          id: restaurantId,
          delivery: restaurantDelivery,
          inPersonPayment,
        } = restaurantStore.restaurant

        const products = this.cartInputProducts

        const serviceFee = !restaurantStore.getServiceFeeAmount
          ? undefined
          : { serviceFeeAmount: restaurantStore.getServiceFeeAmount }

        const promotionsList = !this.appliedPromotions?.length
          ? undefined
          : this.appliedPromotions.map(promotion => ({
              promotion: parsePromotionForCart(promotion),
              coupon: promotion.code,
              products: promotion.catalogProducts.length ? products.filter(p => p.isPromo) : [], // promotedProducts
            }))

        if (!restaurantStore.isCrew && restaurantStore.isAreaDLV)
          await restaurantStore.setDeliveryAddressDistance()
        const distance = restaurantStore.deliveryAddressDistance

        const body = {
          areaCode,
          restaurantData: {
            id: restaurantId,
            delivery: restaurantDelivery,
            inPersonPayment,
            serviceFee,
          },
          tip: this.cartTipOption,
          promotionsList,
          products: products.filter(p => !p.isPromo), // regularProducts
          isCreateOrder: false,
          distance: restaurantStore.isAreaDLV ? distance : undefined,
        }

        const backendTotals = await catalogService.fetchCart({
          countryCode: this.countryCode,
          token: authStore.token,
          body,
        })
        this.setBackendTotals(backendTotals)
      } catch (error) {
        debug('🚀 ~ file: app.store.ts:118 ~ fetchCart ~ error:', error)
        const { key } = handleStoreError(error)
        // error 636 on /cart
        if (key === StoreErrorType.status636) {
          const content = '{shoppingCart.toast.orderMcdia}'
          $toast.show({
            type: ToastType.ERROR,
            content,
          })
          if (this.appliedPromotions?.length) await this.setPromotion({ promo: undefined })
        }
        this.errorCode = key
      } finally {
        this.setFetchingCart(false)
      }
    },
    setBackendTotals(backendTotals: CartDTO) {
      const productsToOverrideCart = []
      // loop products on response
      for (const prod of backendTotals.products) {
        try {
          const {
            id,
            unit,
            optionsGroups,
            isPromo,
            isPromoApplied,
            price,
            priceWithExtras,
            discount,
            discountByUnit,
            priceWithDiscount,
            priceWithDiscountByUnit,
            productType,
            promotions,
            limitReached,
          } = prod // id from response.products is actually identifier!

          // get category on appStore.categories {id, title}
          const category = this.categories
            ?.filter(category => category.active)
            .find(({ products }) => products.some(({ identifier }) => identifier === id))

          // get ProductEcommerce from cartCatalogProducts
          const catalogProduct = this.cartCatalogProducts.find(
            ({ identifier }) => identifier === id
          )

          if (category && catalogProduct) {
            // get price for product
            const computedPrice = {
              price,
              priceUnit: priceWithExtras,
              discount,
              discountByUnit,
              priceWithDiscount,
              priceWithDiscountByUnit,
            }
            // get customization
            const customization = getCartCustomization(
              blendOptionsGroups(optionsGroups, catalogProduct.optionsGroups)
            )

            const cartProduct: CartProduct = {
              id: catalogProduct.id,
              identifier: id,
              name: catalogProduct.name,
              image: catalogProduct.imageUrl,
              optionsGroups,
              unit,
              computedPrice,
              customization,
              categoryIdRfm: category.idRfm,
              categoryTitle: category.title,
              isMcDia: !!catalogProduct.mcDia,
              // promotions
              isPromo: !!isPromo,
              isPromoApplied: !!isPromoApplied,
              productType,
              promotions,
              limitReached,
            }

            productsToOverrideCart.push(cartProduct)
          }
        } catch (error) {
          debug('🚀 ~ file: app.store.ts:191 ~ setBackendTotals ~ error:', error)
        }
      }

      // then set backendTotals
      const cartBackendTotals: CartTotals = {
        total: backendTotals.total,
        subtotal: backendTotals.subtotal,
        tax: backendTotals.extras.tax,
        shipping: backendTotals.extras.delivery || 0,
        serviceFee: backendTotals.extras.serviceFee || 0,
        tip: backendTotals.extras.tip,
        discount: backendTotals.discount,
      }
      this.cart.products = productsToOverrideCart
      this.cartBackendTotals = cartBackendTotals
      const inPersonPaymentAvailable =
        !!backendTotals.additionalData.paymentMethodsAvailable?.inPerson
      this.cartBackendAdditional = {
        inPersonPayment: inPersonPaymentAvailable,
        isFreteFree: !!backendTotals.additionalData.isFreteFree,
        exclusivePayment: backendTotals.exclusivePayment,
        anyPromotionApplies: backendTotals.additionalData.anyPromotionApplies ?? true,
      }
    },
    setCurrentOrder(order?: StoreOrder) {
      this.currentOrder = order
    },
    async repeatOrder(repeatOrderResponse: RepeatOrderDto) {
      try {
        const { originalOrderID } = repeatOrderResponse
        // set originalOrderID
        this.originalOrderID = originalOrderID
        // TODO: track repeat_order_ok
        const restaurantStore = useRestaurantStore()
        const selectedArea = restaurantStore.areaSelected
        let mayReloadCatalog = false

        // check area
        if (!selectedArea || selectedArea !== repeatOrderResponse.area) {
          debug('RepeatProcess: Going to override area')
          restaurantStore.setArea({
            area: repeatOrderResponse.area as RestaurantAreaType,
            updateGoogleMaps: true,
          })
          mayReloadCatalog = true
        }
        // check restaurant
        if (
          !restaurantStore.selectedRestaurant?.restaurant ||
          restaurantStore.selectedRestaurant?.restaurant?.id !== repeatOrderResponse.restaurant.id
        ) {
          debug('RepeatProcess: Going to override restaurant')
          const restaurant = repeatOrderResponse.restaurant as unknown as Restaurant
          restaurantStore.setRestaurant(restaurant)
          await restaurantStore.getRestaurantByCode({
            code: restaurant.code,
            area: restaurantStore.areaSelected!,
          })
          mayReloadCatalog = true
        }
        // set deliveryAddress
        if (
          restaurantStore.areaSelected === RestaurantAreas.DLV &&
          repeatOrderResponse.deliveryPlace
        ) {
          debug('RepeatProcess: Going to override delivery address')
          restaurantStore.setAddress(repeatOrderResponse.deliveryPlace)
        }
        // reload catalog
        if (mayReloadCatalog) {
          debug('RepeatProcess: Going to refresh catalog')
          const restaurant = restaurantStore.selectedRestaurant!.restaurant!
          await this.loadCategories(restaurant.id, restaurantStore.areaSelected)
          debug('RepeatProcess: Refreshed catalog')
        }

        // add products to cart from repeat
        this.addProductsToCartFromRepeat({
          products: repeatOrderResponse.products,
          catalogProducts: repeatOrderResponse.catalogProduct,
        })
        // fetchCart
        await this.fetchCart()

        return true
      } catch (error) {
        debug('file: app.store.ts ~ repeatOrder ~ error:', error)
        return false
      }
    },
    addProductsToCartFromRepeat({
      products,
      catalogProducts,
    }: {
      products: RepeatOrderProduct[]
      catalogProducts: ProductEcommerce[]
    }) {
      this.deleteCartCatalogProducts({ identifiers: [], clearAll: true })

      const prodsToCart: Array<CartProduct> = []

      for (const p of products) {
        const { identifier } = p

        // get category on appStore.categories {id, title}
        const category = this.categories
          ?.filter(category => category.active)
          .find(({ products }) => products.some(prod => prod.identifier === identifier))

        // get ProductEcommerce from cartCatalogProducts
        const catalogProduct = catalogProducts.find(catProd => catProd.identifier === identifier)

        if (category && catalogProduct) {
          // get price for product
          const computedPrice = {
            price: p.price,
            priceUnit: p.price,
          }
          // get customization
          const customization = getCartCustomization(
            blendOptionsGroups(p.optionsGroups, catalogProduct.optionsGroups)
          )

          const cartProduct: CartProduct = {
            id: catalogProduct.id,
            identifier,
            name: catalogProduct.name,
            image: catalogProduct.imageUrl,
            optionsGroups: p.optionsGroups,
            unit: p.unit,
            computedPrice,
            customization,
            categoryIdRfm: category.idRfm,
            categoryTitle: category.title,
            isMcDia: !!catalogProduct.mcDia,
            // promotions
            isPromo: false,
            isPromoApplied: false,
            productType: ProductTypes.REGULAR,
            promotions: catalogProduct.promotions,
          }

          this.addToCartCatalogProducts(catalogProduct)
          prodsToCart.push(cartProduct)
        }
      }

      this.cart.products = prodsToCart
    },
    setRatingIntentData(ratingIntentData: RatingIntentData | undefined) {
      if (!ratingIntentData) {
        this.ratingIntentData = undefined
        return
      }
      const authStore = useAuthStore()
      if (!authStore.isUserLogged) return
      if (!([RestaurantAreas.MOP, RestaurantAreas.DLV] as string[]).includes(ratingIntentData.type))
        return
      this.ratingIntentData = ratingIntentData
    },
    setRatingBackupForOrder(orderId: string, ratingInput?: RateOrderInput) {
      if (!ratingInput) {
        delete this.ratingBackupRecord[orderId]
        return
      }
      this.ratingBackupRecord[orderId] = ratingInput
    },
    setMcDiaCartToastMessage(message: string | undefined) {
      this.mcDiaCartToastMessage = message
    },
    async setPromotion({
      promo,
      kind = PromotionKind.COUPON,
      promotionIdToRemove,
    }: {
      promo?: Promotion
      kind?: PromotionKindType
      promotionIdToRemove?: string
    }) {
      if (!promo) {
        this.clearPromotedProducts({ trackEvent: true })
        // Delete promotion by id
        if (promotionIdToRemove && this.appliedPromotions?.length) {
          const promoPos = this.appliedPromotions.findIndex(
            promo => promo.promotionId === promotionIdToRemove
          )
          this.appliedPromotions.splice(promoPos, 1)
          if (this.cartBackendAdditional && this.appliedPromotions.length === 0) {
            this.cartBackendAdditional.anyPromotionApplies = true
          }
        } else this.appliedPromotions = undefined
      } else {
        if (promo.catalogProducts?.length) {
          const { products, catalogProducts, code } = promo
          // For analytics event
          const coupon = code || tagEmployedCoupon
          this.addProductsToCartFromPromotion({ products: products || [], catalogProducts, coupon })
        }
        // push promos to array
        const promotion = { ...promo, kind }
        if (!this.appliedPromotions) this.appliedPromotions = [promotion]
        else this.appliedPromotions.push(promotion)
      }
      await this.fetchCart()
    },
    clearPromotedProducts(behaviour: { trackEvent: boolean } | undefined = { trackEvent: false }) {
      if (this.cart.products.length) {
        const { trackRemoveFromCart } = useGTM()
        const cartProducts = this.cart.products
        const notPromotedProducts = cartProducts.filter(p => !p.isPromo)

        const { trackEvent } = behaviour
        const promotedCartProducts = cartProducts.filter(p => p.isPromo)

        this.cart.products = notPromotedProducts
        if (promotedCartProducts.length) {
          if (trackEvent) {
            trackRemoveFromCart(promotedCartProducts)
          }
          this.deleteCartCatalogProducts({
            identifiers: promotedCartProducts.map(p => p.identifier),
          })
        }
      }
    },
    addProductsToCartFromPromotion({
      products,
      catalogProducts,
      coupon,
    }: {
      products: PromoProduct[]
      catalogProducts: ProductEcommerce[]
      coupon: string
    }) {
      const prodsToCart: Array<CartProduct> = []

      for (const p of products) {
        const { id: identifier } = p

        // get category on appStore.categories {id, title}
        const category = this.categories
          ?.filter(category => category.active)
          .find(({ products }) => products.some(prod => prod.identifier === identifier))

        // get ProductEcommerce from cartCatalogProducts
        const catalogProduct = catalogProducts.find(catProd => catProd.identifier === identifier)

        if (category && catalogProduct) {
          const basePrice = catalogProduct.price.amount || catalogProduct.unifiedPrice?.amount || 0
          // get price for product
          const computedPrice = {
            price: basePrice * p.qty,
            priceUnit: basePrice,
          }

          // Handle 1º and 3º level mandatory optionsGroups
          const optsGroupsDetailMandatorySelected = autoSelectMandatoryOptionsGroups(
            catalogProduct.optionsGroups
          )
          // get customization
          const customization = getCartCustomization(optsGroupsDetailMandatorySelected)
          const { optionsGroups } = productToDTO(
            { ...catalogProduct, optionsGroups: optsGroupsDetailMandatorySelected },
            0
          )

          const cartProduct: CartProduct = {
            id: catalogProduct.id,
            identifier,
            name: catalogProduct.name,
            image: catalogProduct.imageUrl,
            optionsGroups,
            unit: p.qty,
            computedPrice,
            customization,
            categoryIdRfm: category.idRfm,
            categoryTitle: category.title,
            isMcDia: !!catalogProduct.mcDia,
            // promotions
            isPromo: true,
            isPromoApplied: false,
            promotions: catalogProduct.promotions,
            productType: ProductTypes.REGULAR,
          }

          this.addToCartCatalogProducts(catalogProduct)
          prodsToCart.push(cartProduct)
        }
      }
      const { trackAddToCart } = useGTM()
      this.cart.products = this.cart.products.concat(prodsToCart)
      if (prodsToCart.length) trackAddToCart(prodsToCart, coupon)
    },
    async setCartTips(tipOption?: TipOption) {
      this.cartTipOption = tipOption
      // TODO: Track gtm event add_tip
      await this.fetchCart()
    },
    setFetchingCart(val: boolean) {
      this.fetchingCart = val
    },
    setSendPurchaseFront(val?: boolean) {
      this.sendPurchaseFront = val
    },
    setProductTypeOnDetail(type: ProductType) {
      this.productTypeOnDetail = type
    },
    disableAllProductPromotions() {
      for (const product of this.cart.products) {
        if (!product.promotions) return
        for (const promo of product.promotions) {
          if (promo.type !== PromotionType.SHIPPING_PERCENTAGE) {
            promo.active = false
          }
        }
      }
    },
    removePromoById(promotionId: string) {
      this.appliedPromotions = this.appliedPromotions?.filter(
        promo => promo.promotionId !== promotionId
      )
    },
    clearErrorCode() {
      this.errorCode = undefined
    },
  },
  getters: {
    currencyConfig(): CurrencyConfig | undefined {
      if (this.isEcommerceDisabled || !this.ecommerceConfig) return undefined
      const { currencyAcronym, currencySymbol, allowDecimals, decimalsQty, decimalBehaviour } =
        this.ecommerceConfig
      return {
        countryCode: this.countryCode!,
        language: this.language,
        currencyAcronym,
        currencySymbol,
        allowDecimals,
        decimalsQty,
        decimalBehaviour,
      }
    },
    /* cart totals */
    cartSubTotal(): number {
      if (!this.cart.products.length) return 0
      if (this.cartBackendTotals) return this.cartBackendTotals.subtotal
      return this.cart.products.reduce((acc, curr) => {
        acc += curr.computedPrice.price
        return acc
      }, 0)
    },
    cartTotal(): number {
      if (!this.cart.products.length) return 0
      if (this.cartBackendTotals) return this.cartBackendTotals.total
      return this.cartSubTotal + this.cartTax + this.cartTip + this.cartShipping
    },
    cartTax(): number {
      if (!this.cart.products.length) return 0
      if (this.cartBackendTotals) return this.cartBackendTotals.tax
      return 0
    },
    cartTip(): number {
      if (!this.cart.products.length) return 0
      if (this.cartBackendTotals) return this.cartBackendTotals.tip
      return 0
    },
    cartDiscount(): number {
      if (!this.cart.products.length) return 0
      if (this.cartBackendTotals) return this.cartBackendTotals.discount
      return 0
    },
    cartShipping(): number {
      if (!this.cart.products.length) return 0
      if (this.cartBackendTotals) return this.cartBackendTotals.shipping
      const restaurantStore = useRestaurantStore()
      if (!restaurantStore.isRestaurantSelected) return 0
      if (restaurantStore.areaSelected !== RestaurantAreas.DLV) return 0
      const restaurantDelivery = restaurantStore.restaurant!.delivery
      const deliveryFee = restaurantDelivery?.deliveryFee
      if (!deliveryFee || !this.cartSubTotal) return 0
      const deliveryFreeAmount = restaurantDelivery?.deliveryFreeAmount
      if (!deliveryFreeAmount) return deliveryFee || 0
      return this.cartSubTotal < deliveryFreeAmount ? deliveryFee : 0
    },
    cartServiceFee(): number {
      if (!this.cart.products.length) return 0
      if (this.cartBackendTotals) return this.cartBackendTotals.serviceFee
      return 0
    },
    cartTotals(): CartTotals {
      return {
        total: this.cartTotal,
        subtotal: this.cartSubTotal,
        tax: this.cartTax,
        tip: this.cartTip,
        shipping: this.cartShipping,
        serviceFee: this.cartServiceFee,
        discount: this.cartDiscount,
      }
    },
    cartInputProducts(): CartInputProductDTO[] {
      const cartInputProducts: CartInputProductDTO[] = []
      if (!this.cart.products.length) return cartInputProducts
      for (const cartProd of this.cart.products) {
        const catalogProduct = this.cartCatalogProducts.find(
          ({ identifier }) => identifier === cartProd.identifier
        )
        if (catalogProduct) {
          const {
            id,
            identifier,
            optionsGroups,
            unit,
            isPromo,
            isPromoApplied,
            productType,
            promotions,
          } = cartProd
          if (catalogProduct.id !== id) catalogProduct.id = id
          const cartProductDto: CartInputProductDTO = {
            id,
            identifier,
            unit,
            optionsGroups,
            catalogProduct: parseProductToProductDB(catalogProduct),
            // promotions
            isPromo: isPromo || undefined,
            isPromoApplied: isPromoApplied || undefined,
            promotions,
            productType,
          }
          cartInputProducts.push(cartProductDto)
        }
      }
      return cartInputProducts
    },
    // computed flags
    phoneValidationActive: state => {
      if (!state.mdwConfig || !state.mdwConfig.configuration) return false
      return !!state.mdwConfig.configuration?.phoneValidationActive
    },
    validationPhoneChange: state => {
      if (!state.mdwConfig || !state.mdwConfig.configuration) return false
      return !!state.mdwConfig.configuration?.validationPhoneChange
    },
    phoneValidationData: state => {
      if (!state.mdwConfig) return undefined
      let regex, mask
      if (state.mdwConfig.maskConfig) {
        const fieldPhone = state.mdwConfig.maskConfig.find(
          ({ fieldName }) => fieldName === MaskConfigFieldName.PHONE_SUFFIX
        )
        if (fieldPhone && fieldPhone.types.length) {
          const typePhone = fieldPhone.types[0]
          mask = typePhone.mask
          regex = typePhone.regex
        }
      }

      return {
        mask,
        regex: regex ?? '^\\d{8,13}$',
      }
    },
    documentValidationTypes: state => {
      if (!state.mdwConfig) return undefined
      let types: MaskConfigType[] = []
      if (state.mdwConfig.maskConfig) {
        const fieldDocument = state.mdwConfig.maskConfig.find(
          ({ fieldName }) => fieldName === MaskConfigFieldName.DOCUMENT
        )
        if (fieldDocument && fieldDocument.types.length) {
          types = fieldDocument.types
        }
      }
      return types.length ? types : undefined
    },
    formattedMinAmount(): string {
      try {
        if (
          this.currentOrder ||
          !(
            this.ecommerceConfig?.activeFiscalFields &&
            this.ecommerceConfig?.dynamicFiscalFields.length
          )
        )
          throw new Error('No fiscal active')
        const area = useRestaurantStore().areaSelected!.toLowerCase()
        const minInvoice = this.ecommerceConfig?.minInvoice || []
        const areaMinInvoice = minInvoice.find(
          (minInv: { area: string; minInvoiceAmount: number }) => minInv.area === area
        )
        if (!areaMinInvoice) throw new Error('No areaMinInvoice in minInvoice')
        return formatAmount(areaMinInvoice.minInvoiceAmount, this.currencyConfig)
      } catch (error) {
        // return always a string
        return formatAmount(0, this.currencyConfig)
      }
    },
    enrollmentScreens(): Array<any> {
      return this.mdwConfig?.enrollment?.screens ?? []
    },
    enrollmentDynamicFields(): Array<any> {
      return this.mdwConfig?.dynamicFields ?? []
    },
    hasLoyaltyActive(): boolean {
      return this.ecommerceConfig?.loyalty ?? false
    },
    hasAutoLoyaltyActive(): boolean {
      return this.mdwConfig?.enrollment?.flows?.enrollAutoLoyalty ?? false
    },
    hasEnrollPurchase(): boolean {
      return this.mdwConfig?.enrollment?.flows?.enrollPurchase ?? false
    },
    loyaltyRequiredFields(): Array<any> {
      const screens = this.enrollmentScreens
      const loyaltyFields = screens.map(screen => screen.fields).flat()
      const requiredFields = loyaltyFields.filter(value => {
        const currentValue = this.enrollmentDynamicFields.find(field => field.code === value)
        return currentValue && currentValue.required
      })
      return requiredFields ?? []
    },
    isEcommerceDisabled(): boolean {
      return (this.mdwConfig?.dlvActive === false && this.mdwConfig?.mopActive === false) ?? false
    },
    getAutoApplyPromo(): ProductPromotion | undefined {
      for (const product of this.cart.products) {
        if (!product.promotions) return
        for (const promo of product.promotions) {
          if (promo.type !== PromotionType.SHIPPING_PERCENTAGE && promo.active) {
            return promo
          }
        }
      }
    },
    getErrorCode(): string | undefined {
      return this.errorCode !== '' ? this.errorCode : undefined
    },
    canShowSignInAndSecurity(): boolean {
      return !!this.mdwConfig?.configuration?.showDevicesList
    },
  },
  persist: [
    {
      paths: ['cart', 'cartCatalogProducts', 'ratingBackupRecord'],
      storage: persistedState.localStorage,
    },
  ],
})
