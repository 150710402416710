export const useTranslation = () => {
  const { t } = useLocale()

  const translateInterpolated = (s: string) => {
    const regex = /[{][a-zA-Z.]+[}]/gm
    const matches = s.match(regex)
    if (!matches?.length) return s

    let translated = s
    matches.forEach(match => {
      const translationKey = match.replace('{', '').replace('}', '')
      translated = translated.replaceAll(match, t(translationKey))
    })
    return translated
  }

  return {
    translateInterpolated,
  }
}
