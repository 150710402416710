export const publicConfig = {
  debug: {
    enabled: false,
    requests: true,
    overridableDomain: false,
  },
  services: {
    content: {
      url: "https://backend-mcd-landings-l.appmcdonalds.com/api",
      imageUrl: "https://backend-mcd-landings-l.appmcdonalds.com",
    },
    sso: {
      url: "https://accounts.mcdonalds.com.pe",
    },
    im: {
      url: "https://api-im.mcdonaldscupones.com",
    },
    store: {
      url: "https://api-mcd-ecommerce-{countryCode}.appmcdonalds.com",
      promos: {
        url: "https://promos-mcd-ecommerce.appmcdonalds.com",
      },
      loyalty: {
        url: "https://loyalty-mcd-ecommerce.appmcdonalds.com"
      }
    },
    middleware: {
      url: "https://cache-mcd-middleware.mcdonaldscupones.com",  
    },
    lupap: {
      url: 'https://api.lupap.co/v2',
    },
  },
  sdk: {
    yuno: {
      publicApiKey:
        "prod_gAAAAABjLdm2iCppDcbORiD3-fBYvJvbZY49rMvKWrXCs1XEuA6334jM-vSlR3Mc5zkL1kcIj9-cgcY5h7Key7DtXPe9fRpP5viIUQXRLlRv5OBEAGDDq-iz6dSVvtlVtKFT7Ue9KLcJnxPiLXQspjYFu5GOH74fquHYW4tViUkSLyyYO-BEneQ=",
    },
    datadogRum: {
      enabled: true,
      ddApplicationId: '51bcc0c1-8bd6-40e6-9408-de1e90089756',
      ddApplicationToken: 'pubd9a633d930f436399e5e092d217da6be',
    },
  },
  gtm: {
    containerId: "GTM-M3L33JX",
  },
  recaptcha: {
    enabled: true,
    siteKey: "6Le-fcooAAAAALvuSlm1MBl_XlO7Bn-F6wCmZPJ8",
  },
};
