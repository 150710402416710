import termsFR from './markdown/vouchers/termsFR.md?raw'

export default {
  routes: {
    restaurants: 'restaurants',
    ecommerceHome: 'commandes',
    cart: 'panier',
    staticMenu: {
      linkTitle: 'Produits',
      slug: 'menu',
    },
    checkout: 'checkout',
    profile: 'mon-compte',
    orders: 'mes-commandes',
    signInSecurity: 'connexion-et-securite',
    myDevices: 'mes-appareils',
    personalData: 'données-personnelles',
    downloadApp: 'telechargez-notre-appli',
  },
  auth: {
    login: 'Connexion',
    register: "S'inscrire",
    logOnTo: 'Entrer',
  },
  common: {
    seeAll: 'Voir tous',
    priceFrom: 'À partir ',
    seeMore: 'Voir plus',
    and: 'et',
    continue: 'Continuer',
    return: 'Retourner',
    accept: 'Accepter',
    MOP: 'PickUp',
    DLV: 'McDelivery',
    seeDetail: 'Voir les détails',
    startOrder: 'Commencer la commande',
    start: 'Sélectionner',
    mon: 'Lundi',
    tue: 'Mardi',
    wed: 'Mercredi',
    thu: 'Jeudi',
    fri: 'Vendredi',
    sat: 'Samedi',
    sun: 'Dimanche',
    areas: {
      curb: 'Sur le parking',
      aut: 'AutoMac',
      ealm: 'À table',
      mop: 'Au comptoir',
      dlv: 'McDelivery',
    },
    time: {
      hour: 'heure',
      hours: 'heures',
      minute: 'minute',
      minutes: 'minutes',
      second: 'seconde',
      seconds: 'secondes',
    },
    toast: {
      clipboard: 'Copié dans le presse-papier',
    },
    o: 'ou',
    freeShippingLabel: 'Livraison gratuite',
    free: 'Gratuit',
    offer: 'Offre',
  },
  home: {
    title: 'Accueil',
    products: 'Produits',
    categories: 'Catégories',
    search: 'Trouver une adresse',
    searchButton: 'Démarrer',
    searchTitle: "Comment voulez-vous votre commande aujourd'hui?",
    searchSubtitle:
      "Sélectionnez {pickup} si vous venez chercher votre commande ou McDelivery si vous préférez qu'elle soit expédiée à une adresse",
    searchLocateMe: 'Utiliser ma position actuelle',
    smartcards: {
      multiple: {
        title: 'Vous avez plusieurs commandes en cours',
        secondary: 'Voir les commandes',
      },
      pending: {
        title: 'Commande en attente de paiement',
        secondaire: 'Voir les détails',
        primary: 'Payer',
      },
      pendingPaymentCounter: {
        title: 'Payer au comptoir',
        secondary: 'Voir les détails',
        primary: 'Arrivé',
      },
      afterPayment: {
        pretitle: "Aller chez McDonald's",
        title: 'Commande en cours',
        secondary: 'Voir les détails',
        primary: 'Arrivé',
      },
      advanceSale3days: {
        title: 'Le jour de votre livraison arrivera bientôt',
        secondary: 'En savoir plus',
      },
      advanceSaleInDate: {
        title: 'Commande prête à être livrée',
        secondary: 'En savoir plus',
        primary: 'Recevoir ma commande',
      },
      accepted: {
        title: 'Votre commande a été acceptée',
        secondary: 'Voir les détails',
      },
      acceptedPaymentCounter: {
        title: 'Payer au comptoir',
        secondary: 'Voir les détails',
      },
      preparing: {
        title: 'Commande en cours de préparation',
        secondary: 'Voir les détails',
      },
      delivering: {
        title: 'Votre commande est en route',
        secondary: 'Voir les détails',
        primary: 'Suivre ma commande',
      },
      ready: {
        title: 'La commande est prête à être livrée',
        secondary: 'Voir les détails',
      },
      delivered: {
        title: 'Votre commande a été livrée',
        secondary: 'Voir les détails',
        primary: 'Répéter la commande',
      },
    },
  },
  sidebar: {
    title: {
      normal: 'Profitez de la',
      bold: 'meilleure expérience',
    },
  },
  onboarding: {
    next: 'Continuer',
    stepRestaurant: {
      title: 'Choisissez comme vous le préférez!',
      description:
        "<ul><li><b>McDelivery</b> commande et reçoit n'importe oú</li><li><b>{pickup}</b> trouvez un restaurant pour récupérer votre commande</li></ul>",
    },
    stepRestaurantNoDlv: {
      title: 'Choisissez comme vous le préférez!',
      description:
        '<ul><li><b>{pickup}</b> trouvez un restaurant pour récupérer votre commande</li></ul>',
    },
    stepMOP: {
      title: '{pickup}',
      description: 'Sélectionnez {pickup} si vous venez chercher votre commande',
    },
    stepDLV: {
      title: 'McDelivery',
      description: "Sélectionnez McDelivery si vous préférez qu'elle soit expédiée à une adresse",
    },
    stepCart: {
      title: "Consultez votre commande à partir d'ici",
      description: 'Trouvez tous les produits que vous avez sélectionnés et finalisez votre achat',
    },
    stepAccount: {
      title: 'Découvre "Mon compte"',
      description:
        "Complète ton profil. Vérifie tes préférences et assure-toi d'avoir une expérience unique!",
    },
    stepQR: {
      title: 'Rejoignez McID',
      description: "Scannez le code QR pour vous identifier rapidement et rejoindre McDonald's",
    },
  },
  dataOnboarding: {
    emailValidated: 'E-mail validé avec succès',
    succeeded: 'Inscription réussie',
  },
  profile: {
    title: 'Mon compte',
    account: 'Compte',
    downloadApp: 'Téléchargez Appli',
    help: 'Aide',
    sections: {
      personalData: 'Données personnelles',
      fav: 'Favoris',
      addressSaved: 'Adresses enregistrées',
      favRestaurants: 'Restaurants favoris',
      favProducts: 'Produits favoris',
      historyOrders: 'Historique des commandes',
      orderDetail: 'Détail de la commande',
      paymentMethods: 'Moyens de paiement',
      signInSecurity: 'Connexion et Sécurité',
      manageDevices: 'Gérer les appareils',
      billData: 'Informations de facturation',
      notification: 'Notifications',
      config: 'Configuration',
      logout: 'Se déconnecter',
    },
    notLogged: {
      title: 'Nous vous souhaitons la bienvenue à <b>McDonald’s</b>',
      subtitle:
        "Connectez-vous pour profiter de nos produits, de nos avantages et de bien d'autres choses encore.",
    },
    subSections: {
      personalData: {
        fullName: 'Nom et prénom',
        email: 'Adresse électronique',
        phone: 'Téléphone',
        password: 'Mot de passe',
        birthday: 'Date de naissance',
        identityCard: 'Carte d’identité',
        dataNotProvide: 'Données non fournies',
        formHeaders: {
          fullName: {
            title: 'Nom et prénom',
            subtitle: "Comment voulez-vous que l'on vous appelle ?",
            button: 'Continuer',
          },
          phone: {
            title: 'Numéro de téléphone',
            subtitle:
              'Afin que vous soyez le seul à avoir accès à votre compte, veuillez saisir votre numéro de téléphone.',
            button: 'Sauver',
            buttonAlt: 'Validez et enregistrez',
            verified: 'Téléphone validé',
            notVerified: 'Téléphone non validé',
          },
          birthday: {
            title: 'Date de naissance',
            subtitle:
              'Veuillez saisir votre date de naissance. De cette manière, nous pouvons garantir la sécurité de vos informations.',
            button: 'Continuer',
          },
          identityCard: {
            title: "Carte d'identité",
            subtitle:
              "Veuillez saisir votre numéro d'identité. De cette manière, nous pouvons garantir la sécurité de vos informations.",
            button: 'Continuer',
          },
        },
      },
      manageDevices: {
        text: "Vous avez accédé à votre compte depuis ces appareils. Si vous n'en reconnaissez pas un, vous pouvez fermer la session ou changer les données d'accès",
        thisDevice: 'Sur cet appareil',
        logout: 'Se déconnecter',
        logoutAll: 'Fermer toutes les sessions',
        closeModals: {
          title: 'Confirmez-vous vouloir fermer cette session ?',
          time: '{name} - {appName}{location}, {timeAgo}',
          btnPrimary: 'Se déconnecter',
          btnSecondary: 'Annuler',
        },
        closeAllModals: {
          title: 'Confirmez-vous vouloir fermer toutes les sessions ouvertes?',
          body: 'Toutes les sessions seront fermées, sauf celle sur cet appareil',
          btnPrimary: 'Fermer les sessions',
          btnSecondary: 'Annuler',
        },
        toast: {
          success: 'Session fermée avec succès',
          successAll: 'Sessions fermées avec succès',
          error: 'Il y a eu une erreur. Veuillez réessayer',
        },
        errorModal: {
          title: "Une erreur s'est produite lors de la tentative de suppression de cet appareil",
          titleAll:
            "Une erreur s'est produite lors de la tentative de suppression de tous les appareils",
          body: 'Vérifiez votre connexion et réessayez',
          bodyRetry: 'Vous pouvez réessayer ou annuler',
          button: 'Réessayer',
        },
      },
    },
    VError: {
      required: 'Champ obligatoire',
      customPattern: "Le champ n'est pas correctement formaté",
      strongPassword:
        'Le mot de passe doit contenir au minimum 8 caractères, 1 lettre majuscule, 1 chiffre et 1 caractère spécial.',
      passwordMatch: 'Les mots de passe doivent correspondre.',
      email: 'Adresse email invalide',
      nameValidation:
        'Nom non valide. Un nom valide ne contient que des lettres, des tirets (-) et des espaces.',
      ageValidation: "Vous devez avoir au moins {minAge} ans pour utiliser l'application.",
      minLength: 'Le champ ne respecte pas le nombre minimal de caractères',
      maxLength: 'Le champ ne respecte pas le nombre maximal de caractères',
      cpfValidation: "Le document n'est pas correctement formaté",
      cpfVerified: 'Numéro déjà enregistré',
      phoneValidation: "Le numéro n'est pas au bon format",
      invalidAge: "Vous ne répondez pas à l'âge requis",
      duplicatePhone: 'Téléphone déjà enregistré',
    },
    inputs: {
      dropdownPlaceholder: 'Choisir un',
      documentNumberPlaceholder: 'Numéro de document',
      phoneNumberPlaceholder: 'Numéro de téléphone',
      labelName: 'Nom',
      labelLastName: 'Prénom',
      labelPrefix: 'Préfixe',
      labelSufix: 'Numéro',
      labelCpfType: 'Type de document',
      labelCpf: 'Document',
    },
    tooltipProfile: {
      greeting: 'Bonjour',
    },
  },
  productDetail: {
    customization: {
      title: 'Personnalisez votre produit',
      customizingTitle: 'Comment voulez-vous personnaliser?',
      extra: 'Supplémentaire:',
      actions: {
        save: 'Sauver',
        customize: 'Personnaliser',
        select: 'Sélectionner',
        change: 'Changer',
        add: 'Ajouter',
      },
      chooseOne: 'Choisissez-en un ',
      mandatory: '(Obligatoire)',
    },
    sizes: {
      title: 'Sélectionnez une taille',
      types: {
        SMALL: {
          letter: 'P',
          title: 'Petit',
        },
        MEDIUM: {
          letter: 'M',
          title: 'Moyen',
        },
        LARGE: {
          letter: 'G',
          title: 'Grand',
        },
      },
    },
    actions: {
      payNow: 'Payez maintenant',
      addToCart: 'Ajouter au panier',
      save: 'Sauver',
    },
    alerts: {
      outdaypart: "Ce produit n'est pas disponible pour le moment",
      mcDia: {
        expired: {
          title: 'Produit Gran Día périmé',
          content: "Ce produit n'est plus disponible",
        },
      },
      notAvailableAreas: {
        title: "La zone de livraison n'est pas disponible : {value}",
        titleMultiple: 'Les zones de livraison ne sont pas disponibles: {value}',
      },
      mismatching: {
        NO_RESTAURANT_SELECTED: "Vous n'avez pas de restaurant sélectionné",
        MISMATCHING_AREA: {
          MOP: 'Ce produit est uniquement disponible pour un enlèvement local',
          DLV: 'Ce produit est uniquement disponible pour une livraison à domicile',
        },
        MISMATCHING_RESTAURANT: "Ce produit n'est pas disponible dans votre restaurant",
        MISMATCHING_AREA_RESTAURANT: "Ce produit n'est pas disponible dans votre restaurant",
      },
    },
    mismatching: {
      alerts: {
        NO_RESTAURANT_SELECTED: {
          title: "Vous n'avez pas de restaurant sélectionné",
          body: 'Ce produit est associé au restaurant {restaurantName}',
          accept: 'Continuer',
          cancel: 'Sélectionnez un autre restaurant',
        },
        MISMATCHING_AREA: {
          titleMOP: "Ce produit n'est disponible que pour un enlèvement local",
          titleDLV: 'Ce produit est uniquement disponible pour une livraison à domicile',
          body: '',
          accept: 'Sélectionnez un autre restaurant',
          cancel: '',
        },
        MISMATCHING_RESTAURANT: {
          title: "Ce produit n'est pas disponible dans votre restaurant",
          body: '',
          accept: 'Rechercher dans mon restaurant',
          cancel: 'Sélectionner un autre restaurant',
        },
        MISMATCHING_AREA_RESTAURANT: {
          title: "Ce produit n'est pas disponible dans votre restaurant",
          body: '',
          accept: 'Continuer les achats',
          cancel: 'Sélectionnez un autre restaurant',
        },
        notFoundProductOnCatalog: {
          title: "Ce produit n'a pas été trouvé dans votre restaurant",
          body: '',
          accept: 'Voir le catalogue',
        },
      },
    },
  },
  productDetailStatic: {
    menu: 'Menu',
    moreInfo: "Plus d'information",
    nutritional: {
      title: 'Informations nutritionnelles',
      type: 'Informations nutritionnelles',
      byProduct: 'Par produit',
      idr: 'A.Q.R',
      empty: "Ce produit n'a pas d'informations nutritionnelles",
      items: {
        weight: 'Poids',
        calories: 'Calories (Kcal)',
        sugarTotals: 'Sucres totaux',
        sugarAdded: 'Sucres ajoutés',
        lipids: 'Lipides',
        saturatedFats: 'Graisses saturées',
        transFats: 'Graisses transformées',
        fiber: 'Fibres',
        carbohydrates: 'Glucides',
        proteins: 'Protéines',
        sodium: 'Sodium',
      },
    },
    allergens: {
      title: 'Information sur les allergènes',
      tableTitle: 'Ce produit contient',
      empty: "Ce produit ne contient pas d'allergènes",
      items: {
        twilight: 'Colorant jaune crépusculaire',
        allura: 'Colorant rouge allura',
        fish: 'Poisson',
        milk: 'Lait',
        soy: 'Soja',
        almonds: 'Amandes',
        lactose: 'Lactose',
        pepper: 'Poivre',
        grain: 'Céréales',
        tartrazine: 'Colorant jaune tartrazine',
        egg: 'Œufs',
        gluten: 'Gluten',
        walnuts: 'Noix',
        sulphites: 'Sulfites',
        cereals: 'Céréales',
        mustard: 'Moutarde',
        nuts: 'Fruits secs',
        sesame: 'Sésame',
        crustaceans: 'Crustacés',
        peanut: 'Cacahuètes',
        latex: 'Latex naturel',
        wheat: 'Blé, céréales',
        hazelnuts: 'Noisettes',
        cashewnuts: 'Noix de cajou',
        brazilnuts: 'Noix du Brésil',
        macadamias: 'Noix de macadamia',
        pecans: 'Noix de pécan',
        pistachios: 'Pistaches',
        pinoli: 'Pignons',
        chestnuts: 'Châtaignes',
      },
    },
  },
  shoppingCart: {
    title: 'Ma commande',
    products: 'Produits',
    total: 'Total',
    subtotal: 'Sous-total',
    shipping: 'Livraison',
    serviceFee: {
      name: 'Frais de service',
      info: "Frais couvrant les coûts d'exploitation de la plateforme et le suivi des commandes",
    },
    tip: 'Pourboire',
    tax: 'Impôts',
    points: 'Points consommés',
    extras: 'Suppléments',
    summary: 'Récapitulatif de votre commande',
    empty: {
      title: "Vous n'avez pas encore ajouté de produits",
      text: 'Choisissez ce qui vous plaît dans notre menu ou dans nos offres, et dégustez-le chez vous ou dans le restaurant McDonald’s de votre choix',
    },
    suggestive: {
      title: 'Souhaitez-vous ajouter quelque chose?',
    },
    actions: {
      back: 'Poursuivre les achats',
      next: 'Commander',
      clear: 'Clair',
      edit: 'Éditer',
      seeCart: 'Voir le panier',
    },
    promotions: {
      addPromo: {
        title: 'Codes de réduction et promotions',
        add: 'Ajouter',
        prefix: {
          coupon: 'Code',
          employee: 'Remise pour les employés',
        },
        terms: {
          prefix: "Pour plus d'informations consultez les",
          link: 'termes et conditions',
          sufix: 'du code de réduction.',
        },
      },
      modal: {
        title: 'Réductions et promotions',
        disclaimer:
          "N'oubliez pas que les remises et les promotions ne sont pas cumulables entre elles",
        fields: {
          coupon: {
            label: 'Code',
          },
          employee: 'Réduction pour les employés',
        },
        codeError: "Désolé. Le code de réduction n'est pas valide",
      },
    },
    alerts: {
      delete: {
        title: 'Voulez-vous supprimer ce produit?',
        body: 'Lorsque vous le supprimez, toute personnalisation que vous avez effectuée est également perdue.',
        cancel: 'Annuler',
        accept: 'Supprimer',
      },
      login: {
        title: 'Vous devez être inscrit pour continuer',
        body: "Cliquez sur entrer pour profiter de l'expérience complète et de nombreux autres avantages",
        cancel: 'Pas maintenant',
        accept: 'Entrer',
      },
      clear: {
        title: "Vous supprimez tous les produits de votre panier d'achat",
        body: 'Êtes-vous sûr ?, tous les articles se trouvant actuellement dans votre panier seront vidés',
        cancel: 'Annuler',
        accept: 'Vider',
      },
      cancelOrderConfirmation: {
        title: 'Êtes-vous sûr de vouloir annuler votre commande ?',
        accept: 'Annuler la commande',
        body: 'Pas de problème ! Votre argent vous sera remboursé dans un délai de 1 ou 2 jours ouvrables, selon la banque.',
        cancel: 'Retour à',
      },
      deletePromotedConfirmation: {
        title: "Voulez-vous l'enlever ?",
        body: 'Cela supprimera tous les produits qui correspondent à la promotion.',
        accept: 'Supprimer',
        cancel: 'Annuler',
      },
    },
    toast: {
      orderMcdia: "Cette promotion n'est pas compatible avec les produits de votre commande",
    },
  },
  checkout: {
    title: 'Détails du paiement',
    checkoutAlert: 'Message',
    actions: {
      pay: 'Payer',
    },
    sections: {
      address: {
        title: 'Shipping address',
      },
      tip: {
        title: 'Donner un pourboire au livreur',
        description:
          'Ce pourboire est volontaire et est donné à votre livreur en reconnaissance de son travail.',
        chips: {
          custom_amount: 'Autre',
          custom_percent: 'Autre',
        },
        toast: {
          max: 'Le pourcentage maximum de pourboire est {max} %',
        },
      },
      paymentMethods: {
        title: 'Méthode de paiement',
        add: 'Ajouter une nouvelle carte',
        modify: 'Modifier les cartes',
        methodTypes: {
          credit: 'Crédit',
          debit: 'Débit',
          inPerson: 'Au comptoir',
          MERCADO_PAGO_CHECKOUT_PRO: 'Argent sur le compte',
        },
        extraMethods: {
          MERCADO_PAGO_CHECKOUT_PRO: 'Mercado Pago',
        },
        expired: 'Expirada',
        toast: {
          cardSave: {
            success: 'La carte a été enregistrée correctement',
            error: "Il n'a pas été possible de sauvegarder la carte",
          },
          yappy:
            "N'oubliez pas que lorsque vous passez votre commande via Yappy, elle ne peut pas être annulée depuis notre application.",
        },
        modifyCards: {
          disclaimer: "N'oubliez pas que vous pouvez stocker un maximum de cartes {value}.",
          actions: {
            add: 'Ajouter une carte',
          },
          expiration: "Date d'expiration",
          alerts: {
            deleteCard: {
              title: 'Voulez-vous supprimer cette carte?',
              accept: 'Supprimer',
              cancel: 'Annuler',
            },
          },
        },
        transition: {
          title: 'Nous avons bien reçu votre commande :)',
          subtitle: 'En attente de confirmation de paiement.',
        },
        exclusivePayment:
          'Veuillez noter que seul le mode de paiement sélectionné ci-dessous est disponible pour cette promotion.',
      },
      fiscal: {
        title: 'Données de facturation',
        filled: {
          delete: 'Je ne veux pas de facture',
          action: 'Changer',
        },
        unfilled: {
          subtitle: 'Voulez-vous demander une facture?',
          subtitleMandatory:
            'Pour les achats égaux ou supérieurs à {price}, vous devez entrer vos informations sur la facture.',
          subtitleMandatoryAlways: 'La facture est obligatoire',
          action: 'Demander',
        },
        modal: {
          action: 'Confirmer',
          checkbox: 'A garder en mémoire pour de futurs achats',
        },
        alert: {
          mandatory: {
            title: "Entrez vos informations de facturation pour finaliser l'achat",
            bodyMandatoryAlways: 'Données obligatoires',
            bodyMandatory: 'Données obligatoires pour les achats supérieurs ou égaux à {price}',
            accept: 'Accepter',
            cancel: '',
          },
        },
      },
    },
    paymentResult: {
      yuno: {
        SUCCESS: {
          MOP: {
            title: 'Paiement réussi',
            description:
              'Lorsque vous êtes au restaurant, choisissez une option pour retirer votre commande',
            actions: {
              accept: 'Options de retrait',
              acceptMcdia: 'Voir mes commandes',
              alternative: 'Choisir plus tard',
            },
          },
          DLV: {
            title: 'Paiement réussi',
            description:
              'La commande est en cours de préparation. Vous la recevrez sous peu à votre adresse.',
            actions: {
              accept: 'Suivre la livraison',
              acceptMcdia: "Retour à l'accueil",
              alternative: 'Voir les détails',
            },
          },
        },
        ERROR: {
          paymentDidNotHappen: {
            title: 'Paiement non effectué',
            description:
              'Il y a eu une erreur lors du paiement. Veuillez réessayer ou choisir une autre carte. Si le problème persiste, veuillez contacter votre banque',
            actions: {
              accept: 'Réessayer',
              alternative: 'Choisir ou saisir une autre carte',
            },
          },
          genericError: {
            title: "Quelque chose s'est mal passé 🙁",
            description:
              'Veuillez vérifier si les informations ont été saisies correctement. Vous pouvez réessayer ou choisir une autre carte. Si le problème persiste, veuillez contacter votre banque',
            actions: {
              accept: 'Choisir ou saisir une autre carte',
              alternative: 'Réessayer',
            },
          },
        },
      },
      legacy: {
        success: {
          title: 'Votre paiement a été effectué avec succès',
          description:
            "Suivez les instructions sur l'écran suivant et vous pourrez récupérer votre commande comme vous le souhaitez.",
          actions: {
            accept: 'Compris',
            acceptMcdia: 'Voir mes commandes',
          },
        },
        restaurantOrderError: {
          title: "Nous sommes désolés ! Votre commande n'a pas pu être créée",
          description:
            "Une erreur s'est produite lors de la création de la commande dans le restaurant.",
          actions: {
            accept: 'Voir la commande',
          },
        },
        paymentError: {
          title: "Nous sommes désolés! Votre paiement n'a pas pu être effectué",
          description:
            'Il y a eu un problème dans le traitement de la transaction. Veuillez réessayer ou choisir un autre mode de paiement.',
          actions: {
            accept: 'Voir la commande',
            alternative: 'Choisir un autre moyen de paiement',
          },
        },
      },
    },
  },
  pickupMethods: {
    farAway: {
      title: 'Vous êtes loin du restaurant',
      subtitle:
        'Dans quelques minutes, votre commande sera prête. Êtes-vous sûr de vouloir choisir une option de livraison et commencer la préparation ?',
      actions: {
        continue: 'Options de livraison',
        imOnWay: 'Je suis en route',
      },
    },
    areaSelection: {
      backTitle: 'Méthodes de livraison',
      info: 'La préparation de la commande commence ici. Dans quelques minutes, votre commande sera prête',
      areas: {
        title: 'Comment souhaitez-vous recevoir votre commande?',
        MOP: {
          title: 'Au comptoir',
          description: 'Aller dans la zone de livraison',
          step: {
            title: 'Comment préférez-vous votre commande?',
            subtitle: 'Choisissez l’option que vous souhaitez pour que nous puissions la préparer',
            type: {
              EATIN: 'Manger sur place',
              TAKEOUT: 'Emballer pour emporter',
            },
          },
          area: {
            value: 'Numéro',
            title: 'Indiquez le numéro de votre commande dans la zone de livraison',
            titleDualPoint: 'Retirez votre commande lorsque votre numéro apparaît sur le tableau',
            titleInPerson: 'Allez au comptoir pour payer votre commande',
            subtitle: 'Nous préparons votre commande',
            subtitleInPerson:
              'La préparation de la commande commencera dès que le paiement aura été effectué.',
            alerts: {
              noOrderNumber: {
                title: "Impossible d'obtenir le numéro de retrait",
                accept: 'Aller au détail de la commande',
                body: '',
                cancel: 'Annuler',
              },
            },
          },
        },
        AUT: {
          title: 'Drive Thru',
          description: 'Présentez-vous au haut-parleur et annoncez-vous',
          step: {
            title: 'Votre commande sera livrée par Drive Thru',
            subtitle:
              "La préparation commencera dès la confirmation de la méthode d'enlèvement et la commande ne pourra pas être annulée",
            action: 'Confirmer',
          },
          area: {
            value: 'Numéro',
            title: 'Présentez-vous au haut-parleur pour vous annoncer et récupérer votre commande',
            subtitle: '',
          },
        },
        EALM: {
          title: 'À table',
          description: 'Nous apportons la commande à votre table',
          step: {
            title: 'Inscrivez votre numéro de table',
            subtitle:
              "La préparation commencera dès la confirmation de la méthode d'enlèvement et la commande ne pourra pas être annulée",
            help: "Vous verrez qu'il est collé à l'une des extrémités",
            action: 'Confirmer',
          },
          area: {
            value: 'Table',
            title: "Il ne reste plus qu'à attendre!",
            subtitle: 'Vous recevrez bientôt votre commande prête à être dégustée',
          },
        },
        CURB: {
          title: 'Sur le parking',
          description: 'Nous livrons dans votre garage',
          step: {
            title: 'Entrez votre numéro de garage',
            subtitle:
              "La préparation commencera lorsque vous aurez confirmé la méthode d'enlèvement et la commande ne pourra pas être annulée",
            help: 'Trouvez les dépôts marqués pour que nous puissions livrer votre commande',
            helpLink: 'Les dépôts sont-ils occupés ?',
            action: 'Confirmer',
          },
          area: {
            value: 'Garage',
            title: "Il ne reste plus qu'à attendre!",
            subtitle: 'Vous recevrez bientôt votre commande prête à être dégustée',
          },
        },
        disabled: {
          title: "Le service {areaTranslated} n'est pas disponible pour cette commande.",
          subtitle: 'Choisir une autre option de livraison',
        },
      },
      formErrors: {
        required: 'La valeur est obligatoire',
        minValue: 'La valeur minimale est {minimum}',
        maxValue: 'La valeur maximale est {maximum}',
      },
    },
  },
  errors: {
    page: {
      title: 'Ooops...',
      subtitle: 'Quelque chose a mal tourné',
      description: "Il semble que la page que vous recherchez n'existe pas",
    },
    toast: {
      geo: {
        denied:
          "L'autorisation de géolocalisation a été refusée. Veuillez l'activer si vous souhaitez utiliser le bouton pour vous localiser",
        unsupported: "La géolocalisation n'est pas prise en charge par ce navigateur",
        geocoderError: "Une erreur s'est produite lors de l'obtention de l'adresse",
        unexpectedError:
          "Une erreur inattendue s'est produite. Veuillez réessayer dans quelques instants",
      },
      generic: 'Désolé, il y a eu une erreur. Veuillez réessayer',
    },
    alerts: {
      outOfDayPart: {
        title: "Produit en dehors des heures d'ouverture. N'oubliez pas d'annuler votre commande",
        body: "Votre commande ne peut pas être livrée, car l'un des produits est hors délai. Une fois annulée, votre argent vous sera remboursé dans un délai de 1 ou 2 jours ouvrables",
        accept: 'Annuler la commande',
        cancel: '',
      },
      orderMcdiaOutOfDates: {
        title: "McDonald's",
        body: 'Le délai a expiré, la commande ne peut être annulée',
        accept: 'OK',
        cancel: '',
      },
      orderNotExist: {
        title: "La commande n'existe pas",
        body: 'La commande demandée est introuvable',
        accept: 'Aller aux produits',
        cancel: 'Annuler',
      },
      orderAlreadyPaid: {
        title: 'La commande a été payée',
        accept: 'Accepter',
        body: '',
        cancel: 'Annuler',
      },
      generic: {
        title: "Une erreur inattendue s'est produite",
        accept: 'Accepter',
        body: 'Veuillez réessayer après quelques minutes',
        cancel: '',
      },
      tokenExpired: {
        title: 'Information',
        body: 'Votre temps pour passer la commande a expiré, vous devez vous connecter à nouveau pour terminer la commande',
        accept: 'Accepter',
        cancel: '',
      },
      noCoverage: {
        title: "McDelivery n'est pas disponible pour le moment",
        body: 'En attendant, vous pouvez passer une commande et la retirer au restaurant le plus proche avec PickUp',
        accept: 'PickUp',
        cancel: 'Essayez à nouveau plus tard',
      },
      noRestaurantsOpened: {
        title: 'Désolé, le restaurant est fermé',
        body: "Vous pouvez choisir une autre adresse ou voir s'il y a un restaurant à proximité pour récupérer votre commande",
        accept: 'Recherche de restaurants à proximité',
        cancel: 'Annuler',
      },
      noRiders: {
        title: 'Nos livreurs sont occupés',
        body: 'Vous pouvez réessayer plus tard ou passer une commande et la retirer avec PickUp',
        accept: 'PickUp',
        cancel: 'Essayez à nouveau plus tard',
      },
      productNotAvailable: {
        title: 'La promotion est terminée !',
        body: "Modifiez les produits de votre commande pour continuer l'achat",
        accept: 'Retourner à ma commande',
        cancel: '',
      },
      pickup_unhandled: {
        title: "La méthode d'enlèvement de votre commande n'a pas pu être définie",
        body: '',
        accept: 'Essayer à nouveau',
        cancel: 'Annuler',
      },
      pickup_noRestaurantsOpened: {
        title: 'Restaurant fermé',
        body: "Désolé, le restaurant est fermé ou sur le point de fermer et n'accepte plus de commandes. Vous pourrez récupérer votre commande lorsqu'il sera à nouveau ouvert",
        accept: 'Continuer',
        cancel: 'Annuler',
      },
      pickup_serviceNumberEmpty: {
        title: "Oups ! Quelque chose d'inattendu est arrivé !",
        body: 'Nous avons rencontré un problème lors du chargement du numéro que vous avez saisi. Veuillez réessayer',
        accept: 'Réessayer',
        cancel: '',
      },
      pickup_anyPickupAreaForThisOrderIsAvailable: {
        title: "Le restaurant n'est plus disponible",
        body: 'Vous pouvez annuler la commande pour obtenir un remboursement et choisir une autre option. Nous vous présentons nos excuses !',
        accept: 'Aller à ma commande',
        cancel: '',
      },
      pickup_orderPickupNotAvailable: {
        title: "Le restaurant n'est plus disponible",
        body: 'Vous pouvez annuler la commande pour obtenir un remboursement et choisir une autre option. Nous vous présentons nos excuses !',
        accept: 'Aller à ma commande',
        cancel: '',
      },
      pickup_areaNotAvailable: {
        title: "Le restaurant n'est plus disponible",
        body: 'Vous pouvez annuler la commande pour obtenir un remboursement et choisir une autre option. Nous vous présentons nos excuses !',
        accept: 'Aller à ma commande',
        cancel: '',
      },
      orderErrorPrice: {
        title: "Votre commande n'a pas pu être traitée",
        accept: 'Accepter',
        body: 'Veuillez réessayer après quelques minutes',
        cancel: 'Annuler',
      },
      platformNotValidForPromo: {
        title: "Cette promotion n'est pas compatible",
        body: 'Veuillez sélectionner un autre mode de paiement pour finaliser votre achat',
        accept: 'Choisir un autre mode de paiement',
        cancel: '',
      },
      orderInProgressMultiple: {
        title: 'Vous avez atteint le nombre maximum de commandes en cours',
        accept: 'Mes commandes',
        body: "Tant que vous n'aurez pas retiré au moins une de vos commandes en cours, vous ne pourrez pas effectuer d'autre achat",
        cancel: 'Annuler',
      },
      orderPickupNotAvailable: {
        title:
          "Le restaurant sélectionné lors d'une commande précédente est actuellement indisponible",
        accept: 'Accepter',
        body: 'Veuillez sélectionner un nouveau restaurant',
        cancel: 'Annuler',
      },
      orderGeneric: {
        title: "Votre commande n'a pas pu être traitée",
        accept: 'Accepter',
        body: 'Veuillez réessayer après quelques minutes',
        cancel: 'Annuler',
      },
      orderInProcess: {
        title: 'Erreur',
        body: "La commande est en cours d'acheminement",
        accept: 'Accepter',
        cancel: 'Annuler',
      },
      promotionNotFound: {
        title: 'La promotion est terminée !',
        body: "Modifiez les produits de votre commande pour continuer l'achat",
        accept: 'Retourner à ma commande',
        cancel: '',
      },
      orderProductMismatch: {
        title: 'Erreur lors de la création de votre commande',
        body: "Une erreur s'est produite lors de la création de votre commande et celle-ci ne peut être passée",
        accept: 'Accepter',
        cancel: '',
      },
      countryNotValid: {
        title: "Une erreur s'est produite lors du paiement",
        body: 'Le pays sélectionné ne correspond pas au pays dans lequel la commande a été passée. Veuillez changer de pays pour procéder au paiement',
        accept: 'Accepter',
        cancel: '',
      },
      paymentPendingApproval: {
        title: 'Vous avez déjà tenté un paiement pour cette commande',
        body: "Le paiement de votre commande est en attente d'approbation. Veuillez vérifier l'état de votre commande dans quelques minutes",
        accept: 'Accepter',
        cancel: '',
      },
      promotionNotAvailable: {
        title: "La promotion n'est plus disponible et ne peut être appliquée à cette commande",
        body: '',
        accept: 'Accepter',
        cancel: '',
      },
      orderMcdia: {
        title: 'Votre achat ne peut être honoré!',
        body: "Les produits McDia Feliz ne peuvent pas être achetés avec d'autres produits. Pour poursuivre l'achat, vous devez retirer les produits qui ne correspondent pas à la campagne.",
        accept: 'Supprimer les produits',
        cancel: '',
      },
      fiscalFieldsRequired: {
        title: "Entrez vos informations de facturation pour terminer l'achat",
        body: 'Requis pour les achats supérieurs ou égaux à {value}',
        accept: 'OK',
        cancel: '',
      },
      antifraudTemporalBlock: {
        title: 'Temporary block',
        body: 'Pour la sécurité de votre compte, nous bloquons temporairement la fonction de paiement',
        accept: "Retour à la page d'accueil",
        cancel: '',
      },
      antifraudLimitTransactionsReached: {
        title: 'Limite quotidienne de transactions atteinte',
        body: 'Nous avons identifié un trop grand nombre de transactions de paiement consécutives. Votre compte a été temporairement bloqué pour des raisons de sécurité',
        accept: "Retour à la page d'accueil",
        cancel: '',
      },
      phoneValidationRequired: {
        title: 'Validez votre téléphone',
        body: 'Vous devez valider votre téléphone pour passer une commande',
        accept: 'Accepter',
        cancel: '',
      },
      deviceValidationRequired: {
        title: 'Validez votre appareil',
        body: 'Vous devez valider votre appareil pour passer une commande',
        accept: 'Accepter',
        cancel: '',
      },
      promoExclusivePaymentUsed: {
        title: 'Oups ! Vous avez déjà utilisé cette promotion',
        body: 'Retournez au panier et modifiez les produits pour pouvoir continuer avec la commande',
        accept: 'Retourner à ma commande',
        cancel: '',
      },
      limitProductExclusivePayment: {
        title: "Nous n'autorisons qu'un seul article promotionnel par achat",
        body: "Vous pouvez garder d'autres articles du menu dans cet achat, mais vous ne pouvez ajouter l'offre promotionnelle qu'une seule fois",
        accept: 'Compris',
        cancel: '',
      },
      limitedPromoProduct: {
        title: 'Votre commande inclut déjà des produits avec des réductions',
        body: 'Voulez-vous conserver les réductions actuelles ou appliquer le nouveau code ?',
        accept: 'Conserver les réductions',
        cancel: 'Appliquer le nouveau code',
      },
      employeeDiscount: {
        title: 'Votre commande a déjà des réductions appliquées',
        body: 'Voulez-vous remplacer la promotion ou ajouter le produit sans remise?',
        accept: 'Ajouter sans remise',
        cancel: 'Remplacer la promotion',
      },
      areaNotFound: {
        title: "Le restaurant n'est pas disponible pour les produits sélectionnés",
        body: 'Vous pouvez choisir un autre restaurant ou revenir à votre commande et changer les produits',
        accept: 'Revenir à ma commande',
        cancel: 'Changer de restaurant',
      },
      limitPriceOrder: {
        title: 'La valeur totale de votre achat est trop élevée',
        body: 'Vous avez dépassé la limite autorisée de {value}. Veuillez vérifier les produits dans votre panier',
        accept: 'Retourner à ma commande',
        cancel: '',
      },
      restaurantNotLoyalty: {
        title: 'Ce restaurant ne fait pas partie de {value}',
        body: 'Pour continuer, modifiez les produits de votre commande ou choisissez un autre restaurant. Si vous le faites, votre panier sera vidé',
        accept: 'Retourner à ma commande',
        cancel: 'Changer de restaurant',
      },
      cumulativePromotionsNotAllowed: {
        title: 'Votre commande a déjà des réductions appliquées',
        body: 'Voulez-vous remplacer la promotion ou ajouter le produit sans remise?',
        accept: 'Ajouter sans remise',
        cancel: 'Remplacer la promotion',
      },
      autoApplicablePromotionNotAllowed: {
        title: 'Votre commande inclut déjà des produits avec des réductions',
        body: 'Voulez-vous conserver les réductions actuelles ou appliquer le nouveau code?',
        accept: 'Conserver les réductions',
        cancel: 'Appliquer le nouveau code',
      },
    },
  },
  selectRestaurant: {
    DLV: {
      noNumber: 'Aucun numéro',
      addAddressError: 'Ce champ doit être complété',
      additionlInfoError: 'Ce champ doit être complété',
      completeDirection: 'Complétez votre adresse',
      street: 'Rue',
      number: 'Numéro',
      additionalInfo: 'Informations complémentaires pour le livreur',
      additionalInfoPlaceholder: 'Maison, appartement...',
      additionalInfoDeliveryPlaceholder: 'Aidez-nous à livrer votre commande',
      willSendTo: 'Nous enverrons votre commande à',
      confirmLocation: 'Confirmez la localisation',
      favorite: {
        saveTitle: 'Enregistrer comme adresse favorite',
        formTitle: 'Voulez-vous lui donner un nom ?',
        fields: {
          alias: {
            label: '*Alias',
            required: "L'alias de l'adresse est obligatoire",
          },
        },
      },
    },
    MOP: {
      findYourMcDonalds: "Trouvez votre McDonald's",
      seeList: 'Voir la liste',
      seeMap: 'Voir la carte',
      emptyList: "Nous n'avons trouvé aucun restaurant à proximité dans cette localité.",
    },
    map: {
      adjustPin: 'Ajustez l’épingle à la position appropriée',
    },
    lupap: {
      city: 'Ville',
      form: {
        address: 'Adresse',
        complement: 'Bâtiment, tour, appartement ...',
      },
      continue: 'Continuer',
    },
  },
  alertDialog: {
    mcDeliveryError: {
      title: "Désolé, nous n'avons pas encore de service McDelivery dans votre région",
      body: "Nous travaillons pour pouvoir vous le proposer, mais pour l'instant choisissez une autre adresse ou voyez s'il y a un restaurant à proximité pour récupérer votre commande",
      btnPrimary: 'Rechercher les restaurants à proximité',
      btnSecondary: 'Trouver une autre adresse',
    },
    ridersError: {
      title: "Désolé, il n'y a actuellement aucun chauffeur-livreur disponible dans votre région",
      body: "Vous pouvez choisir une autre adresse ou voir s'il y a un restaurant à proximité pour récupérer votre commande",
      btnPrimary: 'Rechercher les restaurants à proximité',
      btnSecondary: 'Trouver une autre adresse',
    },
    restaurantClosedError: {
      title: 'Désolé, le restaurant est fermé',
      body: "Vous pouvez choisir une autre adresse ou voir s'il y a un restaurant à proximité pour récupérer votre commande",
      btnPrimary: 'Rechercher les restaurants à proximité',
      btnSecondary: 'Trouver une autre adresse',
    },
    genericError: {
      title: "Désolé, une erreur s'est produite",
      body: "Erreur lors de l'obtention des restaurants disponibles",
      btnPrimary: '',
      btnSecondary: '',
    },
    changeRestaurant: {
      titleMop: 'Êtes-vous sûr de vouloir changer le restaurant de ramassage?',
      titleMOP: 'Êtes-vous sûr de vouloir changer le restaurant de livraison?',
      body: 'Vous devrez recommencer votre commande et sélectionner à nouveau dans le menu.',
      btnPrimary: 'Changer de restaurant',
      btnSecondary: 'Annuler',
    },
  },
  restaurantTooltipAlert: {
    closed:
      "N'oubliez pas que le restaurant sélectionné est fermé et n'accepte pas de commandes pour le moment",
    riders: "Il semble qu'il n'y ait aucun coureur pour le moment",
    farFromRestaurant:
      'On dirait que tu es loin du restaurant. Tu peux toujours le changer en cliquant dessus.',
    farFromRestaurantOptions: {
      withoutGeo: {
        title: 'Vérifiez si cette adresse est correcte',
        subtitle:
          'Si vous la changez, activez votre localisation pour la rendre plus précise.<br/><b>Voulez-vous choisir une autre adresse?</b>',
      },
      withGeo: {
        title: 'Nous avons remarqué que vous êtes loin de ce restaurant!',
        subtitle: 'Voulez-vous en choisir un autre?',
      },
    },
    actions: {
      dontChange: 'Non, continuer',
      change: 'Oui, changer',
    },
    farFromRestaurantWithOrder:
      'Nous avons remarqué que vous êtes loin du restaurant où vous avez passé la commande',
    farFromRestaurantWithoutOrder:
      "Nous avons remarqué que vous êtes loin du restaurant où vous avez passé la commande. Vous pouvez le changer en appuyant sur l'adresse",
  },
  restaurant: {
    status: {
      closed: 'Fermé',
      nearToClose: 'Proche de fermer',
      onlyLocal: 'Uniquement des commandes sur place',
    },
    detail: {
      schedule: 'Horaire',
      header: {
        MOP: 'A retirer à',
        DLV: 'Envoyer à',
      },
      howToArrive: 'Comment arriver',
      mop: 'Méthodes de retrait',
      closed: 'Fermé',
      services: {
        title: 'Services',
        breakfast: 'Petit déjeuner',
        mcCafe: 'McCafé',
        timeExtended: '24 heures',
        mcParty: 'McParty',
        playPlace: 'PlaySpace',
        parking: 'Parking',
        wifi: 'WIFI',
        wheelchairAccess: 'Accessibilité',
        dessertCenter: 'Dessert Center',
        shoppingCenter: 'Shopping Center',
      },
    },
  },
  help: {
    links: {
      faqs: {
        title: 'Des doutes?',
        linkText: 'Aide et FAQ’s',
      },
      whatsapp: {
        title: "Besoin d'aide?",
        titleAlt: 'Nous sommes aussi dans',
        linkText: 'Whatsapp',
      },
      sac: {
        title: 'Service clientèle',
        linkText: 'Téléphone',
      },
    },
  },
  orders: {
    orderNumber: 'Numéro de commande',
    delivery: {
      deliveryCode: 'Code de livraison',
      sentFrom: 'Envoyé de',
      description:
        'Fournissez le code au livreur uniquement lors de la réception de votre commande',
    },
    date: {
      today: 'Aujourd’hui',
      yesterday: 'Hier',
    },
    historyOrders: {
      onGoing: "Vous n'avez pas de commande en cours",
      finished: "Vous n'avez pas de commandes terminées",
    },
    status: {
      pending: 'Paiement en attente',
      pendingCounter: 'Payer au comptoir',
      paymentDone: 'Paiement effectué',
      paymentDoneMcdia: 'Se préparer pour la journée',
      paymentDoneMcdia3days: 'Se préparer pour la journée',
      paymentDoneMcdiaInDate: 'Prêt pour la livraison',
      paymentDoneMcdiaExpired: 'Délai expiré',
      goRestaurant: 'Aller au restaurant',
      received: 'Reçu',
      accepted: 'Accepté',
      inProgress: 'En préparation',
      ready: 'Prêt',
      delivering: 'En route',
      canceled: 'Annulé',
      error: 'Erreur de paiement',
      delivered: 'Livré',
      finished: 'Finalisé',
      pickupExpired: 'Temps écoulé',
    },
    areas: {
      aut: 'Livraison chez AutoMac',
      ealm: 'Livraison à table',
      mop: 'Ramassage au comptoir',
      curb: 'Ramassage sur le parking',
    },
    buttons: {
      onGoing: 'En cours',
      finished: 'Terminé',
      cancelOrder: 'Annuler la commande',
      pay: 'Payer',
      reorder: 'Répéter la commande',
      rate: 'Évaluer',
      arrival: 'Atteindre',
      track: 'Suivez commande',
      receive: 'Recevoir ma commande',
    },
    toast: {
      orderCanceled: 'Commande annulée',
      orderCanceledError: "La commande n'a pas pu être annulée",
      orderCanceledMOP: 'La commande est en cours de préparation et ne peut être annulée',
      orderCanceledDLV:
        "Le livreur est déjà en route pour votre domicile et il n'est pas possible d'annuler la livraison",
      orderUnappliedProducts:
        "La promotion a été ajoutée, mais elle ne s'applique pas aux produits de votre commande",
    },
    messages: {
      yappy:
        'Si vous avez des questions concernant votre commande avec Yappy, contactez-nous via notre chat',
      flexCancelledReason: {
        FLEX_CANCELLED_REFUND: {
          title: 'Commande annulée.',
          text: 'Il y a eu un problème, mais ne vous inquiétez pas ! Vous recevrez le remboursement dans 1 ou 2 jours ouvrables',
          button: 'En savoir plus',
        },
        FLEX_CANCELLED_ERR_ADDRESS:
          "Commande annulée. <br/><br/>Il y a eu un problème avec l'adresse de livraison. L'argent ne peut pas être remboursé. Nous sommes désolés pour ce désagrément.",
        FLEX_CANCELLED_ERR_INFO: 'Votre commande a été annulée. Nous vous présentons nos excuses!',
      },
    },
    alert: {
      receiveOrder: {
        title: 'Nous sommes prêts à préparer votre commande',
        body: "Rappelez-vous qu'il est important d'être à l'adresse que vous avez choisie pour recevoir votre commande. Êtes-vous déjà à <b>{address}</b> pour la recevoir ?",
        accept: 'Confirmer',
        cancel: 'Pas encore',
      },
    },
    receiveOrder: {
      texts: {
        'no-riders': {
          title: "Désolé, nous n'avons pas trouvé de livreurs disponibles pour votre commande",
          body: 'Nous travaillons pour résoudre cela dès que possible. Veuillez essayer de demander la livraison à nouveau dans quelques minutes.',
        },
        'no-stock': {
          title:
            "Désolé, nous n'avons pas pu commencer votre commande en raison du manque d'ingrédients",
          body: 'Nous travaillons pour résoudre cela dès que possible. Veuillez essayer de demander la livraison à nouveau dans quelques minutes.',
        },
        'rest-closed': {
          title: 'À ce moment, le restaurant est fermé',
          body: "Pour vous assurer de pouvoir recevoir votre commande, assurez-vous de demander la livraison pendant nos heures d'ouverture.",
        },
        'generic': {
          title: "Désolé, un problème est survenu et nous n'avons pas pu lancer votre commande.",
          body: 'Nous efforçons de résoudre ce problème dès que possible. Veuillez réessayer de demander une livraison ou attendre quelques minutes avant de réessayer.',
        },
      },
      actions: {
        primary: 'Réessayer plus tard',
        secondary: 'Accéder à ma commande',
      },
    },
  },

  SEO: {
    restaurants: {
      title: "Trouvez le McDonald's le plus proche",
      description:
        "Trouvez le restaurant McDonald's le plus proche, où que vous soyez {'|'} Horaires, adresses et menus pour que vous puissiez profiter de vos favoris: livraison ou {pickup}.",
      city: {
        title: "Trouvez le McDonald's le plus proche à {city}",
        description:
          "Découvrez le restaurant McDonald's le plus proche de {city}. Votre hamburger préféré près de chez vous {'|'} Horaires, adresses et menus.",
        restaurant: {
          title: '{restaurant} {city}: Découvrez nos offres',
          description: `Découvrez le restaurant McDonald's le plus proche de {restaurant} {city}. Votre hamburger préféré près de chez vous {'|'} Horaires, adresses et menus.`,
          orders: {
            title: `Mes commandes chez {restaurant} {'|'} McDonald's`,
            description: `Commandez chez {restaurant} à {city} {'|'} Explorez notre menu et profitez de la commodité de la livraison ou du retrait.`,
            area: {
              title: `⇨{area} McDonald's {city}`,
              description:
                'Trouvez et personnalisez le menu que vous préférez avec un accompagnement au choix, des frites et une boisson. Choisissez la taille et commandez chez McDonald’s au meilleur prix ✓.',
              category: {
                title: `Commandez {category} chez {restaurant}`,
                description: `Votre commande McDonald's est maintenant plus proche que jamais. Choisissez votre {category} disponible pour la livraison ou le {pickup} chez {restaurant}.`,
                product: {
                  title: `Commandez {product} chez {restaurant}`,
                  description: `Votre commande McDonald's est maintenant plus proche que jamais. Profitez de {product} disponible pour la livraison ou le {pickup} chez {restaurant}.`,
                },
              },
            },
          },
        },
      },
    },
    menu: {
      title: 'Menu McDonald’s - Tous les produits',
      description:
        'Trouvez et personnalisez le menu que vous préférez avec un accompagnement au choix, des frites et une boisson. Choisissez la taille et commandez chez McDonald’s au meilleur prix ✓.',
      category: {
        title: `{category} McDonald's {'|'} Complétez votre repas`,
        description: `Vous avez faim ? McDonald's propose différentes offres {category} à retirer ou à livrer au meilleur prix ✓ Choisissez votre repas préféré.`,
        product: {
          title: `{product} - {category} {'|'} McDonald's`,
          description: `Profitez de votre {product}, livraison ou {pickup}. 24 heures sur 24 dans votre McDonald's le plus proche ✓ Découvrez nos nouveaux produits et offres sur le site officiel.`,
        },
      },
    },
    order: {
      cart: {
        title: `Examinez et complétez vos commandes {'|'} McDonald’s`,
        description: `Complétez votre commande chez McDonald's {'|'} Trouvez les produits que vous souhaitez et procédez au paiement en ligne facilement et en toute sécurité {'|'} Profitez-en!`,
      },
      cartProductIndex: {
        title: `Examinez et complétez vos commandes {'|'} McDonald’s`,
        description: `Vérifiez votre commande, vous pouvez la compléter ou la modifier facilement {'|'} Trouvez les produits que vous souhaitez et procédez au paiement en ligne facilement et en toute sécurité {'|'} Profitez-en!`,
      },
      checkout: {
        title: `Checkout {'|'} McDonald’s`,
        description: `Complétez votre commande {'|'} Vérifiez votre sélection et entrez vos informations. Prêt à déguster un délicieux repas ! Paiement sécurisé✓`,
      },
    },
    my: {
      account: {
        title: `Mes données personnelles {'|'} McDonald's`,
        description: `Gérez vos données personnelles facilement et en toute sécurité chez McDonald's {'|'} Mettez à jour les informations de votre profil, votre adresse et vos favoris {'|'} Gardez votre compte à jour.`,
        section: {
          title: `{section} {'|'} McDonald's`,
          description: `Gérez vos données personnelles facilement et en toute sécurité chez McDonald's {'|'} Mettez à jour les informations de votre profil, votre adresse et vos favoris {'|'} Gardez votre compte à jour.`,
        },
        my: {
          orders: {
            title: `Mes commandes {'|'} McDonald's`,
            description: `Consultez vos commandes, votre historique d'achats et les détails de vos commandes précédentes {'|'} Suivez vos livraisons et passez une nouvelle commande si vous le souhaitez.`,
            orderId: {
              title: `{orderId} {'|'} McDonald's`,
              description: `Consultez les détails de votre commande {orderId} {'|'} Informations détaillées sur votre achat, l'état de la livraison et les produits achetés chez McDonald's.`,
            },
          },
        },
      },
    },
  },
  phone: {
    phoneValidation: {
      title: 'Numéro de téléphone',
      subtitle:
        'Afin de vous assurer que vous êtes le seul à avoir accès à votre compte, veuillez saisir votre numéro de téléphone',
      continue: 'Validez et enregistrez',
      duplicatePhone: 'Le numéro de téléphone est déjà enregistré. Veuillez en entrer un autre',
    },
    phoneVerification: {
      title: 'Validez votre téléphone',
      subtitle: "Nous venons d'envoyer le code de validation à six chiffres sur votre téléphone",
      notSended: "N'avez-vous pas reçu le code ?",
      notSendedDisclaimerCountdown: 'Vous pouvez renouveler votre demande dans {time}.',
      notSendedDisclaimer: 'Vous pouvez renouveler votre demande maintenant.',
      sendAgain: 'Envoyer le code à nouveau',
      errorCode: {
        CODE_FIELD_ERROR: 'Code erroné',
        CODE_FIELD_ERROR_EXPIRED:
          'Le code a expiré. <br/>Pour valider votre téléphone, cliquez sur “envoyer le code à nouveau”',
      },
    },
    alert: {
      phoneAlreadyValidated: {
        title: 'Désolé, il y a eu une erreur de traitement',
        body: 'Veuillez réessayer',
        accept: 'Continuer',
      },
    },
    toast: {
      generic: 'Désolé, il y a eu une erreur. Veuillez réessayer',
      warning: 'Veuillez attendre {time} avant de redemander un nouveau code',
      success: 'Téléphone vérifié',
    },
    block: {
      title: 'Limite des envois dépassée',
      subtitle:
        "Nous n'avons pas pu valider votre téléphone en raison d'un nombre excessif de soumissions",
      tryAgainCountdown: 'Veuillez réessayer dans {heure}.',
      tryAgainNow: 'Veuillez réessayer maintenant',
      action: "Retour à l'accueil",
    },
  },
  device: {
    deviceVerification: {
      title: 'Validez votre appareil',
      subtitle:
        'Nous venons de vous envoyer le <b>code de validation à quatre chiffres</b> à votre {email}, saisissez-le pour poursuivre votre commande.',
      notSended: "Vous n'avez pas reçu le code?",
      notSendedDisclaimerCountdown:
        "N'oubliez pas de vérifier votre dossier spam. Vous pouvez postuler à nouveau à {moment}.",
      notSendedDisclaimer:
        "N'oubliez pas de vérifier votre dossier spam. Vous pouvez vous réinscrire maintenant.",
      sendAgain: 'Envoyer le code à nouveau',
      errorCode: {
        CODE_FIELD_ERROR: 'Code incorrect',
        CODE_FIELD_ERROR_EXPIRED:
          'Le code a expiré. <br/>Pour valider votre appareil, cliquez sur “envoyer le code à nouveau”',
      },
    },
    alert: {
      deviceAlreadyValidated: {
        title: 'Désolé, il y a eu une erreur dans le processus.',
        body: 'Veuillez réessayer',
        accept: 'Continuer',
      },
    },
    toast: {
      generic: "Une erreur s'est produite. Veuillez réessayer.",
      success: 'Appareil validé avec succès',
    },
    block: {
      title: 'Dépassement de la limite des envois',
      subtitle:
        "Nous n'avons pas pu valider votre appareil en raison d'une livraison excédentaire.",
      tryAgainCountdown: 'Veuillez réessayer dans {time}.',
      tryAgainNow: 'Veuillez réessayer maintenant.',
      action: "Retour à l'accueil",
    },
  },
  email: {
    emailVerification: {
      title: 'Vérifiez votre courrier électronique',
      subtitle:
        'Nous venons de vous envoyer le <b>code de validation à quatre chiffres</b> à votre {email}, saisissez-le pour poursuivre votre commande.',
      notSended: "Vous n'avez pas reçu le code?",
      notSendedDisclaimerCountdown:
        "N'oubliez pas de vérifier votre dossier spam. Vous pouvez postuler à nouveau à {moment}.",
      notSendedDisclaimer:
        "N'oubliez pas de vérifier votre dossier spam. Vous pouvez vous réinscrire maintenant.",
      sendAgain: 'Envoyer le code à nouveau',
      errorCode: {
        CODE_FIELD_ERROR: 'Code incorrect',
        CODE_FIELD_ERROR_EXPIRED:
          'Le code a expiré. <br/>Pour valider votre courrier électronique, cliquez sur “envoyer le code à nouveau”',
      },
    },
    alert: {
      emailAlreadyValidated: {
        title: 'Désolé, il y a eu une erreur dans le processus.',
        body: 'Veuillez réessayer',
        accept: 'Continuer',
      },
    },
    toast: {
      generic: "Une erreur s'est produite. Veuillez réessayer.",
      success: 'Sauvegarde réussie des données',
      successAlt: 'Courrier confirmé avec succès',
    },
    block: {
      title: 'Dépassement de la limite des envois',
      subtitle:
        "Nous n'avons pas pu valider votre courrier électronique en raison d'une livraison excédentaire.",
      tryAgainCountdown: 'Veuillez réessayer dans {time}.',
      tryAgainNow: 'Veuillez réessayer maintenant.',
      action: "Retour à l'accueil",
    },
  },
  requiredFields: {
    title: 'Remplissez vos coordonnées',
    subtitle:
      'Saisissez les informations manquantes pour poursuivre votre achat. Ces informations seront stockées en toute sécurité et nous ne vous les demanderons plus jamais',
    actions: {
      continue: 'Continuer',
    },
    alerts: {
      genericError: {
        title: 'Désolé, il y a eu une erreur de traitement',
        body: 'Veuillez réessayer',
        accept: 'Continuer',
      },
      duplicatePhone: {
        title: 'Téléphone déjà enregistré',
        body: 'Le numéro de téléphone est déjà enregistré. Veuillez en entrer un autre',
        accept: 'Continuer',
      },
    },
  },
  repeatOrder: {
    success: {
      title: 'Êtes-vous sûr de vouloir passer une nouvelle commande ?',
      body: 'La commande sera créée dans le même restaurant que celui où vous avez passé la commande. Si vous avez des produits dans votre panier, ils seront remplacés par les produits de cet achat. Les prix des produits peuvent avoir changé',
      accept: 'Continuer',
      cancel: 'Annuler',
    },
    genericError: {
      title: "Oups, il n'est pas possible de renouveler la commande pour le moment",
      body: 'Entre-temps, veuillez consulter notre catalogue ou réessayer',
      accept: 'Essayez à nouveau',
      cancel: 'Voir le catalogue',
    },
    noRiders: {
      title:
        "Désolé, il n'y a actuellement aucun chauffeur de livraison disponible dans votre région",
      body: 'Nous sommes confrontés à une forte demande de commandes. Veuillez réessayer dans quelques minutes.',
      accept: 'Accepter',
      cancel: '',
    },
    mopNotAvailable: {
      title: 'Oups, il ne sera pas possible de répéter la commande',
      body: "{pickup} n'est actuellement pas disponible pour ce restaurant. Veuillez réessayer dans quelques minutes",
      accept: 'Accepter',
      cancel: '',
    },
    dlvNotAvailable: {
      title: 'Oups, il ne sera pas possible de répéter la commande',
      body: "Ce restaurant n'est actuellement pas disponible pour McDelivery. Veuillez réessayer dans quelques minutes.",
      accept: 'Accepter',
      cancel: '',
    },
    restaurantNotFound: {
      title: "Oh non, nous n'avons pas trouvé votre restaurant",
      body: "Nous vous recommandons d'essayer de commander dans un autre restaurant",
      accept: 'Compris',
      cancel: '',
    },
    closedRestaurant: {
      title: 'Notre restaurant est actuellement fermé',
      body: 'Il ne sera pas possible de renouveler cette commande. Nous vous suggérons de choisir un autre restaurant pour effectuer vos achats.',
      accept: 'Compris',
      cancel: '',
    },
    permittedAreasProducts: {
      title: "Oh non, cette commande n'est plus disponible",
      body: "Certains des produits de cette commande ne peuvent être enlevés par aucune des méthodes d'enlèvement disponibles",
      accept: 'Compris',
      cancel: '',
    },
    outOfDaypartProducts: {
      title: "Oups, il n'est pas possible de passer une nouvelle commande pour le moment",
      body: 'Certains produits ne correspondent pas à votre calendrier de préparation. Veuillez essayer de passer une nouvelle commande ou de commencer un nouvel achat',
      accept: 'Compris',
      cancel: '',
    },
    notExistingProducts: {
      title: "Oups, pour l'instant, il n'est pas possible de renouveler la commande",
      body: 'Certains des produits de cet achat ne figurent plus dans le catalogue. Veuillez essayer de passer une nouvelle commande ou de commencer un nouvel achat',
      accept: 'Compris',
      cancel: '',
    },
  },
  contact: {
    title: 'Complétez le formulaire avec vos coordonnées',
    inputs: {
      firstname: 'Prénom',
      lastname: 'Nom',
      email: 'Email',
      phone: 'Téléphone',
      city: 'Ville',
      local: 'Restaurant',
      date: 'Jour',
      time: 'Heure',
      message: 'Message',
      newsletterAccepted:
        "J'accepte de recevoir des informations sur les nouveautés et les promotions de McDonald's",
      subject: {
        label: 'Raison',
        placeholder: 'Choisissez-en un',
        option: {
          suggestion: 'Suggestion',
          inquire: 'Demande',
          openDoors: 'Portes ouvertes',
          criticism: 'Critique',
          claim: 'Réclamation',
          praise: 'Louange',
          personalData: 'Données personnelles',
          other: 'Autre',
        },
      },
    },
    toast: {
      error: "Désolé, une erreur inattendue s'est produite. Veuillez réessayer plus tard",
      success: 'Le formulaire de contact a été soumis avec succès',
    },
    action: {
      save: 'Enregistrer',
    },
  },
  rating: {
    stars: {
      help: 'Choisissez de 1 à 5 étoiles pour évaluer votre commande',
      new: {
        title: "Comment s'est déroulée votre expérience avec cette commande?",
      },
      edit: {
        title: 'Continuer à évaluer la dernière commande?',
        notNow: 'Pas maintenant',
        continue: 'Continuer',
      },
    },
    form: {
      rate: "Évaluez votre satisfaction à l'égard de :",
      anyProblem: 'Avez-vous eu des problèmes avec la commande ?',
      send: 'Envoyer',
    },
    finalStep: {
      title: "Merci d'avoir choisi McDonald's!",
      ok: {
        subtitle:
          "Nous apprécions vos commentaires et voulons nous assurer que votre expérience chez McDonald's est la meilleure possible",
      },
      ko: {
        subtitle:
          "Nous voulons nous assurer que votre expérience chez McDonald's est la meilleure possible",
        subtitle2:
          "Si vous avez des questions ou si vous avez besoin d'aide, veuillez contacter notre centre de service à la clientèle - nous sommes là pour vous aider!",
      },
    },
    toasts: {
      NOT_FOUND: "Désolé, la commande n'a pas été trouvée",
      ALREADY_RATED: 'Cette commande a déjà été évaluée',
      RATING_EXPIRED: "Désolé, le délai d'évaluation de cette commande a expiré",
      UNKNOWN: "Désolé, une erreur inattendue s'est produite. Veuillez réessayer plus tard",
    },
  },
  overrideRestaurant: {
    autoselectRestaurantAndArea: {
      title: 'Êtes-vous sûr de vouloir changer de restaurant ?',
      body: 'Si vous souhaitez continuer, les produits du panier actuel seront supprimés',
      accept: 'Continue',
      cancel: 'Annuler',
    },
    autoselectRestaurantAndAreaError: {
      title: "Une erreur s'est produite lors de la sélection du restaurant",
      body: "Le restaurant sélectionné n'est pas disponible. Désolé, veuillez réessayer plus tard",
      accept: 'Continuer',
      cancel: 'Annuler',
    },
  },
  redirection: {
    error: {
      title: 'Quelque chose a mal tourné',
      fallbackLink: 'Cliquez ici pour continuer',
    },
  },
  enrollment: {
    BirthDate: {
      title: 'Date de naissance',
      body: "Il est nécessaire de compléter votre date de naissance afin de vérifier que nos utilisateurs ont l'âge minimum requis pour utiliser l'application",
      dateLabel: 'Date de naissance',
    },
    Name: {
      title: 'Nom et prénom',
      body: 'Comment souhaiteriez-vous que nous vous appelions ?',
      label: {
        name: 'Prénom',
        lastName: 'Nom de famille',
      },
    },
    Document: {
      title: "Document d'identité",
      body: "Entrez votre numéro d'identité.De cette manière, nous pouvons garantir la sécurité de vos informations",
      labelCpfType: 'Type de document',
      dropdownPlaceholder: 'Choisissez un',
      documentLabel: 'Numéro de document',
      warningTitle: "Consultez plus d'informations",
      warningBody: 'Service client',
      warning: {
        title: 'Document déjà enregistré',
        body: "Ce numéro de document est déjà enregistré pour l'email {email}. Pour plus d'informations, veuillez contacter le ",
      },
    },
    Phone: {
      title: 'Numéro de téléphone',
      body: 'Afin de garantir que vous seul ayez accès à votre compte, veuillez entrer votre numéro de téléphone',
      label: {
        prefix: 'Préfixe',
        number: 'Número',
      },
    },
  },
  vouchers: {
    title: 'Vous avez disponibles',
    description: 'Vous pouvez obtenir un code Minecraft si vous avez les points nécessaires',
    redeemCoupon: {
      title: 'Voulez-vous échanger <br/><b>{value} pts.</b> points pour obtenir <br/>un code?',
      accept: 'Je veux obtenir un code',
      cancel: 'Pas maintenant',
    },
    appliedCoupon: {
      title: "Félicitations ! Tu viens d'obtenir ton code Minecraft",
      useCodeInfo: 'Comment utiliser les codes?',
      codeExpiration: 'Ton code expire le',
      accept: 'Retour',
    },
    codeList: {
      title: 'Voir mes codes obtenus',
      description: 'Codes',
      code: 'Code',
      expiration: 'Expiration',
    },
    notification: {
      title: 'Un seul code peut être activé par compte Minecraft',
      description:
        "Échangez vos points du { startDate } au { endDate } ou jusqu'à épuisement du stock de { stock } unités. Le code active 25 add-ons McDonald's dans Minecraft : Bedrock Edition",
    },
    errors: {
      generic: "Quelque chose n'a pas fonctionné comme prévu. Veuillez réessayer plus tard",
      maximumPurchasesExceeded: "Vous avez dépassé le nombre maximum d'achats de codes Minecraft",
      noPointsAvailable:
        "Vous n'avez pas les points nécessaires pour obtenir un code. Il vous faut { value } pts pour l'obtenir",
    },
  },
  stepsReference: 'Étape {current} sur {total}',
  cancellationPolicy: {
    titleClose: 'Détails de commande',
    title: "Politique d'annulation",
    subtext:
      "Malheureusement, des imprévus peuvent survenir et vous pourriez avoir besoin d'annuler votre commande. Ci-dessous, vous trouverez les cas où l'annulation peut être effectuée directement via l'application McDonald's, ainsi que d'autres indications :",
    first: {
      title: 'Commande effectuée via Pickup',
      text1:
        "Vous pourrez demander l'annulation avant de confirmer que vous êtes au restaurant et avant que la commande soit envoyée en préparation. Si la commande a été effectuée par échange de points My McDonald 's, les points seront automatiquement restitués.",
      text2:
        "Si vous avez envoyé une commande en préparation et que vous avez rencontré un imprévu, veuillez contacter notre Service Client (SAC) via les canaux disponibles dans l'application McDonald's - téléphone: {phone}, WhatsApp: {whatsApp} et e-mail: ",
    },
    second: {
      title: 'Commande effectuée via la livraison',
      text1:
        "Une fois la commande confirmée via l'application, la préparation commencera et la commande ne pourra ni être annulée ni modifiée.",
      text2:
        'Si vous avez des questions, des critiques ou des suggestions après la livraison de la commande, veuillez contacter notre Service Client.',
      text3:
        "Si votre commande n'a pas été livrée et qu'elle n'a pas encore été annulée, veuillez contacter notre Service Client.",
    },
    important: {
      title: 'AVIS IMPORTANT',
      text1:
        'Pour finaliser la commande, le livreur demandera TOUJOURS un code de validation de livraison.',
      text2:
        "Ne fournissez pas le code avant de recevoir la commande. Si vous avez fourni le code avant d'avoir la commande en main, et pour une raison quelconque vous ne l'avez pas reçue, aucun remboursement ne sera effectué.",
      text3:
        'Si votre commande est annulée, ne vous inquiétez pas, nous demanderons automatiquement le remboursement du montant de la commande. Le délai estimé pour le remboursement peut varier selon le mode de paiement, comme détaillé ci-dessous :',
      listItem1:
        "Pour les achats effectués par carte de débit, le remboursement sera effectué : Jusqu'à 7 jours ouvrables à compter de la date d'annulation.",
      listItem2:
        "Pour les achats effectués par carte de crédit, le remboursement sera effectué : Jusqu'à 30 jours ouvrables à compter de la date d'annulation et selon la date de clôture de la facture du client.",
      listItem3:
        "Pour les achats effectués via Mercado Pago, le remboursement sera effectué : Jusqu'à 2 jours ouvrables à compter de la date d'annulation.",
      listItem4:
        "Si un autre problème survient, pour que nous puissions évaluer votre demande, n'hésitez pas à nous contacter via les canaux suivants:",
      listItem5: '',
      listItem6: '',
      listItem7: '',
      lastText: '',
      phone: 'Téléphone : {phone}',
      whatsApp: 'WhatsApp : {whatsApp}',
      email: 'E-mail: ',
    },
  },
  markdown: {
    vouchers: {
      terms: termsFR,
    },
  },
}
