const DEFAULT_WINDOW_HEIGHT = 800

export const useResizedWindowHeight = () => {
  const windowHeight = ref(process.client ? window.innerHeight : DEFAULT_WINDOW_HEIGHT)

  const handleResize = () => {
    windowHeight.value = window.innerHeight
  }

  onMounted(() => window.addEventListener('resize', handleResize))
  onUnmounted(() => window.removeEventListener('resize', handleResize))

  return { windowHeight }
}
