import { defineNuxtPlugin } from '#app'
import { ToastConfig } from '~/lib/models/ui/toast'
import { useLayoutStore } from '~/stores/layout.store'

type ToastPlugin = {
  show: (config: ToastConfig) => void
  clearAll: () => void
}

declare module '#app' {
  interface NuxtApp {
    $toast: ToastPlugin
  }
}

export default defineNuxtPlugin(() => {
  const layoutStore = useLayoutStore()

  const toast: ToastPlugin = {
    show: layoutStore.addToast,
    clearAll: layoutStore.clearAllToasts,
  }

  return {
    provide: {
      toast,
    },
  }
})
