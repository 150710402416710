export const LOYALTY_DATA_ONBOARDING_KEYS = {
  // legacy phone error
  BIRTHDATE_STR: 'birthDateStr',
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  CPF: 'cpf',
  DOCUMENT_TYPE: 'documentType',
  PHONE_NUMBER_SUFIX: 'phoneNumberSufix',
} as const

export const SCREENS_TITLES = {
  // legacy phone error
  BIRTHDATE: 'BirthDate',
  DOCUMENT: 'Document',
  PHONE: 'Phone',
  NAME: 'Name',
} as const

export const SCREENS_FIELDS = {
  // legacy phone error
  BIRTHDATE_STR: 'birthDateStr',
  PHONE: 'phone',
  NAME: 'name',
  LASTNAME: 'lastName',
  CPF: 'cpf',
  DOCUMENT_TYPE: 'documentType',
  OPT_IN_CPF: 'optInCpf',
} as const
