import { publicConfig } from '~/config/public'

export function useSiteDomain({ protocol = false, noOverride = false } = {}) {
  const overridenDomain = useCookie('domain')

  const userDomain = process.client ? window.location.host : useRequestHeaders().host

  const _protocol = process.env.NODE_ENV === 'production' ? 'https://' : 'http://'

  const domain =
    !noOverride &&
    (publicConfig.debug.overridableDomain || userDomain === 'mcd-landings-l.appmcdonalds.com') &&
    overridenDomain.value
      ? overridenDomain.value
      : `${protocol ? _protocol : ''}${userDomain}`

  return String(domain).trim()
}

export const getQueryString = () => {
  const fullPath = useRoute().fullPath
  const posQueryParams = fullPath.indexOf('?')

  if (posQueryParams !== -1) {
    const queryString = fullPath.substring(posQueryParams)
    return queryString
  }
  return undefined
}
