import { StoreService } from '../store.service'
import { countryConfigSchemaDto } from './country.dtos'

class CountryService extends StoreService {
  constructor(c: string) {
    super('country', c)
  }

  async getConfigByCountry({ countryCode }: { countryCode: string }) {
    const res = await this.request(
      {
        url: `/countries`,
        headers: {
          'x-app-country': countryCode,
        },
      },
      countryConfigSchemaDto
    )
    return res
  }
}

export const countryService = (country: string) => new CountryService(country)
