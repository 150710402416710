<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    fill?: string
    width?: string
    height?: string
  }>(),
  {
    fill: '#292929',
    width: '16',
    height: '16',
  }
)
</script>

<template>
  <svg
    class="cursor-pointer"
    data-test="close-icon"
    :width="width"
    :height="height"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5509 24.7834C27.1367 25.3692 27.1367 26.3189 26.5509 26.9047L25.8438 27.6118L13.9999 15.7679L3.21661 26.5512C2.63082 27.137 1.68108 27.137 1.09529 26.5512L0.388184 25.8441L12.2321 14.0001L1.44866 3.21661C0.862872 2.63082 0.862871 1.68108 1.44866 1.09529L2.15576 0.388184L13.9999 12.2323L24.7834 1.44888C25.3692 0.863092 26.3189 0.863091 26.9047 1.44888L27.6118 2.15598L15.7677 14.0001L26.5509 24.7834Z"
      :fill="props.fill"
    />
  </svg>
</template>
