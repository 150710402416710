import Debug from 'debug'
import { publicConfig } from '~/config/public'

const debug = Debug('app:initialization')

if (publicConfig.debug.enabled) {
  Debug.enable('app*')
  // eslint-disable-next-line no-console
  console.log('%cmcd-landings', 'font-size:20px;color:#ffbc0d;font-weight:bold')
  debug('config', JSON.parse(JSON.stringify(publicConfig)))
} else {
  Debug.disable()
  if (process.client) localStorage.removeItem('debug')
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      debug: Debug('app:debug'),
    },
  }
})
