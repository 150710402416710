import { LocalizationStore } from '~/lib/services/content/content.configuaration.dtos'

export const useCountry = () => {
  const contentStore = useContentStore()
  const config = contentStore.site?.configuration
  const localization = config?.find(
    config => config.__component === 'configuration.localization'
  ) as LocalizationStore

  return localization?.country || 'AR'
}
