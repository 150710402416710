import pickBy from 'lodash/pickBy'
import { RouteRecordRaw, RouteParams, RouteLocationNormalizedLoaded } from 'vue-router'
import { ComposerTranslation } from 'vue-i18n'
import capitalize from 'lodash/capitalize'
import { Page, Site } from './services/content/content.dtos'
import { Messages } from '~/plugins/2.i18n'

const HOME_ROUTE = 'home'
const PAGE_ROUTE = ':page(.*)*'
const MENU_ROUTE_PREFIX = '/menu'
const FAQS_ROUTE_PREFIX = 'faqs'

type Seo = Site['SEO']
type Router = RouteLocationNormalizedLoaded & { routes: readonly RouteRecordRaw[] }

function mergeObjects(siteSeo: Seo, otherSeo: Partial<Seo>) {
  if (!siteSeo && !otherSeo) return {}
  if (!siteSeo) return otherSeo
  if (!otherSeo) return siteSeo

  const cleanOtherSeo = pickBy(otherSeo)
  return { ...siteSeo, ...cleanOtherSeo }
}

function checkIfKeyIsPresent(str: string, key: string) {
  if (str.includes(key)) return null
  return str
}

const getDynamicValue = (value: string | string[] | undefined) => {
  return Array.isArray(value) ? '' : value?.replaceAll('-', ' ')
}

function getDynamicValues(routeParams: RouteParams) {
  const city = getDynamicValue(routeParams?.citySlug)
  const restaurant = getDynamicValue(routeParams?.restaurantSlug)
  const product = getDynamicValue(routeParams?.productSlug)
  const category = getDynamicValue(routeParams?.categorySlug)
  const section = getDynamicValue(routeParams?.sectionSlug)
  const orderId = getDynamicValue(routeParams?.orderId)
  let area = getDynamicValue(routeParams?.areaSlug)
  if (area === 'mcdelivery') area = 'Delivery'

  return {
    city: capitalize(city),
    restaurant: capitalize(restaurant),
    product: product ? product.split(' ').map(capitalize).join(' ') : '',
    category: category ? category.split(' ').map(capitalize).join(' ') : '',
    area: capitalize(area),
    section: capitalize(section),
    orderId: orderId || '',
  }
}

function getEcommerceMeta(router: Router, site: Seo, t: ComposerTranslation<Messages>) {
  const key = (router.name as String).replaceAll('-', '.').replaceAll('Slug', '')
  const ecommerceSeo = {
    title: checkIfKeyIsPresent(t(`SEO.${key}.title`, getDynamicValues(router.params)), key),
    description: checkIfKeyIsPresent(
      t(`SEO.${key}.description`, {
        ...getDynamicValues(router.params),
        pickup: t('common.MOP').toLowerCase(),
      }),
      key
    ),
    preventIndexing: false,
  } as Partial<Seo>

  return mergeObjects(site, ecommerceSeo) as Seo
}

export function getHead({
  site,
  page,
  locale,
  origin,
  t,
  router,
}: {
  site: Site | undefined
  page: Page | undefined
  locale: string
  origin: string
  t: ComposerTranslation<Messages>
  router: Router
}) {
  const ecommerceRoutes = [...new Set(router.routes.map(route => route.path.split('/')[1]))].filter(
    route => route !== HOME_ROUTE && route !== PAGE_ROUTE
  )

  let currentRouteName = (router.name as string)?.split('-')[0]
  if (currentRouteName === 'my') currentRouteName = 'my-account'

  let meta = mergeObjects(site?.SEO, page?.SEO) as Seo

  if (!site && !page) {
    return {
      title: 'Loading...',
    }
  }

  if (ecommerceRoutes.includes(currentRouteName)) {
    meta = getEcommerceMeta(router, site?.SEO, t)
    // Add canonical on menu, or /faqs route
    if (
      meta &&
      (router.path.includes(MENU_ROUTE_PREFIX) ||
        router.path.includes(FAQS_ROUTE_PREFIX) ||
        ['restaurants', 'restaurants-citySlug', 'restaurants-citySlug-restaurantSlug'].includes(
          router.name
        ))
    )
      meta.canonical = `${origin}${router.path}`
  } else if (!meta) {
    meta = { image: null, metaTags: null, canonical: `${origin}${router.path}` }
  } else {
    meta.canonical = `${origin}${router.path}`
  }
  // if is a 404 page dont include canonical
  if (router.name === 'page' && !page && meta?.canonical) meta.canonical = undefined

  const metaTitle = meta?.title || site?.SEO?.title || site?.name || ''
  const metaDescription = meta?.description || site?.SEO?.title || site?.name || ''

  const ogTitle = meta?.title || metaTitle
  const ogDescription = meta?.description || metaTitle
  const imgUrl = meta?.image?.url || ''
  const ogImage = !imgUrl ? '' : imgUrl.includes('http') ? imgUrl : `${origin}${imgUrl}`
  const ogImageAlt = meta?.imageAlt || ogTitle

  const link = []
  if (meta?.canonical) link.push({ rel: 'canonical', href: meta?.canonical })
  if (site?.favicon?.url) {
    link?.push({ rel: 'icon', type: 'image/x-icon', href: site.favicon.url })
  } else {
    link?.push({ rel: 'icon', type: 'image/x-icon', href: '/favicon.png' })
  }

  const head = {
    htmlAttrs: {
      lang: locale,
    },
    title: metaTitle,
    meta: [
      {
        hid: 'description', // prevent duplicate tag (meta in nuxt.config)
        name: 'description',
        content: metaDescription,
      },
      { property: 'og:title', content: ogTitle },
      { property: 'og:description', content: ogDescription },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: origin },
      { property: 'og:image', content: ogImage },
      { property: 'og:image:alt', content: ogImageAlt },
      { name: 'robots', content: meta?.preventIndexing ? 'noindex, nofollow' : 'index, follow' },
    ],
    link,
    script: [],
  }

  if (meta?.metaTags && Array.isArray(meta?.metaTags)) {
    for (const metaTag of meta?.metaTags) {
      const { name, content } = metaTag
      head.meta?.push({ name, content })
    }
  }

  return head
}
