import { z } from 'zod'

export const ToastType = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
} as const

export const ToastLocation = {
  TOP: 'top',
  BOTTOM: 'bottom',
} as const

export const defaultToastDuration = 5

export const toastSchema = z.object({
  key: z.string(),
  type: z.string().default(ToastType.SUCCESS),
  location: z.string().default(ToastLocation.TOP),
  duration: z.number().default(defaultToastDuration),
  content: z.string(),
  // Add link for actions in a future
})

export type Toast = z.infer<typeof toastSchema>

export interface ToastConfig {
  type?: string
  location?: string
  duration?: number
  content: string
  // add link for actions in a future
  once?: boolean
}
