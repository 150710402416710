import { computed, getCurrentInstance } from 'vue'

export const useVModel = (props: any, propName: string, fnGetter?: Function) => {
  const vm = getCurrentInstance()?.proxy

  return computed({
    get() {
      if (fnGetter) return fnGetter(props[propName])
      return props[propName]
    },
    set(value) {
      vm?.$emit(`update:${propName}`, value)
    },
  })
}
