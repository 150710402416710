import { z } from 'zod'

export const sessionSchema = z.object({
  sessionId: z.number(),
  applicationId: z.string(),
})

export const userDeviceDto = z.object({
  brand: z.string().nullable(),
  browser: z.string().nullable(),
  createdAt: z.string(),
  customerId: z.string(),
  deviceIdentifier: z.string(),
  deviceIdentifierId: z.string(),
  ip: z.string().nullable(),
  location: z.string().nullable(),
  model: z.string().nullable(),
  os: z.string().nullable(),
  sessionsIds: z.array(sessionSchema),
  updatedAt: z.string(),
  verifiedAt: z.string().nullable(), // Date
})

export const userDeviceReqDto = z.object({
  docs: z.array(userDeviceDto),
})

export const userDevicesDto = userDeviceReqDto
export type UserDevice = z.infer<typeof userDeviceDto>
