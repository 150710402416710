import { z } from 'zod'
import { imageDto, linkDto } from './content.shared.dtos'
import { menuItemsDto } from './footer/footer.dtos'

/* Title renderAs dto */

export const HtmlHeadings = {
  H1: 'heading-1',
  H2: 'heading-2',
  H3: 'heading-3',
  H4: 'heading-4',
  H5: 'heading-5',
  H6: 'heading-6',
} as const

export const titleRenderAsUnion = z.union([
  z.literal(HtmlHeadings.H1),
  z.literal(HtmlHeadings.H2),
  z.literal(HtmlHeadings.H3),
  z.literal(HtmlHeadings.H4),
  z.literal(HtmlHeadings.H5),
  z.literal(HtmlHeadings.H6),
])

export type TitleRenderAsType = z.infer<typeof titleRenderAsUnion>

/**
 * Blocks
 */
const baseBlockDto = z.object({ id: z.number() })

/**
 * Card
 */
const blockCardDto = baseBlockDto.extend({
  __component: z.literal('block.card'),
  buttonAction: linkDto.nullable().optional(),
  image: imageDto.nullable().optional(),
})
export type BlockCard = z.infer<typeof blockCardDto>

/**
 * Rich Text
 */
const blockRichTextDto = baseBlockDto.extend({
  __component: z.literal('block.rich-text'),
  text: z.string(),
})
export type BlockRichText = z.infer<typeof blockRichTextDto>

/**
 * Carousel
 */

// Carousel Wide
const carouselSlideWideDto = z.object({
  slideTitle: z.string(),
  image: imageDto,
  buttonAction: linkDto.nullable().optional(),
  buttonCustomPosition: z.string(),
})
export type CarouselSlideWide = z.infer<typeof carouselSlideWideDto>

const blockCarouselWideDto = baseBlockDto.extend({
  __component: z.literal('block.carousel-wide'),
  autoplay: z.boolean(),
  autoplayDelaySeconds: z.number(),
  slides: z.array(carouselSlideWideDto),
})
export type BlockCarouselWide = z.infer<typeof blockCarouselWideDto>

/**
 * Accordion
 */

// Collapsable element inside accordion
const collapsableDto = z.object({
  id: z.number().nullable(),
  title: z.string(),
  text: z.string(),
})
export type Collapsable = z.infer<typeof collapsableDto>

export const blockAccordionDto = baseBlockDto.extend({
  __component: z.literal('block.accordion'),
  id: z.number().nullable(),
  title: z.string(),
  elements: z.array(collapsableDto),
})
export type BlockAccordion = z.infer<typeof blockAccordionDto>

/**
 * Collapsable Menu
 * accordion: omit __component from blockAccordionDto because it's not needed
 * when it's inside a collapsable menu
 */

const faqsItemDto = z.object({
  id: z.number(),
  title: z.string(),
  icon: imageDto.nullable(),
  accordion: blockAccordionDto.omit({ __component: true }),
})
export type FaqsItem = z.infer<typeof faqsItemDto>

export const blockCollapsableMenuDto = baseBlockDto.extend({
  __component: z.literal('block.collapsable-menu'),
  id: z.number(),
  title: z.string(),
  faqsItem: z.array(faqsItemDto),
  commonFaqs: z
    .object({
      id: z.number(),
      title: z.string(),
      elements: z.array(collapsableDto),
    })
    .nullable(),
  menuItems: z.array(menuItemsDto).nullable(),
})

export type BlockCollapsableMenu = z.infer<typeof blockCollapsableMenuDto>

/**
 * Text Full Screen Block
 */
export const blockTextFullScreenDto = baseBlockDto.extend({
  __component: z.literal('block.text-full-screen'),
  title: z.string().nullable().optional(),
  content: z.string().nullable().optional(),
  backgroundColor: z.string().nullable().optional(),
  primaryBtn: linkDto.nullable().optional(),
  secondaryBtn: linkDto.nullable().optional(),
})

export type BlockTextFullScreen = z.infer<typeof blockTextFullScreenDto>

/**
 * Featured Tile
 */
export const blockFeaturedTileDto = baseBlockDto.extend({
  __component: z.literal('block.featured-tile'),
  id: z.number(),
  title: z.string(),
  titleRenderAs: titleRenderAsUnion.nullable().optional().default(HtmlHeadings.H2),
  content: z.string().nullable().optional(),
  imageDesktop: imageDto,
  imageMobile: imageDto,
  imagePosition: z.enum(['left', 'right']),
  bulletPoints: z
    .object({
      title: z.string(),
      bulletPointsList: z.array(z.object({ id: z.number(), content: z.string() })),
    })
    .nullable()
    .optional(),
})

export type FeaturedTile = z.infer<typeof blockFeaturedTileDto>

/**
 * Background Tiles
 */
export const BackgroundTileDto = baseBlockDto.extend({
  title: z.string(),
  content: z.string(),
  primaryBtn: linkDto.nullable().optional(),
  secondaryBtn: linkDto.nullable().optional(),
  tertiaryBtn: linkDto.nullable().optional(),
  imageDesktop: imageDto,
  imageMobile: imageDto,
})

export type BackgroundTile = z.infer<typeof BackgroundTileDto>

export const blockBackgroundTilesDto = baseBlockDto.extend({
  __component: z.literal('block.background-tiles'),
  backgroundTiles: z.array(BackgroundTileDto),
})

export type BackgroundTiles = z.infer<typeof blockBackgroundTilesDto>

/**
 * Marketing Cards
 */
export const MarketingCardDto = baseBlockDto.extend({
  title: z.string().nullable().optional(),
  url: z.string(),
  blank: z.boolean(),
  download: z.boolean(),
  imageDesktop: imageDto,
  imageMobile: imageDto,
})

export const blockMarketingCardsDto = baseBlockDto.extend({
  __component: z.literal('block.marketing-cards'),
  marketingCards: z.array(MarketingCardDto),
})

export type MarketingCards = z.infer<typeof blockMarketingCardsDto>

/**
 * Header Tiles Block
 */
export const blockHeaderTileDto = baseBlockDto.extend({
  __component: z.literal('block.header-tile'),
  title: z.string(),
  content: z.string().nullable().optional(),
  primaryBtn: linkDto.nullable().optional(),
  secondaryBtn: linkDto.nullable().optional(),
  imageDesktop: imageDto.nullable().optional(),
  imageMobile: imageDto.nullable().optional(),
})

export type BlockHeaderTile = z.infer<typeof blockHeaderTileDto>

/**
 *  Tiles Banner Full Block
 */
export const blockTileBannerFullDto = baseBlockDto.extend({
  __component: z.literal('block.tile-banner-full'),
  title: z.string(),
  primaryBtn: linkDto.nullable().optional(),
  content: z.string(),
  backgroundColor: z.enum(['yellow', 'white']),
  imagePosition: z.enum(['left', 'right']),
  imageType: z.enum(['imageFull', 'imagePartial']),
  imageDesktop: imageDto,
  imageMobile: imageDto,
  isShownWide: z.boolean().nullable().optional(),
})

export type BlockTileBannerFull = z.infer<typeof blockTileBannerFullDto>

/**
 *  3 Scroll Cards Block
 */
export const fragmentScrollCardDto = baseBlockDto.extend({
  title: z.string(),
  subTitle: z.string(),
  image: imageDto,
  url: z.string().nullable().optional(),
  blank: z.boolean().nullable().optional(),
  download: z.boolean().nullable().optional(),
})

export const blockScrollCardsDto = baseBlockDto.extend({
  __component: z.literal('block.scroll-cards'),
  titleRenderAs: titleRenderAsUnion.nullable().optional().default(HtmlHeadings.H3),
  scrollBtnColor: z.enum(['white', 'yellow', 'black']).nullable().optional(),
  positionCard: z.enum(['top', 'center', 'bottom']).nullable().optional(),
  scrollCards: z.array(fragmentScrollCardDto),
})

export type BlockScrollCards = z.infer<typeof blockScrollCardsDto>

/**
 *  Tiles 2 Banner Block
 */
export const fragmentTileBannerDto = baseBlockDto.extend({
  title: z.string(),
  content: z.string(),
  primaryBtn: linkDto.nullable().optional(),
  imageDesktop: imageDto.nullable().optional(),
  imageMobile: imageDto.nullable().optional(),
})

export const blockBannerTilesDto = baseBlockDto.extend({
  __component: z.literal('block.banner-tiles'),
  bannerTiles: z.array(fragmentTileBannerDto),
})

export type BlockBannerTiles = z.infer<typeof blockBannerTilesDto>

/**
 *  Slider Full Text Block
 */
export const fragmentSliderFullTextDto = baseBlockDto.extend({
  title: z.string(),
  content: z.string(),
  imageDesktop: imageDto,
  imageMobile: imageDto,
})

export const blockSliderFullTextsDto = baseBlockDto.extend({
  __component: z.literal('block.slider-full-texts'),
  sliderFullTexts: z.array(fragmentSliderFullTextDto),
})

export type BlockSliderFullTexts = z.infer<typeof blockSliderFullTextsDto>

/**
 * ContactForm
 */
const blockContactFormDto = baseBlockDto.extend({
  __component: z.literal('block.contact-form'),
  id: z.number(),
})
export type BlockContactForm = z.infer<typeof blockContactFormDto>

/**
 * Redirection Block
 *  */

const blockRedirection = baseBlockDto.extend({
  __component: z.literal('block.redirection'),
  destination: z.string(),
  instantRedirect: z.boolean(),
  countdownMessage: z.string(),
  forwardedQueryParams: z.string(),
})

export type BlockRedirection = z.infer<typeof blockRedirection>

/**
 * Union
 */
export const blockDto = z.discriminatedUnion('__component', [
  blockCardDto,
  blockRichTextDto,
  blockCarouselWideDto,
  blockAccordionDto,
  blockCollapsableMenuDto,
  blockFeaturedTileDto,
  blockBackgroundTilesDto,
  blockMarketingCardsDto,
  blockTextFullScreenDto,
  blockHeaderTileDto,
  blockTileBannerFullDto,
  blockSliderFullTextsDto,
  blockBannerTilesDto,
  blockScrollCardsDto,
  blockContactFormDto,
  blockRedirection,
])
export type Block = z.infer<typeof blockDto>

export type BlockKeyUnion = Block['__component'] extends `${string}.${infer Rest}` ? Rest : never
