import { Buffer } from 'buffer'
import CryptoJS from 'crypto-js'
import { isCypressSpecialBehaviour } from '~/utils/uiUtils'

export const decryptMdwConfig = (data: string) => {
  try {
    // For cypress tests parse response.data as json
    if (isCypressSpecialBehaviour()) return JSON.parse(data)
    const decoded = Buffer.from(data, 'base64')
    const secret = decoded.subarray(0, 32)
    const iv = decoded.subarray(32, 48)
    const encrypted = Buffer.from(decoded.subarray(48).toString('utf8'), 'base64')

    const key = CryptoJS.enc.Hex.parse(secret.toString('hex'))
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(encrypted.toString('base64')),
      iv: CryptoJS.enc.Hex.parse(iv.toString('hex')),
    })

    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
      iv: CryptoJS.enc.Hex.parse(iv.toString('hex')),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    })

    const resultString = decrypted.toString(CryptoJS.enc.Utf8).toString()
    // WIP - resultString has some last unicode? chars unknown and break JSON.parse
    const lastExtraUnicodeChars = resultString.slice(resultString.lastIndexOf('}') + 1)
    const sliced = resultString.replace(lastExtraUnicodeChars, '')
    const json = JSON.parse(sliced)

    // zod parse
    return json
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ file: cryptoUtils.ts:32 ~ decryptMdwConfig ~ error:', error)
    return undefined
  }
}
