<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    max: number
    min: number
    count: number
    showDelete?: boolean
    showAlwaysDelete?: boolean
    small?: boolean
    disableAdd?: boolean
    disableSubstract?: boolean
  }>(),
  {
    showDelete: true,
    showAlwaysDelete: false,
    small: true,
    disableAdd: false,
    disableSubstract: false,
  }
)
const emit = defineEmits(['update:count', 'delete'])

const countState = useVModel(props, 'count')

const mustShowDelete = computed(() => {
  if (props.showAlwaysDelete) return true
  return props.showDelete && countState.value === props.min
})
const canAddValue = computed(() => {
  if (props.disableAdd) return false
  return !props.max || countState.value < props.max
})
const canSubstractValue = computed(() => {
  if (props.disableSubstract) return false
  return countState.value > props.min
})

const substractValue = () => {
  const count = countState.value - 1
  countState.value = count
}

const addValue = () => {
  const count = countState.value + 1
  countState.value = count
}

const deleteItem = () => {
  emit('delete')
}
</script>
<template>
  <div
    data-test="store-quantity-selector"
    class="my-2 flex items-center justify-between rounded-2xl border border-gray-500 p-2"
    :class="{ 'store-quantity-selector': !small, 'store-quantity-selector--small': small }"
  >
    <button
      v-if="mustShowDelete"
      data-test="quantity-button--delete"
      class="quantity-button"
      @click="deleteItem()"
    >
      <img src="/images/icons/trash.svg" alt="delete" />
    </button>
    <button
      v-else
      data-test="quantity-button--substract"
      class="quantity-button"
      :disabled="!canSubstractValue"
      @click="substractValue"
    >
      <img src="/images/icons/substract.svg" alt="substract" />
    </button>
    <div class="store-quantity-value">{{ count }}</div>
    <button
      data-test="quantity-button--add"
      class="quantity-button"
      :disabled="!canAddValue"
      @click="addValue"
    >
      <img src="/images/icons/add.svg" alt="add" />
    </button>
  </div>
</template>

<style scoped>
.store-quantity-selector {
  @apply h-[28px] min-w-[90px] md:h-[35px] md:min-w-[100px];
}
.store-quantity-selector--small {
  @apply h-[28px] min-w-[90px] max-w-[90px];
}
.store-quantity-selector--small .quantity-button {
  @apply h-auto w-[20px];
}
.store-quantity-selector .quantity-button {
  @apply h-auto w-[24px];
}

.quantity-button[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>
