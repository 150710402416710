export const useFormatAmount = () => {
  const currencyConfig = computed(() => useAppStore().currencyConfig)

  const format = (amount: number) => formatAmount(amount, currencyConfig.value)
  const getCurrencyAcronym = () => currencyConfig.value?.currencyAcronym || ''
  const getCurrencySymbol = () => currencyConfig.value?.currencySymbol || ''

  return {
    format,
    getCurrencyAcronym,
    getCurrencySymbol,
  }
}
