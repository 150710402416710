import { Coords } from '../models/geolocation'
import { RestaurantLite } from '../services/store/restaurant/restaurant.dto'

export type RestaurantLiteWithDistance = RestaurantLite & {
  distance?: number
  ecommerce?: boolean
  isFavorite?: boolean
}
export type RestaurantLiteWithDistanceList = RestaurantLiteWithDistance[]

export type SelectedLocation =
  | google.maps.places.AutocompletePrediction
  | google.maps.GeocoderResult

export type SelectedLocationWithCoords = SelectedLocation & Coords

export const RestaurantAreas = {
  MOP: 'MOP',
  DLV: 'DLV',
} as const

export type RestaurantAreaType = (typeof RestaurantAreas)[keyof typeof RestaurantAreas]

export const StoreRestaurantAreas = {
  CURB: 'curb',
  AUT: 'aut',
  EALM: 'ealm',
  MOP: 'mop',
  DLV: 'dlv',
} as const

export type StoreRestaurantAreasUnion =
  (typeof StoreRestaurantAreas)[keyof typeof StoreRestaurantAreas]

export const RestaurantScheduleTypes = {
  OPEN: 'OPEN',
  NEAR_TO_CLOSE: 'NEAR_TO_CLOSE',
  ONLY_LOCAL: 'ONLY_LOCAL',
  CLOSED: 'CLOSED',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
} as const

export type RestaurantScheduleTypesUnion =
  (typeof RestaurantScheduleTypes)[keyof typeof RestaurantScheduleTypes]

export type OpenServices = {
  [StoreRestaurantAreas.CURB]: boolean
  [StoreRestaurantAreas.AUT]: boolean
  [StoreRestaurantAreas.EALM]: boolean
  [StoreRestaurantAreas.MOP]: boolean
  [StoreRestaurantAreas.DLV]: boolean
}

export type OpenServicesState = {
  [StoreRestaurantAreas.CURB]: RestaurantScheduleTypesUnion
  [StoreRestaurantAreas.AUT]: RestaurantScheduleTypesUnion
  [StoreRestaurantAreas.EALM]: RestaurantScheduleTypesUnion
  [StoreRestaurantAreas.MOP]: RestaurantScheduleTypesUnion
  [StoreRestaurantAreas.DLV]: RestaurantScheduleTypesUnion
}

export type RestaurantGeneralSchedule = {
  start: string
  end: string
}

export type RestaurantSchedule = {
  hourOpen: string
  hourClose: string
}

export const RestaurantWeeklyDays = {
  MONDAY: 'mon',
  TUESDAY: 'tue',
  WEDNESDAY: 'wed',
  THURSDAY: 'thu',
  FRIDAY: 'fri',
  SATURDAY: 'sat',
  SUNDAY: 'sun',
} as const

export type RestaurantWeeklyDaysUnion =
  (typeof RestaurantWeeklyDays)[keyof typeof RestaurantWeeklyDays]

export const RestaurantWeeklyDaysOrder = [
  RestaurantWeeklyDays.MONDAY,
  RestaurantWeeklyDays.TUESDAY,
  RestaurantWeeklyDays.WEDNESDAY,
  RestaurantWeeklyDays.THURSDAY,
  RestaurantWeeklyDays.FRIDAY,
  RestaurantWeeklyDays.SATURDAY,
  RestaurantWeeklyDays.SUNDAY,
]

export type StoreRestaurantOpenState = OpenServicesState

export type GeneralServiceHourDayOfWeek = {
  day: RestaurantWeeklyDaysUnion
  timePeriods: RestaurantGeneralSchedule[]
}

export type GeneralHourTimeSlots = {
  daysOfWeek: GeneralServiceHourDayOfWeek[]
}

export type GeneralHoursByDay = {
  day: RestaurantWeeklyDaysUnion
  schedule: string
}

export type ServiceHourDayOfWeek = {
  day: RestaurantWeeklyDaysUnion
  timePeriods: RestaurantSchedule[]
}

/* Mismatching restaurant and area */

export const MismatchingState = {
  NO_RESTAURANT_SELECTED: 'NO_RESTAURANT_SELECTED',
  MISMATCHING_AREA: 'MISMATCHING_AREA',
  MISMATCHING_RESTAURANT: 'MISMATCHING_RESTAURANT',
  MISMATCHING_AREA_RESTAURANT: 'MISMATCHING_AREA_RESTAURANT',
} as const

export type MismatchingStateType = (typeof MismatchingState)[keyof typeof MismatchingState]

export const structureRestaurantWeeklyDays = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
} as const

export type OpeningHoursSpecificationRestaurant = {
  '@type': string
  'dayOfWeek': string
  'opens': string
  'closes': string
}
