import axios from 'axios'
import { BaseService } from '../base.service'

import { loyaltyDto, loyaltyPointsDto } from './loyalty.dto'
import { publicConfig } from '~/config/public'
import logRequests from '~/lib/axiosUtils'

class LoyaltyService extends BaseService {
  readonly client
  readonly baseURL

  constructor() {
    super()
    this.baseURL = publicConfig.services.store.loyalty.url
    this.client = axios.create({
      baseURL: this.baseURL,
      headers: {
        'x-app-version': 'web-2.0.0',
      },
    })
    logRequests('loyalty', this.client)
  }

  async registerLoyalty({ accessToken, country }: { accessToken: string; country: string }) {
    return this.request(
      {
        method: 'POST',
        url: 'customer/opt-in',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'x-app-country': country,
        },
      },
      loyaltyDto
    )
  }

  async getPoints({ accessToken, country }: { accessToken: string; country: string }) {
    const res = await this.request(
      {
        method: 'GET',
        url: '/customer/points',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'x-app-country': country,
        },
      },
      loyaltyPointsDto
    )
    return res
  }
}

export const loyaltyService = new LoyaltyService()
