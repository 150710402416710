import cloneDeep from 'lodash/cloneDeep'
import {
  Category,
  OptionChild,
  OptionMain,
  OptionsGroupsChild,
  OptionsGroupsMain,
  ProductEcommerce,
  ProductEcommerceLite,
  ProductPrice,
  ProductTypes,
} from '~/lib/services/store/catalog/catalog.dtos'
import {
  OptionsGroupsDBMain,
  ProductDB,
  ProductDBPrice,
} from '~/lib/services/store/catalog/cart.dtos'
import { CurrencyConfig, DecimalBehaviour } from '~/lib/services/store/country/country.dtos'
import {
  OpenServicesState,
  RestaurantScheduleTypes,
  StoreRestaurantAreasUnion,
} from '~/lib/interfaces/restaurant'
import { StoreOrder } from '~/lib/services/store/order/order.dto'
import {
  OptionsGroupsType,
  OptionDTO,
  OptionsGroupsMainDTO,
  ProductDTO,
} from '~/lib/services/store/common.dto'

/**
 * Slugify strings for url
 *  */
export const slugify = (text: string): string =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')

export const getRouteParams = (
  routeParams: { [key: string]: string | string[] },
  params: string[]
) => {
  const extracted: { [key: string]: string } = {}
  params.forEach(param => {
    const routeParam = routeParams[param] || ''
    if (routeParam) {
      const value = Array.isArray(routeParam) ? routeParam[0] : routeParam
      extracted[param] = value
    }
  })
  return extracted
}

/**
 * Price formatting
 */
export const setDecimals = (amount: number, config?: CurrencyConfig): number => {
  const maxDecimals = !config?.allowDecimals ? 0 : config.decimalsQty
  const decimalBehaviour = config?.decimalBehaviour || DecimalBehaviour.TRUNCATE
  const base = 10
  const fractionedAmount = amount / base
  const operator = Math.pow(base, maxDecimals - 1)
  const actions: { [key: string]: () => number } = {
    [DecimalBehaviour.TRUNCATE]: () => Math.trunc(fractionedAmount * operator) / operator,
    [DecimalBehaviour.ROUND_UP]: () => Math.ceil(fractionedAmount * operator) / operator,
    [DecimalBehaviour.ROUND_GENERIC]: () => Math.round(fractionedAmount * operator) / operator,
  }
  return actions[decimalBehaviour]() * base
}

export function formatAmount(quantity: number, config?: CurrencyConfig): string {
  if (!config) return ''
  const { language, countryCode, currencyAcronym, currencySymbol } = config
  if (!language || !countryCode || !currencyAcronym) return ''
  const amountRounded = setDecimals(quantity, config)
  const maxDecimals = !config?.allowDecimals ? 0 : config.decimalsQty
  const total = amountRounded / 100
  // language is in format 'es_ar' or 'pt'
  const lang = language.split('_')[0]
  const locale = `${lang}-${countryCode}`
  const currency = currencyAcronym
  const style = 'currency'

  const formatter = new Intl.NumberFormat(locale, {
    style,
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: maxDecimals,
    maximumFractionDigits: maxDecimals,
  })
  const fraction = new Intl.NumberFormat(locale, {
    style,
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: maxDecimals,
    maximumFractionDigits: maxDecimals,
  })

  const formattedWithCode = Number.isInteger(total)
    ? fraction.format(total)
    : formatter.format(total)
  return formattedWithCode.replace(currency, currencySymbol)
}

/* Price calculation */
// helper functions
export const getAllOptions = (product: ProductEcommerce): OptionMain[] => {
  const allGroups = product.optionsGroups || []
  return allGroups.reduce(
    (prev: OptionMain[], cur) => prev.concat(getFilteredOptionsOnlySelectedMain(cur) || []),
    []
  )
}
export const getAllThirdLevelOptions = (product: ProductEcommerce): OptionChild[] => {
  const allOptions = getAllOptions(product)
  const allThirdLevelGroups: OptionsGroupsChild[] = allOptions.reduce(
    (prev: OptionsGroupsChild[], cur) => prev.concat(cur.optionsGroups || []),
    []
  )
  return (
    allThirdLevelGroups
      .reduce(
        (prev: OptionChild[], cur) => prev.concat(getFilteredOptionsOnlySelectedChild(cur) || []),
        []
      )
      // filter leave 4º level options not present already in allOptions
      .filter(optChild => !allOptions.some(opt => opt.identifier === optChild.identifier))
  )
}
export const getOptionAmount = (option: OptionMain | OptionChild): number => {
  const extra = option.qty - option.initialQty
  return extra > 0 ? extra * option.price?.amount : 0
}

export const getProductExtrasAmount = (product: ProductEcommerce) => {
  if (!product) return 0
  const allOptions = getAllOptions(product)
  const allThirdLevelOptions = getAllThirdLevelOptions(product)

  const optionsBill: number[] = allOptions.map(option => getOptionAmount(option))
  const thirdLevelBill: number[] = allThirdLevelOptions.map(option => getOptionAmount(option))
  const optionsAmount = optionsBill.reduce((prev, cur) => prev + cur, 0)
  const thirdLevelAmount = thirdLevelBill.reduce((prev, cur) => prev + cur, 0)
  return optionsAmount + thirdLevelAmount
}

export const getProductBaseAmount = (product: ProductEcommerce) => {
  if (!product) return 0
  return product.price?.amount || 0
}

export const getProductTotalAmount = (product: ProductEcommerce) => {
  let extras = 0
  if (!product.unifiedPrice) extras = getProductExtrasAmount(product)
  else {
    // when unifiedPrice product base is 0
    // compute here like almost one option of product is selected (one with minimun value) to compute at least unifiedPrice.amount
    const clone = cloneDeep(product)
    const prodOptGroupsTouched = clone.optionsGroups.map(optGM => {
      if (optGM.type !== OptionsGroupsType.CHOICE) return optGM
      if (!optGM.options.length || optGM.options.some(optM => optM.qty === 1)) return optGM
      const optionsSortedMinValue = optGM.options.sort((a, b) => a.price.amount - b.price.amount)
      optionsSortedMinValue[0].qty = 1
      return {
        ...optGM,
        options: optionsSortedMinValue,
      }
    })

    clone.optionsGroups = prodOptGroupsTouched
    extras = getProductExtrasAmount(clone)
  }
  const base = getProductBaseAmount(product)
  return base + extras
}

export const getProductTotalUnitsAmount = (product: ProductEcommerce, unit: number) => {
  return getProductTotalAmount(product) * unit
}

/* Product Extras resume */
export const getProductCustomizationResume = (
  optGroups: OptionsGroupsMain[] | OptionsGroupsChild[],
  joinChar = ', '
) => {
  const getOptionsCustomization = (optG: OptionsGroupsMain | OptionsGroupsChild) => {
    const isExtraGroup = optG.type === OptionsGroupsType.CANADD
    const isComponentGroup = optG.type === OptionsGroupsType.COMPONENT
    const isCondimentGroup = optG.type === OptionsGroupsType.CONDIMENT
    const prefix = isExtraGroup ? `${'{productDetail.customization.extra}'} ` : ''

    // Only loop trough selected options not all in CHOICE optionsGroup
    const filteredOptions = getFilteredOptionsOnlySelected(optG)

    const optionTexts = filteredOptions
      .map(curr => {
        let acc = ''
        const isCheckboxOption = curr.max === 1 && curr.min === 0

        if (isComponentGroup) {
          // is a max 1 option deselected
          if (isCheckboxOption && curr.qty < curr.initialQty) {
            acc += `<del>${curr.name}</del>`
          } else {
            // regular component
            const isDiffering = curr.qty !== curr.initialQty ? curr.qty - curr.initialQty : 0
            const differingQty = curr.qty - curr.initialQty
            if (isDiffering) {
              if (differingQty > 0) acc += `${differingQty} ${curr.name}`
              else {
                const qty = Math.abs(differingQty)
                acc += `<del>${qty} ${curr.name}</del>`
              }
            }
          }
        } else if (isExtraGroup || isCondimentGroup) {
          const differingQty = curr.qty > curr.initialQty ? curr.qty - curr.initialQty : 0
          if (differingQty) acc += `${differingQty} ${curr.name}`
        }

        // has more than 4º level (curr.optionsGroups.options[...])
        if ('optionsGroups' in curr && curr.optionsGroups.length) {
          acc += getProductCustomizationResume(curr.optionsGroups)
        }
        return acc
      })
      .filter(t => t)

    const text = optionTexts.join(joinChar)
    return text ? `${prefix} ${text}` : ''
  }

  const optGroupsTexts = optGroups
    .map(optGM => {
      const optionsText = getOptionsCustomization(optGM)
      return optionsText
    })
    .filter(t => t)
  return optGroupsTexts.join(joinChar)
}

export const getCartCustomization = (optGroups: OptionsGroupsMain[]) => {
  const texts = []
  for (const optGMain of optGroups) {
    let customizationOptGroup = ''
    let groupName = ''
    let isOptionSelected = false
    const isChoiceGroup = optGMain.type === OptionsGroupsType.CHOICE
    if (isChoiceGroup) {
      const optionSelected = optGMain.options.find(opt => opt.qty === 1)
      if (optionSelected) {
        isOptionSelected = true
        groupName = optionSelected?.name
        customizationOptGroup = getProductCustomizationResume(optionSelected.optionsGroups, ' ')
      }
    } else {
      customizationOptGroup = getProductCustomizationResume([optGMain], ' ')
      groupName = optGMain.title
    }

    if (isChoiceGroup && isOptionSelected && !customizationOptGroup) texts.push(`${groupName}`)
    else if (customizationOptGroup)
      texts.push(`${isOptionSelected ? groupName : ''} ${customizationOptGroup}`)
  }
  return texts.join(' - ')
}

/* Cart helpers */
export const getFilteredOptionsOnlySelected = (
  optGroup: OptionsGroupsMain | OptionsGroupsChild
): OptionMain[] | OptionChild[] =>
  optGroup.type === OptionsGroupsType.CHOICE
    ? optGroup.options.filter(opt => opt.qty === 1)
    : optGroup.options

export const getFilteredOptionsOnlySelectedMain = (optGroup: OptionsGroupsMain): OptionMain[] =>
  getFilteredOptionsOnlySelected(optGroup) as OptionMain[]

export const getFilteredOptionsOnlySelectedChild = (optGroup: OptionsGroupsChild): OptionChild[] =>
  getFilteredOptionsOnlySelected(optGroup) as OptionChild[]

export const productToDTO = (product: ProductEcommerce, unit: number) => {
  const productDTO: ProductDTO = {
    id: product.id,
    identifier: product.identifier,
    unit,
    optionsGroups: product.optionsGroups.map(optGM => {
      const firstLevelFilteredOptions = getFilteredOptionsOnlySelectedMain(optGM)
      return {
        identifier: optGM.identifier,
        title: optGM.title, // only for debug
        type: optGM.type,
        options: firstLevelFilteredOptions.map(optM => ({
          identifier: optM.identifier,
          name: optM.name, // only for debug
          unit: optM.qty,
          optionsGroups: optM.optionsGroups.map(optGChild => {
            const thirdLevelFilteredOptions = getFilteredOptionsOnlySelectedChild(optGChild)
            return {
              identifier: optGChild.identifier,
              title: optGChild.title, // only for debug
              type: optGChild.type,
              options: thirdLevelFilteredOptions.map(optChild => ({
                identifier: optChild.identifier,
                name: optChild.name, // only for debug
                unit: optChild.qty,
              })),
            }
          }),
        })),
      }
    }),
    productType: product.productType || ProductTypes.REGULAR,
    promotions: product.promotions,
  }
  return productDTO
}

export const findDeepOptionGroup = (
  optionGroupIdentifier: string,
  collection: OptionsGroupsMain[]
) => {
  const foundedOptionGroup = collection.find(
    (optGM: OptionsGroupsMain) => optGM.identifier === optionGroupIdentifier
  )
  if (foundedOptionGroup) return foundedOptionGroup
  const choiceOptionsGroups = collection.filter(og => og.type === OptionsGroupsType.CHOICE)
  for (const optGMain of choiceOptionsGroups) {
    const options = getFilteredOptionsOnlySelectedMain(optGMain)
    // undefined for not founded option inside a optG present on cart but not on view (productDetail v2)
    const optionSelected = options?.[0] || undefined
    if (optionSelected) {
      const foundedThirdLevelOptionGroup = optionSelected.optionsGroups.find(
        (optGM: OptionsGroupsChild) => optGM.identifier === optionGroupIdentifier
      )
      if (foundedThirdLevelOptionGroup) return foundedThirdLevelOptionGroup
    }
  }
}

export const getAvailableOptionsGroups = (optionsGroups: OptionsGroupsMain[]) => {
  const availableOptionsGroups = cloneDeep(optionsGroups)
  // autoselect CHOICE group having only one option available
  const mappedOptionsGroups = availableOptionsGroups.map(optGM => {
    if (optGM.type === OptionsGroupsType.CHOICE && optGM.options.length === 1) {
      const selectedOption = { ...optGM.options[0], qty: 1 }
      return {
        ...optGM,
        options: [selectedOption],
      }
    }
    return optGM
  })
  return mappedOptionsGroups
}

interface OptionsGroupedByOptionGroup {
  [key: string]: OptionDTO[]
}

/**
 * Blend optionsGroups from ProductDTO with optionsGroups of base product and convert them to a OptionsGroupsMain[]
 * @param optGsDTO OptionsGroupsMainDTO[]
 * @param optGsProductBase OptionsGroupsMain[]
 * @returns  OptionsGroupsMain[]
 */
export const blendOptionsGroups = (
  optGsDTO: OptionsGroupsMainDTO[],
  optGsProductBase: OptionsGroupsMain[]
): OptionsGroupsMain[] => {
  // All productDetail options inside optionsGroups of DTO, groupedBy OptGroup.identifier
  const reducedAllProductOptionsDTO: OptionsGroupedByOptionGroup = optGsDTO.reduce((prev, curr) => {
    const optGroupIdentifier = curr.identifier // id I guess is deprecated not has be on model
    prev[optGroupIdentifier] = [...curr.options]
    return prev
  }, {} as OptionsGroupedByOptionGroup)

  // get option in DTO from option identifier
  const getOptionFromDTO = (optGroupIndentifier: string, optIdentifier: string) => {
    const options = reducedAllProductOptionsDTO[optGroupIndentifier] || []
    return options.find(optGsDTOMain => optGsDTOMain.identifier === optIdentifier)
  }

  // Loop through optionsGroups in ProductBase
  return optGsProductBase.map((groupPrd, _groupPrdIndex) => ({
    ...groupPrd,
    options: groupPrd.options.map((optPrd, _optPrdIndex) => {
      const optFromDTO = getOptionFromDTO(groupPrd.identifier, optPrd.identifier)
      return {
        ...optPrd,
        qty: optFromDTO ? optFromDTO.unit : optPrd.qty,
        // Inside top level options map optionsGroups from $optionFromProd
        optionsGroups: optPrd.optionsGroups.map((optGsProdChild, _indexOptsChild) => {
          // PERSONALIZATION -  find 3º level optionsGroup from DTO that matches that optionsGroups.identifier from productDetail
          const thirdLevelOptGrpDTO = optFromDTO?.optionsGroups?.find(
            optGsChild => optGsChild.identifier === optGsProdChild.identifier
          )

          // NO PERSONALIZATION -  find 3º level optionsGroup from productBase
          const thirdLevelOptGrpPrd = optPrd.optionsGroups?.find(
            optGsChild => optGsChild.identifier === optGsProdChild.identifier
          )

          return {
            ...optGsProdChild,
            options: optGsProdChild.options.map(optsProdChild => {
              // PERSONALIZATION - find option from DTO that matches this $optsProdChild we are looping
              const option = thirdLevelOptGrpDTO?.options?.find(
                opt => opt.identifier === optsProdChild.identifier
              )
              // NO PERSONALIZATION - find option from productBase that matches this $optsProdChild we are looping inside $optGs
              const optionPrd = thirdLevelOptGrpPrd?.options?.find(
                opt => opt.identifier === optsProdChild.identifier
              )

              // qty of option in third level optionGroups
              const qty = option ? option?.unit || 0 : optionPrd?.qty || 0

              return {
                ...optsProdChild,
                qty,
              }
            }),
          }
        }),
      }
    }),
  }))
}

export const parseProductToProductDB = (product: ProductEcommerce): ProductDB => {
  const createPriceDB = (price: ProductPrice): ProductDBPrice => ({
    amount: price.amount,
    tax: price.taxConfig,
  })

  // change identifier in optionsGroup,options to id
  const optsGroupMapped: OptionsGroupsDBMain[] = product.optionsGroups.map(optGMain => ({
    ...optGMain,
    id: optGMain.identifier,
    options: optGMain.options.map(optsMain => ({
      ...optsMain,
      price: createPriceDB(optsMain.price),
      id: optsMain.identifier,
      initialQty: undefined,
      optionsGroups: optsMain.optionsGroups.map(optGChild => ({
        ...optGChild,
        id: optGChild.identifier,
        options: optGChild.options.map(optsChild => ({
          ...optsChild,
          price: createPriceDB(optsChild.price),
          id: optsChild.identifier,
          initialQty: undefined,
        })),
      })),
    })),
  }))

  const productDB: ProductDB = {
    ...product,
    _id: product.id,
    id: product.identifier,
    available: !!product.active,
    price: createPriceDB(product.price),
    originalPrice: product.originalPrice ? createPriceDB(product.originalPrice) : undefined,
    unifiedPrice: product.unifiedPrice ? createPriceDB(product.unifiedPrice) : undefined,
    optionsGroups: optsGroupMapped,
  }
  return productDB
}

/* Product Schedule utils */
/**
 * Helper to see if any areas of the product are enabled to start a order
 * @param areas: string[]
 * @param restaurantState: OpenServicesState
 * @returns boolean
 */
export const isProductEnabledByAreas = (
  productAreas: string[],
  restaurantState: OpenServicesState
): boolean => {
  const availableAreas: StoreRestaurantAreasUnion[] = productAreas.map(
    area => area.toLowerCase() as StoreRestaurantAreasUnion
  )
  return availableAreas.some(
    area =>
      restaurantState[area] === RestaurantScheduleTypes.OPEN ||
      restaurantState[area] === RestaurantScheduleTypes.NEAR_TO_CLOSE
  )
}

export const getOrderTotals = (order: StoreOrder) => {
  const price = order.price
  return {
    total: price.total,
    subtotal: price.subTotal,
    tax: price.tax || 0,
    tip: price.extras.tip || 0,
    shipping: price.extras.delivery || 0,
    serviceFee: price.extras.serviceFee || 0,
    discount: price.extras.discount || 0,
  }
}

export const foundProductByIdentifier = (
  categories: Category[],
  identifier: string
): { category: Category; product: ProductEcommerceLite } | undefined => {
  for (const cat of categories) {
    const foundedProd = cat.products.find(p => p.identifier === identifier)
    if (foundedProd) return { category: cat, product: foundedProd }
  }
  return undefined
}

/* Autoselect 1º level and 3º level mandatory optGroups (for promoted products added to cart) */
export const autoSelectMandatoryOptionsGroups = (optsGs: OptionsGroupsMain[]) => {
  // Auto select in product detail response DTO
  const clonedOptionGroups = cloneDeep(optsGs)
  const optionsGroups = clonedOptionGroups.map(optGM => {
    if (optGM.type === OptionsGroupsType.CHOICE) {
      const options = [...optGM.options]
      const optionSelected = options[0]
      optionSelected.qty = 1

      // 3º level mandatory
      const thirdLevelChoice = optionSelected.optionsGroups.find(
        optGChild => optGChild.type === OptionsGroupsType.CHOICE
      )
      if (thirdLevelChoice && thirdLevelChoice.options.length) {
        const thirdLevelOptionSelected = thirdLevelChoice.options[0]
        thirdLevelOptionSelected.qty = 1
      }

      return {
        ...optGM,
        options,
      }
    }
    return optGM
  })

  return optionsGroups
}
