import { StoreService } from '../store.service'
import { CartInputDto, cartDto } from './cart.dtos'
import {
  catalogLiteDto,
  // productDetailSchema,
  productEcommerceDto,
  suggestiveDto,
  Category,
} from './catalog.dtos'
import { RestaurantAreaType } from '~/lib/interfaces/restaurant'

class CatalogService extends StoreService {
  constructor(country: string) {
    super('catalog', country)
  }

  async getProductDetail({
    countryCode,
    area,
    restaurantId,
    categoryId,
    productId,
  }: {
    countryCode: string
    area: RestaurantAreaType
    restaurantId: string
    categoryId: string
    productId: string
  }) {
    const res = await this.request(
      {
        url: `/catalog/product/${productId}/detail`,
        params: {
          area,
          restaurant: restaurantId,
          category: categoryId || undefined,
          outdaypart: true,
        },
        headers: { 'X-App-Country': countryCode },
      },
      productEcommerceDto
    )

    return res
  }

  async getFeaturedCategories({
    countryCode,
    restaurant,
    area,
  }: {
    countryCode: string
    restaurant: string
    area: RestaurantAreaType
  }) {
    const requestKey = `featured-${area}-${restaurant}`
    const promise = async () =>
      await this.request(
        {
          url: `/catalog/featured/lite`,
          params: { restaurant, area, outdaypart: true },
          headers: { 'X-App-Country': countryCode, 'catalog-version': 'v2' },
        },

        catalogLiteDto
      )
    return await useApiSessionCache<Category[]>(requestKey, promise, {
      secondsToRevalidate: 60,
    })
  }

  async getStoreCategories({
    countryCode,
    restaurant,
    area,
  }: {
    countryCode: string
    restaurant: string
    area: RestaurantAreaType
  }) {
    const requestKey = `categories-${area}-${restaurant}`
    const promise = async () =>
      await this.request(
        {
          url: `/catalog/menu/lite`,
          params: { restaurant, area, outdaypart: true },
          headers: { 'X-App-Country': countryCode, 'catalog-version': 'v2' },
        },
        catalogLiteDto
      )
    return await useApiSessionCache<Category[]>(requestKey, promise, {
      secondsToRevalidate: 60,
    })
  }

  /* Cart */
  async fetchCart({
    countryCode,
    token,
    body,
  }: {
    countryCode: string
    token: string
    body: CartInputDto
  }) {
    const res = await this.request(
      {
        url: `/orders/cart`,
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-App-Country': countryCode,
          'cart-version': 'v2',
        },
        data: body,
      },
      cartDto
    )
    return res
  }

  async fetchSuggestive({
    token,
    countryCode,
    products,
  }: {
    token: string
    countryCode: string
    products: string
  }) {
    const res = await this.request(
      {
        url: `/catalog/crossseling`,
        method: 'GET',
        headers: {
          'X-App-Country': countryCode,
          'Authorization': `Bearer ${token}`,
          'cross-selling-version': 'v3',
        },
        params: {
          productsList: products,
        },
      },
      suggestiveDto
    )
    return res
  }
}

export const catalogService = (country: string) => new CatalogService(country)
