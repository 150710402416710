import { z } from 'zod'

/* Checkout session */
const yunoPriceDto = z.object({
  price: z.number(),
})

export type YunoPriceDTO = z.infer<typeof yunoPriceDto>

const yunoCheckoutSessionInput = yunoPriceDto.extend({
  orderId: z.string(),
  pathCallback: z.string(),
})

export type YunoCheckoutSessionInput = z.infer<typeof yunoCheckoutSessionInput>

export const yunoCheckoutSessionDto = z.object({
  checkoutSession: z.string(),
})

/* YunoPaymentMethod */
const yunoMethodCheckout = z.object({
  session: z.string(),
  sdkRequiredAction: z.boolean(),
})

const yunoMethodCardData = z.object({
  lfd: z.string(),
  brand: z.string(),
  issuer: z.string().nullable(),
  type: z.string(),
  expiredAt: z.string(),
})

const yunoMethod = z.object({
  name: z.string(),
  description: z.string(),
  type: z.string(),
  category: z.string(),
  icon: z.string(),
  vaultedToken: z.string().nullable(),
  lastSuccessfullyUsed: z.boolean().nullable(),
  checkout: yunoMethodCheckout,
  cardData: yunoMethodCardData.optional(),
  expired: z.boolean().optional().default(false),
  sendPurchaseEventByApps: z.boolean(),
})

export type YunoMethod = z.infer<typeof yunoMethod>

const yunoCustomerMethod = yunoMethod
  .extend({
    status: z.string(),
  })
  .omit({ checkout: true })

export type YunoCustomerMethod = z.infer<typeof yunoCustomerMethod>

/* Payment */
const yunoStartPaymentInput = z.object({
  token: z.object({
    tokenOTT: z.string(),
  }),
  orderId: z.string(),
  checkOutSession: z.string(),
})

export type YunoStartPaymentInput = z.infer<typeof yunoStartPaymentInput>

/* DTOs */
export const yunoMethodsCheckoutDto = z.object({
  paymentMethods: z.array(yunoMethod),
})

export const yunoMethodsCustomerDto = z.object({
  paymentMethods: z.array(yunoCustomerMethod),
})

export const yunoInitEnrollmentDto = z.object({
  customerSession: z.string(),
  status: z.string(), // YunoEnrollmentStatusType
})

export const yunoUnenrollDto = z.any()

const yunoStartPaymentCheckoutDto = z.object({
  session: z.string(),
  sdkRequiredAction: z.boolean(),
})

export const yunoStartPaymentDto = z.object({
  status: z.string(), // YunoPaymentStatusType
  subStatus: z.string(), // YunoPaymentSubStatusType
  merchantOrderId: z.string(),
  checkout: yunoStartPaymentCheckoutDto,
  statusOrder: z.string(),
})

export const SpecialPaymentType = {
  IN_PERSON: 'inperson',
} as const
