/* eslint-disable camelcase */
import { createI18n } from 'vue-i18n'
import { type DeepPartial } from '~/types/typeUtils'
import es from '~/locales/es'
import pt from '~/locales/pt'
import en from '~/locales/en'
import fr from '~/locales/fr'
import es_ar from '~/locales/variants/es_ar'
import es_uy from '~/locales/variants/es_uy'
import es_pe from '~/locales/variants/es_pe'
import es_ec from '~/locales/variants/es_ec'
import { Locales } from '~/lib/services/content/content.configuaration.dtos'

/**
 * The `es` language needs to have all the available keys,
 * the rest can be partial
 *
 * To add a new locale:
 *  - Create the locale/{lang}.ts
 *  - Declare the {lang} in `type Locales`
 *  - Import the file and add to `messages` object
 */

export type Messages = typeof es

export default defineNuxtPlugin(({ vueApp }) => {
  const i18n = createI18n<{ message: DeepPartial<Messages> }, Locales>({
    legacy: false,
    locale: 'es',
    fallbackLocale: 'es',
    messages: {
      es_ar,
      es_uy,
      es_pe,
      es_ec,
      es,
      pt,
      en,
      fr,
    },
  })

  vueApp.use(i18n)
})
